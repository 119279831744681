import { ToastService } from '../../../../services/toast/toast.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { SignupService } from 'src/app/services/signup/signup.service';
import Utils, { GetHelper } from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-unified-company-complementary-bond',
  templateUrl: './signup-unified-company-complementary-bond.component.html',
  styleUrls: ['./signup-unified-company-complementary-bond.component.scss'],
})
export class SignupUnifiedCompanyComplementaryBondComponent
  implements OnInit, OnDestroy
{
  form = this.formBuilder.group({
    fund: [null, [Validators.required]],
    co_obligation: [false, []],
  });

  showForm = true;
  isShareholder = false;

  columns: TableColumn<CompanyBondResponse>[] = [];

  data: any[] = [];

  removeModal = false;

  loading = false;

  sending = false;

  event: any;

  needInviteFund = false;

  fundOptions: SelectItem[] = [];

  haveCoobligation = false;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private api: ApiService,
    private toast: ToastService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.getFunds();
    this.getDataAndFillForm();
    this.isShareholder = this.router.url.includes('shareholder');

    this.columns = [
      {
        name: 'Fundo de interesse',
        cell: (row) => String(row.fund.name),
      },

      {
        name: this.isShareholder ? '' : 'Possuí coobrigação',
        cell: (row) =>
          this.isShareholder ? '' : row.co_obligation ? 'Sim' : 'Não',
      },
    ];

    this.event = this.signupService.sendComplementaryCompanyData.subscribe(
      (value) => {
        if (value === 'complementaryBond') {
          const isFund =
            this.authService.user.active_register.register.role.slug.includes(
              '-FI'
            );

          if (isFund) {
            this.signupService.setComplementaryCompanyStep(
              'complementaryAdmin'
            );
            this.signupService.changeCompanyStepEvent.emit('admin');
          } else {
            this.signupService.setComplementaryCompanyStep(
              'complementaryRepresentatives'
            );
            this.signupService.changeCompanyStepEvent.emit('representatives');
          }
        }
      }
    );

    this.signupService.fillFormEvent.subscribe((value) => {
      this.form.patchValue({
        fund: this.fundOptions[0].value,
      });

      //   this.sendData();
    });
  }

  getDisabled() {
    if (!this.showForm) {
      return false;
    }

    return this.form.invalid;
  }
  async triggerForm() {
    if (this.showForm) {
      await this.sendData();
      this.showForm = false;
    } else {
      this.showForm = true;
      this.signupService.setCompanyFormStatus('complementaryBond', false);
    }
  }

  setInvitedFund() {
    const user = this.authService.getUser();
    this.needInviteFund = false;

    const fundId = user.active_register.register.interest_fund;

    const hasInvitedFundId =
      user.active_register.register.invite &&
      user.active_register.register.invite.data.fund;

    if (hasInvitedFundId || fundId) {
      this.needInviteFund = true;

      const alreadyHaveFund = this.data.some((item) =>
        item.fund.id === hasInvitedFundId
          ? user.active_register.register.invite.data.fund
          : fundId
      );

      if (alreadyHaveFund) {
        this.needInviteFund = false;
        return;
      }

      this.form.patchValue({
        fund: hasInvitedFundId
          ? user.active_register.register.invite.data.fund
          : fundId,
      });
    }
  }

  handleCancel() {
    this.form.reset();
    this.form.controls.co_obligation.setValue(false);
    this.showForm = false;

    this.signupService.setCompanyFormStatus(
      'complementaryBond',
      this.data.length > 0
    );
  }

  async removeInterest(index: number) {
    try {
      const finded = this.data[index];

      const { message } = await this.api.delete({
        route: `api/registration/assignor_fund_interest/${finded.id}/`,
        token: true,
      });

      this.toast.show('info', 'Sucesso', message);

      this.getDataAndFillForm();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  async getDataAndFillForm() {
    this.loading = true;
    try {
      const data = await this.api.get({
        route: 'api/registration/assignor_fund_interest/',
        token: true,
      });

      this.data = data;

      this.haveCoobligation = data.some((item) => item.co_obligation);

      this.showForm = this.data.length === 0;

      this.signupService.setCompanyFormStatus(
        'complementaryBond',
        this.data.length > 0
      );

      this.setInvitedFund();
      this.checkRepresentativeValidation();
    } catch (error) {
      console.log('error', error);
    }
    this.loading = false;
  }

  async checkRepresentativeValidation() {
    const { data } = await this.api.get<ApiResponse<RepresentativeResponse[]>>({
      route: 'api/registration/representative/',
      token: true,
    });

    const representatives_data = data;

    let blocked = false;

    if (this.haveCoobligation) {
      const isBlocked = representatives_data.some((item) => {
        const isGuarantor = item.info && item.info.representative.guarantor;
        const isSolidarity =
          item.info && item.info.representative.solidarity_debtor;
        const needPartner = !item.info.representative.spousal_consent;

        if ((isGuarantor || isSolidarity) && needPartner) {
          if (item.marital_id === null) {
            return true;
          }
        }

        return false;
      });

      blocked = isBlocked;
    }

    this.signupService.setCompanyFormStatus(
      'complementaryRepresentatives',
      blocked ?? representatives_data.length > 0
    );
  }

  async sendData() {
    this.sending = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload: any = {
        fund: valuesHelper.get('fund'),
        co_obligation: valuesHelper.get('co_obligation'),
      };

      const response = await this.api.post<ApiResponse<CompanyBondResponse>>({
        route: `api/registration/assignor_fund_interest/?id=${this.authService.user.active_register.register.id}`,
        token: true,
        body: payload,
      });

      this.toast.show('info', 'Sucesso', response.message);

      this.form.reset();
      this.form.controls.co_obligation.setValue(false);

      this.getDataAndFillForm();
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/fund',
        token: false,
      });

      const filterRepeatedValues = Utils.filterRepeatedValues(res.funds, 'id');

      const funds = filterRepeatedValues.map((fund: any) => {
        return {
          label: fund.name,
          value: fund.id,
        };
      });

      this.fundOptions = funds;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar fundos de interesse');
    }
  }
}
