<div class="pagination-container flex-column align-items-end"
    [class.d-none]="!show" [class.dash-pagination]="dashPagination">
    <div class="pagination-buttons" *ngIf="pages.length > 1">
        <button class="pagination-button prev my-1" (click)="prevPage()"
            [class.disabled]="currentPage === 1">
            Anterior
        </button>


        <ng-container *ngIf="showFirstPage">
            <button class="pagination-button my-1" (click)="changePage(1)">
                1
            </button>

            <div *ngIf="currentPage >= 6">
                ...
            </div>
        </ng-container>

        <ng-container *ngFor="let page of visiblePages; let i = index">
            <button class="pagination-button my-1"
                [class.active]="page === currentPage"
                (click)="changePage(page)">
                {{page}}
            </button>
        </ng-container>

        <ng-container *ngIf="showLastPage">
            <div *ngIf="currentPage <= lastPage - 5">
                ...
            </div>

            <button class="pagination-button my-1"
                (click)="changePage(lastPage)">
                {{lastPage}}
            </button>
        </ng-container>

        <button class="pagination-button next my-1" (click)="nextPage()"
            [class.disabled]="currentPage === this.pages.length">
            Próximo
        </button>
    </div>
    <div *ngIf="pages.length === 1">

    </div>

    <span class="pagination-indicator mt-2"><b>{{offset}}</b> de
        <b>{{count}}</b></span>
</div>