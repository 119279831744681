import { Component, OnInit } from '@angular/core';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-providers',
  templateUrl: './dashboard-providers.component.html',
  styleUrls: ['./dashboard-providers.component.scss'],
})
export class DashboardProvidersComponent implements OnInit {
  providers: any[] = [];
  filteredProviders: any[] = [];

  loading = false;

  constructor(private api: ApiService, private toast: ToastService) {}

  ngOnInit(): void {
    this.getRequesteds();
  }

  async getRequesteds() {
    this.loading = true;
    try {
      const res = await this.api.get({
        route: 'api/approvals/user/pending?role=provider',
        token: true,
      });

      const providers: any[] = [];

      res.forEach((requested: any) => {
        const newRequested = { ...requested };
        newRequested.departments.sort((a: any, b: any) => {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });

        newRequested['department'] = newRequested.departments.find(
          (department: any) => department.order === newRequested.step
        );

        const roleSlug = newRequested.user.role.slug;

        if (roleSlug.includes('PROVIDER')) {
          providers.push(newRequested);
        }
      });

      this.providers = providers;
      this.filteredProviders = providers.slice(0, 10);
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  getInitials(user: any) {
    const full_name = user.first_name + ' ' + user.last_name;

    return Utils.getInitials(full_name);
  }

  getStatus(item: any) {
    if (item.completed) {
      if (item.is_approved) {
        return 'Aprovado internamente';
      } else {
        return 'Cadastro rejeitado';
      }
    } else {
      return item.department?.department.name ?? 'Departamento não definido';
    }
  }

  getStatusInfo(item: any) {
    if (item.completed) {
      if (item.is_approved) {
        if (item.joined_funds > 0) {
          return `Integrado a ${item.joined_funds} fundos.`;
        } else {
          return 'Aguardando integração ao fundo';
        }
      } else {
        return item.department.department.name;
      }
    } else {
      return `Aguardando aprovação (${item.step}/${item.departments.length})`;
    }
  }

  renderLink(applicable, id) {
    return [`/app/details/${applicable}/`, id];
  }
}
