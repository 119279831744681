<ng-container *ngIf="loading">
    <div class="row">
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<form [formGroup]="form" *ngIf="!loading">
    <ng-container *ngIf="showForm">
        <div class="row">
            <div class="col-12 col-lg-6">
                <app-select label="Fundo de interesse" [group]="form"
                    [readOnly]="needInviteFund" controlName="fund"
                    [items]="fundOptions">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-4" *ngIf="!isShareholder">
                <app-spacer [top]="4"></app-spacer>
                Possuí coobrigação com o fundo?
                <app-spacer [bottom]="16"></app-spacer>

                <div class="d-flex">
                    <app-radio
                        [isChecked]="form.controls.co_obligation.value === true"
                        formControlName="co_obligation" [value]="true">
                        Sim
                    </app-radio>
                    <app-spacer [right]="32"></app-spacer>
                    <app-radio
                        [isChecked]="form.controls.co_obligation.value === false"
                        formControlName="co_obligation" [value]="false">
                        Não
                    </app-radio>
                </div>
                <!-- <app-radio
                    [isChecked]="form.controls[option.formControl].value === suboption.value"
                    [formControlName]="option.formControl"
                    [value]="suboption.value">
                    <div class="flex-1 d-flex flex-column">
                        <span
                            class="basic-content-description"><strong>{{i+1}})</strong>
                            {{suboption.label}}</span>
                    </div>
                </app-radio> -->


                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6 d-flex">
                <app-button (onClick)="triggerForm()" [loading]="sending"
                    [disabled]="getDisabled()" class="add-button mr-3"
                    [outline]="true">
                    <i class="fas fa-plus-circle mr-2"></i>
                    Salvar interesse
                </app-button>

                <app-button *ngIf="data.length > 0" (onClick)="handleCancel()"
                    class="add-button" [outline]="true">
                    Cancelar
                </app-button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!showForm">
        <div class="row">
            <div class="col-12">
                <app-table [columns]="columns" [data]="data"
                    (onRemove)="removeInterest($event)"></app-table>
                <app-spacer [bottom]="32"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6">

                <app-button *ngIf="data.length> 0" (onClick)="triggerForm()"
                    [disabled]="getDisabled()" class="add-button"
                    [outline]="true">
                    <i class="fas fa-plus-circle mr-2"></i>
                    Adicionar outro fundo
                </app-button>
            </div>
        </div>
    </ng-container>
</form>