import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import Utils from 'src/app/helpers/utils';

import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-approval',
  templateUrl: './dashboard-approval.component.html',
  styleUrls: ['./dashboard-approval.component.scss'],
})
export class DashboardApprovalComponent implements OnInit {
  @ViewChild('toggleOptions') toggleOptions: ElementRef;

  reproveForm = this.formBuilder.group({
    description: [null, [Validators.required]],
  });

  tabs: Tab[] = [];
  previousUrl: string = '';

  status = [
    {
      department: 'Compliance',
      approved_datetime: '23/10/2021 às 16:32',
      completed: true,
    },
    {
      department: 'Cadastro',
      approved_datetime: null,
      completed: true,
    },
    {
      department: 'Diretoria',
      approved_datetime: null,
      completed: false,
    },
  ];

  permissionObj: PermissionValues = {
    canChange: false,
  };

  bonds: BondValues[] = [];

  tabIndex = 0;
  activedTab = 'dados-complementares';

  approval: UserApprovalResponse = null;
  allApprovals: VersionProps[];

  cards: any = undefined;

  role = '';

  applicableRole = 'ADM';

  departmentAttemps = {};

  isSimplified = false;
  isFund = false;
  self_view = false;
  signing = true;
  warningModal = false;
  approve = false;
  resetApproval = false;
  loading = true;
  showOptions = false;
  showCtaOptions = false;
  isApprover = false;
  confirmEditModal = true;

  validation_token = undefined;

  sign_view = undefined;

  register_id = null;
  homologated = false;
  hasDraft = false;

  partners: PartnerResponse[] = [];
  formattedData: PartnerResponse[] = [];

  provider = {
    admin: null,
    manager: null,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private api: ApiService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    protected sanitizer: DomSanitizer,
    private maskPipe: MaskPipe,
    private storageService: StorageService,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.toggleOptions?.nativeElement.contains(e.target)) {
        this.showOptions = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.authService.reset();
  }

  ngOnInit(): void {
    this.register_id = this.route.snapshot.params['id'];
    let range = this.router.url.split('/').length;
    this.role = this.router.url.split('/')[range - 2];

    if (!this.authService.verifyPermission([`de_can_view_${this.role}`])) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    /* get query params */
    this.route.queryParams.subscribe((params) => {
      if (params.sign_view) {
        this.sign_view = params.sign_view;

        Swal.fire({
          title: '<b>Valide os dados</b>',
          html: 'Verifique se os dados estão corretos e clique em <b>"Assinar como representante"</b> para prosseguir com a aprovação.',
          icon: 'info',
          confirmButtonText: 'Validar dados',
          confirmButtonColor: '#3085d6',
        });
      }
    });

    if (this.authService.token === undefined) {
      this.router.navigateByUrl(
        `/login/?role=${this.role}&id=${this.route.snapshot.params.id}`
      );
    }

    this.route.params.subscribe((params) => {
      this.loading = true;
      this.signing = true;
      this.tabIndex = 0;
      this.activedTab = 'dados-complementares';
      this.authService.reset();

      this.self_view = this.authService.user.active_register
        ? this.authService.user.active_register.register.id === params.id
        : false;

      this.authService.reloadComplementaryDataEvent.emit(params);

      this.getApproval();
    });

    this.isApprover =
      this.authService.user.is_superuser ||
      this.authService.user.active_register.register.is_approver;

    this.setPermissions();
  }

  async getApproval(revision: number = null, version: number = null) {
    if (this.authService.user) {
      const user_role = this.authService.user.active_register
        ? this.authService.user.active_register.register.role
        : this.authService.user.role;

      this.applicableRole = user_role.slug.split('-')[0];
    } else {
      this.applicableRole = 'ASSIGNOR';
    }

    try {
      const req_params = {
        register: this.route.snapshot.params.id,
      };

      if (revision) {
        req_params['revision'] = revision;
      }

      if (version) {
        req_params['version'] = version;
      }

      if (this.validation_token) {
        req_params['validation_token'] = this.validation_token;
      }

      const data = await this.api.get<UserApprovalResponse[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      const sortByHighest = data[0].versions.sort(
        (a, b) => b.version_number - a.version_number
      );

      this.allApprovals = sortByHighest;

      this.handleSetTabs(data[0]);

      this.loading = false;
      this.signing = false;

      return;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar dados: ' + error);
    }

    this.loading = false;
    this.signing = false;
  }

  async handleSetTabs(approval) {
    this.tabs = [];

    //   Arrumar a lógica de tabs

    if (this.applicableRole === 'ADM') {
      this.tabs.push({ label: 'Minutas', value: 'minutas' });
      this.tabs.push({ label: 'Contratos', value: 'contratos' });
    }

    this.isSimplified = approval?.register?.is_simplified;

    if (this.isSimplified) {
      this.tabs = [
        { label: 'Dados complementares', value: 'dados-complementares' },
        { label: 'Anexos', value: 'anexos' },
        ...this.tabs,
      ];

      this.loading = false;
      this.signing = false;

      return;
    }

    this.approval = approval;

    this.isFund = this.approval?.register?.role?.slug.includes('PJ-FI');

    if (this.isFund) {
      this.tabs = [
        { label: 'Dados complementares', value: 'dados-complementares' },
        { label: 'Prestadores de serviço', value: 'prestadores' },
        { label: 'Anexos', value: 'anexos' },
        { label: 'Histórico', value: 'historico' },
        { label: 'Assinaturas', value: 'assinaturas' },
        { label: 'Fundos vinculados', value: 'vinculos' },
        ...this.tabs,
      ];

      this.getFundsLinks();
      this.loading = false;
      this.signing = false;

      return;
    }

    this.tabs = [
      { label: 'Dados complementares', value: 'dados-complementares' },
      { label: 'Profissional', value: 'profissional' },
      { label: 'Representantes', value: 'representantes' },
      { label: 'Grupos e poderes', value: 'grupos-e-poderes' },
      { label: 'Anexos', value: 'anexos' },
      { label: 'Histórico', value: 'historico' },
      { label: 'Assinaturas', value: 'assinaturas' },
      { label: 'Fundos vinculados', value: 'vinculos' },
      ...this.tabs,
    ];

    if (this.role === 'shareholder' && this.approval?.register?.type === 'PF') {
      this.tabs = [
        { label: 'Dados complementares', value: 'dados-complementares' },
        { label: 'Profissional', value: 'profissional' },
        {
          label: 'Rendimentos e patrimônios',
          value: 'rendimentos-patrimonios',
        },
        { label: 'Representantes', value: 'representantes' },
        {
          label: 'Grupos e poderes',
          value: 'grupos-e-poderes',
        },
        { label: 'Anexos', value: 'anexos' },
        { label: 'Histórico', value: 'historico' },
        { label: 'Fundos vinculados', value: 'vinculos' },
      ];
    }

    if (this.approval?.register?.type === 'PJ') {
      this.getPartners();

      let filters = ['profissional'];

      if (this.role !== 'shareholder') {
        filters.push('rendimentos-patrimonios');

        this.tabs.splice(1, 0, {
          label: 'Composição acionária',
          value: 'composicao-acionaria',
        });
      }

      this.tabs = this.tabs.filter((tab) => !filters.includes(tab.value));
    }

    this.getFundsLinks();
  }

  async getFundsLinks() {
    try {
      const data = await this.api.get({
        route: `api/registration/assignor_fund_interest`,
        params: {
          register: this.register_id,
        },
        token: true,
      });

      const newData = data.map((item: any) => {
        return {
          short_name: item.fund.fantasy_name,
          name: item.fund.name,
          anbima_classification_label: item.fund.anbima_classification,
          id: item.fund.id,
          document: item.fund.document,
          coobligation: item.co_obligation,
        };
      });

      this.bonds = [...newData];
      //   const fundIds = this.bonds.map((item) => item.id);

      //   if (this.isFund) this.getProviders(fundIds);
    } catch (error) {}
  }

  //   async getProviders(ids: number[]) {
  //     await Promise.all([
  //       this.getProvider(ids, 'admin'),
  //       this.getProvider(ids, 'manager'),
  //     ]);
  //   }

  //   async getProvider(ids: number[], role: string) {
  //     try {
  //       const res = await this.api.get({
  //         route: 'api/registration/register_admin',
  //         token: true,
  //         params: {
  //           funds: [ids],
  //           role: role,
  //         },
  //       });

  //       const data = res.map((item: any) => {
  //         return {
  //           label: item.name,
  //           value: item.id,
  //           extra: item.document,
  //           role: item.role,
  //         };
  //       });

  //       const selectedProvider = data.find(
  //         (item) => item.value === this.approval.register[role]
  //       );

  //       this.provider[role] = selectedProvider;
  //     } catch (error) {
  //       //   this.toast.show('error', 'Erro!', 'Erro ao carregar administradores');
  //     }
  //   }

  async getPartners() {
    this.register_id = this.route.snapshot.params.id;

    try {
      const data = await this.api.get<PartnerResponse[]>({
        route: `api/registration/partners/?id=${this.register_id}`,
        token: true,
      });

      this.partners = data;

      this.formatData();
    } catch (error) {}
  }

  formatData() {
    const firstObject: PartnerResponse = {
      id: undefined,
      percentage: 0,
      isOpen: true,
      pep: false,
      full_name: 'Grupo principal',
      document: '',
      parent: null,
      title: '',
      pep_bond: '',
      pep_relationship: false,
      pep_relationship_document: '',
      pep_relationship_name: '',
      shareholders: [],
      register: '',
      collapsed: false,
    };

    const rootObjects = [];

    const partnersData = this.approval.history.Partners || this.partners;

    partnersData.forEach((item) => {
      if (item.parent === null) {
        firstObject.shareholders.push(item);
      }

      if (item.document.length > 14) {
        rootObjects.push(item);
      }
    });

    const newData = partnersData.map((item) => {
      return {
        ...item,
        collapsed: false,
        isOpen: true,
      };
    });

    const newFormattedData = [firstObject, ...newData];

    this.formattedData = newFormattedData;
  }

  filteredApproval(attempt, approval) {
    return approval.filter((_approval) => _approval.attempt === attempt);
  }

  closeWarningModal() {
    this.warningModal = false;

    this.reproveForm.reset();
  }

  handleApproval(
    approve: boolean,
    reset?: boolean,
    homologated: boolean = false
  ) {
    this.approve = approve;
    this.warningModal = true;
    this.resetApproval = reset ?? false;
    this.homologated = homologated;
    this.showCtaOptions = false;
  }

  formatPhone(phone) {
    return this.maskPipe.transform(phone, '(00) 00000-0000');
  }

  async signApproval(approve: boolean, homologated: boolean = false) {
    this.signing = true;
    try {
      let payload: any = homologated
        ? {
            reason: '',
            signed: true,
            restart: false,
            department_approval_id: this.approval.current_department.id,
            approved: true,
            user_approval: {
              id: this.approval.id,
              approved: true,
              is_draft: false,
              completed: true,
              homologated: true,
              is_approved: true,
            },
            departments: this.approval.queue.departments.map(
              (department) => department.id
            ),
          }
        : {
            user_approval: this.approval.id,
            department_approval: this.approval.current_department.id,
            approved: approve,
            restart: this.resetApproval,
            reason: approve ? '' : this.reproveForm.value.description,
            signed: true,
          };

      const res = homologated
        ? await this.api.patch({
            route: `api/approvals/user_approval_signature/`,
            token: true,
            body: payload,
          })
        : await this.api.post({
            route: 'api/approvals/user_approval_signature/',
            token: true,
            body: payload,
          });

      if (approve) {
        this.toast.show(
          'info',
          'Tudo certo',
          `O cadastro foi ${
            homologated ? 'homologado' : 'aprovado'
          } com sucesso`
        );
      } else {
        this.toast.show(
          'info',
          `Cadastro ${this.resetApproval ? 'retornado' : 'reprovado'}`,
          'Uma notificação foi enviada ao responsável'
        );
      }

      this.warningModal = false;
      this.reproveForm.reset();

      if (this.resetApproval) {
        this.router.navigateByUrl('/app/registers');
      } else {
        await this.getApproval();
      }
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao aprovar inscrição');
      console.error(error);
    }
    this.signing = false;
  }

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;

    this.tabIndex = index;
  }

  getInitials(item: UserApprovalResponse) {
    const full_name =
      item.register.type.toUpperCase() === 'PF'
        ? item.register.person.full_name
        : item.register.company.corporate_name;

    return Utils.getInitials(full_name);
  }

  getMapAddress(approval: any) {
    if (approval.address) {
      const fullAddress = encodeURIComponent(
        `${approval.address.public_place}, ${approval.address.number}, ${approval.address.district}, ${approval.address.city} - ${approval.address.uf}`
      );
      const mapUrl = `http://maps.google.com/maps?q=${fullAddress}&z=15&output=embed`;

      return this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
    }

    return '';
  }

  calculateTotal(properties: any) {
    let total = 0;

    properties.forEach((property: any) => {
      total += Number(property.current_value);
    });

    return total;
  }

  getFileStatus(status: any) {
    switch (status) {
      case 1:
        return 'Arquivo Enviado';
      case 2:
        return 'Reprovado';
      case 3:
        return 'Aprovado';
      default:
        return 'Desconhecido';
    }
  }

  changeSituation(attachment: any, situation: number) {
    Swal.fire({
      title: situation === 2 ? 'Reprovar documento' : 'Aprovar documento',
      text:
        situation === 2
          ? 'Deseja reprovar este documento?'
          : 'Deseja aprovar este documento?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      input: 'textarea',
      inputPlaceholder: 'Motivo (opcional)',
      showLoaderOnConfirm: true,
      preConfirm: (description) => {
        const payload = {
          id: attachment.id,
          situation,
          description,
        };

        return this.api
          .put({
            route: 'api/approvals/approval_file',
            token: true,
            body: payload,
          })
          .then((res) => {
            Swal.fire('Sucesso', 'Documento aprovado com sucesso', 'success');
          })
          .catch(() => {
            Swal.fire('Erro', 'Erro ao aprovar documento', 'error');
          });
      },
    });
  }

  async redirectToEdit() {
    if (!this.self_view) {
      await this.handleGetAcessToAccount();
    }

    await this.api.patch({
      route: 'api/registration/trace_step/me/',
      body: { substep: 'general', step: 'register' },
      token: true,
    });

    const user = this.authService.getUser();

    this.router.navigate([
      `/signup/${
        this.approval.register.role.applicable
      }/${this.approval.register.type.toLowerCase()}`,
    ]);
  }

  getDisplayName(item: UserApprovalResponse) {
    if (item.register.type.toUpperCase() === 'PJ') {
      return item.register.company.corporate_name;
    }

    if (item.register.type.toUpperCase() === 'PF') {
      return item.register.person.full_name;
    }

    return '';
  }

  getDisplayProfileType(item: UserApprovalResponse) {
    if (item.register.is_simplified) {
      return 'Simplificado;';
    }

    if (item.register.role.applicable === 'provider') {
      return (
        item.register.agent.map((agent: any) => agent.name).join(', ') +
        ' • ' +
        item.register.role.name
      );
    }

    const profile_type_dict = {
      assignor: 'Cedente',
      provider: 'Prestador de serviços',
      shareholder: 'Cotista',
    };

    const agent =
      item.register.agent.length > 0
        ? item.register.agent.map((agent: any) => agent.name).join(', ')
        : '';

    return `
      ${profile_type_dict[item.register.role.applicable]} ${
      agent ? '- ' + agent : ''
    } • ${item.register.role.name}`;
  }

  getPhone(item: UserApprovalResponse) {
    if (item.register.type.toUpperCase() === 'PF') {
      return item.register.person.cellphone.number;
    }

    if (item.register.type.toUpperCase() === 'PJ') {
      return item.register.company.cellphone.number;
    }

    return '';
  }

  getSignerName(sign: UserApprovalSignature) {
    if (sign.register) {
      if (sign.register.type.toUpperCase() === 'PF') {
        return sign.register.person.full_name;
      }

      if (sign.register.type.toUpperCase() === 'PJ') {
        return sign.register.company.corporate_name;
      }
    } else {
      if (sign.user) {
        return sign.user.name;
      }
      return 'Usuário não encontrado';
    }
  }

  async handleRepresentativeSign() {
    if (!this.sign_view) {
      Swal.fire({
        title: 'Erro',
        text: 'Não é possível assinar o cadastro, pois sua solicitação de assinatura não foi encontrada.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });

      return;
    }

    await Swal.fire({
      title: 'Confirmação',
      text: 'É necessário que realize a assinatura digital para avançar para a etapa de aprovação',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Assinar',
      cancelButtonText: 'Cancelar',

      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.api
          .patch({
            route: `api/registration/company_sign/${this.sign_view}/`,
            body: { signed: true },
            token: true,
          })
          .then((res) => {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Assinado com sucesso',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });

            this.router.navigateByUrl(
              `/status/pj?company=${this.approval.register.company.id}`
            );
          })
          .catch((error) => {
            Swal.fire({
              title: 'Erro!',
              text: 'Erro ao assinar',
              icon: 'error',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
            });
          });
      },
    });
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_fund`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }

  renderLink() {
    return '';
  }

  toggleShowOptions() {
    this.showCtaOptions = !this.showCtaOptions;
  }

  async handleGetAcessToAccount() {
    const uudi = this.route.snapshot.params.id;
    let id: number;

    const foundedRegister = this.authService.accountsArr.find((account) => {
      return account.register.id === uudi;
    });

    if (!foundedRegister) {
      try {
        const res = await this.api.post({
          route: 'api/registration/request/register',
          body: { register_id: uudi },
          token: true,
        });

        id = res.data.id;
      } catch (error) {
        console.log(error);
        this.toast.show(
          'error',
          'Erro!',
          'Erro ao solicitar acesso a conta ativa'
        );
      }
    } else {
      id = foundedRegister.id;
    }

    await this.api.patch({
      route: `user/me/`,
      token: true,
      body: {
        active_register: id,
      },
    });

    const { data } = await this.api.get<ApiResponse<NewLoginResponse>>({
      route: 'user/me/',
      token: true,
    });

    this.authService.setUser(data);

    const permissionsArr = data.active_register.permissions_role.permissions;

    this.authService.permissions = permissionsArr;
    this.storageService.set('permissions', permissionsArr, true);
  }

  formatDateTime(date: string) {
    return new Date(date).toLocaleString('pt-BR');
  }

  async changeApproval(approval: VersionProps) {
    this.showOptions = false;

    this.loading = true;
    this.signing = true;
    this.activedTab = 'dados-complementares';
    this.tabIndex = 0;

    this.authService.reset();
    this.authService.approvalData.revision = approval.revision;
    this.authService.approvalData.version = approval.version_number;
    this.authService.changeVersion.emit();
    this.getApproval(approval.revision, approval.version_number);
  }
}
