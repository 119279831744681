import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AgentsService {
  data: SelectItem[] | null = null;

  onChange = new EventEmitter();

  constructor() {}
}
