<div class="dash">
    <div class="row">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card no-padding">

                <ng-container *ngIf="loadingDrafts">
                </ng-container>

                <ng-container *ngIf="!loadingDrafts">

                    <app-back-button></app-back-button>

                    <div
                        class="dash-card-header d-flex align-items-start justify-content-between">

                        <h2>Minutas</h2>

                        <div>
                            <app-button (onClick)="toggleModal(true)"
                                [inverted]="true" class="add-button mr-2">
                                <i
                                    class="fas fa-magnifying-glass mr-2 text-primary"></i>
                                Filtrar listagem
                            </app-button>


                            <app-button (onClick)="redirectToForm()"
                                class="add-button" *ngIf="canAdd">
                                <i class="fas fa-plus-circle mr-2"></i>
                                Adicionar minuta
                            </app-button>
                        </div>
                    </div>

                    <div class="dash-card-body  padding-container">
                        <div clsas="styled-scroll">

                            <table class="bordered">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <!-- <th>Papeis</th> -->
                                        <th>Tipo de minuta</th>
                                        <th>Status</th>
                                        <th>Vigência</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let draft of drafts">
                                        <td class="">
                                            <div class="d-flex flex-column">
                                                <span
                                                    class="documents-name">{{draft.name}}</span>
                                                <span
                                                    class="documents-description">{{joinRoles(draft.roles)}}</span>
                                            </div>
                                        </td>
                                        <!-- <td><span class="documents-description">{{joinRoles(draft.roles)}}</span></td> -->
                                        <td>{{draft.type.name}}</td>
                                        <td>{{draft.enabled ? 'Ativado' :
                                            'Desativado'}}</td>
                                        <td><span class="documents-description">
                                                {{draft.initial_date | date:
                                                'dd/MM/yyyy'}} até <br>
                                                {{draft.final_date | date:
                                                'dd/MM/yyyy'}}
                                            </span>
                                        </td>
                                        <td>
                                            <div class="dash-card-item-buttons">
                                                <button class="dash-item-button"
                                                    (click)="deleteDraft(draft.id)"
                                                    *ngIf="canDelete">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                                <app-spacer [right]="16"
                                                    *ngIf="canDelete || canEdit"></app-spacer>

                                                <button class="dash-item-button"
                                                    (click)="redirectToForm(draft.id)"
                                                    *ngIf="canEdit">
                                                    <i class="fas fa-pen"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="drafts.length === 0">
                                        <td>Nenhum documento adicionado</td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card-pagination">
                <app-pagination [data]="drafts" [dashPagination]="true"
                    [show]="!loadingDrafts">
                </app-pagination>
            </div>
        </div>
    </div>
</div>


<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="toggleModal(false)">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <app-input [group]="form" controlName="name"
                    label="Nome da minuta" placeholder="Digite">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <div class="row">

            <div class="col-12 col-lg-8">
                <app-select [items]="typeItems" [group]="form"
                    controlName="type" label="Tipo de minuta"
                    placeholder="Selecione">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-4">
                <app-select [items]="statusItems" [group]="form"
                    controlName="enabled" label="Status"
                    placeholder="Selecione">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>


        <div class="row">
            <div class="col-12 col-lg-6">

                <app-masked-input [group]="form" controlName="initial_date"
                    mask="00/00/0000" label="Início da vigência"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-masked-input [group]="form" controlName="final_date"
                    mask="00/00/0000" label="Fim da vigência"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <app-select [items]="applicableItems" [multiple]="true"
                    [group]="form" controlName="roles"
                    label="Aplicável aos papeis" placeholder="Selecione">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="verifyIdIsApplicable('roles', 'provider')">
                <div class="col-12">
                    <app-select [items]="agentItems" [group]="form"
                        [multiple]="true" controlName="agents"
                        label="Aplicável aos prestadores"
                        placeholder="Selecione">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </ng-container>

            <ng-container *ngIf="verifyIdIsApplicable('roles', 'assignor')">
                <div class="col-12 col-lg-4">
                    <app-select [items]="coobligationItems" [group]="form"
                        controlName="coobligation" label="Coobrigação"
                        placeholder="Selecione">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </ng-container>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [outline]="true" [bordless]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="submitForm()" class="add-button">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>

    </form>
</app-side-modal>