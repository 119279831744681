import UIDefaultTheme from 'src/themes';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
  Validators,
} from '@angular/forms';

export class BrValidators {
  static telefone(tel: any) {
    const telClean = tel.replace(/[^\d]+/g, '');
    tel = tel.replace(/_/g, '');
    if (!(telClean.length === 10 || telClean.length === 11)) {
      return false;
    }
    if (telClean[0] == 0 || telClean[2] == 0) {
      return false;
    }
    return true;
  }

  static celular(cel: any) {
    let celClean = cel.replace(/[^\d]+/g, '');
    celClean = celClean.replace(/_/g, '');
    if (celClean.length !== 11) {
      return false;
    }
    if (celClean[0] == 0 || celClean[2] < 5) {
      return false;
    }
    return true;
  }

  static cep(cep: string) {
    const cepClean = cep.replace(/[^\d]+/g, '');
    const exp = /\d{2}\.\d{3}\-\d{3}/;
    if (!exp.test(cep) && cepClean.length !== 8) {
      return false;
    }
    return true;
  }

  static modulo11Custom = (
    string: string,
    size: number,
    maxMult = string.length,
    by10 = true
  ) => {
    if (!by10) size = 1;
    for (let n = 1; n <= size; n++) {
      let soma = 0;
      let mult = 2;
      for (let i = string.length - 1; i >= 0; i--) {
        soma += mult * parseInt(string.charAt(i));
        mult++;
        if (mult > maxMult) mult = 2;
      }
      let dig;
      if (by10) {
        dig = ((soma * 10) % 11) % 10;
      } else {
        dig = soma % 11;
        if (dig == 10) dig = 0;
      }
      string += dig;
    }
    return string.substr(string.length - size, size);
  };

  static create_cpf(strCPF: string) {
    strCPF = strCPF.replace(/[^\d]+/g, '');
    if (strCPF === '00000000000') {
      return false;
    }

    const restoscustom = BrValidators.modulo11Custom(
      strCPF.substr(0, strCPF.length - 2),
      2,
      12
    );
    return restoscustom;
  }

  static cpf(strCPF: any) {
    // Valida se tem apenas número, - ou .
    let precisaFicarVazio = strCPF.replace(/^[0-9.-]*$/gm, '');
    if (precisaFicarVazio != '') return false;

    strCPF = strCPF.replace(/[^\d]+/g, '');
    if (strCPF.length !== 11) {
      return false;
    }

    //verifica se todos os numeros sao iguais
    const expIguais = /^(?!.*(\d)\1{10}).*$/;
    if (!strCPF.match(expIguais)) {
      return false;
    }

    // valida digito verificados
    const restos = BrValidators.create_cpf(strCPF);
    if (!restos || restos != strCPF.substr(-2)) {
      return false;
    }
    return true;
  }

  static create_cnpj(cnpj: string) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') {
      return false;
    }

    if (cnpj.length !== 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false;
    }

    // Valida DVs
    let tamanho: number = cnpj.length - 2;
    let numeros: any = cnpj.substring(0, tamanho);
    let soma: any = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    const resultados = [0, 0];
    resultados[0] = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultados[1] = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    return resultados;
  }

  static cnpj(cnpj: any) {
    // Valida se tem apenas número, - ou .
    let precisaFicarVazio = cnpj.replace(/^[0-9./-]*$/gm, '');
    if (precisaFicarVazio != '') return false;

    cnpj = cnpj.replace(/[^\d]+/g, '');
    let tamanho = cnpj.length - 2;
    const digitos = cnpj.substring(tamanho);
    const resultados = BrValidators.create_cnpj(cnpj);
    if (
      !resultados ||
      resultados[0] !== parseInt(digitos.charAt(0), 10) ||
      resultados[1] !== parseInt(digitos.charAt(1), 10)
    ) {
      return false;
    }

    return true;
  }
}
export class UtilsValidators {
  static isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }

  static telefone(control: AbstractControl) {
    if (UtilsValidators.isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return BrValidators.telefone(v) ? null : { telefone: true };
  }

  static celular(control: AbstractControl) {
    if (UtilsValidators.isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return BrValidators.celular(v) ? null : { celular: true };
  }

  static cep(control: AbstractControl) {
    if (UtilsValidators.isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return BrValidators.cep(v) ? null : { cep: true };
  }

  static cpf(control: AbstractControl) {
    if (UtilsValidators.isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return BrValidators.cpf(v) ? null : { cpf: true };
  }

  static cnpj(control: AbstractControl) {
    if (UtilsValidators.isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return BrValidators.cnpj(v) ? null : { cnpj: true };
  }
}
export default class Utils {
  static lastMessage: string = '';

  static noEmptyFields(obj: any): any {
    const updated = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        if (obj[key].length > 0) {
          updated[key] = obj[key];
        }
      } else {
        if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
          updated[key] = obj[key];
        }
      }
    }

    return updated;
  }

  static makeID(length: number = 16) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static convertToSlug(text: string) {
    let str = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    return str;
  }

  static convertBRdateToUSdate(date: string | null | undefined) {
    if (date) {
      const dateArray = date.split('/');
      const reversedDateArray = dateArray.reverse();
      return reversedDateArray.join('-');
    }
    return null;
  }

  static onlyNumbers(str: string) {
    if (str) {
      return String(str).replace(/[^\d]/g, '');
    }
    return '';
  }

  static onlyLetters(str: string) {
    if (str) {
      return String(str).replace(/[^a-zA-Z]/g, '');
    }
    return '';
  }

  static removeSpecialChars(str: string) {
    if (str) {
      return String(str).replace(/[^a-zA-Z0-9]/g, '');
    }
    return '';
  }

  static formatBrl(value: number) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  static brlToNumber(value: string | undefined) {
    if (value && typeof value === 'string') {
      if (value.includes('R$')) {
        value = value.split('R$').join('');
      }

      if (value.includes('.')) {
        value = value.split('.').join('');
      }

      if (value.includes(',')) {
        value = value.split(',').join('.');
      }

      value = value.split(' ').join('');

      return Number(value);
    }

    if (typeof value === 'number') {
      return value;
    }

    return 0;
  }

  static todayString() {
    return new Date().toISOString().split('T')[0];
  }

  static tomorrowString() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  }

  static validDate() {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 25);

    return today.toISOString().split('T')[0];
  }

  static getErrors(form: any): string[] {
    const errors: string[] = [];
    if (form) {
      Object.keys(form.controls).forEach((key) => {
        const controlErrors: ValidationErrors | null | undefined =
          form?.get(key)?.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            errors.push(key.replace('_', ' ') + ' ' + keyError);
          });
        }
      });

      console.error('getErrors', errors);
    }
    return errors;
  }

  static validateWhenNotEmpty(validator: ValidatorFn) {
    return function (control: FormControl) {
      if (control.value) {
        return validator(control);
      }

      return null;
    };
  }

  static validateIfDirty(validator: ValidatorFn) {
    return function (control: FormControl) {
      const form: any = control.parent;

      if (form) {
        const isDirty = Object.keys(form.controls).some((key) => {
          if (form.get(key).dirty) {
            return true;
          }
          return false;
        });

        if (isDirty) {
          return validator(control);
        }

        return null;
      }
      return null;
    };
  }

  static ageValidation(control: FormControl) {
    if (control.value) {
      const date = new Date(control.value);
      const today = new Date();
      let age = today.getFullYear() - date.getFullYear();
      const month = today.getMonth() - date.getMonth();
      const day = today.getDate() - date.getDate();

      if (month < 0 || (month === 0 && day < 0)) {
        age--;
      }

      if (age < 18) {
        return { age: true };
      }
    }

    return null;
  }

  static validateDateValid(control: FormControl) {
    if (control.value && control.value.length == 10) {
      const date = control.value.split('/');

      if (date.length != 3) {
        return { date: true };
      }

      const day = date[0];
      const month = date[1];
      const year = date[2];

      if (day && month && year) {
        const date = new Date(year, month - 1, day);

        if (
          date.getFullYear() != year ||
          date.getMonth() != month - 1 ||
          date.getDate() != day
        ) {
          return { date: true };
        }

        if (date.toString() == 'Invalid Date') {
          return { date: true };
        }

        return null;
      }
    }

    return { date: true };
  }

  static validateLegalAge(control: FormControl) {
    const dataInvalid = Utils.validateDateValid(control);

    if (dataInvalid === null) {
      const dateArr = control.value.split('/');

      const dateDay = dateArr[0];
      const dateMonth = dateArr[1];
      const dateYear = dateArr[2];

      const date = new Date(dateYear, dateMonth - 1, dateDay);

      const today = new Date();
      let age = today.getFullYear() - date.getFullYear();
      const month = today.getMonth() - date.getMonth();
      const day = today.getDate() - date.getDate();

      if (month < 0 || (month === 0 && day < 0)) {
        age--;
      }

      if (age < 18) {
        return { age: true };
      }
    }

    return null;
  }

  static validateMinDate(control: FormControl) {
    const dataInvalid = Utils.validateDateValid(control);

    if (dataInvalid === null) {
      const dateArr = control.value.split('/');

      const dateDay = dateArr[0];
      const dateMonth = dateArr[1];
      const dateYear = dateArr[2];

      const date = new Date(dateYear, dateMonth - 1, dateDay);

      const year = date.getFullYear();

      const minDate = new Date(`01/01/1900`);

      if (date < minDate) {
        return { min: true };
      }

      return null;
    }

    return dataInvalid;
  }

  static validateMaxDate(control: FormControl) {
    const dataInvalid = Utils.validateDateValid(control);

    if (dataInvalid === null) {
      const dateArr = control.value.split('/');

      const dateDay = dateArr[0];
      const dateMonth = dateArr[1];
      const dateYear = dateArr[2];

      const date = new Date(dateYear, dateMonth - 1, dateDay);

      const year = date.getFullYear();

      const maxDate = new Date(`12/31/${year + 100}`);

      if (date > maxDate) {
        return { max: true };
      }

      return null;
    }

    return dataInvalid;
  }

  static validateMaxMinToday(control: FormControl, max: boolean = true) {
    const dataInvalid = Utils.validateDateValid(control);

    if (dataInvalid === null) {
      const dateArr = control.value.split('/');

      const dateDay = dateArr[0];
      const dateMonth = dateArr[1];
      const dateYear = dateArr[2];

      const date = new Date(dateYear, dateMonth - 1, dateDay);
      const today = new Date();

      if (max) {
        if (date > today) {
          return { max: true };
        }
      } else {
        if (date < today) {
          return { min: true };
        }
      }

      return null;
    }

    return dataInvalid;
  }

  static verifyDateLessCurrentDate(currentDate: string, comparedDate: string) {
    const currentDateArr = currentDate.split('/');

    const currentDay = Number(currentDateArr[0]);
    const currentMonth = Number(currentDateArr[1]);
    const currentYear = Number(currentDateArr[2]);

    const comparedDateArr = comparedDate.split('/');

    const comparedDay = Number(comparedDateArr[0]);
    const comparedMonth = Number(comparedDateArr[1]);
    const comparedYear = Number(comparedDateArr[2]);

    const currentNewDate = new Date(currentYear, currentMonth - 1, currentDay);
    const comparedNewDate = new Date(
      comparedYear,
      comparedMonth - 1,
      comparedDay
    );

    return currentNewDate < comparedNewDate;
  }

  static validateOneOf(validators: ValidatorFn[]) {
    return function (control: FormControl) {
      const isValid = validators.some((validator) => {
        return validator(control) === null;
      });

      if (isValid) {
        return null;
      }

      return { invalid: true };
    };
  }

  static validateIfFilled(validator: ValidatorFn, filter: string[] = []) {
    return function (control: FormControl) {
      const form: any = control.parent;

      if (form) {
        const isFilled = Object.keys(form.controls)
          .filter((key) => filter.includes(key))
          .some((key) => {
            if (form.get(key).value !== null || form.get(key).value !== null) {
              return true;
            }

            return false;
          });

        if (isFilled) {
          return validator(control);
        }

        return null;
      }
      return null;
    };
  }

  static resetZipcodeFields = (form: any) => {
    form.controls.public_place.reset();
    form.controls.number.reset();
    form.controls.complement.reset();
    form.controls.district.reset();
    form.controls.city.reset();
    form.controls.uf.reset();
    form.controls.country.reset();
  };

  static validateCpfCnpj(controls: FormControl) {
    const validateCpf = UtilsValidators.cpf(controls);
    const validateCnpj = UtilsValidators.cnpj(controls);

    if (validateCpf === null || validateCnpj === null) {
      return null;
    }

    return { invalid: true };
  }

  static maxDigits(max: number) {
    return function (control: FormControl) {
      if (control.value) {
        const numbers = String(control.value).split('.');

        if (numbers[0].length > max) {
          return { maxDigits: true };
        }
      }

      return null;
    };
  }

  static maxNumber(max: number) {
    return function (control: FormControl) {
      if (control.value) {
        if (typeof control.value === 'string') {
          const num = Number(control.value);
          if (num > max) {
            return { maxNumber: true };
          }
        } else {
          if (control.value > max) {
            return { maxNumber: true };
          }
        }
      }

      return null;
    };
  }

  static setTitle(role: string) {
    let title = '';

    switch (role) {
      case 'ASSIGNOR-PF-PF':
        title = 'Cadastro Cedente Pessoa Física | ' + UIDefaultTheme.brand.name;
        break;
      case 'ASSIGNOR-PJ-FI':
        title =
          'Cadastro Cedente Fundo de Investimento | ' +
          UIDefaultTheme.brand.name;
        break;
      case 'ASSIGNOR-PJ-IF':
        title =
          'Cadastro Cedente Instituição Financeira | ' +
          UIDefaultTheme.brand.name;
        break;
      case 'ASSIGNOR-PJ-PJ':
        title =
          'Cadastro Cedente Pessoa Jurídica | ' + UIDefaultTheme.brand.name;
        break;
      case 'SHAREHOLDER-PJ-FI':
        title =
          'Cadastro Cotista Fundo de Investimento | ' +
          UIDefaultTheme.brand.name;
        break;
      case 'SHAREHOLDER-PJ-IF':
        title =
          'Cadastro Cotista Instituição Financeira | ' +
          UIDefaultTheme.brand.name;
        break;
      case 'SHAREHOLDER-PF-PFNR':
        title = 'Cadastro Cotista Pessoa Física | ' + UIDefaultTheme.brand.name;
        break;
      case 'SHAREHOLDER-PF-PFR':
        title = 'Cadastro Cotista Pessoa Física | ' + UIDefaultTheme.brand.name;
        break;
      case 'SHAREHOLDER-PJ-PJ':
        title =
          'Cadastro Cotista Pessoa Jurídica | ' + UIDefaultTheme.brand.name;
        break;
      case 'SHAREHOLDER-PJ-PJNR':
        title =
          'Cadastro Cotista Pessoa Jurídica | ' + UIDefaultTheme.brand.name;
        break;
      default:
        title = UIDefaultTheme.brand.name;
        break;
    }

    return title;
  }

  static filterRepeatedValues(array: any[], key: string) {
    const filteredArray = array.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t[key] === item[key])
    );

    return filteredArray;
  }

  static searchMessage(obj) {
    const searched = [];

    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (key === 'message') {
          if (!searched.includes(obj[key])) {
            searched.push(obj[key]);
          }
        } else if (typeof obj[key] === 'object') {
          const found = Utils.searchMessage(obj[key]);

          if (found.length > 0) {
            found.forEach((item) => {
              if (!searched.includes(item)) {
                searched.push(item);
              }
            });
          }
        }
      });
    }

    return searched;
  }

  static whitespaceValidator(form: FormControl): ValidationErrors {
    return form.value && form.value.startsWith(' ')
      ? { whitespace: true }
      : null;
  }

  static getInitials(name: any) {
    const arr = name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ');

    const range = arr.length - 1;
    let haveLastName = false;
    let lastNameindex = range;
    let firstNameIndex = 0;
    let lastNameChar = '';
    let firstNameChar = '';

    while (firstNameIndex <= range) {
      if (arr[firstNameIndex] !== '') {
        firstNameChar = arr[firstNameIndex][0];
        break;
      }

      firstNameIndex++;
    }

    while (lastNameindex !== firstNameIndex || haveLastName) {
      if (arr[lastNameindex] !== '') {
        lastNameChar = arr[lastNameindex][0];
        haveLastName = true;
        break;
      }

      lastNameindex--;
    }

    return firstNameChar + lastNameChar;
  }

  static consoleLog(message) {
    // Prevente equals last
    if (message !== this.lastMessage) {
      this.lastMessage = message;
    }
  }

  static renderValue(parentPath: string[], key, from: any) {
    const firstPath = parentPath[0];
    const rest = parentPath.slice(1);
    let registerPath = from.register;

    const historyPaths = {
      professional: 'Professional',
      address: 'Address',
      person: 'Person',
      company: 'Company',
    };

    let historyPath = from.history[historyPaths[firstPath]]
      ? from.history[historyPaths[firstPath]][0]
      : null;

    if (historyPath !== null) {
      rest.forEach((path) => {
        historyPath = historyPath[path];
      });
    }

    parentPath.forEach((path) => {
      registerPath = registerPath[path];
    });

    return historyPath
      ? historyPath[key]
      : registerPath
      ? registerPath[key]
      : null;
  }
}

export class GetHelper {
  refObject: any;
  newObject: any;

  constructor(object: any) {
    this.refObject = { ...object };
    this.newObject = { ...object };
  }

  get(key: string) {
    if (this.refObject.hasOwnProperty(key)) {
      if (this.newObject.hasOwnProperty(key)) {
        delete this.newObject[key];
      }

      return this.refObject[key];
    }
  }

  getRemainingObject() {
    return { ...this.newObject };
  }
}
