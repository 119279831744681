import { ToastService } from './../../../../services/toast/toast.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SignupService } from 'src/app/services/signup/signup.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { AuthService } from 'src/app/services/auth/auth.service';

import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';
import { StorageService } from 'src/app/services/storage/storage.service';
import Swal from 'sweetalert2';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-signup-unified-company-complementary-composition',
  templateUrl:
    './signup-unified-company-complementary-composition.component.html',
  styleUrls: [
    './signup-unified-company-complementary-composition.component.scss',
  ],
})
export class SignupUnifiedCompanyComplementaryCompositionComponent
  implements OnInit, OnDestroy
{
  @Input() userRole: string;

  showForm = true;

  shareholding: CompanyComplementaryCompositionValues[] = [];

  form = this.formBuilder.group({
    document: [null, [Validators.required, Utils.validateCpfCnpj]],
    full_name: [null, [Validators.required]],
    percentage: [
      '',
      [Validators.required, Validators.max(100), Validators.min(0.01)],
    ],
    title: ['', []],
    pep: [false, []],
    pep_relationship: [false, []],
    pep_relationship_document: [null, []],
    pep_relationship_name: [null, []],
    pep_bond: [null, []],
  });

  showWarning = false;

  addAnotherModal = false;
  removeModal = false;

  parentID: number | undefined = undefined;
  selectedID: number | undefined = undefined;

  partner: Partial<CompanyComplementaryCompositionValues>;

  loading = true;

  data: PartnerResponse[] = [];

  formattedData: PartnerResponse[] = [];

  sending = false;

  event: any;

  isSA = false;
  foundPerson = false;
  foundRelationPerson = false;

  isEditing = false;
  userDocument = '';
  invalidDocument = false;
  generalObj: any;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    public maskPipe: MaskPipe,
    public authService: AuthService,
    public storageService: StorageService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.getDataAndFillForm();
    this.getGeneralData();

    document.onkeyup = (event) => {
      if (event.key === 'Enter' && !this.getDisabled()) {
        if (this.showForm) {
          this.handleTriggerForm(0);
        }

        if (this.addAnotherModal) {
          this.handleTriggerForm(this.parentID, true);
        }
      }
    };

    this.form.controls.pep_relationship_document.valueChanges.subscribe(
      async (value) => {
        if (!value) {
          this.foundRelationPerson = false;
        }

        const document = Utils.onlyNumbers(value);

        if (document.length === 11) {
          const sameDocument =
            Utils.onlyNumbers(this.form.controls.document.value) === document;

          if (sameDocument) {
            this.form.controls.pep_relationship_document.setErrors({
              sameDocument: true,
            });
          } else {
            this.form.controls.document.setErrors(null);
            this.form.controls.pep_relationship_document.setErrors(null);
          }

          const { name, foundPerson } = await this.api.getPersonName(value);

          this.foundRelationPerson = foundPerson;

          if (name) this.form.controls.pep_relationship_name.setValue(name);
        }
      }
    );

    this.form.controls.document.valueChanges.subscribe(async (value) => {
      if (!value) {
        this.foundPerson = false;
      }

      const document = Utils.onlyNumbers(value);

      const idx = this.data.findIndex((item) => item.id === this.parentID);

      let partnersArr = [];

      if (idx === -1) {
        partnersArr = this.data.filter((item) => !item.parent);
      } else {
        partnersArr = this.data.filter(
          (item) => item.parent === this.data[idx].id
        );
      }

      if (document.length === 14 || document.length === 11) {
        const sameDocument =
          Utils.onlyNumbers(
            this.form.controls.pep_relationship_document.value
          ) === document;

        if (sameDocument) {
          this.form.controls.document.setErrors({
            sameDocument: true,
          });
          return;
        } else {
          this.form.controls.document.setErrors(null);
          this.form.controls.pep_relationship_document.setErrors(null);
        }

        this.invalidDocument = false;

        const { name, foundPerson } = await this.api.getPersonName(value);

        this.foundPerson = foundPerson;
        if (name) this.form.controls.full_name.setValue(name);

        if (
          partnersArr.some(
            (item) => Utils.onlyNumbers(item.document) === document
          ) &&
          !this.isEditing
        ) {
          this.toast.show('error', 'Atenção', 'Documento já adicionado!');
          this.form.controls.document.setErrors({ alreadyAdded: true });
        } else {
          this.form.controls.document.setErrors(null);
        }
      }

      if (document.length === 14) {
        if (this.userDocument === document) {
          this.invalidDocument = true;
          this.form.controls.document.setErrors({ isOwner: true });
        }
      }
    });

    this.form.controls.full_name.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        if (Utils.onlyLetters(value)) {
          this.form.controls.full_name.setErrors(null);
        } else {
          this.form.controls.full_name.setErrors({ onlyNumbers: true });
        }
      });

    this.event = this.signupService.sendComplementaryCompanyData.subscribe(
      (value) => {
        if (value === 'complementaryComposition') {
          this.signupService.setComplementaryCompanyStep(
            'complementaryEconomic'
          );
          this.signupService.changeCompanyStepEvent.emit('economic');
        }
      }
    );

    this.signupService.fillFormEvent.subscribe(() => {
      const pep_relationship = fakerbr.random.boolean();
      const isCompany = fakerbr.random.boolean();

      const document = isCompany
        ? this.maskPipe.transform(fakerbr.br.cnpj(), '00.000.000/0000-00')
        : this.maskPipe.transform(fakerbr.br.cpf(), '000.000.000-00');

      const full_name = isCompany
        ? fakerbr.company.companyName()
        : fakerbr.name.findName();

      this.form.patchValue({
        pep_relationship: pep_relationship,
        pep_relationship_document: pep_relationship
          ? this.maskPipe.transform(fakerbr.br.cpf(), '000.000.000-00')
          : null,
        pep_relationship_name: pep_relationship
          ? fakerbr.name.findName()
          : null,
        pep_bond: pep_relationship ? 'Sócio' : null,
        document: document,
        full_name: full_name,
        percentage: fakerbr.random.number({
          min: 0.01,
          max: 100,
        }),
        pep: fakerbr.random.boolean(),
      });
    });

    this.form.controls.pep_relationship.valueChanges.subscribe(() =>
      this.verifyPepRelationshipFields()
    );
  }

  async getGeneralData() {
    try {
      const { data } = await this.api.get<ApiResponse<Company>>({
        route: 'api/registration/pj_general/me/',
        token: true,
      });

      this.userDocument = data.document.number;
    } catch (error) {
      console.log(error);
    }
  }

  verifyPepRelationshipFields() {
    if (this.form.controls.pep_relationship.value) {
      this.form.controls.pep_relationship_document.setValidators([
        Validators.required,
        UtilsValidators.cpf,
      ]);
      this.form.controls.pep_relationship_name.setValidators([
        Validators.required,
      ]);
      this.form.controls.pep_bond.setValidators([Validators.required]);
    } else {
      this.form.controls.pep_relationship_document.setValidators([]);
      this.form.controls.pep_relationship_name.setValidators([]);
      this.form.controls.pep_bond.setValidators([]);
    }

    this.form.controls.pep_relationship_document.updateValueAndValidity();
    this.form.controls.pep_relationship_name.updateValueAndValidity();
    this.form.controls.pep_bond.updateValueAndValidity();
  }

  getShareholdersPercentage(item_id?: number) {
    let maxPercentage = 0;

    if (item_id !== undefined) {
      const partner = this.data.find((item) => item.id === item_id);
      if (partner) {
        maxPercentage = partner.shareholders.reduce((prev, curr) => {
          return curr.id !== this.selectedID
            ? Number(prev.toFixed(4)) + Number(curr.percentage.toFixed(4))
            : Number(prev.toFixed(4));
        }, 0);
      }
    } else {
      const rootObjects = this.data.filter((item) => item.parent === null);
      maxPercentage = rootObjects.reduce((prev, curr) => {
        return curr.id !== this.selectedID
          ? Number(prev.toFixed(4)) + Number(curr.percentage.toFixed(4))
          : Number(prev.toFixed(4));
      }, 0);
    }

    return Number(maxPercentage.toFixed(4));
  }

  getDisabled() {
    if (this.addAnotherModal) {
      return this.form.invalid;
    }

    if (this.showForm) {
      return this.form.invalid;
    }

    return false;
  }

  async handleTriggerForm(item_id?: number, toggleModal = false) {
    if (this.isEditing) {
      await this.sendData();
      return;
    }

    this.parentID = item_id != undefined ? item_id : 0;
    const maxPercentage = Number(
      (100 - Number(this.getShareholdersPercentage(item_id))).toPrecision(7)
    );

    this.form.controls.percentage.setValidators([
      Validators.required,
      Validators.max(maxPercentage),
      Validators.min(0.01),
    ]);

    this.form.controls.percentage.updateValueAndValidity();

    if (toggleModal) {
      if (this.addAnotherModal) {
        await this.sendData();
        this.addAnotherModal = false;
      } else {
        this.addAnotherModal = true;
        this.signupService.setCompanyFormStatus(
          'complementaryComposition',
          false
        );
      }
    } else {
      await this.sendData();
    }
  }

  formatPercentage(value: number) {
    const valueString = String(value);
    const isFloat = valueString.includes('.');

    if (isFloat) {
      const splitted = valueString.split('.');

      if (splitted[1].length === 1) {
        return `${valueString}0`;
      }
    }

    return valueString;
  }

  setFieldsByRole() {
    if (this.authService.user) {
      if (
        this.authService.user.active_register.register.role.slug ===
        `${this.userRole.toUpperCase()}-PJ-FI`
      ) {
        this.form.controls.document.setValidators([]);
        this.form.controls.percentage.setValidators([]);
      } else {
        this.form.controls.document.setValidators([Validators.required]);
        this.form.controls.percentage.setValidators([
          Validators.required,
          Validators.max(100),
          Validators.min(0.01),
        ]);
      }

      this.form.controls.document.updateValueAndValidity();
      this.form.controls.percentage.updateValueAndValidity();
    }
  }

  async handleCloseModal() {
    this.addAnotherModal = false;
    this.parentID = undefined;
    this.selectedID = undefined;
    this.isEditing = false;
    this.form.reset();

    const minimunValue = await this.getMinimumPercentageValue();

    this.signupService.setCompanyFormStatus(
      'complementaryComposition',
      this.getShareholdersPercentage() >= minimunValue ||
        this.getShareholdersPercentage() === 100
    );
  }

  handleRemoveModal(item: PartnerResponse) {
    this.parentID = item.id;
    this.removeModal = true;
  }

  handleRemoveCancel() {
    this.removeModal = false;
    this.parentID = undefined;
  }

  getSlugLabel() {
    const label = `${this.userRole.toUpperCase()}-PJ-FI`;

    return label;
  }

  async removeData() {
    const partner = this.data.find((item) => item.id === this.parentID);

    if (partner.shareholders.length > 0) {
      this.handleRemoveCancel();
      this.toast.show(
        'error',
        'Erro!',
        'Para remover um sócio, é necessário remover todos os seus dependentes.'
      );

      return;
    }

    try {
      await this.api.delete({
        route: `api/registration/partners/${this.parentID}/`,
        token: true,
      });

      const newData = this.data.filter((item) => item.id !== this.parentID);

      this.data = newData;

      this.getDataAndFillForm();

      this.removeModal = false;

      this.showForm = newData.length === 0;

      this.toast.show('info', 'Sucesso!', 'Sócio removido com sucesso!');
    } catch (error) {
      this.toast.show('error', 'Erro!', error.error.message);
    }
  }

  formatData() {
    const firstObject: PartnerResponse = {
      id: undefined,
      percentage: 0,
      isOpen: true,
      pep: false,
      full_name: 'Grupo principal',
      document: '',
      parent: null,
      title: '',
      pep_bond: '',
      pep_relationship: false,
      pep_relationship_document: '',
      pep_relationship_name: '',
      shareholders: [],
      register: '',
      collapsed: false,
    };

    const rootObjects = [];

    this.data.forEach((item) => {
      if (item.parent === null) {
        firstObject.shareholders.push(item);
      }

      if (item.document.length > 14) {
        rootObjects.push(item);
      }
    });

    const newData = this.data.map((item) => {
      return {
        ...item,
        collapsed: false,
        isOpen: true,
      };
    });

    // const newFormattedData = [firstObject, ...rootObjects];

    const newFormattedData = [firstObject, ...newData];

    this.formattedData = newFormattedData;
  }

  async getDataAndFillForm(showLoading = true) {
    this.loading = showLoading;
    try {
      const data = await this.api.get<PartnerResponse[]>({
        route: 'api/registration/partners/',
        token: true,
      });

      this.data = data;

      if (this.data.length > 0) {
        this.showForm = false;
      }

      this.formatData();

      const newGeneralObj = await this.api.get<ApiResponse<Company>>({
        route: 'api/registration/pj_general/me/',
        token: true,
      });

      this.generalObj = newGeneralObj;

      const minimunValue = this.getMinimumPercentageValue();

      this.isSA = minimunValue === 25;

      this.signupService.setCompanyFormStatus(
        'complementaryComposition',
        this.getShareholdersPercentage() >= minimunValue ||
          this.getShareholdersPercentage() === 100
      );
    } catch (error) {
      console.error(error);
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error);
      }
    }
    this.loading = false;
  }

  getMinimumPercentageValue() {
    const frameworks = this.signupService.choices().frameworks;

    let minimunValue = 25;

    if (frameworks) {
      minimunValue =
        frameworks.find(
          (frameworks) =>
            frameworks.value === String(this.generalObj.data.framework)
        ).label === 'Sociedade Anônima (S/A)'
          ? 25
          : 100;
    }

    return minimunValue;
  }

  async sendData() {
    this.sending = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const finded = this.data.find((item) => item.id === this.selectedID);

      let payload: any = {
        percentage: valuesHelper.get('percentage'),
        pep: valuesHelper.get('pep') ?? false,

        full_name: valuesHelper.get('full_name'),
        document: valuesHelper.get('document'),

        title: valuesHelper.get('title'),
      };

      if (this.form.controls.pep_relationship.value) {
        payload = {
          ...payload,
          pep_bond: valuesHelper.get('pep_bond'),
          pep_relationship: valuesHelper.get('pep_relationship'),
          pep_relationship_document: valuesHelper.get(
            'pep_relationship_document'
          ),
          pep_relationship_name: valuesHelper.get('pep_relationship_name'),
        };
      }

      if (finded) {
        const findedDocument = Utils.onlyNumbers(finded.document);
        const document = Utils.onlyNumbers(valuesHelper.get('document'));

        const changedToPF = findedDocument.length > document.length;

        if (!changedToPF) {
          payload['shareholders'] = finded.shareholders ?? [];
        } else {
          const result = await Swal.fire({
            title: 'Atenção!',
            text: 'Ao alterar o tipo de documento, todos os dependentes serão removidos. Deseja continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
          });

          if (!result.isConfirmed) {
            this.sending = false;
            return;
          }
        }
      }

      if (this.parentID > 0) {
        payload['parent'] = this.parentID;
      }

      const res = this.isEditing
        ? await this.api.patch<ApiResponse<PartnerResponse>>({
            route: `api/registration/partners/${this.selectedID}/`,
            token: true,
            body: payload,
          })
        : await this.api.post<ApiResponse<PartnerResponse>>({
            route: 'api/registration/partners/',
            token: true,
            body: payload,
          });

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');

      this.form.reset();

      this.getDataAndFillForm(false);

      if (this.isEditing) {
        const findedDocument = Utils.onlyNumbers(finded.document);
        const document = Utils.onlyNumbers(valuesHelper.get('document'));

        this.handleCloseModal();
        this.showWarning =
          findedDocument.length === 14 && document.length === 14;
      }
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  handleEdit(item: PartnerResponse) {
    this.parentID = item.parent ?? undefined;
    this.selectedID = item.id;
    this.isEditing = true;

    this.form.patchValue({
      document: item.document,
      full_name: item.full_name,
      percentage: item.percentage,
      pep: item.pep,
      pep_relationship: item.pep_relationship,
      pep_relationship_document: item.pep_relationship_document,
      pep_relationship_name: item.pep_relationship_name,
      pep_bond: item.pep_bond,
    });

    const maxPercentage =
      100 -
      Number(this.getShareholdersPercentage(this.parentID).toPrecision(7));

    this.form.controls.percentage.setValidators([
      Validators.required,
      Validators.max(maxPercentage),
      Validators.min(0.01),
    ]);

    this.form.controls.percentage.updateValueAndValidity();

    this.addAnotherModal = true;
    this.signupService.setCompanyFormStatus('complementaryComposition', false);
  }

  showChildrens() {}

  getSpace(index: number) {
    const multiplyNumber = this.getMultiplier(index);

    return multiplyNumber * 32;
  }

  getMultiplier(index: number) {
    let multiplyNumber = this.formattedData[index].id ? 1 : 0;
    let haveParent = this.formattedData[index].parent !== null;
    let parentId = 0;
    let findedIndex = index;

    while (haveParent) {
      multiplyNumber++;
      parentId = this.formattedData[findedIndex].parent;

      if (parentId === null) {
        haveParent = false;
        break;
      }

      findedIndex = this.formattedData.findIndex(
        (item) => item.id === parentId
      );

      haveParent = this.formattedData[findedIndex].parent !== null;
    }

    return multiplyNumber;
  }

  handleKeyEnter(event: any) {
    event.preventDefault();
    if (event.keyCode === 13 && this.form.valid) {
      //   this.triggerForm();
    }
  }

  handleSetCollapsed(index: number, isOpen: boolean) {
    if (!isOpen) {
      this.formattedData[index].shareholders.forEach((item) => {
        const shareholderIndex = this.formattedData.findIndex(
          (i) => item.id === i.id
        );

        this.formattedData[shareholderIndex].collapsed = false;
      });

      this.formattedData[index].isOpen = true;
      return;
    }

    this.formattedData[index].isOpen = false;

    if (isOpen) {
      this.formattedData[index].shareholders.forEach((item) => {
        const shareholderIndex = this.formattedData.findIndex(
          (i) => item.id === i.id
        );

        this.formattedData[shareholderIndex].collapsed = true;

        this.handleSetCollapsed(shareholderIndex, true);
      });
    }
  }
}
