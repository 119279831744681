import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { permission } from 'src/app/data/permissions';
import Utils from 'src/app/helpers/utils';
import { LoadingService } from 'src/app/services/Loading/loading.service';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form = this.formBuilder.group({
    username: [null, [Validators.required]],
  });
  error = 'Campo inválido';
  valid: boolean = false;

  passwordForm = this.formBuilder.group({
    password: [null, [Validators.required, Validators.minLength(8)]],
  });

  newPasswordForm = this.formBuilder.group({
    newPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        (control: FormControl) => {
          const password = control.value;

          if (!this.hasNumber(password)) return { noNumber: true };
          if (!this.hasLowerCase(password)) return { noLowerCase: true };
          if (!this.hasUpperCase(password)) return { noUpperCase: true };
          if (!this.hasSpecialCharacter(password))
            return { noSpecialCharacter: true };

          return null;
        },
      ],
    ],
  });
  forgotModal: boolean = false;
  codeModal: boolean = false;

  loading = false;

  isAssignor = false;

  token = '';
  username = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    public authService: AuthService,
    private toastService: ToastService,
    private activedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(({ username }) => {
      this.authService.forgotValue = username;
    });

    this.isAssignor = this.router.url.includes('assignor');

    this.activedRoute.queryParams.subscribe((params) => {
      if (params.token && params.username) {
        const numbers = Utils.onlyNumbers(params.token);
        if (numbers.length < 4) {
          Swal.fire({
            title: 'Link inválido!',
            text: 'O link para recuperar senha é inválido ou expirou',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
          }).then(() => {
            this.router.navigateByUrl(`/login`);
          });
        } else {
          this.username = params.username;
          this.token = params.token;
        }
      } else {
        Swal.fire({
          title: 'Link inválido!',
          text: 'O link para recuperar senha é inválido ou expirou',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
        }).then(() => {
          this.router.navigateByUrl(`/login`);
        });
      }
    });
  }

  redirectUser(response: NewLoginResponse) {
    if (response.is_staff) {
      this.router.navigateByUrl(`/app/dashboard`);
      return;
    }

    if (response.active_register) {
      if (response.active_register.register.trace_step) {
        const {
          active_register: {
            register: {
              trace_step: { step_display },
              role: { applicable },
              type,
            },
          },
        } = response;

        if (step_display === 'Cadastro') {
          this.router.navigateByUrl(
            `/signup/${applicable}/${type.toLowerCase()}`
          );
          return;
        }
      }
    }

    this.router.navigateByUrl(`/app/dashboard`);
    return;
  }

  //   redirectUser(data: NewLoginResponse) {
  //     if (data.active_register.register.role.slug) {
  //       const role = data.active_register.register.role.slug.split('-');

  //       if (role[0] === 'ADM') {
  //         this.router.navigateByUrl(`/app/dashboard`);

  //         return;
  //       }
  //     }

  //     const url = this.isAssignor
  //       ? `/signup/assignor/${data.type.toLowerCase()}`
  //       : `/signup/${data.type.toLowerCase()}`;

  //     switch (data.trace_step.register_step) {
  //       case 'Dados complementares':
  //         this.router.navigateByUrl(url, {
  //           state: { step: 0 },
  //         });
  //         break;
  //       case 'Rendimentos & patrimônios':
  //         this.router.navigateByUrl(url, {
  //           state: { step: 1 },
  //         });
  //         break;
  //       case 'Deaclarações e autorizações':
  //         this.router.navigateByUrl(url, {
  //           state: { step: 2 },
  //         });
  //         break;
  //       case 'Análise de perfil de investidor':
  //         this.router.navigateByUrl(url, {
  //           state: { step: 3 },
  //         });
  //         break;
  //       case 'Declaração de Condição de Investidor':
  //         this.router.navigateByUrl(url, {
  //           state: { step: 4 },
  //         });
  //         break;
  //       case 'Informações Fiscais':
  //         this.router.navigateByUrl(url, {
  //           state: { step: 5 },
  //         });
  //         break;
  //       case 'Anexos & comprovantes':
  //         this.router.navigateByUrl(url, {
  //           state: { step: 6 },
  //         });
  //         break;
  //       case 'Análise':
  //         this.router.navigateByUrl(`/app/dashboard`);
  //         break;
  //       case 'Concluido':
  //         this.router.navigateByUrl(`/app/dashboard`);
  //         break;
  //       default:
  //         break;
  //     }
  //   }

  openModal() {
    this.forgotModal = true;
  }

  closeModal() {
    this.forgotModal = false;
  }

  hasLowerCase(str: string) {
    return str.toUpperCase() !== str;
  }

  hasUpperCase(str: string) {
    return str.toLowerCase() !== str;
  }

  hasSpecialCharacter(str: string) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
  }

  hasNumber(str: string) {
    return /\d/.test(str);
  }

  passwordStrength(): number[] {
    const password = this.newPasswordForm.get('newPassword')?.value ?? '';

    const strengthSteps = [];

    if (password !== '') strengthSteps.push(0);
    if (password.length >= 8) strengthSteps.push(1);
    if (this.hasNumber(password)) strengthSteps.push(2);
    if (this.hasLowerCase(password) && this.hasUpperCase(password))
      strengthSteps.push(3);
    if (this.hasSpecialCharacter(password)) strengthSteps.push(4);

    return strengthSteps;
  }

  passwordStrengthPercentage(): number {
    return (100 * this.passwordStrength().length) / 5;
  }

  passwordStrengthText(): string {
    const percentage = this.passwordStrengthPercentage();

    if (percentage > 60 && percentage < 90) {
      return 'Sua senha precisa melhorar';
    } else if (percentage >= 90) {
      return 'Sua senha parece bem segura';
    }

    return 'Sua senha está fraca';
  }

  async sendPassword() {
    this.loading = true;

    const password = this.newPasswordForm.get('newPassword')?.value;

    try {
      const payload = {
        username: this.username,
        token: this.token,
        password,
      };

      const response = await this.apiService.post({
        route: 'password-reset-complete/',
        body: payload,
      });

      this.toastService.show(
        'info',
        'Sucesso!',
        'Senha alterada com sucesso,Você será redirecionado'
      );

      if (response) {
        const { data } = await this.apiService.post<
          ApiResponse<NewLoginResponse>
        >({
          route: 'new-login/',
          body: { username: payload.username, password: payload.password },
        });

        let permissionsArr = data.active_register.permissions_role
          ? data.active_register.permissions_role.permissions
          : [];

        this.authService.setToken(data.tokens.access);
        this.authService.setUser(data);

        if (
          data.active_register.register.role.applicable === 'representative'
        ) {
          const defaultPermissions: Permission[] = permission;

          permissionsArr = permissionsArr.concat(defaultPermissions);
        }

        this.authService.permissions = permissionsArr;
        this.storageService.set('permissions', permissionsArr, true);

        this.loading = false;
        this.redirectUser(data);
      }
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  redirectToLogin() {
    this.router.navigateByUrl(`/login`);
  }
}
