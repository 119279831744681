<div class="row no-gutters">
    <div class="col-11 offset-lg-1">
        <div class="row">
            <div class="col-12">
                <span class="basic-content-title">
                    Declaração de Condição de Pessoa Não Domiciliada nos EUA
                    (“Non US Person”)
                    ou Pessoa Domiciliada nos EUA (“US Person”)
                </span>
                <app-spacer [bottom]="54"></app-spacer>
            </div>
        </div>

        <form [formGroup]="form">

            <div class="row">
                <div class="col-12">
                    <span class="basic-content-description">Nos termos da
                        Instrução
                        Normativa da Receita Federal do Brasil nº 1.571/15 e
                        Decreto 8.506/15, é considerado
                        como US Person: o cidadão norte-americano por ser
                        nascido nos EUA, ou por ter pais americanos (cidadania
                        derivada) ou, ainda, por ter a cidadania americana
                        (naturalização); ser residente fiscal dos EUA por
                        possuir Green
                        Card, ou ter presença substancial nos EUA para fins
                        tributários, isto é, por pelo menos 31 dias nos últimos
                        12
                        meses, ou por 183 dias nos últimos 36 meses; e/ou ter
                        parte de empresa (Domestic Partnership ou Domestic
                        Corporation) ou Trust constituído nos EUA.</span>
                    <app-spacer [bottom]="16"></app-spacer>

                    <span class="basic-content-description">
                        Com este intuito e para possibilitar a determinação
                        correta pela Banvox da condição e qualificação de
                        seus
                        clientes para os propósitos de tributação dos EUA na
                        qualidade de Non-US Person ou US Person, o(s)
                        cliente(s),
                        neste ato, declara(m) e confirma(m) à Banvox que:
                    </span>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="offset-1 col-10">
                    <p class="tax-option-title">
                        É cidadão natural, naturalizado ou possui
                        residência fiscal nos EUA?
                        <span *ngIf="form.controls.citizen_us.value === null"
                            class="is-required">*</span>
                    </p>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="d-flex offset-1">
                        <app-radio formControlName="citizen_us"
                            [isChecked]="form.controls.citizen_us.value === 'yes'"
                            value="yes">
                            Sim
                        </app-radio>
                        <app-spacer [right]="32"></app-spacer>

                        <app-radio
                            [isChecked]="form.controls.citizen_us.value === 'no'"
                            formControlName="citizen_us" value="no">
                            Não
                        </app-radio>
                    </div>

                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="offset-1 col-10">
                    <p class="tax-option-title">
                        É empresa, ou possui partfe de empresa constituída nos
                        EUA e que tenha sede/filial permanente no país?
                        <span
                            *ngIf="form.controls.permanent_resident_us.value === null"
                            class="is-required">*</span>
                    </p>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="d-flex offset-1">
                        <app-radio formControlName="permanent_resident_us"
                            [isChecked]="form.controls.permanent_resident_us.value === 'yes'"
                            value="yes">
                            Sim
                        </app-radio>
                        <app-spacer [right]="32"></app-spacer>

                        <app-radio
                            [isChecked]="form.controls.permanent_resident_us.value === 'no'"
                            formControlName="permanent_resident_us" value="no">
                            Não
                        </app-radio>
                    </div>

                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="offset-1 col-10">
                    <p class="tax-option-title">
                        Possui Visto de Residência Permanente (Green Card)?
                        <span
                            *ngIf="form.controls.has_green_card.value === null"
                            class="is-required">*</span>
                    </p>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="d-flex offset-1">
                        <app-radio formControlName="has_green_card"
                            [isChecked]="form.controls.has_green_card.value === 'yes'"
                            value="yes">
                            Sim
                        </app-radio>
                        <app-spacer [right]="32"></app-spacer>

                        <app-radio
                            [isChecked]="form.controls.has_green_card.value === 'no'"
                            formControlName="has_green_card" value="no">
                            Não
                        </app-radio>
                    </div>

                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="offset-1 col-10">
                    <p class="tax-option-title">
                        Fornecer o SSN (Social Security Number) dos EUA:
                        <span
                            *ngIf="form.controls.ssn.value === null && form.controls.ssn_free.value === false"
                            class="is-required">*</span>
                    </p>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="d-flex offset-1">
                        <app-input [group]="form" controlName="ssn"
                            placeholder="Digite o SSN" [maxLength]="16"
                            [readOnly]="form.controls.ssn_free.value">
                        </app-input>
                        <app-spacer [right]="32"></app-spacer>

                        <app-radio [multiple]="true" formControlName="ssn_free">
                            Não possuo SSN
                        </app-radio>
                    </div>

                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="offset-1 col-10">
                    <p class="tax-option-title">
                        Entrega declaração de imposto de renda e bens nos EUA
                        (US Tax Return) por qualquer motivo não listado?
                        <span *ngIf="form.controls.us_tax_return.value === null"
                            class="is-required">*</span>
                    </p>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="d-flex offset-1">
                        <app-radio formControlName="us_tax_return"
                            [isChecked]="form.controls.us_tax_return.value === 'yes'"
                            value="yes">
                            Sim
                        </app-radio>
                        <app-spacer [right]="32"></app-spacer>

                        <app-radio
                            [isChecked]="form.controls.us_tax_return.value === 'no'"
                            formControlName="us_tax_return" value="no">
                            Não
                        </app-radio>
                    </div>

                    <div *ngIf="form.controls.us_tax_return.value === 'yes'">
                        <app-spacer [bottom]="16"></app-spacer>
                        <app-input type="textarea" [group]="form"
                            controlName="description_us_tax" [rows]="4"
                            label="Favor informar o motivo abaixo"
                            [noResize]="true" [maxLength]="300">
                        </app-input>
                    </div>
                    <app-spacer [bottom]="54"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-10 d-flex flex-column">
                    <span class="basic-content-subtitle">Titularidade</span>
                    <app-spacer [bottom]="16"></app-spacer>
                    <span class="basic-content-description">O(s) cliente(s)
                        abaixo
                        assinado(s) declara(m), neste ato, ser(em) titular(es),
                        de acordo com os princípios
                        tributários dos EUA, dos bens e rendimentos registrados
                        em suas contas junto à Banvox ou, se houver outros
                        beneficiários, que nenhum desses outros beneficiários é
                        uma “US Person” de acordo com os princípios
                        tributários daquele país.</span>
                    <app-spacer [bottom]="48"></app-spacer>
                </div>

            </div>

            <div class="row">
                <div class="col-10 d-flex flex-column">
                    <span class="basic-content-subtitle">Alteração de
                        circunstâncias em
                        relação à condição de Non-US Person</span>
                    <app-spacer [bottom]="16"></app-spacer>
                    <span class="basic-content-description">Enquanto mantiver a
                        condição de
                        cliente, comprometo-me a notificar a Banvox, no
                        prazo de 30 dias caso a
                        minha condição de Non-US Person seja alterada para a
                        condição de US Person.
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                    <span class="basic-content-description">
                        O não preenchimento da declaração de condição de Pessoa
                        Não Domiciliada nos EUA ou Domiciliada nos EUA
                        implica na impossibilidade de relacionamento com a
                        Banvox.
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                    <span class="basic-content-description">
                        O cliente, neste ato, na qualidade de “US Person”,
                        autoriza a Banvox reportar informações cadastrais e
                        financeiras às autoridades competentes.</span>
                    <app-spacer [bottom]="48"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="offset-2 col-8">
                    <app-radio [multiple]="true" formControlName="signed">
                        <div class="flex-1 d-flex flex-column">
                            <span class="tax-option-title">Por ser expressão da
                                verdade, assino a presente
                            </span>
                            <app-spacer [bottom]="8"></app-spacer>
                            <span class="tax-option-subtitle">Ao selecionar este
                                item, você estará assinando este
                                contrato
                            </span>
                        </div>
                    </app-radio>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>
        </form>
    </div>
</div>