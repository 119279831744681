<div class="app-input-container" [formGroup]="group">
    <div class="app-input-label-row">
        <label class="app-input-label" *ngIf="label" [for]="controlName">
            <span *ngIf="getRequired()" class="required-field"
                [class.error]="getInvalid()">*</span>
            {{ label }}
        </label>

        <button *ngIf="button !== ''" class="app-input-label-link"
            (click)="onButtonClicked($event)">{{button}}</button>
    </div>
    <div class="app-input-entry-container" [ngClass]="getClasses()">
        <ng-container *ngIf="!standalone;else standaloneInput">
            <ng-container *ngIf="!usePatterns;else patternTemplate">
                <input class="app-input-entry" [class.alwaysValid]="alwaysValid"
                    [type]="type" [id]="controlName"
                    [name]="name ?? controlName" [placeholder]="placeholder"
                    [formControlName]="controlName" [readOnly]="!!readOnly"
                    [maxlength]="max" [prefix]="prefix" [suffix]="suffix"
                    [mask]="mask ?? ''" [dropSpecialCharacters]="false" />
            </ng-container>
        </ng-container>

        <ng-template #standaloneInput>
            <ng-container *ngIf="!usePatterns;else patternTemplate">
                <input class="app-input-entry" [type]="type" [id]="controlName"
                    [name]="name ?? controlName" [placeholder]="placeholder"
                    [readOnly]="!!readOnly" [prefix]="prefix" [suffix]="suffix"
                    [mask]="mask ?? ''" [dropSpecialCharacters]="false"
                    [(ngModel)]="value" [ngModelOptions]="{standalone: true}"
                    (change)="handleChange($event)" />
            </ng-container>
        </ng-template>

        <ng-template #patternTemplate>
            <input class="app-input-entry" [type]="type" [id]="controlName"
                [name]="name ?? controlName" [placeholder]="placeholder"
                [formControlName]="controlName" [readOnly]="!!readOnly"
                [prefix]="prefix" [suffix]="suffix" [mask]="mask ?? ''"
                [dropSpecialCharacters]="false" [patterns]="pattern" />
        </ng-template>


        <div *ngIf="label === 'CEP *'" class="app-input-icon search">
            <svg-icon src="assets/images/icon_search.svg"></svg-icon>
        </div>

        <div *ngIf="getError()" class="app-input-icon error">
            <i class="fas fa-exclamation"></i>
        </div>
    </div>
    <p class="app-input-helper" *ngIf="helpText && !getError()">{{helpText}}
    </p>
    <p class="app-input-error" *ngIf="getError()">{{errorText}}</p>
</div>