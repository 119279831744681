import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FundService } from 'src/app/services/fund/fund.service';

type ProviderProps = {
  label: string;
  value: string;
  role: string;
  extra: string;
};
@Component({
  selector: 'app-dashboard-approval-providers',
  templateUrl: './dashboard-approval-providers.component.html',
  styleUrls: ['./dashboard-approval-providers.component.scss'],
})
export class DashboardApprovalProvidersComponent implements OnInit {
  //   @Input() admin: ProviderProps;
  //   @Input() manager: ProviderProps;

  //   @Output() onClick = new EventEmitter<MouseEvent>();

  columns: TableColumnList<any>[] = [
    {
      name: 'Nome',
      cell: (row) => {
        return `
    <div class="dash-card-item p-0">
        <div class="dash-card-item-initials mr-3">
            <span>${this.getInitials(row.name)}</span>
        </div>

        <div class="dash-card-item-content">
            <div class="dash-card-item-content-title">
                ${row.name}
            </div>
            <div class="dash-card-item-content-subtitle">
                ${row.document ? this.formatDocument(row.document) : '-'}
            </div>
        </div>
    </div>
        `;
      },
      size: 2,
    },
    {
      name: 'Função',
      cell: (row) => {
        return `
        <div class="dash-card-item-content">
      <div class="dash-card-item-content-title">
         ${row.provider_type}
      </div>
      <div class="dash-card-item-content-subtitle">
        ${row.role}
      </div>
  </div>`;
      },
    },
    {
      name: '',
      cell: (row) =>
        `<div class="d-flex align-items-center icon-container">
                <a href="/app/details/provider/${row.id}">
                    <i class="fas fa-eye mr-0"></i>
                </a>
            </div>`,
    },
  ];
  data: any[] = [];
  loading = true;

  constructor(
    private fundService: FundService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    if (this.authService.approvalData.service_providers) {
      this.data = this.authService.approvalData.service_providers;
      this.loading = false;
    } else {
      this.getApprovalData();
    }
  }

  async getApprovalData() {
    try {
      const req_params = {
        register: this.route.snapshot.params.id,
        tab: 'service_providers',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<any[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      const admin =
        data[0].register.admin && data[0].register.admin.length > 0
          ? data[0].register.admin[0]
          : null;
      const manager =
        data[0].register.manager && data[0].register.manager.length > 0
          ? data[0].register.manager[0]
          : null;

      if (admin) {
        admin.provider_type = 'Administrador';
      }

      if (manager) {
        manager.provider_type = 'Gestor';
      }

      this.data = [admin, manager].filter((item) => item);
      this.authService.approvalData.service_providers = this.data;

      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  formatDate(date: string) {
    const day = new Date(date).toLocaleDateString();
    const hours = new Date(date).toLocaleTimeString();
    return `${day} às ${hours}`;
  }

  formatDocument(document: string) {
    if (document.length < 14) {
      return (
        'CPF: ' +
        document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
      );
    }

    return (
      'CNPJ: ' +
      document.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      )
    );
  }

  getInitials(name: string) {
    return name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }
}
