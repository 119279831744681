<div class="dashboard">
    <div class="row w-100">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card fund-dash">
                <app-back-button></app-back-button>

                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="dash-card-header">
                            <span>Solicitar acesso</span>
                        </div>
                    </div>

                    <div class="col-12">
                        <form [formGroup]="form"
                            class="d-flex align-items-end padding-container w-100">
                            <div class="row w-100">
                                <div class="col-8 d-flex align-items-end">
                                    <app-masked-input [group]="form"
                                        controlName="document" label="Documento"
                                        helpText="Se pessoa física inserir CPF"
                                        style="width: 100%;" mask="CPF_CNPJ"
                                        placeholder="12.345.678/0001-09">
                                    </app-masked-input>

                                    <app-spacer [left]="8"></app-spacer>

                                    <div class="w-100">
                                        <div class="d-flex align-items-center">
                                            <app-button
                                                (onClick)="checkDocument()"
                                                [disabled]="form.invalid"
                                                [outline]="true"
                                                [loading]="loading">
                                                Validar documento
                                            </app-button>
                                        </div>
                                        <app-spacer [bottom]="22"></app-spacer>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col-12">
                        <div *ngIf="documentChecked"
                            class="solicitation-container">
                            <app-empty-content [isVisible]="true"
                                [title]="renderTitle()"
                                [description]="renderSubtitle()"
                                [buttonLabel]="alreadyHaveAccess || userNotFound ? '' : 'Solicitar acesso ao cadastro'"
                                [haveButtonIcon]="false"
                                (onClick)="toggleModal()" [icon]="renderImg()">
                            </app-empty-content>
                        </div>
                    </div>

                    <app-warning-modal [active]="requestModal">
                        <h3>Atenção</h3>
                        <app-spacer [bottom]="24"></app-spacer>

                        <form [formGroup]="requestForm">
                            <p>
                                A empresa SOCINAL LTDA já possui cadastro no
                                sistema, para solicitar acesso aos dados, por
                                favor indique o Fundo que este será vinculado
                            </p>
                            <app-spacer [bottom]="16"></app-spacer>
                            <app-select label="Fundo de interesse"
                                [group]="requestForm" controlName="fund"
                                [items]="fundOptions">
                            </app-select>
                            <app-spacer [bottom]="56"></app-spacer>

                            <app-button (onClick)="sendRequest()"
                                [center]="true" [loading]="sending"
                                [disabled]="this.requestForm.invalid">
                                Enviar solicitação para responsável
                            </app-button>
                            <app-spacer [bottom]="8"></app-spacer>
                            <app-button (onClick)="toggleModal()"
                                [outline]="true" [center]="true"
                                [disabled]="sending" class="add-button">
                                Cancelar
                            </app-button>
                        </form>

                    </app-warning-modal>
                </div>
            </div>
        </div>
    </div>
</div>