import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils, { GetHelper } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export type CompanyIncomeSalaryValues = {
  salary: string;
  other_income: string;
  id: number;
  financial_position_at_pj: string;
};

@Component({
  selector: 'app-signup-unified-company-income',
  templateUrl: './signup-unified-company-income.component.html',
  styleUrls: ['./signup-unified-company-income.component.scss'],
})
export class SignupUnifiedCompanyIncomeComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    wage: [0, [Validators.min(1)]],
    other_income: [0, [Validators.min(1)]],
    financial_position_at_pj: [
      null,
      [
        Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
      ],
    ],
  });

  event: any;
  data: CompanyIncomeSalaryValues;

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private toast: ToastService,
    private api: ApiService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.event = this.signupService.sendCompanyData.subscribe((value) => {
      if (value === 'income') {
        this.sendData();
      }
    });

    this.getData();

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setCompanyFormStatus(
        'incomeSalary',
        status === 'VALID'
      );
    });
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/wage/me/',
        token: true,
      });

      this.form.patchValue({
        ...data,
        financial_position_at_pj: data.financial_position_at_pj
          ? data.financial_position_at_pj.split('-').reverse().join('/')
          : null,
      });

      this.data = data;
    } catch (error) {
      console.error(error);
    }
  }

  async sendData() {
    this.signupService.loading = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        wage: valuesHelper.get('wage'),
        other_income: valuesHelper.get('other_income'),
        financial_position_at_pj: valuesHelper.get('financial_position_at_pj')
          ? valuesHelper
              .get('financial_position_at_pj')
              .split('/')
              .reverse()
              .join('-')
          : null,
      };

      if (this.data) {
        const { data } = await this.api.put({
          route: `api/registration/wage/${this.data.id}/`,
          token: true,
          body: payload,
        });

        this.data = data;
      } else {
        await this.api.post({
          route: 'api/registration/wage/',
          token: true,
          body: payload,
        });
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.signupService.loading = false;
  }
}
