import { Theme } from './../index.d';

const UI_THEME_DEFAULT: Theme = {
  pallete: {
    main: '#0062b3',
    'alternative-main': '#005bb9',
    'main-light': '#80b1d9',
    'main-alternative-light': '#4d91ca',
    'main-lighter': '#ccdfef',
    'main-alternative-lighter': '#f2f6f9',
    'new-ligther': '#f4f4f4',
    'new-gray': '#525252',
    black: '#242424',
    'new-black': '#161616',
    'alternative-dark': '#191919',
    'input-black': '#4c4c4c',
    'alternative-black': '#3b3b3b',
    'light-gray': '#fff8f5',
    secondary: '#ff7e40',
    'alternative-secondary': '#ff7610',
    'alternative-secondary-light': '#ff9c36',
    'secondary-light': '#ffbca4',
    'secondary-lighter': '#ffe5d8',
    'yellow-light': '#ffe396',
    dark: '#666666',
    'dark-light': '#7f7f7f',
    gray: '#a4a3a3',
    'alternative-gray': '#929292',
    'gray-light': '#a6a6a6',
    'gray-dark': '#484747',
    light: '#808080',

    green: '#00b329',
    'light-purple': '#f4f2f9',
    'light-purple-opacity': '#1971BA',

    'secondary-font': "'Proxima Nova', serif",
    'main-font': "'Open Sans', sans-serif",

    'font-xxs': '10px',
    'font-xs': '12px',
    'font-sm': '14px',
    'font-md': '16px',
    'font-lg': '18px',
    'font-xl': '24px',
    'font-xxl': '32px',

    'main-dark': '#2680eb',
    'transparent-main-light': '#1191e23d',

    'secondary-dark': '#e8c61c',
    'transparent-secondary-dark': '#ffc73d52',

    purple: '#651fff',
    'lighter-purple': '#e0d2ff',
    'lighter-alternative-purple': '#d5cce7',
    'alternative-dark-purple': '#6d4dae',
    'dark-purple': '#410cb3',
    'transparent-purple': 'rgba(151, 128, 197, 1)',

    orange: '#ff911f',
    'orange-light': '#ffbfa0',

    danger: '#e80606',
    'danger-dark': '#950000',
    'danger-light': '#ef5151',
    'danger-lighter': '#facdcd',

    lighter: '#e5e5e5',
    lightness: '#f8f8f8',
    'transparent-gray': '#d6dcd97a',

    white: '#ffffff',
    'transparent-black': '#00000014',
    'alternative-transparent-black': 'rgba(0, 0, 0, 0.4)',

    logo: '',
    'logo-horizontal': '',
    // logo: 'url("/assets/images/logo-cmcapital3.png")',
    // 'logo-horizontal': 'url("/assets/images/logo-cmcapital.png")',
  },
  brand: {
    name: 'Prosperità',
    desc: 'Módulo de cadastro',
    prefix: 'prosperita',
    footerText: 'Prosperità - Todos os direitos reservados 2022',
    logoText: 'Prosperità',
    logoWidth: 60,
    minWidth: 60,
    logo: '',
    logoHorizontal: '',
    favicon: '',
    // logo: '/assets/images/logo-cmcapital3.png',
    // logoHorizontal: '/assets/images/logo-cmcapital.png',
    // favicon: 'cm-favicon.ico',
    simplifiedFund: false,
  },
  api: {
    local: 'http://localhost:8000',
    dev: 'https://dev.api.data.prosperita.io',
    stage: 'https://stage.api.data.prosperita.io',
    prod: 'https://api.data.prosperita.io',
    clarity_id: 'fhco559uuk',
    sentry_dsn: '',
  },
};

export default UI_THEME_DEFAULT;
