<div class="row no-gutters">

    <div class="col-12 offset-lg-1 col-lg-10">

        <div class="row">
            <div class="col-12 col-lg-9">
                <span class="basic-content-title">
                    Informações sobre a situação
                    patrimonial e financeira</span>
                <app-spacer [bottom]="48"></app-spacer>
            </div>
        </div>

        <app-signup-unified-company-income-realty></app-signup-unified-company-income-realty>
        <app-spacer [bottom]="70"></app-spacer>

        <div class="row">
            <div class="col-12 col-lg-9">
                <span class="section-title">
                    Outros Bens e Direitos, inclusive
                    aplicações financeiras
                </span>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <app-signup-unified-company-income-others></app-signup-unified-company-income-others>
        <app-spacer [bottom]="70"></app-spacer>

        <form [formGroup]="form">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <app-masked-input [group]="form"
                        controlName="financial_position_at_pj" mask="00/00/0000"
                        label="Data da fundação" placeholder="dd/mm/aaaa">
                    </app-masked-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>


                <div class="col-12 col-lg-6">
                    <app-currency-input [group]="form" controlName="wage"
                        label="Patrimônio liquido" mask="separator.2"
                        placeholder="R$ 20.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-currency-input [group]="form"
                        controlName="other_income"
                        label="Faturamento médio mensal" mask="separator.2"
                        placeholder="R$ 10.000,00">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>
        </form>

    </div>
</div>