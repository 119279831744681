<app-container>
    <div class="row no-gutters w-100 auth-layout signup-basic-page">
        <aside class="col-12 col-lg-4">
            <div class="basic-sidebar-body">
                <div *ngIf="!loadingService.loading">
                    <img [src]="UIDefaultTheme.brand.logoHorizontal"
                        class="sidebar-logo d-none d-lg-flex">

                    <app-spacer [bottom]="56" class="d-none d-lg-block">
                    </app-spacer>

                    <app-stepper [steps]="steps" [activeIndex]="activeStep">
                    </app-stepper>
                </div>

                <div *ngIf="loadingService.loading">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                        height: '80px',
                        width: '200px',
                        'border-radius': '10px'
                      }">
                    </ngx-skeleton-loader>

                    <app-spacer [bottom]="56" class="d-none d-lg-block">
                    </app-spacer>

                    <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
                </div>

            </div>

            <div class="basic-sidebar-footer">
                <div class="basic-sidebar-footer-content">
                    <div class="row no-gutters w-100">
                        <div class="col-4 left-side">
                            <ng-container *ngIf="!loadingService.loading">
                                <i class="fal fa-question-circle"
                                    placement="top" ngbTooltip="Ajuda"
                                    tooltipClass="custom-tooltip"
                                    *ngIf="UIDefaultTheme.brand.supportEmail"
                                    (click)="toggleSupportModal()"></i>

                                <span class="basic-sidebar-footer-text"
                                    [class.text-right]="UIDefaultTheme.brand.supportEmail">
                                    {{message}}
                                </span>
                            </ng-container>

                            <ng-container *ngIf="loadingService.loading">
                                <ngx-skeleton-loader [theme]="{
                                    height: '40px',
                                    width: '200px',
                                    marginBottom:'0px'
                                }">
                                </ngx-skeleton-loader>
                            </ng-container>
                        </div>

                        <div class="col-8 right-side"
                            [class.inviteLabels]="label !== null && name !== '' && activeStep >= 1">

                            <ng-container *ngIf="loadingService.loading">
                                <ngx-skeleton-loader [theme]="{
                                    height: '40px',
                                    width: '200px',
                                    marginBottom:'0px'
                                }">
                                </ngx-skeleton-loader>
                            </ng-container>

                            <ng-container *ngIf="!loadingService.loading">
                                <ng-container
                                    *ngIf="label !== null && name !== '' && activeStep >= 1">
                                    <div class="basic-info">
                                        <h3>{{label}}</h3>
                                        <h4>{{name}}</h4>
                                    </div>
                                </ng-container>

                                <app-button
                                    [disabled]="!canProceed() || loading"
                                    [loading]="loading" (onClick)="proceed()"
                                    [next]="true" [class.d-none]="hided">
                                    <div class="basic-siderbar-footer-button">
                                        <span
                                            class="basic-sidebar-footer-button-text"
                                            [class.avaliable]="canProceed() || loading">Seguir</span>
                                        <svg-icon [src]="getButtonIcon()"
                                            [class.avaliable]="canProceed() || loading">
                                        </svg-icon>
                                    </div>
                                </app-button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </aside>

        <main class="col-12 col-lg-8 basic-content">
            <ng-container [ngSwitch]="activeStep">
                <ng-container *ngSwitchCase="0">
                    <app-signup-unified-personal [userRole]="role">
                    </app-signup-unified-personal>
                </ng-container>

                <ng-container *ngSwitchCase="1">
                    <app-signup-unified-password>
                    </app-signup-unified-password>
                </ng-container>

                <ng-container *ngSwitchCase="2">
                    <app-signup-unified-profile>
                    </app-signup-unified-profile>
                </ng-container>

                <ng-container *ngSwitchCase="3">
                    <app-signup-unified-requirements [userRole]="role">
                    </app-signup-unified-requirements>
                </ng-container>
            </ng-container>
        </main>
    </div>

    <ng-container *ngIf="validateForm">
        <app-basic-modal>
            <app-validate (onDigitsValidated)="onValidate($event)"
                title="Validação de segurança"
                description="Informe o código de segurança que enviamos para o número do seu celular">
            </app-validate>
        </app-basic-modal>
    </ng-container>
</app-container>
```
<ng-container *ngIf="UIDefaultTheme.brand.supportEmail">
    <app-warning-modal [active]="showSupportModal" [hasBorder]="true"
        (onClose)="toggleSupportModal()">
        <h2>Precisa de ajuda</h2>
        <app-spacer [bottom]="8"></app-spacer>
        <p>você pode entrar em contato conosco pelos meios abaixo:</p>
        <app-spacer [bottom]="8"></app-spacer>
        <a
            [href]="'mailto:' + UIDefaultTheme.brand.supportEmail">{{UIDefaultTheme.brand.supportEmail}}</a>
    </app-warning-modal>
</ng-container>