import { Component, OnInit } from '@angular/core';
import Utils from 'src/app/helpers/utils';
import { AgentsService } from 'src/app/services/agents/agents.service';
import { ApiService } from 'src/app/services/api/api.service';
import { AuditService } from 'src/app/services/audit/audit.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ZeroingService } from 'src/app/services/zeroing/zeroing.service';
@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss'],
})
export class DashboardAdminComponent implements OnInit {
  names: { [Key: string]: string } = {
    ALL: 'Todos',
    ADM: 'Administração',
    ASSIGNOR: 'Cedente',
    SHAREHOLDER: 'Cotista',
    PROVIDER: 'Prestador de serviço',
    GUARANTOR: 'Avalista',
    REPRESENTATIVE: 'Representante',
  };

  constructor(
    private api: ApiService,
    private fundsService: FundService,
    private toast: ToastService,
    private agentsService: AgentsService,
    private auditService: AuditService,
    private zeroingService: ZeroingService
  ) {}

  ngOnInit(): void {
    this.getFunds();
    this.getAgents();
    this.getAudit();
    this.getZeroing();
  }

  async getAgents() {
    try {
      const res = await this.api.get({
        route: 'api/roles',
        token: true,
      });

      const newAgentsOptions = res.map((_agent: any) => {
        const roles = _agent.slug.split('-');

        if (roles[0].includes('ADM') || roles[0].includes('REPRESENTATIVE')) {
          return undefined;
        }
        return {
          label: `${this.names[roles[0]]} ${_agent.name}`,
          value: _agent.id,
          extra: roles.includes('PJ') ? 'PJ' : 'PF',
        };
      });

      this.agentsService.data = newAgentsOptions.filter(
        (agent: any) => agent !== undefined
      );

      this.agentsService.onChange.emit();
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async getZeroing() {
    try {
      const res = await this.api.get({
        route: 'api/registration/zeroing_fund',
        token: true,
      });

      const zeroingOptions = res.map((_zeroing: any) => {
        return {
          label: _zeroing.corporate_name,
          value: _zeroing.id,
        };
      });

      this.zeroingService.data = zeroingOptions;
      this.zeroingService.onChange.emit();
    } catch (error) {
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async getAudit() {
    try {
      const res = await this.api.get({
        route: 'api/registration/audit/',
        token: true,
      });

      const auditOptions = res.map((_audit: any) => {
        return {
          label: _audit.corporate_name,
          value: _audit.id,
        };
      });

      this.auditService.data = auditOptions;
      this.auditService.onChange.emit();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/fund',
        token: true,
      });

      const filteredArr = Utils.filterRepeatedValues(res.funds, 'id');

      const funds = filteredArr.map((fund: any) => {
        return {
          label: fund.name,
          value: fund.id,
        };
      });

      this.fundsService.fundList = funds;
      this.fundsService.onChange.emit();
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar fundos de interesse');
    }
  }
}
