import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreviousUrlService } from 'src/app/services/previous-url/previous-url.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Input() label?: string = 'Retornar ao painel';
  @Input() step: number | null = null;
  @Input() defaultRoute: string | null = '/app/dashboard';
  @Input() useDefaultRoute: boolean = false;

  pastRoute = '';

  constructor(private router: Router, private urlService: PreviousUrlService) {}

  ngOnInit(): void {
    if (this.useDefaultRoute) {
      this.pastRoute = this.defaultRoute;
    } else {
      this.pastRoute = this.urlService.getPreviousUrl();
    }
  }

  redirectUser() {
    if (this.step) {
      this.router.navigateByUrl(this.pastRoute, {
        state: { step: this.step },
      });
    } else {
      this.router.navigateByUrl(this.pastRoute);
    }
  }
}
