<form [formGroup]="form">

    <ng-container *ngIf="!showForm">
        <app-table [columns]="columns" [data]="data" [footerData]="footerData"
            (onRemove)="removeOthers($event)">
        </app-table>
        <app-spacer [bottom]="35"></app-spacer>
    </ng-container>

    <ng-container *ngIf="showForm">
        <div class="row">
            <div class="col-12 col-lg-8">
                <app-input [group]="form" controlName="type" label="Tipo"
                    placeholder="Investimento, imóvel etc">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-4">
                <app-currency-input [group]="form" controlName="current_value"
                    label="Valor atual" placeholder="R$ 900.000,00">
                </app-currency-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-12">
                <app-input [group]="form" controlName="description"
                    [maxLength]="80" label="Descrição" placeholder="(opcional)">
                </app-input>
                <app-spacer [bottom]="32"></app-spacer>
            </div>
        </div>
    </ng-container>

    <div class="row">
        <div class="col-12 align-items-center">
            <app-button (onClick)="triggerForm()" [disabled]="buttonDisabled()"
                class="add-button mr-3" [outline]="true">
                <i class="fas fa-plus-circle mr-2"></i>
                Adicionar
            </app-button>

            <app-button (onClick)="handleCancel()" [outline]="true"
                *ngIf="showForm && data.length > 0">
                Cancelar
            </app-button>
        </div>
    </div>
</form>