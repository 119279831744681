import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GetHelper } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export type CompanyConditionsValues = {
  investor_condition: string;
  signed: boolean;
  id?: number;
};

@Component({
  selector: 'app-signup-unified-company-conditions',
  templateUrl: './signup-unified-company-conditions.component.html',
  styleUrls: ['./signup-unified-company-conditions.component.scss'],
})
export class SignupUnifiedCompanyConditionsComponent
  implements OnInit, OnDestroy
{
  form = this.formBuilder.group({
    investor_condition: [null, [Validators.required]],
    signed: [false, [Validators.requiredTrue]],
  });

  data: CompanyConditionsValues;

  options = [
    {
      value: 'never',
      label:
        'Declaro, sob as penas da lei, que possuo investimentos financeiros em valor superior a R$ 10.000.000,00 (dez milhões de reais)',
    },
    {
      value: 'up_to_five',
      label:
        'Declaro, sob as penas da lei, que possuo investimentos financeiros em valor superior a R$ 1.000.000,00 (um milhão de reais)',
    },
    {
      value: 'up_to_fifteen',
      label:
        'Declaro que tenho sido aprovado em exames de qualificação técnica ou possuo certificações aprovadas pela CVM como requisitos para o registro de agentes autônomos de investimento, administradores de carteira, analistas e consultores de valores mobiliários, em relação a seus recursos próprios',
    },
  ];
  event: any;

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.event = this.signupService.sendCompanyData.subscribe((value) => {
      if (value === 'conditions') {
        this.sendData();
      }
    });

    this.getData();

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setCompanyFormStatus('conditions', status === 'VALID');
    });
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/investor_profile_pj/me/',
        token: true,
      });

      this.form.patchValue({
        investor_condition: data.investor_condition,
        signed: data.investor_condition ? true : false,
      });
      this.form.updateValueAndValidity();

      this.data = data;
    } catch (error) {
      console.log(error);
    }
  }

  async sendData() {
    this.signupService.loading = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        investor_condition: valuesHelper.get('investor_condition'),
        signed: valuesHelper.get('signed'),
      };

      if (this.data && this.data.id) {
        await this.api.put({
          route: `api/registration/investor_profile_pj/${this.data.id}/`,
          token: true,
          body: payload,
        });
      } else {
        await this.api.post({
          route: 'api/registration/investor_profile_pj/',
          token: true,
          body: payload,
        });
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.signupService.loading = false;
  }
}
