<div class="attachment-section">
    <div class="d-flex align-items-center justify-content-between">
        <h2>{{label}}</h2>
        <button type="button" class="collapse-button" (click)="isCollapsed = ! isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <i [class]="isCollapsed ? 'fas fa-plus' : 'fas fa-times' "></i>
        </button>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <app-spacer [top]="30"></app-spacer>
        <ng-content>
        </ng-content>
    </div>
</div>