import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
})
export class TableListComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns: TableColumnList<any>[] = [];

  @Input() size: string = '12px';

  @Input() removeButton: boolean = false;
  @Input() custom: boolean = false;
  @Input() footerData: any[] = [];
  @Input() paddingBottom?: string;

  @Output() onRemove = new EventEmitter<number>();

  columnsToDisplay: TableColumnList<any>[] = [];
  footerColumnsToDisplay: TableColumnList<any>[] = [];
  gridTemplate = 'repeat(auto-fit, 1fr)';

  constructor() {}

  ngOnInit(): void {
    const newColumns = [...this.columns].map((column) => ({
      ...column,
      size: column.size || 1,
    }));

    const gridTemplate = newColumns
      .map((column) => `${column.size}fr`)
      .join(' ');
    this.gridTemplate = gridTemplate;

    const newFooterColumns = [...this.columns];

    newFooterColumns.push({ name: '', cell: () => '' });

    this.columnsToDisplay = newColumns;
    this.footerColumnsToDisplay = newFooterColumns;
  }

  ngAfterViewInit() {}

  getGrid() {
    return {
      'grid-template-columns': this.gridTemplate,
    };
  }

  setPadding(size: string) {
    return {
      'padding-top': size,
      'padding-bottom': size,
    };
  }

  onRemoveClicked(index: number) {
    this.onRemove.emit(index);
  }

  handleClick(e: MouseEvent) {
    e.target;
  }
}
