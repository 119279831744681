<div class="user-settings-modal-background" [class.active]="active">
    <div class="user-settings-modal">
        <div class="user-settings-modal-header">
            <h2>{{title}}</h2>
            <span (click)="handleCloseModal()">+</span>
        </div>
        <svg-icon [src]="figure"></svg-icon>

        <div class="user-settings-modal-content">
            <ng-content></ng-content>
        </div>

        <div class="user-settings-modal-footer">
            <app-button [phantom]="true" (onClick)="handleCloseModal()">
                Cancelar
            </app-button>

            <app-button (onClick)="handleSubmit()" [loading]="isLoading"
                [disabled]="isDisabled">
                {{confirmLabel}}
            </app-button>
        </div>
    </div>
</div>