import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-signup-unified-profile',
  templateUrl: './signup-unified-profile.component.html',
  styleUrls: ['./signup-unified-profile.component.scss'],
})
export class SignupUnifiedProfileComponent implements OnInit {
  form = this.formBuilder.group({
    type: [null, [Validators.required]],
  });

  role: string = '';

  profileTypes: Choice[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private router: Router
  ) {
    let urlParts = this.router.url.split('/basic')[0].split('/');
    let range = urlParts.length;
    this.role = urlParts[range - 1];

    const roles = signupService.getRoles();

    const getRoleIcon = (role: any) => {
      const upercaseRole = this.role.toUpperCase();

      switch (role.slug) {
        case `${upercaseRole}-PF-PF`:
          return 'icon-person';

        case `${upercaseRole}-PJ-PJ`:
          return 'icon-business';

        case `${upercaseRole}-PJ-FI`:
          return 'icon-funds';

        default:
          return 'icon-person';
      }
    };

    const newChoices = roles
      .filter(
        (role) =>
          role.is_visible &&
          (role.applicable === 'all' || role.applicable === this.role)
      )
      .sort((a, b) => a.id - b.id)
      .map((role) => {
        const roleValues = role.slug.split('-');

        const icon = getRoleIcon(role);

        return {
          icon,
          title: `${role.name}`,
          description: role.description,
          value: roleValues[2],
          extra: roleValues[1],
        };
      });

    this.profileTypes = newChoices;
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((values) => {
      const roles = this.signupService.getRoles();

      const profile = roles
        .filter(
          (role) =>
            role.is_visible &&
            (role.applicable === 'all' || role.applicable === this.role)
        )
        .find((_role) => _role.slug.split('-')[2] === `${values.type}`);

      const roleValues = profile?.slug ? profile.slug.split('-') : [];

      this.signupService.setBasicFormData('profile', {
        type: roleValues[1],
        role: roleValues[2],
        id: profile?.id,
      });
    });

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setBasicFormStatus('profile', status === 'VALID');

      if (status === 'VALID') {
        this.signupService.submitBasicForm.emit();
      }
    });
  }
}
