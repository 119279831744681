<div class="app-input-container" [formGroup]="group">
    <div class="app-input-label-row">
        <label class="app-input-label" *ngIf="label" [for]="controlName">
            <span *ngIf="getRequired()" class="required-field"
                [class.error]="getInvalid()">*</span>
            {{ label }}
        </label>

        <button *ngIf="button !== ''" class="app-input-label-link"
            (click)="onButtonClicked($event)">{{button}}</button>
    </div>
    <div class="app-input-entry-container" [ngClass]="getClasses()">

        <input class="app-input-entry" [type]="type" [id]="controlName"
            [name]="name ?? controlName" [placeholder]="placeholder"
            [maxLength]="maxLength" [readOnly]="!!readOnly" currencyMask
            [options]="options" [formControlName]="controlName"
            autocomplete="off" />

        <div *ngIf=" getError()" class="app-input-icon error">
            <i class="fas fa-exclamation"></i>
        </div>
    </div>
    <p class="app-input-helper" *ngIf="helpText && !getError()">{{helpText}}
    </p>
    <p class="app-input-error" *ngIf="getError()">{{errorText}}</p>
</div>