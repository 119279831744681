import { Injectable } from '@angular/core';
import Utils from 'src/app/helpers/utils';
import { ApiService } from '../api/api.service';
import { ToastService } from '../toast/toast.service';

export type ViaCepResponse = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private apiService: ApiService, private toast: ToastService) {}

  fetchZipcode(cep: string) {
    return this.apiService.post<ViaCepResponse>({
      route: `api/registration/zipcode`,
      body: { zipcode: cep },
      token: true,
    });
  }

  async getZipcode(zipcode: string) {
    const zipcodeNum = Utils.onlyNumbers(zipcode);

    if (zipcodeNum.length === 8) {
      try {
        const zipcodeRes = await this.fetchZipcode(zipcodeNum);

        return zipcodeRes;
      } catch (error) {
        this.toast.show(
          'error',
          'Erro',
          'Informações de CEP não encontradas, verifique se o CEP está correto.'
        );
        console.error(error);
      }
    }

    return undefined;
  }
}
