import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { countriesOptions } from 'src/app/data/countries';
import { statesOptions } from 'src/app/data/states';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

export type CompanyIncomeRealtyValues = {
  type: string;
  current_value: string;
  address: {
    cep: string;
    public_place: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    uf: string;
    country: string;
  };
};
@Component({
  selector: 'app-signup-unified-company-income-realty',
  templateUrl: './signup-unified-company-income-realty.component.html',
  styleUrls: ['./signup-unified-company-income-realty.component.scss'],
})
export class SignupUnifiedCompanyIncomeRealtyComponent implements OnInit {
  form = this.formBuilder.group({
    type: [null, [Validators.required]],
    current_value: [null, [Validators.required, Validators.min(1)]],

    cep: [null, [UtilsValidators.cep, Validators.required]],
    public_place: [null, [Validators.required]],
    number: [null, [Validators.required]],
    complement: [null, []],
    district: [null, [Validators.required]],
    city: [null, [Validators.required]],
    uf: [null, [Validators.required]],
    country: [null, [Validators.required]],
  });

  columns: TableColumn<any>[] = [
    { name: 'Tipo do imóvel', cell: (row) => row.type },
    {
      name: 'Endereço completo',
      cell: (row) => `
      ${
        row.address && row.address.cep
          ? ` <span>
            ${row.address.public_place}, ${row.address.number}, ${
              row.address.complement ?? ''
            } ${row.address.district}
            </span>
            <span class="table-subtitle">${row.address.city}, ${
              row.address.uf
            } — ${row.address.cep}</span>`
          : ''
      }

      `,
    },
    {
      name: 'Valor atual',
      cell: (row) => Utils.formatBrl(Number(row.current_value)),
    },
  ];

  data: any[] = [];

  footerData: Partial<CompanyIncomeRealtyValues>[] = [];

  showForm = true;

  zipcodeFetched = false;

  states = statesOptions;
  countries = countriesOptions;

  constructor(
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private signupService: SignupService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  setFooterData() {
    const total = String(
      this.data.reduce(
        (prev, curr) => Number(prev) + Number(curr.current_value ?? 0),
        0
      )
    );

    this.footerData = [
      {
        type: '<span><strong>Total declarado<strong></span>',

        current_value: total,
      },
    ];
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/properties/',
        token: true,
      });

      this.data = data;

      this.signupService.setCompanyFormStatus(
        'incomeRealty',
        this.data.length > 0
      );

      this.showForm = this.data.length === 0;

      this.setFooterData();
    } catch (error) {
      console.error(error);
    }
  }

  async addNewRealty() {
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        type: valuesHelper.get('type'),
        current_value: valuesHelper.get('current_value'),
        address: [
          {
            cep: valuesHelper.get('cep'),
            public_place: valuesHelper.get('public_place'),
            number: valuesHelper.get('number'),
            complement: valuesHelper.get('complement'),
            district: valuesHelper.get('district'),
            city: valuesHelper.get('city'),
            uf: valuesHelper.get('uf'),
            country: valuesHelper.get('country'),
            type: 1,
          },
        ],
      };

      await this.api.post({
        route: 'api/registration/properties/',
        token: true,
        body: payload,
      });

      this.zipcodeFetched = false;
      this.form.reset();

      this.getData();
    } catch (error) {
      console.log(error);
    }
  }

  async removeRealty(index: number) {
    try {
      await this.api.delete({
        route: `api/registration/properties/${this.data[index].id}/`,
        token: true,
      });

      const newData = [...this.data];
      newData.splice(index, 1);

      this.data = newData;
      this.signupService.setCompanyFormData('incomeRealty', newData);

      this.signupService.setCompanyFormStatus(
        'incomeRealty',
        this.data.length > 0
      );

      this.showForm = this.data.length === 0;

      this.setFooterData();
    } catch (error) {
      console.log(error);
    }
  }

  getDisabled() {
    if (this.showForm) {
      return this.form.invalid;
    }

    return false;
  }

  triggerForm() {
    if (this.showForm) {
      this.addNewRealty();
    }

    this.showForm = !this.showForm;
  }

  handleCancel() {
    this.showForm = false;
    this.form.reset();
  }

  async searchZipcode(event: any) {
    const zipcode = event.target.value;

    try {
      const zipcodeRes = await this.utilsService.getZipcode(zipcode);

      if (zipcodeRes) {
        this.form.patchValue({
          public_place: zipcodeRes.logradouro,
          district: zipcodeRes.bairro,
          city: zipcodeRes.localidade,
          uf: zipcodeRes.uf,
          country: '1',
        });

        this.zipcodeFetched = true;
      }
    } catch (error) {
      this.zipcodeFetched = false;
      console.error(error);
    }
  }
}
