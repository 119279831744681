<div class="table-details-container" [style.paddingBottom.px]="paddingBottom"
    [class.custom]="custom">
    <ul class="app-table-details" [ngStyle]="getGrid()">
        <li *ngFor="let column of columns" class="app-table-details-header"
            [style.text-align]="column.align ?? 'match-parent'">
            {{column.name}}</li>
    </ul>

    <ul *ngFor="let row of data; let i = index" class="app-table-details-body"
        [ngStyle]="getGrid()">
        <li *ngFor="let column of columnsToDisplay"
            [innerHtml]="column.cell(row)" class="app-table-details-body-row"
            [style.text-align]="column.align ?? 'match-parent'"
            (click)="handleClick($event)" [ngStyle]="setPadding(size)">
        </li>
    </ul>

    <ng-container *ngIf="footerData.length > 0">
        <ul *ngFor="let item of footerData" class="app-table-details-body"
            style="display:grid" [ngStyle]="getGrid()">
            <li *ngFor="let column of footerColumnsToDisplay"
                class="app-table-details-body-row"
                [innerHtml]="column.cell(item)">
            </li>
        </ul>
    </ng-container>
</div>