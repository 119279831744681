import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GuardUtils {
  constructor(public authService: AuthService) {}

  isAuthenticated() {
    let user = this.authService.getUser();

    return user !== undefined;
  }

  isAdmin() {
    const user = this.authService.getUser();

    if (user) {
      if (user.is_staff) {
        return true;
      }
    }

    return false;
  }

  isPerson() {
    const user = this.authService.getUser();

    if (user) {
      if (user.active_register.register.type === 'PF') {
        return true;
      }
    }

    return false;
  }

  isCompany() {
    const user = this.authService.getUser();

    if (user) {
      if (user.active_register.register.type === 'PJ') {
        return true;
      }
    }

    return false;
  }

  isCompleted() {
    const user = this.authService.getUser();

    if (user) {
      if (user.trace_step) {
        if (user.trace_step.step === 'Análise') {
          return true;
        }
      }
    }

    return false;
  }
}
