<ng-container [ngSwitch]="forgotStep">

  <ng-container *ngSwitchCase="0">
    <div class="forgot-container">

      <div class="password-icon">
        <img src="/assets/images/forgot-password-icon.png" alt="Icone">
      </div>

      <app-spacer [bottom]="54"></app-spacer>

      <p class="forgot-title">Esqueci a senha</p>

      <app-spacer [bottom]="24"></app-spacer>

      <p class="forgot-description">
        Informe abaixo o canal para receber as instruções de renovação da sua senha de acesso.
      </p>

      <app-spacer [bottom]="32"></app-spacer>

      <form [formGroup]="recoveryForm">
        <app-choices [choices]="profileTypes" formControlName="type"></app-choices>

        <app-spacer [bottom]="24"></app-spacer>

        <app-button [full]="true" [disabled]="!recoveryForm.valid || loading" (click)="sendChoice()" [loading]="loading"
          [center]="true">
          Solicitar instruções</app-button>

        <app-spacer [bottom]="12"></app-spacer>

        <app-button [disabled]="loading" [outline]="true" (click)="closeForgot()" [center]="true">
          Cancelar</app-button>
      </form>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="1">
    <div class="forgot-instructions">
      <div class="password-icon">
        <img src="/assets/images/forgot-password-icon2.png" alt="Icone">
      </div>

      <app-spacer [bottom]="54"></app-spacer>

      <div class="forgot-instructions-title">
        <span>{{forgotInstructionTitle}}</span>
      </div>


      <app-spacer [bottom]="24"></app-spacer>

      <div class="forgot-instructions-description">
        <span>{{forgotInstructionText}}</span>
      </div>


      <app-spacer [bottom]="48"></app-spacer>

      <app-button [full]="true" (click)="closeForgot()" [disabled]="loading" [loading]="loading" [center]="true">
        Entendi
      </app-button>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="2">

    <app-validate [noPadding]="true" (onDigitsValidated)="onCodeValidate($event)" title="Validação de segurança"
      [description]="'Informe o código de segurança que enviamos para email ' + hidedEmail"></app-validate>

  </ng-container>


  <ng-container *ngSwitchCase="3">
    <div class="forgot-instructions">
      <div class="forgot-instructions-icon-container">
        <div class="forgot-instructions-icon-bg"></div>
        <div class="forgot-instructions-icon">
          <i class="fas fa-check"></i>
        </div>
      </div>

      <app-spacer [bottom]="54"></app-spacer>

      <div class="forgot-instructions-title">
        <span>Senha atualizada com sucesso</span>
      </div>


      <app-spacer [bottom]="24"></app-spacer>

      <div class="forgot-instructions-description">
        <span>Tudo certo com seu acesso à plataforma. Você será redirecionado para a página de início em 5
          segundos.</span>
      </div>

    </div>
  </ng-container>

</ng-container>
