import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

type SettingsPermissions = {
  audit: boolean;
  zeroing: boolean;
  document: boolean;
  agent: boolean;
};

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss'],
})
export class DashboardSettingsComponent implements OnInit {
  canView: SettingsPermissions = {
    agent: false,
    document: false,
    zeroing: false,
    audit: false,
  };

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.setPermissions();
  }

  setPermissions() {
    const permissionArr = [
      'de_can_view_agent',
      'de_can_view_document',
      'de_can_view_zeroing_fund',
      'de_can_view_audit',
    ];

    permissionArr.forEach((permission) => {
      const label = permission.split('_')[3];

      this.canView[`${label}`] = this.authService.verifyPermission([
        permission,
      ]);
    });
  }
}
