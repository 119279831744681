<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <ng-container *ngIf="data.length > 0">
                <app-table-list [columns]="columns"
                    [data]="data"></app-table-list>
            </ng-container>

            <app-empty-content [isVisible]="data.length === 0"
                title="Nenhum grupo encontrado"
                description="Não foi encontrado nenhum grupo nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>
        </div>

        <ng-container *ngIf="powerGroupData.length > 0">
            <div class="dash-details-card card-padding-top mt-4">
                <app-table-list [columns]="powerGroupColumns"
                    [data]="powerGroupData">
                </app-table-list>
            </div>
        </ng-container>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-side-modal [active]="powerGroupDetails" title="Detalhes do grupo de poder"
    (onClose)="closeModal()" paddingBottom="30">
    <div class="container">
        <div class="row" *ngIf="selectedPowerGroup">
            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Tipo de poder
                </span>

                <span class="dash-details-card-item-title">
                    {{formatPower(selectedPowerGroup.power_type)}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column"
                *ngIf="selectedPowerGroup.default_power_type && selectedPowerGroup.default_power_type.length > 0">
                <span class="dash-details-card-item-subtitle">
                    Poder padrão
                </span>

                <span class="dash-details-card-item-title">
                    {{formatPower(selectedPowerGroup.default_power_type)}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Grupo principal
                </span>

                <span class="dash-details-card-item-title">
                    {{formatGroup([selectedPowerGroup.main_group])}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Grupo secundário
                </span>

                <span class="dash-details-card-item-title">
                    {{formatGroup(selectedPowerGroup.secondary_group,false)}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Qt. mín. de assinaturas necessárias
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.necessary_signs_quantity}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Assinantes necessários
                </span>

                <pre
                    class="dash-details-card-item-title">{{formatPowerIntegrants(selectedPowerGroup.necessary_signs)}}</pre>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Qt. mín. de assinaturas obrigatórias
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.required_signs_quantity}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>


            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Assinantes obrigatórios
                </span>

                <pre
                    class="dash-details-card-item-title">{{formatPowerIntegrants(selectedPowerGroup.required_signs)}}</pre>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column"
                *ngIf="selectedPowerGroup && selectedPowerGroup.min_sign_amount">
                <span class="dash-details-card-item-subtitle">
                    Valor min. para assinatura
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.min_sign_amount | currency:'BRL'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column"
                *ngIf="selectedPowerGroup && selectedPowerGroup.min_sign_amount">
                <span class="dash-details-card-item-subtitle">
                    Valor máx. para assinatura
                </span>

                <span class="dash-details-card-item-title">
                    {{formatMaxValue(selectedPowerGroup)}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column"
                *ngIf="selectedPowerGroup && selectedPowerGroup.description">
                <span class="dash-details-card-item-subtitle">
                    Descrição dos poderes
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPowerGroup.description}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>
    </div>
</app-side-modal>