import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PersonFormKeys,
  SignupService,
} from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-signup-unified-person-complementary',
  templateUrl: './signup-unified-person-complementary.component.html',
  styleUrls: ['./signup-unified-person-complementary.component.scss'],
})
export class SignupUnifiedPersonComplementaryComponent implements OnInit {
  tabs: Tab[] = [
    {
      label: 'Geral',
      error: !this.signupService.personFormValid.complementaryGeneral,
      errorKey: 'complementaryGeneral',
    },
    {
      label: 'Endereço',
      error: !this.signupService.personFormValid.complementaryAddress,
      errorKey: 'complementaryAddress',
    },
    {
      label: 'Dados bancários',
      error: !this.signupService.personFormValid.complementaryBank,
      errorKey: 'complementaryBank',
    },
    {
      label: 'Profissional',
      error: !this.signupService.personFormValid.complementaryProfessional,
      errorKey: 'complementaryProfessional',
    },
    {
      label: 'Representantes',
      error: !this.signupService.personFormValid.complementaryRepresentatives,
      errorKey: 'complementaryRepresentatives',
    },
  ];

  tabIndex = 0;
  role: string | null = null;

  title = '';

  stepSlug: PersonSteps = 'complementaryGeneral';

  constructor(public signupService: SignupService, private router: Router) {}

  ngOnInit(): void {
    let urlParts = this.router.url.split('/pf')[0].split('/');
    let range = urlParts.length;
    this.role = urlParts[range - 1];
    this.stepSlug = this.signupService.getPersonStep();
    this.setTitle();

    this.signupService.personStatusChangeEvent.subscribe(() => {
      this.tabs.forEach((tab) => {
        const key: PersonFormKeys = tab.errorKey ?? '';

        tab.error = !this.signupService.personFormValid[key] ?? false;
      });
    });

    this.signupService.changePersonStepEvent.subscribe((step) => {
      const aux = `complementary${
        step.charAt(0).toUpperCase() + step.slice(1)
      }` as PersonSteps;

      this.stepSlug = aux;
      this.setTitle();
    });
  }

  setTitle() {
    switch (this.stepSlug) {
      case 'complementaryGeneral':
        this.title = 'Geral';
        break;

      case 'complementaryAddress':
        this.title = 'Endereço';
        break;

      case 'complementaryBank':
        this.title = 'Dados bancários';
        break;

      case 'complementaryProfessional':
        this.title = 'Profissional';
        break;

      case 'complementaryRepresentatives':
        this.title = 'Representantes';
        break;

      default:
        break;
    }
  }
}
