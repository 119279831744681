import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-funds',
  templateUrl: './dashboard-funds.component.html',
  styleUrls: ['./dashboard-funds.component.scss'],
})
export class DashboardFundsComponent implements OnInit {
  funds: any[] = [];
  filteredFunds: any[] = [];

  constructor(private api: ApiService, private toast: ToastService) {}

  ngOnInit(): void {
    this.getFunds();
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/fund',
        token: true,
      });

      this.funds = res;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  getFundInitials(fund: any) {
    return fund.name
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }
}
