<div class="row">
    <div class="col-12">
        <div class="dash-card card-padding-top">
            <app-empty-content [isVisible]="links.length === 0"
                title="Nenhum vinculo encontrado"
                description="Não foi encontrado nenhum vinculo nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <div class="dash-card-body" *ngIf="links.length > 0">
                <div class="dash-card-scroll">
                    <table>
                        <thead>
                            <tr class="alternative-header">
                                <th>Nome</th>
                                <th>Papel</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let link of links">
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-initials">
                                            <span>{{getInitials(link.represented_register)}}</span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{link.person ?
                                                link.represented_register.person.full_name
                                                :
                                                link.represented_register.company.corporate_name}}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{formatDocument(link)}}
                                            </div>
                                        </div>

                                    </div>
                                </td>

                                <td>
                                    {{formatItemName(link.represented_register)}}
                                </td>
                                <td style="text-align: right;">
                                    <a [routerLink]="renderLink(link)">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>