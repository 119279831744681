import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  data = { visible: false, type: 'info', title: '', message: '' };

  constructor(private toastService: ToastService) {
    this.toastService.data.subscribe((data) => {
      const newData = { ...this.data, ...data };
      this.data = newData;
    });
  }

  ngOnInit(): void {}

  hide() {
    this.toastService.hide();
  }
}
