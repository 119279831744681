<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12">
        <div class="dash-details-card">
            <div class="row" *ngIf="data.length > 0">
                <div class="col-12" style="overflow-x:auto;">
                    <div class="custom-table-container pt-3">
                        <table class="custom-table" *ngIf="data.length >= 2">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Relacionamento</th>
                                    <th>% Participação</th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container
                                    *ngFor="let item of data; let i = index">
                                    <tr [(ngbCollapse)]="item.collapsed">
                                        <td>
                                            <div
                                                class="d-flex align-items-center">
                                                <app-spacer
                                                    [left]="getSpace(i)"></app-spacer>

                                                <div *ngIf="item.shareholders && item.shareholders.length === 0"
                                                    style="width: 26px;">
                                                </div>

                                                <ng-container
                                                    *ngIf="item.shareholders && item.shareholders.length > 0">
                                                    <button
                                                        (click)="handleSetCollapsed(i, item.isOpen)">
                                                        <i
                                                            class="fas fa-chevron-down"></i>
                                                    </button>
                                                </ng-container>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div>
                                                    <div>
                                                        {{item.full_name}}
                                                    </div>
                                                    <div
                                                        class="custom-table-sub-text">
                                                        {{item.document}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div>
                                                <div>
                                                    {{item.pep_relationship_name
                                                    ?? ''}}
                                                    <ng-container
                                                        *ngIf="item.pep_bond">
                                                        ({{item.pep_bond}})
                                                    </ng-container>
                                                </div>
                                                <div
                                                    class="custom-table-sub-text">
                                                    {{item.pep_relationship_document
                                                    ?? ''}}
                                                </div>
                                            </div>

                                        </td>

                                        <td>
                                            {{item.percentage}}%

                                            <ng-container
                                                *ngIf="item.shareholders && item.shareholders.length > 0">
                                                (total
                                                {{getShareholdersPercentage(item.id)}}%)
                                            </ng-container>
                                        </td>


                                    </tr>


                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <div *ngIf="data.length === 0" class="card-padding-bottom">
                <span class="dash-card-item-content-title text-center d-block">
                    Nenhum acionista registrado
                </span>
            </div>
        </div>

        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-side-modal [active]="compositionDetails"
    [title]="compositionDetails ? selectedPartner.full_name : '-'"
    (onClose)="closeModal()" paddingBottom="30">
    <div class="container" *ngIf="selectedPartner">
        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    {{selectedPartner.document &&
                    selectedPartner.document.length > 14 ? 'CNPJ' : 'CPF'}}
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPartner ? selectedPartner.document : '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column"
                *ngIf="selectedPartner.pep">
                <span class="dash-details-card-item-badged-list">
                    Declaro PEP
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    participação
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedPartner ? selectedPartner.percentage : '-'}}%
                </span>
                <app-spacer [bottom]="32"></app-spacer>
            </div>
        </div>

        <ng-container *ngIf="selectedPartner.pep_relationship">
            <app-label
                label="Possuo relacionamento com pessoa politicamente exposta">
            </app-label>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        Nome
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPartner.pep_relationship_name}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        Documento
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPartner.pep_relationship_document}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        Vínculo
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedPartner.pep_bond}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>
        </ng-container>

        <ng-container
            *ngIf="selectedPartner.files && selectedPartner.files.length > 0 ">
            <div class="row">
                <div class="col-12">
                    <h3>Anexos</h3>
                </div>
                <!-- <div class="col-12">
                    <app-attachment-button [files]="selectedPartner.files"
                        [full]="true"
                        (onSelect)="handleSelect($event)"></app-attachment-button>
                </div> -->
            </div>

            <app-spacer [bottom]="32"></app-spacer>
        </ng-container>
    </div>
</app-side-modal>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="file">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div>{{file.type.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>
    <ng-container class="app-modal-body">
        <ng-container *ngIf="file">
            <img [src]="url" *ngIf="!isPDF && !isXLS" style="width:100%">
            <iframe [src]="url" *ngIf="isPDF || isXLS" frameborder="0"
                height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>
</app-modal>