<div class="app-toast-container" [class.active]="data.visible" [class.error]="data.type === 'error'"
  [class.warning]="data.type === 'warning'" [class.info]="data.type === 'info'" (click)="hide()">
  <div class="app-toast-icon">
    <i class="fas fa-exclamation"></i>
  </div>

  <div class="app-toast-message">
    <p class="app-toast-title">{{data.title}}</p>
    <p class="app-toast-text">{{data.message}}</p>
  </div>

  <div class="app-toast-close">
    <i class="fas fa-times"></i>
  </div>
</div>
