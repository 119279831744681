import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spacer',
  templateUrl: './spacer.component.html',
  styleUrls: ['./spacer.component.scss'],
})
export class SpacerComponent implements OnInit {
  @Input() top: number = 0;
  @Input() right: number = 0;
  @Input() bottom: number = 0;
  @Input() left: number = 0;

  constructor() {}

  ngOnInit(): void {}

  getMarginTop(): string {
    return `${this.top}px`;
  }

  getMarginRight(): string {
    return `${this.right}px`;
  }

  getMarginBottom(): string {
    return `${this.bottom}px`;
  }

  getMarginLeft(): string {
    return `${this.left}px`;
  }
}
