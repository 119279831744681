<div class="auth-layout d-flex">
    <aside>
        <div class="sidebar">
            <div class="sidebar-body-recovery">
                <form [formGroup]="newPasswordForm">
                    <div class="password-container">
                        <div class="box-content">

                            <ng-container *ngIf="loadingService.loading">
                                <ngx-skeleton-loader></ngx-skeleton-loader>

                                <app-spacer [bottom]="93"></app-spacer>

                                <ngx-skeleton-loader [theme]="{
                                    height: '90px'
                                  }"></ngx-skeleton-loader>

                                <app-spacer [bottom]="48"></app-spacer>

                                <ngx-skeleton-loader
                                    count="4"></ngx-skeleton-loader>

                                <app-spacer [bottom]="21"></app-spacer>

                                <ngx-skeleton-loader [theme]="{
                                    height: '90px'
                                  }"></ngx-skeleton-loader>

                                <app-spacer [bottom]="32"></app-spacer>

                                <ngx-skeleton-loader [theme]="{
                                    height: '40px'
                                  }"></ngx-skeleton-loader>


                                <ngx-skeleton-loader [theme]="{
                                    height: '30px'
                                  }"></ngx-skeleton-loader>

                            </ng-container>


                            <ng-container *ngIf="!loadingService.loading">
                                <div class="back-to-authstep"
                                    (click)="redirectToLogin()">
                                    <i class="fas fa-arrow-left"></i>
                                    <span>Voltar para tela inicial</span>
                                </div>

                                <app-spacer [bottom]="93"></app-spacer>


                                <p class="box-title">Vamos criar uma nova senha
                                </p>
                                <app-spacer [bottom]="48"></app-spacer>

                                <div class="row">

                                    <div class="col-12">
                                        <app-radio [readOnly]="true"
                                            [isChecked]="passwordStrength().includes(1)">
                                            <span class="radio-text"
                                                [class.active]="passwordStrength().includes(1)">Mínimo
                                                de 8
                                                caracteres</span>
                                        </app-radio>
                                        <app-spacer [bottom]="14"></app-spacer>
                                    </div>
                                    <div class="col-12">
                                        <app-radio [readOnly]="true"
                                            [isChecked]="passwordStrength().includes(2)">
                                            <span class="radio-text"
                                                [class.active]="passwordStrength().includes(2)">Mínimo
                                                de 1
                                                número</span>

                                        </app-radio>
                                        <app-spacer [bottom]="14"></app-spacer>
                                    </div>
                                    <div class="col-12">
                                        <app-radio [readOnly]="true"
                                            [isChecked]="passwordStrength().includes(3)">
                                            <span class="radio-text"
                                                [class.active]="passwordStrength().includes(3)">Letras
                                                maiúsculas e
                                                minúsculas</span>
                                        </app-radio>
                                        <app-spacer [bottom]="14"></app-spacer>
                                    </div>
                                    <div class="col-12">
                                        <app-radio [readOnly]="true"
                                            [isChecked]="passwordStrength().includes(4)">
                                            <span class="radio-text"
                                                [class.active]="passwordStrength().includes(4)">Caracteres
                                                especiais</span>
                                        </app-radio>
                                        <app-spacer [bottom]="21"></app-spacer>
                                    </div>

                                    <div class="col-12">
                                        <p class="password-description-text">
                                            Para reforçar a segurança da sua
                                            senha, não
                                            utilize
                                            informações
                                            pessoais como datas de nascimentos
                                            ou números de telefones e nem
                                            números
                                            sequenciais.
                                        </p>
                                        <app-spacer [bottom]="32"></app-spacer>
                                    </div>

                                    <div class="col-12">
                                        <app-input type="password"
                                            label="Defina uma senha de acesso"
                                            [group]="newPasswordForm"
                                            controlName="newPassword"
                                            placeholder="Digite sua senha">
                                        </app-input>
                                        <div class="password-meter">
                                            <p class="password-meter-text">
                                                {{passwordStrengthText()}}</p>

                                            <div class="password-meter-bars">
                                                <div class="password-meter-bar"
                                                    [class.active]="passwordStrengthPercentage() > 30">
                                                </div>
                                                <div class="password-meter-bar"
                                                    [class.active]="passwordStrengthPercentage() > 60">
                                                </div>
                                                <div class="password-meter-bar"
                                                    [class.active]="passwordStrengthPercentage() > 90">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <app-spacer [bottom]="32"></app-spacer>
                                        <app-button
                                            [disabled]="newPasswordForm.invalid || loading"
                                            [loading]="loading" [full]="true"
                                            (onClick)="sendPassword()"
                                            [center]="true">Salvar nova senha e
                                            entrar
                                        </app-button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>

            <div class="sidebar-footer">
                Powered by Prosperità Inovação e Tecnologia Ltda © 2018 - 2022
            </div>
        </div>
    </aside>

    <div class="login-background">
        <img src="/assets/images/login-bg.png">
    </div>
</div>