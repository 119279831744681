import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard-security',
  templateUrl: './dashboard-security.component.html',
  styleUrls: ['./dashboard-security.component.scss'],
})
export class DashboardSecurityComponent implements OnInit {
  form = this.formBuilder.group({
    current_password: [null, [Validators.required, Validators.minLength(8)]],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        (control: FormControl) => {
          const password = control.value;

          if (!this.hasNumber(password)) return { noNumber: true };

          if (!this.hasLowerCase(password)) return { noLowerCase: true };

          if (!this.hasUpperCase(password)) return { noUpperCase: true };

          if (!this.hasSpecialCharacter(password))
            return { noSpecialCharacter: true };

          return null;
        },
      ],
    ],
  });

  step = 0;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  hasLowerCase(str: string) {
    return str.toUpperCase() !== str;
  }

  hasUpperCase(str: string) {
    return str.toLowerCase() !== str;
  }

  hasSpecialCharacter(str: string) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
  }

  hasNumber(str: string) {
    return /\d/.test(str);
  }

  passwordStrength(): number[] {
    const password = this.form.get('password')?.value ?? '';

    const strengthSteps = [];

    if (password !== '') strengthSteps.push(0);
    if (password.length >= 8) strengthSteps.push(1);
    if (this.hasNumber(password)) strengthSteps.push(2);
    if (this.hasLowerCase(password) && this.hasUpperCase(password))
      strengthSteps.push(3);
    if (this.hasSpecialCharacter(password)) strengthSteps.push(4);

    return strengthSteps;
  }

  passwordStrengthPercentage(): number {
    return (100 * this.passwordStrength().length) / 5;
  }

  passwordStrengthText(): string {
    const percentage = this.passwordStrengthPercentage();

    if (percentage > 60 && percentage < 90) {
      return 'Sua senha precisa melhorar';
    } else if (percentage >= 90) {
      return 'Sua senha parece bem segura';
    }

    return 'Sua senha está fraca';
  }

  validate() {
    this.step = 1;
  }
}
