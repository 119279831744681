<div class="search-container" (click)="click($event)">
    <div class="app-select-container" [formGroup]="group">
        <i class="fas fa-search"></i>
        <div class="app-select-entry-container" [class.active]="isActive">
            <div class="row no-gutter">
                <div class="col-8 bg-gray p-0">
                    <input class="app-input-entry bg-gray" [type]="type"
                        [id]="controlName" [name]="name ?? controlName"
                        [placeholder]="placeholder" [maxLength]="maxLength"
                        [attr.min]="min" [attr.max]="max"
                        [formControlName]="controlName" autocomplete="off">
                </div>

                <div
                    class="col-4 d-flex align-items-center justify-content-end bg-gray border-radius">
                    <div class="search-helper" *ngIf="isActive">
                        Para concluir sua pesquisa, pressione
                        <span>
                            <svg-icon
                                src="/assets/images/icon_keyboard.svg"></svg-icon>
                            Enter
                        </span>
                    </div>
                </div>
            </div>

            <div class="dropdown-container" [class.d-none]="!isActive">
                <ng-container *ngIf="visibleItems.length > 0 && !isSearching">
                    <ul class="dropdow-header" [ngStyle]="getGrid()">
                        <li class="each-header" *ngFor="let column of columns">
                            {{column.name}}
                        </li>
                    </ul>

                    <ul class="option-container"
                        *ngFor="let row of visibleItems; let i = index"
                        [ngStyle]="getGrid()">
                        <li class="each-option" (click)="selectItem(i,$event)"
                            *ngFor="let column of columnsToDisplay"
                            [innerHtml]="column.cell(row)">
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="visibleItems.length === 0 && !isSearching">
                    <span class="no-result">Nenhum resultado encontrado </span>
                </ng-container>

                <ng-container *ngIf="isSearching">
                    <span class="no-result">
                        <i
                            class="fas fa-circle-notch fa-spin app-button-icon"></i>
                        Buscando resultados...
                    </span>
                </ng-container>
            </div>
        </div>
        <p class="app-select-helper" *ngIf="helpText">{{helpText}}</p>
    </div>
</div>