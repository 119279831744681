import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-invites',
  templateUrl: './dashboard-invites.component.html',
  styleUrls: ['./dashboard-invites.component.scss'],
})
export class DashboardInvitesComponent implements OnInit {
  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
    canDelete: false,
  };

  form = this.formBuilder.group({
    name: [null, [Validators.required]],
    role: [null, [Validators.required]],
    agent: [null, []],
    document: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    fund: [null, [Validators.required]],
    is_approver: [false, []],
  });

  filterForm = this.formBuilder.group({
    name: [null, []],
    email: [null, [Validators.email]],
  });

  loading = false;
  showFilterModal = false;
  offset = 0;
  count = 0;
  invites: InviteProps[] = [];
  filteredInvites: InviteProps[] = this.invites;
  newInvite = false;
  dataFilters: any = null;
  editing = false;
  inviteId = null;

  roleOptions: SelectItem[] = [
    {
      label: 'Cedente',
      value: 'assignor',
    },
    {
      label: 'Cotista',
      value: 'shareholder',
    },
    {
      label: 'Prestador de serviço',
      value: 'provider',
    },
  ];

  fundOptions: SelectItem[] = [];
  agentOptions: SelectItem[] = [{ label: 'Todos', value: null }];

  invitesStatistics = {
    sendedInvites: 0,
    usedInvites: 0,
    expiredInvites: 0,
  };

  showCancelModal = false;
  selectedId = null;

  inviteDetails: InviteProps = null;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private authService: AuthService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.getFunds();
    this.getAgents();
    this.getData();

    this.form.controls.role.valueChanges.subscribe((value) => {
      if (value === 'provider') {
        this.form.controls.agent.setValidators([Validators.required]);
        this.form.controls.fund.setValidators([]);
      } else {
        this.form.controls.agent.setValidators([]);
        this.form.controls.fund.setValidators([Validators.required]);
      }

      this.form.controls.agent.updateValueAndValidity();
      this.form.controls.fund.updateValueAndValidity();
    });
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          filters[key] = value;
        }
      });

      const { count, data, offset } = await this.api.get({
        route: 'api/registration/invite/',
        params: {
          page: 1,
          ...filters,
        },
        token: true,
      });

      this.dataFilters = filters;
      this.count = count;
      this.offset = offset;

      this.invites = data;

      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
    this.loading = false;
  }

  async getData(page: number = 1) {
    try {
      const sender = this.authService.user.active_register.register.id;

      const params = {
        ...this.dataFilters,
        page,
        sender,
      };

      const { data, offset, count } = await this.api.get({
        route: 'api/registration/invite/',
        params,
        token: true,
      });

      this.invites = data;
      this.setInviteStatistics(data);
      this.count = count;
      this.offset = offset;

      this.loading = false;
    } catch (error) {
      console.warn(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os convites.'
      );
    }
  }

  resetForm() {
    this.loading = true;
    this.filterForm.reset();
    this.dataFilters = null;
    this.getData();
    this.showFilterModal = false;
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal;
  }

  closeModal() {
    this.newInvite = false;
    this.showFilterModal = false;
    this.inviteDetails = null;
    this.editing = false;
    this.inviteId = null;
    this.form.reset();
  }

  getDisabled() {
    return this.form.invalid;
  }

  async sendInvite() {
    try {
      if (this.form.value.document) {
        const alreadyInvited = this.invites.find(
          (item) => item.data.document === this.form.value.document
        );

        if (alreadyInvited) {
          this.toast.show(
            'error',
            'Erro',
            'Este documento já possui um convite pendente para o fundo'
          );
          return;
        }
      }

      const res = await this.api.post({
        route: 'api/registration/invite/',
        token: true,
        body: {
          data: {
            ...this.form.value,
            register: this.authService.user.active_register.register.id,
          },
          type: this.form.controls.role.value,
        },
      });

      this.toast.show(
        'info',
        'Sucesso',
        `
         ${this.formatName(this.form.value.agent)}
         convidado com sucesso
         `
      );

      //   const arr = [...this.invites, res];

      //   this.invites = arr;

      //   this.setInviteStatistics(res);

      this.getData();

      this.closeModal();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao enviar o convite');
    }
  }

  setInviteStatistics(arr: InviteProps[] | InviteProps) {
    let usedInvites = this.invitesStatistics.usedInvites;
    let expiredInvites = this.invitesStatistics.expiredInvites;

    let auxArr: InviteProps[];

    if (Array.isArray(arr)) {
      auxArr = arr;
    } else {
      auxArr = [arr];
    }

    auxArr.forEach((item) => {
      if (item.used) {
        usedInvites++;
      } else {
        const expirationDate = this.displayExpirationDate(item.created_at);
        const today = new Date();

        const expirationDateArr = expirationDate.split('/');

        const expirationDateObj = new Date(
          parseInt(expirationDateArr[2]),
          parseInt(expirationDateArr[1]) - 1,
          parseInt(expirationDateArr[0])
        );

        if (expirationDateObj < today) {
          expiredInvites++;
        }
      }
    });

    this.invitesStatistics = {
      sendedInvites: this.invites.length,
      usedInvites: usedInvites,
      expiredInvites: expiredInvites,
    };
  }

  formatName(name: string) {
    switch (name) {
      case 'assignor':
        return 'Cedente';
      case 'shareholder':
        return 'Cotista';
      case 'provider':
        return 'Prestador de serviço';

      default:
        return 'Cedente';
    }
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/fund',
        token: true,
      });

      const filteredArr = Utils.filterRepeatedValues(res.funds, 'id');

      const funds = filteredArr.map((fund: any) => {
        return {
          label: fund.name,
          value: fund.id,
          extra: fund.fantasy_name[0] ?? null,
        };
      });

      this.fundOptions = funds;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar fundos de interesse');
    }
  }

  async getAgents() {
    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.agentOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  displayInterestFund(id: number) {
    const fund = this.fundOptions.find((fund) => fund.value === id);
    const label = fund ? fund.extra ?? fund.label : '';
    return fund ? label : '';
  }

  displayStatus(item: InviteProps) {
    let label = 'Enviado';

    const date = item.updated_at.split('T')[0].split('-');
    const time = item.updated_at.split('T')[1].split('.')[0].split(':');
    const formatedDate = `${date[2]}/${date[1]}/${date[0]} | ${time[0]}:${time[1]}`;

    if (item.used) {
      label = `Utilizado ${formatedDate}`;
    } else {
      const expirationDate = this.displayExpirationDate(item.created_at);
      const today = new Date();

      const expirationDateArr = expirationDate.split('/');

      const expirationDateObj = new Date(
        parseInt(expirationDateArr[2]),
        parseInt(expirationDateArr[1]) - 1,
        parseInt(expirationDateArr[0])
      );

      if (expirationDateObj < today) {
        label = 'Expirado';
      }

      if (item.resent) {
        label = 'Reenviado';
      }

      if (item.canceled) {
        label = `Cancelado - ${formatedDate}`;
      }
    }

    return label;
  }

  displaySendDate(dateStr: string) {
    const date = dateStr.split('T')[0].split('-');
    const hours = dateStr.split('T')[1].split('.')[0].split(':');

    return `${date[2]}/${date[1]}/${date[0]} às ${hours[0]}:${hours[1]}`;
  }

  displayExpirationDate(dateStr: string) {
    const date = dateStr.split('T')[0].split('-');

    const newDate = new Date(
      parseInt(date[0]),
      parseInt(date[1]) - 1,
      parseInt(date[2])
    );

    newDate.setDate(newDate.getDate() + 7);

    return `${newDate.getDate()}/${
      newDate.getMonth() + 1
    }/${newDate.getFullYear()}`;
  }

  displayAgents(id: number[] | number) {
    const idArr = Array.isArray(id) ? id : [id];

    if (!id || (idArr && idArr.length === 0)) return '';

    const agents = this.agentOptions.filter((agent) =>
      idArr.includes(agent.value)
    );

    return agents.map((agent) => agent.label).join(', ');
  }

  toggleModal() {
    this.newInvite = !this.newInvite;
  }

  getInitials(item: InviteProps) {
    let name = item.data.name ?? null;

    return name ? Utils.getInitials(name) : '-';
  }

  async cancelInvite() {
    try {
      const finded = this.invites.find((item) => item.id === this.selectedId);
      await this.api.delete({
        route: 'api/registration/invite/',
        params: {
          token: finded.token,
        },
        token: true,
      });

      this.toast.show('info', 'Sucesso', 'Convite cancelado com sucesso');
      this.getData();
      this.handleShowCancelModal();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao cancelar o convite');
    }
  }

  handleShowCancelModal(id: number = null) {
    this.showCancelModal = !this.showCancelModal;

    this.selectedId = id;
  }

  renderInviteLabels() {
    const finded = this.invites.find((item) => item.id === this.selectedId);

    return finded ? finded.data.name + ' | ' + finded.data.document : '';
  }

  handleResendInvite(id: number) {
    this.newInvite = true;
    this.editing = true;

    const finded = this.invites.find((item) => item.id === id);

    this.inviteId = id;

    this.form.patchValue({
      name: finded.data.name,
      role: finded.type,
      agent: finded.data.agent,
      document: finded.data.document,
      email: finded.data.email,
      fund: finded.data.fund,
      is_approver: finded.data.is_approver,
    });
  }

  async resendInvite() {
    const id = this.inviteId;

    try {
      const finded = this.invites.find((item) => item.id === id);
      const res = await this.api.post({
        route: 'api/registration/invite/resend/',
        token: true,
        body: {
          token: finded.token,
          email: this.form.controls.email.value,
          document: this.form.controls.document.value,
          type: finded.type,
          intent: finded.intent,
          fund: this.form.controls.fund.value,
        },
      });

      this.toast.show('info', 'Sucesso', 'Convite reenviado com sucesso');
      this.closeModal();

      this.getData();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao reenviar o convite');
    }
  }

  openDetailsModal(id: number) {
    this.inviteDetails = this.invites.find((item) => item.id === id);
  }
}
