import UI_THEME_DEFAULT from './labels/UI_THEME_DEFAULT';
import UI_THEME_CMCAPITAL from './labels/UI_THEME_CMCAPITAL';
import UI_THEME_AVENTIS from './labels/UI_THEME_AVENTIS';
import UI_THEME_MILENIO from './labels/UI_THEME_MILENIO';
import { environment } from 'src/environments/environment';

const customLabels = {
  UI_THEME_DEFAULT,
  UI_THEME_CMCAPITAL,
  UI_THEME_AVENTIS,
  UI_THEME_MILENIO,
};

const environmentTheme = environment.uiTheme;

let UIDefaultTheme = customLabels[environmentTheme];

if (!UIDefaultTheme) {
  UIDefaultTheme = customLabels.UI_THEME_DEFAULT;
}

export default UIDefaultTheme;
