import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils from 'src/app/helpers/utils';
import { FundService, FundValues } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import UIDefaultTheme from 'src/themes';
import fakerbr from 'faker-br';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-fund-approval-register-complementary',
  templateUrl:
    './dashboard-fund-approval-register-complementary.component.html',
  styleUrls: [
    './dashboard-fund-approval-register-complementary.component.scss',
  ],
})
export class DashboardFundApprovalRegisterComplementaryComponent
  implements OnInit
{
  form = this.formBuilder.group({
    tax_classification_deadline: [null, [Validators.required]],
    tax_classification_tax: [null, []],
    risk_classification: [null, [Validators.required]],
    analytical_description_of_credit_rights: [null, [Validators.required]],
    creditors_rights: [null, [Validators.required]],
    credit_rights_will_be_granted: [null, []],
    assignment_expectation_greater_than_400: [null, []],
    delivery_asset_quotas_issuance: [null, []],
    quota_fair_value: [null, []],
  });

  creditors_rights_items = [
    { value: '0', label: 'Escriturais' },
    { value: '1', label: 'Físicos' },
    { value: '2', label: 'N/A' },
  ];

  fund: FundValues = undefined;

  UIDefaultTheme = UIDefaultTheme;

  constructor(
    private formBuilder: FormBuilder,
    public fundService: FundService,
    public signupService: SignupService,
    public toast: ToastService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((status) => {
      Utils.getErrors(this.form);
    });

    if (this.UIDefaultTheme.brand.simplifiedFund) {
      this.form.controls.tax_classification_deadline.setValidators([]);
      this.form.controls.tax_classification_tax.setValidators([]);
      this.form.updateValueAndValidity();
    }

    this.setData(this.fundService.data);

    this.form.controls.delivery_asset_quotas_issuance.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.form.controls.quota_fair_value.setValidators([
            Validators.required,
            Utils.maxDigits(10),
          ]);
        } else {
          this.form.controls.quota_fair_value.setValidators([]);
        }

        this.form.controls.quota_fair_value.updateValueAndValidity;
      }
    );

    this.form.valueChanges.subscribe((values: Partial<FundValues>) => {
      this.fundService.setFundFormData({
        ...this.fundService.data,
        ...values,
      });
    });

    this.setRandomData();
  }

  setRandomData() {
    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        tax_classification_deadline: String(
          fakerbr.random.number({ min: 1, max: 2 })
        ),
        tax_classification_tax: String(
          fakerbr.random.number({ min: 1, max: 5 })
        ),
        risk_classification: fakerbr.random.words(3),
        analytical_description_of_credit_rights: fakerbr.random.words(3),
        creditors_rights: 1,
        credit_rights_will_be_granted: true,
        assignment_expectation_greater_than_400: true,
        delivery_asset_quotas_issuance: true,
        quota_fair_value: fakerbr.random.number({
          min: 500000,
          max: 2000000,
        }),
      });
    });
  }

  setData(values: FundValues) {
    this.fund = values;

    if (values) {
      this.form.patchValue({
        ...values,
        tax_classification_deadline: values.tax_classification_deadline
          ? String(values.tax_classification_deadline)
          : null,
        tax_classification_tax: values.tax_classification_tax
          ? String(values.tax_classification_tax)
          : null,
      });
    }
  }
}
