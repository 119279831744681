import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { UtilsService } from 'src/app/services/utils/utils.service';
import Utils from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-audit',
  templateUrl: './dashboard-audit.component.html',
  styleUrls: ['./dashboard-audit.component.scss'],
})
export class DashboardAuditComponent implements OnInit {
  auditForm = this.formBuilder.group({
    document: [null, [Validators.required]],
    corporate_name: [null, [Validators.required]],
    constitution_date: [null, [Validators.required]],
    branch: [null, [Validators.required]],
    email: [null, [Validators.required]],
    zipcode: [null, [Validators.required]],
    public_place: [null, [Validators.required]],
    number: [null, [Validators.required, Validators.max(99999)]],
    complement: [null, []],
    district: [null, [Validators.required]],
    city: [null, [Validators.required]],
    uf: [null, [Validators.required]],
    country: [null, [Validators.required]],
  });

  permmissionObj = {
    canAdd: true,
    canEdit: true,
    canDelete: true,
  };

  DDIOptions = DDIOptions;

  audits: any[] = [];
  auditDetails: boolean = false;
  auditLinks: boolean = false;

  zipcodeFetched = false;

  addressObj: AddressProps = {
    public_place: '',
    district: '',
    city: '',
    uf: '',
    country: '',
  };

  loading = false;

  loadingAudit = true;

  addAudit = false;
  audit: any = undefined;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private maskPipe: MaskPipe,
    private utilsService: UtilsService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.authService.verifyPermission(['de_can_view_audit'])) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.getRequesteds();
    this.setData();
  }

  addAuditToggle(status: boolean) {
    this.addAudit = status;
  }

  closeModal() {
    this.auditDetails = false;
    this.auditLinks = false;
    this.addAudit = false;

    setTimeout(() => {
      this.auditForm.reset();
      this.zipcodeFetched = false;
      this.audit = undefined;
    }, 500);
  }

  async handleSearchZipcode(event: any, isValue: boolean = false) {
    this.loading = true;
    const zipcode = isValue ? event : event.target.value;

    var obj = {
      public_place: '',
      district: '',
      city: '',
      uf: '',
      country: '',
    };

    try {
      const zipcodeRes = await this.utilsService.getZipcode(zipcode);

      if (zipcodeRes) {
        const foundCep = Object.keys(zipcodeRes).some((key) => {
          return key === 'partner_response' ? false : zipcodeRes[key] !== '';
        });

        if (foundCep) {
          obj = {
            public_place: zipcodeRes.logradouro,
            district: zipcodeRes.bairro,
            city: zipcodeRes.localidade,
            uf: zipcodeRes.uf,
            country: '23',
          };

          this.auditForm.patchValue(obj);

          this.zipcodeFetched = true;
        } else {
          Utils.resetZipcodeFields(this.auditForm);
          this.auditForm.updateValueAndValidity();

          this.zipcodeFetched = zipcode.length === 9;
        }
      }
    } catch (error) {
      this.zipcodeFetched = false;
      console.error(error);
    }
    this.addressObj = obj;

    this.loading = false;
  }

  setaudit(audit: any) {
    this.auditForm.patchValue({
      ...audit,
      manager_ddi: '55',
    });

    this.handleSearchZipcode(audit.zipcode, true);

    this.audit = audit;

    this.addAuditToggle(true);
  }

  async getRequesteds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/audit/',
        token: true,
      });

      this.audits = res;
      this.loadingAudit = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async addNewAudit() {
    try {
      const values = {
        ...this.auditForm.value,
        street: this.auditForm.value.public_place,
        state: this.auditForm.value.uf,
      };

      const alreadyHaveDocument = this.audits.find(
        (audit) => audit.document === values.document
      );

      const alreadyHaveEmail = this.audits.find(
        (audit) => audit.email === values.email
      );

      if (alreadyHaveDocument || alreadyHaveEmail) {
        this.toast.show(
          'error',
          'Erro',
          `Já existe uma auditoria com esse ${
            alreadyHaveDocument ? 'documento' : 'email'
          }.`
        );
        return;
      }

      const res = this.audit
        ? await this.api.put({
            route: `api/registration/audit/${this.audit.id}/`,
            token: true,
            body: values,
          })
        : await this.api.post({
            route: 'api/registration/audit/',
            token: true,
            body: values,
          });

      //   if (this.audit) {
      //     const newAudits = [...this.audits];
      //     const index = newAudits.findIndex((audit) => audit.id === res.id);
      //     newAudits[index] = res;
      //     this.audits = newAudits;
      //   } else {
      //     const newAudits = [res, ...this.audits];
      //     this.audits = newAudits;
      //   }

      //   this.filteredAudits = [...this.audits].splice(0, 10);

      this.getRequesteds();

      this.toast.show(
        'info',
        `Auditoria ${this.audit ? 'atualizada' : 'adicionada'} com sucesso`,
        `a auditoria ${values.corporate_name} foi ${
          this.audit ? 'atualizada' : 'adicionada'
        }`
      );

      this.closeModal();
      this.audit = undefined;

      this.auditForm.reset();
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao cadastrar a auditoria.'
      );
    }
  }

  setData() {
    this.signupService.fillFormEvent.subscribe(() => {
      const companyName = fakerbr.company.companyName();

      const zipcode = fakerbr.address.zipCodeValidByState('SP');

      const formatted = this.maskPipe.transform(zipcode, '00000-000');

      this.auditForm.patchValue({
        document: this.maskPipe.transform(
          fakerbr.br.cnpj(),
          '00.000.000/0000-00'
        ),
        corporate_name: companyName,
        constitution_date: fakerbr.date.past(25).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        branch: '1',
        email: fakerbr.internet.email(),
        zipcode: formatted,
        number: fakerbr.random.number({ min: 1, max: 1000 }),
        complement: 'Casa',
      }),
        this.handleSearchZipcode({
          target: { value: formatted },
        });

      this.zipcodeFetched = true;
    });
  }

  getDisabled() {
    return this.auditForm.invalid;
  }

  getInitials(user: any) {
    const full_name = user.corporate_name.split(' ');
    const initials = full_name[0].charAt(0) + full_name[1].charAt(0);

    return initials;
  }

  getFundInitials(name: any) {
    const companyNameArray = name.split(' ');
    const initials = companyNameArray
      .map((word: string) => word.charAt(0))
      .join('');
    return `${initials[0].toUpperCase()}${
      initials[1] ? initials[1].toUpperCase() : ''
    }`;
  }

  showauditDetails(audit: any) {
    this.audit = audit;
    this.auditDetails = true;
  }

  linkLabel(audit: any) {
    if (audit) {
      return `Vínculos de ${audit.corporate_name}`;
    }
    return '';
  }

  showLinks(audit: any) {
    this.audit = audit;
    this.auditLinks = true;
  }
}
