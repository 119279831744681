import { ToastService } from '../../../../services/toast/toast.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { SignupService } from 'src/app/services/signup/signup.service';
import Utils, { GetHelper } from 'src/app/helpers/utils';
import { MaskPipe } from 'ngx-mask';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-signup-unified-company-complementary-admin',
  templateUrl: './signup-unified-company-complementary-admin.component.html',
  styleUrls: ['./signup-unified-company-complementary-admin.component.scss'],
})
export class SignupUnifiedCompanyComplementaryAdminComponent
  implements OnInit, OnDestroy
{
  form = this.formBuilder.group({
    admin: [null, [Validators.required]],
  });

  loading = false;
  data: any[];
  sending = false;
  event: any;
  provider: any;
  funds: any[] = [];
  registerData: any;

  representativesData: Partial<Representative>[] = [];

  representativeDetails = false;
  selectedRepresentative: any = null;
  isContribuitor = false;
  powerGroupDetails = false;
  selectedPowerGroup: any = null;

  powerGroupData: Partial<RelevancePowerValues>[] = [];
  groupData: Groups[] = [];

  columns: TableColumnList<any>[] = [
    {
      name: 'Nome',
      cell: (row) => `
          <span>${row.full_name}
      </span>
          <span class="app-table-details-body-row-subtitle">
          ${this.formatCpf(row.document_number)}
          </span>
        `,
    },
    {
      name: 'Início de Mandato',
      cell: (row) =>
        `<div>de ${this.formatDate(row.min_term_validity)}</div>
        `,
    },
    {
      name: 'Fim de Mandato',
      cell: (row) =>
        `<div>${
          row.expiration_date
            ? this.formatDate(row.expiration_date)
            : 'indeterminado'
        }</div>`,
    },
    {
      name: '',
      cell: (row) =>
        `<div class="icon-container">
            <div class="representative-details row-${row.id}">
                <i class="fas fa-eye ml-auto"></i>
            </div>
        </div> `,
    },
  ];

  groupColumns: TableColumnList<Groups>[] = [
    {
      name: 'Grupos',
      cell: (row) => row.group,
    },
    // {
    //   name: 'Tipo do grupo',
    //   cell: (row) => this.renderGroupLabel(row.representative_type),
    // },
    {
      name: 'Pessoas',
      cell: (row) => row.people.join(', '),
    },
  ];

  powerGroupColumns: TableColumnList<any>[] = [
    {
      name: 'Grupo principal',
      cell: (row) => row.main_group,
    },
    {
      name: 'Grupo secundário',
      cell: (row) =>
        row.secondary_group ? row.secondary_group.join(', ') : '-',
    },
    {
      name: 'Assinaturas obrigatórias',
      cell: (row) => row.required_signs_quantity,
    },
    {
      name: 'Assinaturas necessárias',
      cell: (row) => row.necessary_signs_quantity,
    },
    {
      name: 'Poderes',
      cell: (row) => row.powers.join(', '),
      size: 1.5,
    },
    {
      name: '',
      cell: (row) =>
        `<div class="icon-container">
              <div class="power-details row-${row.id}">
                  <i class="fas fa-eye ml-auto"></i>
              </div>
          </div> `,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private authService: AuthService,
    private api: ApiService,
    private toast: ToastService,
    private maskPipe: MaskPipe
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.getRegisterData();

    this.event = this.signupService.sendComplementaryCompanyData.subscribe(
      (value) => {
        if (value === 'complementaryAdmin') {
          this.signupService.setComplementaryCompanyStep(
            'complementaryManager'
          );
          this.signupService.changeCompanyStepEvent.emit('manager');
        }
      }
    );
  }

  setHandlers() {
    const representative = document.querySelectorAll('.representative-details');

    representative.forEach((button) => {
      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-')[1];

        const finded = this.representativesData.find((item: any) => {
          if (item.id) {
            return Number(item.id) === Number(id);
          }

          return false;
        });

        if (finded) {
          this.openModal(finded);
        }
      });
    });

    const power = document.querySelectorAll('.power-details');

    power.forEach((button) => {
      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-')[1];

        const finded = this.powerGroupData.find((item: any) => {
          if (item.id) {
            return Number(item.id) === Number(id);
          }

          return false;
        });

        if (finded) {
          this.openPowerModal(finded);
        }
      });
    });
  }

  openModal(item: Partial<Representative>) {
    this.representativeDetails = true;
    this.selectedRepresentative = item;

    this.isContribuitor = this.selectedRepresentative.department ? true : false;
  }

  getDisabled() {
    return this.form.invalid;
  }

  async getRegisterData() {
    this.loading = true;
    try {
      const { data } = await this.api.get({
        route: `api/registration/register/${this.authService.user.active_register.register.id}`,
        token: true,
      });

      this.registerData = data;

      await this.getBondFunds();
      if (data.admin) this.getRepresentativesAndGroups(data.admin);
    } catch (error) {
      console.log(error);
    }

    this.loading = false;
  }

  async getBondFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/assignor_fund_interest/',
        token: true,
      });

      const data = res.map((item: any) => {
        return item.fund.id;
      });

      this.funds = data;

      await this.getProviders();
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar fundos de vínculo');
    }
  }

  async sendData() {
    this.sending = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload: any = {
        admin: valuesHelper.get('admin'),
      };

      await this.api.patch({
        route: `api/registration/register/${this.authService.user.active_register.register.id}/`,
        body: {
          // this.registerData
          admin: payload.admin,
        },
        token: true,
      });

      this.getRepresentativesAndGroups(payload.admin);

      this.toast.show(
        'info',
        'Sucesso',
        'Administrador adicionado com sucesso!'
      );

      this.form.reset();
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  async getRepresentativesAndGroups(admin: string) {
    this.provider = this.data.find((item) => item.value === admin);

    try {
      const res = await this.api.get({
        route: `api/registration/register_admin/${admin}`,
        token: true,
      });

      this.representativesData = res.representatives;
      this.groupData = res.power_groups;
      this.powerGroupData = res.powers;

      setTimeout(() => {
        this.setHandlers();
      }, 500);

      this.signupService.setCompanyFormStatus('complementaryAdmin', true);
    } catch (error) {
      console.log(error);
    }
  }

  async removeProvider() {
    try {
      this.provider = null;
    } catch (error) {
      console.log(error);
    }
  }

  async getProviders() {
    try {
      const res = await this.api.get({
        route: 'api/registration/register_admin',
        token: true,
        params: {
          funds: [this.funds],
          role: 'admin',
        },
      });

      const data = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
          extra: item.document,
        };
      });

      this.data = data;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao carregar administradores');
    }
  }

  formatDocument(document: string) {
    if (document.length < 14) {
      return (
        'CPF: ' +
        document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
      );
    }

    return (
      'CNPJ: ' +
      document.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      )
    );
  }

  formatCpf(value: string) {
    const newValue = this.maskPipe.transform(value, '000.000.000-00');

    return newValue;
  }

  formatDate(date?: string) {
    return date ? date.split('-').reverse().join('/') : '-';
  }

  formatGroupIntegrants(membersId: number[]) {
    let usersArr: any[] = [];

    if (this.representativesData.length > 0) {
      this.representativesData.map((item) => {
        if (membersId.includes(item.id)) {
          usersArr.push(this.formatCpf(item.cpf_cnpj) + ' | ' + item.full_name);
        }
      });
    }

    return usersArr ? usersArr.join(', ') : '';
  }

  formatGroup(groupIds: any[]) {
    let groupsArr: any[] = [];

    if (this.groupData.length > 0) {
      this.groupData.map((item) => {
        if (groupIds.includes(Number(item.id))) {
          groupsArr.push(item.name);
        }
      });
    }

    return groupsArr.length > 0 ? groupsArr.join(', ') : '-';
  }

  formatAddress(address: any) {
    const formatedAddress = `
    ${address.public_place}, ${address.number}, ${address.complement ?? ''}\n${
      address.district
    }, ${address.city} - ${address.uf}

    `;

    return formatedAddress;
  }

  formatNumber(number: string) {
    const numberWithoutDDI = number.slice(-11);
    const ddd = numberWithoutDDI.slice(0, 2);
    const onlyNumber = numberWithoutDDI.slice(2);
    const formatPhone = this.maskPipe.transform(onlyNumber, '00000-0000');

    const formatedPhone = `+55 (${ddd}) ${formatPhone}`;
    return formatedPhone;
  }

  getChoicesName(object: string, value: any) {
    const choices = this.signupService.signupChoices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  getNacionality(value: any) {
    const choices = this.signupService.nationalityChocies;

    if (value) {
      return choices.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  closeModal() {
    this.representativeDetails = false;
    this.powerGroupDetails = false;
  }

  openPowerModal(item: Partial<RelevancePowerValues>) {
    this.powerGroupDetails = true;
    this.selectedPowerGroup = item;
  }

  formatMaxValue(item) {
    if (item.max_sign_amount) {
      return Utils.formatBrl(item.max_sign_amount);
    } else {
      return 'Valor ilimitado';
    }
  }

  formatPowerIntegrants(membersId: number[]) {
    let usersArr: any[] = [];
    if (this.representativesData.length > 0) {
      this.representativesData.map((item) => {
        if (membersId.includes(item.id)) {
          usersArr.push(this.formatCpf(item.cpf_cnpj) + ' | ' + item.full_name);
        }
      });
    }

    return usersArr ? usersArr.join(', ') : '';
  }

  //   renderGroupLabel(type: string) {
  //     let label = '';

  //     switch (type) {
  //       case 'guarantor':
  //         label = 'Avalista';
  //         break;
  //       case 'solidarity_debtor':
  //         label = 'Devedor Solidário';
  //         break;

  //       default:
  //         label = 'Representante';
  //         break;
  //     }

  //     return label;
  //   }

  formatPower(power: any, isObj: boolean = false) {
    let powersArr: any[] = [];
    let authorizations = this.signupService.signatureAuthorizationChoices;

    const newPower = !isObj ? power : power.map((item) => item.id);

    if (newPower.length > 0) {
      authorizations.map((item) => {
        if (newPower.includes(Number(item.value))) {
          powersArr.push(item.label);
        }
      });
    }

    return powersArr ? powersArr.join(', ') : '';
  }
}
