import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  version = 'beta_1.1.0';

  constructor() {}

  get(key: string, parse: boolean = false) {
    try {
      const value = localStorage.getItem(key);

      if (value) {
        return parse ? JSON.parse(value) : value;
      }

      return undefined;
    } catch (error) {
      return undefined;
    }
  }

  set(key: string, value: any, parse: boolean = false) {
    try {
      let parsedValue = parse ? JSON.stringify(value) : value;

      localStorage.setItem(key, parsedValue);

      return true;
    } catch (error) {
      return undefined;
    }
  }

  remove(key: string) {
    try {
      localStorage.removeItem(key);

      return true;
    } catch (error) {
      return undefined;
    }
  }

  logout() {
    try {
      localStorage.removeItem('@app:token');
      localStorage.removeItem('@app:user');
      localStorage.removeItem(`@app:first_visit@${this.version}`);
      localStorage.removeItem('@app:preuser');

      return true;
    } catch (error) {
      return undefined;
    }
  }

  clearForm() {
    try {
      localStorage.removeItem('complementaryAddress');
      localStorage.removeItem('complementaryBank');
      localStorage.removeItem('complementaryComposition');
      localStorage.removeItem('complementaryEconomicForm');
      localStorage.removeItem('complementaryGeneral');
      localStorage.removeItem('complementaryRepresentatives');
      localStorage.removeItem('powers');
      localStorage.removeItem('relevancePowers');
      localStorage.removeItem('complementaryProfessional');
      localStorage.removeItem('haveRepresentative');

      return true;
    } catch (error) {
      return undefined;
    }
  }

  clear() {
    localStorage.clear();
  }

  setToken(token: string) {
    return this.set('@app:token', token);
  }

  getToken() {
    return this.get('@app:token');
  }

  setStep(step: string, data: any) {
    return this.set(`@app:step:${step}`, data, true);
  }

  getStep(step: string) {
    return this.get(`@app:step:${step}`, true);
  }

  setUser(user: any) {
    return this.set('@app:user', user, true);
  }

  getUser() {
    return this.get('@app:user', true);
  }

  setPreUser(preuser: any) {
    return this.set('@app:preuser', preuser, true);
  }
  getPreUser(): any {
    return this.get('@app:preuser', true);
  }

  setVisited() {
    return this.set(`@app:first_visit@${this.version}`, false);
  }

  getVisited() {
    return this.get(`@app:first_visit@${this.version}`);
  }
}
