import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() data: any[] = [];
  @Input() count: number = null;
  @Input() offset: number = null;
  @Input() resultsPerPage: number = 10;

  @Input() show: boolean = true;

  @Input() dashPagination: boolean = false;

  @Output() onPageChange = new EventEmitter<number>();
  @Output() onDataChange = new EventEmitter<any[]>();

  currentPage = 1;
  pages = [1];
  lastPage = 1;
  visiblePages = [];
  showLastPage = false;
  showFirstPage = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.paginateData();

    if (changes.count === null) {
      this.count = changes.data.currentValue.length;
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.paginateData();
    }, 1000);

    if (this.count === null) {
      this.count = this.data.length;
    }
  }

  paginateData() {
    const pageNumber = Math.ceil(this.count / this.resultsPerPage);

    const pages = [];

    for (let i = 1; i <= pageNumber; i++) {
      pages.push(i);
    }

    this.pages = pages;
    this.lastPage = this.pages.length;

    this.setVisiblePages();
  }

  setVisiblePages() {
    this.visiblePages = [];

    if (this.pages.length <= 7) {
      this.visiblePages = this.pages;
    } else {
      if (this.currentPage < 4) {
        this.visiblePages = [1, 2, 3, 4, 5, 6, 7];
      } else if (this.currentPage > this.lastPage - 3) {
        this.visiblePages = [
          this.lastPage - 6,
          this.lastPage - 5,
          this.lastPage - 4,
          this.lastPage - 3,
          this.lastPage - 2,
          this.lastPage - 1,
          this.lastPage,
        ];
      } else {
        this.visiblePages = [
          this.currentPage - 3,
          this.currentPage - 2,
          this.currentPage - 1,
          this.currentPage,
          this.currentPage + 1,
          this.currentPage + 2,
          this.currentPage + 3,
        ];
      }
    }

    this.showLastPage = this.currentPage < this.lastPage - 3;
    this.showFirstPage = this.currentPage > 4;
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.onPageChange.emit(this.currentPage);
    }
  }

  nextPage() {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
      this.onPageChange.emit(this.currentPage);
    }
  }

  changePage(page: number) {
    this.currentPage = page;
    this.data = [];

    this.paginateData();

    this.onPageChange.emit(page);
  }
}
