import { GuardUtils } from './../GuardUtils';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private guardUtils: GuardUtils,
    private router: Router,
    private toast: ToastService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const authenticated = this.guardUtils.isAuthenticated();

    if (authenticated) {
      return true;
    }

    const url = route.data.redirectTo;
    const redirect = state.url;

    const decodedRedirect = encodeURIComponent(state.url);

    const fullUrl = `${url}?redirect=${decodedRedirect}`;

    this.toast.show(
      'error',
      'Erro!',
      `Você precisa estar logado para acessar essa página!`
    );

    return this.router.navigateByUrl(fullUrl);
  }
}
