import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() isChecked = false;
  @Input() multiple = false;
  @Input() alternativeStyle = false;

  @Input() readOnly = false;
  @Input() disabled = false;

  @Input() align: Aligns = 'start';

  @Input() value: any;

  @Output() onCheckChanged = new EventEmitter<any>();

  checked: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.checked = this.isChecked;
  }

  ngOnChanges() {
    this.checked = this.isChecked;
  }

  _radioValue: any;

  get radioValue() {
    return this._radioValue;
  }

  set radioValue(value: any) {
    this._radioValue = value;

    this.onChangeCallback(value);
    this.onCheckChanged.emit(value);
  }

  check() {
    if (!this.readOnly && !this.disabled) {
      const newChecked = !this.checked;
      this.checked = newChecked;

      if (this.value === undefined) {
        this.radioValue = newChecked;
      } else {
        this.radioValue = newChecked ? this.value : null;
      }
    }
  }

  getAlignClass() {
    return `app-radio-container align-${this.align}`;
  }

  onChangeCallback = (_: any) => {};
  onTouchedCallback = () => {};

  writeValue(value: any) {
    if (value !== this.radioValue) {
      this.radioValue = value;

      if (typeof value === 'boolean') {
        this.checked = value;
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
