interface Country {
  initials: string;
  name: string;
}

export const countriesItems: Country[] = [{ initials: '1', name: 'Brasil' }];

export const countriesOptions: SelectItem[] = countriesItems.map((country) => ({
  label: country.name,
  value: country.initials,
}));
