import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-settings-modal',
  templateUrl: './user-settings-modal.component.html',
  styleUrls: ['./user-settings-modal.component.scss'],
})
export class UserSettingsModalComponent implements OnInit {
  @Input() active: boolean;
  @Input() isDisabled: boolean;
  @Input() figure: string;
  @Input() confirmLabel: string;
  @Input() title: string;
  @Input() isLoading: boolean;

  @Output() onClose = new EventEmitter();

  @Output() onSubmit = new EventEmitter();

  @Output() onCancel = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleCloseModal() {
    this.onClose.emit();
  }

  handleSubmit() {
    this.onSubmit.emit();
  }
}
