<div class="dash-card">
    <div class="dash-card-header">
        <div>
            <span *ngIf="is_approver">
                <ng-container *ngIf="loading">
                    <ngx-skeleton-loader [theme]="{
                        width: '150px'
                      }">
                    </ngx-skeleton-loader>
                </ng-container>

                <ng-container *ngIf="!loading">
                    Aguardando aprovação
                </ng-container>
            </span>
        </div>

        <div class="d-flex">
            <ng-container *ngIf="loading">
                <ngx-skeleton-loader [theme]="{
                    width: '200px',
                    height: '43px'
                  }">
                </ngx-skeleton-loader>
            </ng-container>

            <ng-container *ngIf="!loading">
                <!-- <app-button-with-dropdown label="Convidar cedente"
                    *ngIf="canInvite" (onClick)="handleNewInvite('assignor')"
                    [outline]="true" (toggleShowOptions)="toggleShowOptions()"
                    [showOptions]="showOptions" [haveIcon]="true">
                    <button class="dropdown-button" [class.outline]="true"
                        (click)="handleNewInvite('shareholder')">Convidar
                        cotista
                    </button>
                    <button class="dropdown-button" [class.outline]="true"
                        (click)="handleNewInvite('provider')">Convidar
                        prestador
                        de serviço
                    </button>
                </app-button-with-dropdown> -->

                <ng-container *ngIf="is_approver">
                    <!-- <app-spacer [right]="18"></app-spacer>

                <app-button [inverted]="true" (onClick)="toggleFilterModal()"
                    class="add-button">
                    <svg-icon src="assets/images/icon_filter.svg" class="mr-2">
                    </svg-icon>
                    Filtrar listagem
                    <ng-container *ngIf="filters !== 0">
                        ({{ filters }})
                    </ng-container>
                </app-button> -->
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="dash-card-body">
        <div class="dash-card-statistics" *ngIf="is_approver">
            <ng-container *ngIf="loading">
                <div class="col-12 p-0">
                    <div class="row w-100 m-0">
                        <div class="pl-0 col-4">
                            <ngx-skeleton-loader [theme]="{
                    height: '89px',
                    width: '100%',
                    margin:'0'
                  }">
                            </ngx-skeleton-loader>
                        </div>

                        <div class="pl-0 col-4">
                            <ngx-skeleton-loader [theme]="{
                    height: '89px',
                    width: '100%',
                    margin:'0'
                  }">
                            </ngx-skeleton-loader>
                        </div>

                        <div class="p-0 col-4">
                            <ngx-skeleton-loader [theme]="{
                    height: '89px',
                    width: '100%',
                    margin:'0'
                  }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="dash-card-statistics-item">
                    <span class="dash-card-statistics-title">Cadastros em
                        andamento</span>

                    <span class="dash-card-statistics-value">{{
                        statistics.in_progress
                        }}</span>
                </div>

                <div class="dash-card-statistics-item">
                    <span class="dash-card-statistics-title">Pendentes no meu
                        departamento</span>
                    <app-spacer [bottom]="4"></app-spacer>

                    <span class="dash-card-statistics-value">{{
                        statistics.pending
                        }}</span>
                </div>

                <div class="dash-card-statistics-item">
                    <span class="dash-card-statistics-title">Cadastros
                        homologados</span>
                    <app-spacer [bottom]="4"></app-spacer>

                    <span class="dash-card-statistics-value">
                        {{
                        statistics.homologated
                        }}
                    </span>
                </div>
            </ng-container>
        </div>

        <div class="dash-card-scroll" *ngIf="is_approver">
            <table *ngIf="loading" class="skeleton-table">
                <thead>
                    <tr>
                        <th *ngFor="let number of [0,1,2]">
                            <ngx-skeleton-loader [theme]="{
                                width: '100px'
                              }">
                            </ngx-skeleton-loader>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let number of [0,1,2]">
                        <td>
                            <div class="dash-card-item">
                                <div class="dash-card-item-initials">
                                    <span>
                                        <ngx-skeleton-loader appearance="circle"
                                            [theme]="{
                                        height: '48px',
                                        width: '48px'
                                      }">
                                        </ngx-skeleton-loader>
                                    </span>
                                </div>

                                <app-spacer [right]="16"></app-spacer>

                                <div class="dash-card-item-content">
                                    <div class="dash-card-item-content-title">
                                        <ngx-skeleton-loader [theme]="{
                                            height: '21px',
                                            width: '130px',
                                            margin:'0'
                                          }">
                                        </ngx-skeleton-loader>
                                    </div>
                                    <div
                                        class="dash-card-item-content-subtitle">
                                        <ngx-skeleton-loader [theme]="{
                                            height: '18px',
                                            width: '110px'
                                          }">
                                        </ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '21px',
                                width: '200px'
                              }">
                            </ngx-skeleton-loader>
                        </td>

                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '21px',
                                width: '100px'
                              }">
                            </ngx-skeleton-loader>
                        </td>
                    </tr>

                </tbody>
                <app-spacer [bottom]="28"></app-spacer>
            </table>

            <table *ngIf="assignors.length> 0 && !loading">
                <thead>
                    <tr>
                        <th>Empresa</th>
                        <th>Papel</th>
                        <th>Estágio Atual</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of assignors">
                        <td>
                            <div class="dash-card-item">
                                <div class="dash-card-item-initials">
                                    <span>{{getInitials(item)}}</span>
                                </div>

                                <app-spacer [right]="16"></app-spacer>

                                <div class="dash-card-item-content">
                                    <div class="dash-card-item-content-title">
                                        <app-info-badge
                                            [homologation]="item.attempt == 1"
                                            [blocked]="item.attempt > 1"
                                            [label]="item.attempt > 1 ? 'Atualização' : 'Novo'"></app-info-badge>
                                    </div>
                                    <div class="dash-card-item-content-title">
                                        {{getDisplayName(item)}}
                                    </div>
                                    <div
                                        class="dash-card-item-content-subtitle">
                                        Aprovação iniciada em {{item.created_at
                                        | date: "dd/MM/yyyy"}} | Aprovação
                                        atualizada em {{item.updated_at | date:
                                        "dd/MM/yyyy"}}
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td [style.max-width]="'300px'"
                            [style.word-break]="'break-all'">
                            <div class="dash-card-item-content">
                                <div class="dash-card-item-content-title">
                                    {{formatItemName(item)}}
                                </div>
                            </div>
                        </td>

                        <td>
                            <span class="status">{{getStatus(item)}}
                            </span>
                            <div class="status-aprovation">
                                {{getStatusInfo(item)}}
                            </div>
                        </td>

                        <td style="text-align: right">
                            <a [routerLink]="renderLink(item)">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <ng-container *ngIf="filtered">
                <div class="empty-card"
                    *ngIf="assignors.length === 0 && !loading">
                    <div class="img-container">
                        <img src="/assets/images/figure_3d_search.png"
                            alt="icone">
                    </div>
                    <app-spacer [bottom]="48"></app-spacer>

                    <h3>Não encontramos resultados para sua busca</h3>
                    <p>
                        Não há registros para o termo digitado, verifique a
                        ortografia ou tente novamente com outras
                        palavras
                    </p>
                </div>
            </ng-container>

            <ng-container *ngIf="!filtered">
                <div class="empty-card"
                    *ngIf="assignors.length === 0 && !loading">
                    <div class="img-container">
                        <img src="/assets/images/star.png" alt="icone">
                    </div>
                    <app-spacer [bottom]="48"></app-spacer>

                    <h3>Nada por enquanto</h3>
                    <p>
                        Até o momento não há cadastros pendentes de aprovação
                        para o seu departamento
                    </p>
                </div>
            </ng-container>
        </div>

        <div class="empty-card consultant" *ngIf="!is_approver">
            <div class="img-container">
                <img src="/assets/images/target.png" alt="icone">
            </div>
            <app-spacer [bottom]="48"></app-spacer>

            <h3>Plataforma de cadastro</h3>
            <p>
                Realize consultas através do campo de busca localizada no topo
                da página ;)
            </p>
        </div>

        <div class="pb-4"></div>


        <div class="dash-card-button d-flex justify-content-between">
            <ng-container *ngIf="loading">
                <ngx-skeleton-loader [theme]="{
                    width: '150px'
                  }">
                </ngx-skeleton-loader>
            </ng-container>

            <ng-container *ngIf="!loading && canViewUsers">
                <app-button [phantom]="true"
                    (onClick)="redirectTo('/app/registers')">
                    Visualizar todos os registros</app-button>
            </ng-container>
        </div>
    </div>
</div>

<div class="dash-card-pagination">
    <app-pagination [data]="assignors" [show]="!loading"
        [dashPagination]="true">
    </app-pagination>
</div>

<app-side-modal [active]="newInvite" [title]="renderInviteTitle()"
    (onClose)="closeModal()">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12" *ngIf="selectedRole === 'provider'">
                <app-select label="Agente" [group]="form" controlName="agent"
                    [items]="agentOptions" [multiple]="true">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-input [group]="form" controlName="name" label="Nome"
                    [helpText]="selectedRole === 'shareholder' ? 'Se pessoa jurídica, inserir a razão social' : 'Se pessoa física, inserir nome completo'"
                    [placeholder]="selectedRole === 'shareholder' ? 'Digite' : 'ACME LTDA'">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-masked-input [group]="form" controlName="document"
                    label="CPF / CNPJ" mask="CPF_CNPJ"
                    placeholder="Insira seu documento">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input type="email" label="E-mail" [group]="form"
                    controlName="email">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12" *ngIf="selectedRole === 'assignor'">
                <app-select label="Fundo de interesse" [group]="form"
                    controlName="fund" [items]="fundOptions">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="form" controlName="due_date"
                    mask="00/00/0000" label="Validade do convite"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="selectedRole === 'provider'">
                <app-radio [multiple]="true" formControlName="is_approver">
                    <span class="app-radio-text">Delegar poderes de
                        aprovação</span>
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="closeModal()" [phantom]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="sendInvite()" class="add-button"
                        [disabled]="getDisabled()">
                        Disparar convite
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>


<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="closeModal()">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input label="Por nome ou documento" [group]="filterForm"
                    controlName="search" placeholder="Ex.: ACME LTDA">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="agentOptions" [group]="filterForm"
                    controlName="agents" placeholder="Selecione"
                    label="Por agente de atuação" [multiple]="true">
                </app-select>
                <app-spacer [bottom]="32"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="roleOptions" [group]="filterForm"
                    controlName="roles" placeholder="Selecione"
                    label="Por papel de atuação" [multiple]="true">
                </app-select>
                <app-spacer [bottom]="32"></app-spacer>
            </div>

            <div class="col-12">
                <h3>Por data de cadastro</h3>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-masked-input [group]="filterForm"
                    controlName="created_from" mask="00/00/0000" label="De"
                    placeholder="dd/mm/aaaa"
                    [errorText]="errrorMessageObj.created_from">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-masked-input [group]="filterForm" controlName="created_to"
                    [errorText]="errrorMessageObj.created_to" mask="00/00/0000"
                    label="Até" placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="32"></app-spacer>
            </div>


            <div class="col-12">
                <h3>Por data de atualização</h3>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-masked-input [group]="filterForm"
                    controlName="updated_from" mask="00/00/0000" label="De"
                    [errorText]="errrorMessageObj.updated_from"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-masked-input [group]="filterForm" controlName="updated_to"
                    [errorText]="errrorMessageObj.updated_to" mask="00/00/0000"
                    label="Até" placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [outline]="true" [bordless]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterUsers()" class="add-button">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>