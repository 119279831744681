<div class="dash">
    <div class="row">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card no-padding">
                <ng-container *ngIf="!loading">
                    <app-back-button></app-back-button>
                </ng-container>

                <div
                    class=" dash-card-header d-flex align-items-start justify-content-between">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            width: '180px',
                            height: '25px'
                          }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <h2>Documentos</h2>
                    </ng-container>


                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                        width: '180px',
                        height: '40px'
                      }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button (onClick)="formToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i>
                            Adicionar documento
                        </app-button>
                    </ng-container>
                </div>

                <div class="dash-card-body  padding-container">
                    <div clsas="styled-scroll">
                        <ng-container *ngIf="loading">
                            <table #table>
                                <thead>
                                    <tr>
                                        <th>
                                            <ngx-skeleton-loader [theme]="{
                                            height: '15px',
                                            width: '125px',
                                            marginBottom:'0'
                                          }">
                                            </ngx-skeleton-loader>

                                        </th>

                                        <th>
                                            <ngx-skeleton-loader [theme]="{
                                            height: '15px',
                                            width: '125px',
                                            marginBottom:'0'
                                          }">
                                            </ngx-skeleton-loader>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                        <td>
                                            <ngx-skeleton-loader [theme]="{
                                                height: '15px',
                                                width: '100px'
                                              }">
                                            </ngx-skeleton-loader>

                                            <div>
                                                <ngx-skeleton-loader [theme]="{
                                                height: '30px',
                                                width: '150px',
                                            marginBottom:'0'
                                              }">
                                                </ngx-skeleton-loader>
                                            </div>
                                        </td>
                                        <td>
                                            <ngx-skeleton-loader [theme]="{
                                            height: '25px',
                                            width: '200px',
                                            marginBottom:'0'
                                          }">
                                            </ngx-skeleton-loader>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>

                        <table class="bordered" *ngIf="!loading">
                            <thead>
                                <tr>
                                    <th>Documento</th>
                                    <th>Vínculo</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let document of documents">
                                    <td class="">
                                        <div class="d-flex flex-column">
                                            <span
                                                class="documents-name">{{document.name}}</span>
                                            <span
                                                class="documents-description">{{document.description}}</span>
                                        </div>
                                    </td>
                                    <td>{{formatRoles(document.roles)}}</td>
                                    <td>
                                        <div class="dash-card-item-buttons">
                                            <button class="dash-item-button"
                                                (click)="removeDocument(document.id)"
                                                *ngIf="permissionObj.canDelete">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                            <app-spacer
                                                [right]="16"></app-spacer>

                                            <button class="dash-item-button"
                                                (click)="setDocument(document)"
                                                *ngIf="permissionObj.canChange">
                                                <i class="fas fa-pen"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr *ngIf="documents.length === 0">
                                    <td>Nenhum documento adicionado</td>
                                </tr>

                            </tbody>
                            <app-spacer [bottom]="28"></app-spacer>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card-pagination">
                <app-pagination [data]="documents" [dashPagination]="true"
                    [show]="!loading">
                </app-pagination>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="modal" (onClose)="closeModal()"
    [title]="document ? 'Atualizar documento' : 'Adicionar documento'"
    paddingBottom="30">
    <ng-container *ngIf="modal">
        <div class="row align-items-center" [formGroup]="form">
            <div class="col-12">
                <app-input label="Nome do documento" [group]="form"
                    controlName="name" helpText="Máximo de 30 caracteres"
                    [maxLength]="30">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Descrição" [rows]="3" [group]="form"
                    controlName="description"
                    helpText="Máximo de 210 caracteres" [maxLength]="210">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="agentsOptions" [multiple]="true"
                    label="Defina para quais agentes este documento deve ser solicitado"
                    [group]="form" controlName="roles">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="showFrameworks">
                <app-select [items]="frameworkList" [multiple]="true"
                    label="Defina o enquadramento da empresa" [group]="form"
                    controlName="frameworks">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="required">
                    <span class="app-radio-text">Preenchimento
                        obrigatório</span>
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="have_due_date">
                    <span class="app-radio-text">Possui data de validade</span>
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-radio [multiple]="true" formControlName="individual"
                    [disabled]="disableIndividual">
                    <span class="app-radio-text">Solicitar individualmente para
                        cada pessoa física</span>
                </app-radio>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="cardsList" [multiple]="true"
                    label="Grupo de referência"
                    helpText="Defina o grupo onde o anexo ficara visível no detalhe do cadastro"
                    [group]="form" controlName="show_in"
                    [readOnly]="disableShowIn">
                </app-select>
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
            </div>

            <div class=" col-12 col-lg-6 text-right">
                <app-button [disabled]="form.invalid" (click)="addDocument()">
                    {{document ? 'Atualizar documento' : 'Adicionar documento'}}
                </app-button>
            </div>
        </div>
    </ng-container>

</app-side-modal>