<div class="app-stepper">
    <div class="app-stepper-title">
        <span>Você está em</span>
    </div>

    <app-spacer [bottom]="19"></app-spacer>

    <div class="app-stepper-content">
        <div *ngFor="let step of steps; let i = index"
            class="app-stepper-content-step" [class.current]="activeIndex === i"
            [class.done]="activeIndex > i" [class.disabled]="step.disabled"
            [class.d-none]="step.hided">
            <div class="app-stepper-content-step-label"
                [style.cursor]="haveNavigation ? 'pointer' : 'auto'"
                [class.last]="i === steps.length - 1"
                (click)="handleChangeStep(step)">
                <div class="app-stepper-content-step-icon">
                    <!-- <i [class]="activeIndex > i ? 'far fa-check-circle' : step.icon"></i> -->
                    <svg-icon
                        [src]="activeIndex > i ? 'assets/images/icon-step-checked.svg' : step.svg"></svg-icon>
                </div>

                <div class="app-stepper-content-step-title">
                    <span>{{step.title}}</span>
                </div>
            </div>

            <div *ngFor="let substep of step.substeps; let idx = index"
                class="sub-steps"
                [style.cursor]="haveNavigation ? 'pointer' : 'auto'"
                [class.current]="activeSubstepSlug === substep.slug && activeIndex === 0"
                [class.passedStep]="handlePassedStep(substep.slug)"
                (click)="handleChangeSubStep(substep)">
                <div class="sub-steps-bullet">
                    <span></span>
                </div>
                <span>
                    {{substep.title}}
                </span>
            </div>
        </div>
    </div>
</div>