import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-attachment-button',
  templateUrl: './attachment-button.component.html',
  styleUrls: ['./attachment-button.component.scss'],
})
export class AttachmentButtonComponent implements OnInit {
  constructor(private api: ApiService) {}

  @Input() slugArr: fileSlugArrProps[] = [];
  @Input() full: boolean = false;

  @Output() onSelect = new EventEmitter<MouseEvent>();

  showOptions = false;

  ngOnInit(): void {}

  toggleShowOptions() {
    this.showOptions = !this.showOptions;
  }

  handleShowVisualizer(slug) {
    this.onSelect.emit(slug);
    this.showOptions = false;
  }
}
