<div class="dashboard">
    <div class="row w-100">
        <div class="col-12 ml-auto mr-auto">
            <div class="dash-card">
                <div class="dash-card-header">
                    <span>
                        <ng-container *ngIf="loading">
                            <ngx-skeleton-loader [theme]="{
                        width: '150px'
                      }">
                            </ngx-skeleton-loader>
                        </ng-container>

                        <ng-container *ngIf="!loading">
                            Convites
                        </ng-container>
                    </span>
                </div>

                <div class="fund-dash-header-buttons  justify-content-between">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                    width: '200px',
                    height: '43px'
                  }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button [inverted]="true"
                            (onClick)="toggleFilterModal()" class="add-button">
                            <svg-icon src="assets/images/icon_filter.svg">
                            </svg-icon>
                            Filtrar listagem
                        </app-button>

                        <app-spacer [right]="18"></app-spacer>

                        <app-button class="add-button" (click)="toggleModal()">
                            <i class="fas fa-plus-circle mr-2"></i>
                            Novo convite
                        </app-button>
                    </ng-container>
                </div>

                <app-spacer [bottom]="32"></app-spacer>

                <div class="dash-card-body">
                    <div class="dash-card-scroll">
                        <table *ngIf="loading" class="skeleton-table">
                            <thead>
                                <tr>
                                    <th *ngFor="let number of [0,1,2]">
                                        <ngx-skeleton-loader [theme]="{
                                width: '100px'
                              }">
                                        </ngx-skeleton-loader>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let number of [0,1,2]">
                                    <td>
                                        <div class="dash-card-item">
                                            <div
                                                class="dash-card-item-initials">
                                                <span>
                                                    <ngx-skeleton-loader
                                                        appearance="circle"
                                                        [theme]="{
                                        height: '48px',
                                        width: '48px'
                                      }">
                                                    </ngx-skeleton-loader>
                                                </span>
                                            </div>

                                            <app-spacer
                                                [right]="16"></app-spacer>

                                            <div class="dash-card-item-content">
                                                <div
                                                    class="dash-card-item-content-title">
                                                    <ngx-skeleton-loader
                                                        [theme]="{
                                            height: '21px',
                                            width: '130px',
                                            margin:'0'
                                          }">
                                                    </ngx-skeleton-loader>
                                                </div>
                                                <div
                                                    class="dash-card-item-content-subtitle">
                                                    <ngx-skeleton-loader
                                                        [theme]="{
                                            height: '18px',
                                            width: '110px'
                                          }">
                                                    </ngx-skeleton-loader>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <ngx-skeleton-loader [theme]="{
                                height: '21px',
                                width: '200px'
                              }">
                                        </ngx-skeleton-loader>
                                    </td>

                                    <td>
                                        <ngx-skeleton-loader [theme]="{
                                height: '21px',
                                width: '100px'
                              }">
                                        </ngx-skeleton-loader>
                                    </td>
                                </tr>

                            </tbody>
                            <app-spacer [bottom]="28"></app-spacer>
                        </table>

                        <table *ngIf="invites.length> 0 && !loading">
                            <thead>
                                <tr>
                                    <th>Nome / Razão social</th>
                                    <th>papel</th>
                                    <th>Fundo de interesse</th>
                                    <th>Status</th>
                                    <th>Data de envio</th>
                                    <th>Data de expiração</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of invites">
                                    <td>
                                        <div class="dash-card-item">
                                            <div
                                                class="dash-card-item-initials">
                                                <span>{{getInitials(item)}}</span>
                                            </div>

                                            <app-spacer
                                                [right]="16"></app-spacer>
                                            <div class="dash-card-item-content">
                                                <div
                                                    class="dash-card-item-content-title">
                                                    {{item.data.name ?? '-'}}
                                                </div>
                                                <div
                                                    class="dash-card-item-content-subtitle">
                                                    {{item.data.document}}
                                                    <br>
                                                    {{item.data.email}}
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{item.type_display}}
                                            </div>
                                            <div class="dash-card-item-content-subtitle"
                                                *ngIf="item.type === 'provider'">
                                                {{displayAgents(item.data.agent
                                                ?? item.data.role)}}
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        {{displayInterestFund(item.data.fund)}}
                                    </td>

                                    <td>
                                        {{displayStatus(item)}}
                                    </td>
                                    <td>
                                        {{displaySendDate(item.created_at)}}
                                    </td>
                                    <td>
                                        {{displayExpirationDate(item.created_at)}}
                                    </td>
                                    <td style="text-align: right">
                                        <div class="d-flex justify-content-end">
                                            <button class="button mr-2"
                                                title="detalhes do convite"
                                                (click)="openDetailsModal(item.id)">
                                                <i class="fas fa-eye"></i>
                                            </button>

                                            <button class="button mr-2"
                                                (click)="handleResendInvite(item.id)"
                                                title="reenviar convite"
                                                *ngIf="!item.used && !item.canceled">
                                                <i class="fas fa-share"></i>
                                            </button>

                                            <button class="button"
                                                title="cancelar convite"
                                                (click)="handleShowCancelModal(item.id)">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- <ng-container *ngIf="filtered">
                <div class="empty-card"
                    *ngIf="invites.length === 0 && !loading">
                    <div class="img-container">
                        <img src="/assets/images/figure_3d_search.png"
                            alt="icone">
                    </div>
                    <app-spacer [bottom]="48"></app-spacer>

                    <h3>Não encontramos resultados para sua busca</h3>
                    <p>
                        Não há registros para o termo digitado, verifique a
                        ortografia ou tente novamente com outras
                        palavras
                    </p>
                </div>
            </ng-container> -->
                    </div>

                    <div class="pb-4"></div>
                </div>
            </div>

            <div class="dash-card-pagination">
                <app-pagination [data]="invites" [count]="count"
                    [show]="!loading" [offset]="offset"
                    (onPageChange)="changePageData($event)"
                    [dashPagination]="true">
                </app-pagination>
            </div>
        </div>
    </div>
</div>


<app-warning-modal [active]="showCancelModal"
    (onClose)="handleShowCancelModal()">
    <h3>Atenção</h3>
    <app-spacer [bottom]="24"></app-spacer>
    <p>
        Você tem certeza que deseja cancelar o convite de
        <strong>{{ renderInviteLabels() }}</strong>?
    </p>
    <app-spacer [bottom]="56"></app-spacer>

    <app-button (onClick)="cancelInvite()" [center]="true" [danger]="true">
        Sim
    </app-button>
    <app-spacer [bottom]="8"></app-spacer>
    <app-button (onClick)="handleShowCancelModal()" [center]="true"
        class="add-button">
        Não
    </app-button>
</app-warning-modal>



<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="filterForm" controlName="name" label="Nome"
                    placeholder="Digite">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input type="email" label="E-mail" [group]="filterForm"
                    [alwaysValid]="true" controlName="email">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" class="add-button">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-side-modal [active]="newInvite"
    [title]="editing ? 'Reenviar convite' : 'Novo convite'"
    (onClose)="closeModal()">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <app-select label="Papel" [group]="form" controlName="role"
                    [items]="roleOptions" [readOnly]="inviteId">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12" *ngIf="form.controls.role.value === 'provider'">
                <app-select label="Agente" [group]="form" controlName="agent"
                    [items]="agentOptions" [multiple]="true"
                    [readOnly]="inviteId && form.controls.agent.value && form.controls.agent.value.length > 0">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-input [group]="form" controlName="name" label="Nome"
                    [helpText]="form.controls.role.value === 'shareholder' ? 'Se pessoa jurídica, inserir a razão social' : 'Se pessoa física, inserir nome completo'"
                    [placeholder]="form.controls.role.value === 'shareholder' ? 'Digite' : 'ACME LTDA'"
                    [readOnly]="inviteId">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6">
                <app-masked-input [group]="form" controlName="document"
                    label="Documento"
                    helpText="Se PF inserir CPF, caso PJ inserir CNPJ"
                    mask="CPF_CNPJ" placeholder="Insira seu documento"
                    [readOnly]="inviteId">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input type="email" label="E-mail" [group]="form"
                    controlName="email">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12" *ngIf="form.controls.role.value !== 'provider'">
                <app-select label="Fundo de interesse" [group]="form"
                    controlName="fund" [items]="fundOptions">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="form.controls.role.value === 'provider'">
                <app-radio [multiple]="true" formControlName="is_approver">
                    <span class="app-radio-text">
                        Delegar poderes de aprovação
                    </span>
                </app-radio>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="closeModal()" [phantom]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button
                        (onClick)="editing ? resendInvite() : sendInvite()"
                        class="add-button" [disabled]="getDisabled()">
                        {{editing ? 'Reenviar convite' : 'Disparar convite'}}
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-side-modal [active]="inviteDetails !== null" title="Convite"
    (onClose)="closeModal()" paddingBottom="30">
    <div class="container" *ngIf="inviteDetails !== null">
        <div class="row">
            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Nome / Razão social
                </span>

                <span class="dash-details-card-item-title">
                    {{inviteDetails.data.name}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    CPF / CNPJ
                </span>

                <span class="dash-details-card-item-title">
                    {{inviteDetails.data.document}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Email
                </span>

                <span class="dash-details-card-item-title">
                    {{inviteDetails.data.email}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Papel
                </span>

                <span class="dash-details-card-item-title">
                    {{inviteDetails.type_display}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column"
                *ngIf="inviteDetails.type === 'provider'">
                <span class="dash-details-card-item-subtitle">
                    Função do prestador
                </span>

                <span class="dash-details-card-item-title">
                    {{displayAgents(inviteDetails.data.agent ??
                    inviteDetails.data.role)}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Fundo de interesse
                </span>

                <span class="dash-details-card-item-title">
                    {{inviteDetails.data.fund ?
                    displayInterestFund(inviteDetails.data.fund) : '-' }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Inteção do convite
                </span>

                <span class="dash-details-card-item-title">
                    {{inviteDetails.intent_display}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Status
                </span>

                <span class="dash-details-card-item-title">
                    {{displayStatus(inviteDetails)}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Data de expiração
                </span>

                <span class="dash-details-card-item-title">
                    {{displayExpirationDate(inviteDetails.created_at)}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>
    </div>
</app-side-modal>