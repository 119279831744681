import { MaskPipe } from 'ngx-mask';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Utils from 'src/app/helpers/utils';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  forgotStep: number = 0;

  recoveryForm = this.formBuilder.group({
    type: [null, [Validators.required]],
  });
  error = 'Campo inválido';

  @Output() onForgotClose = new EventEmitter<boolean>();

  forgotInstructionTitle: string = '';
  forgotInstructionText: string = '';

  code = '0000';

  loading = false;

  profileTypes: Choice[] = [
    {
      icon: 'fas fa-envelope',
      title: 'Via e-mail',
      description:
        'Você receberá as instruções através do seu endereço de e-mail cadastrado',
      value: 'email',
    },
    {
      icon: 'fas fa-comment-alt-dots',
      title: 'Via SMS',
      description:
        'Você receberá as instruções através do seu número de telefone cadastrado',
      value: 'phone',
    },
  ];

  hidedEmail = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private apiService: ApiService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (this.authService.code !== '0000') {
      this.forgotStep = 3;

      setTimeout(() => {
        this.authService.setUserAuth('authStep', '0000');
        this.closeForgot();
      }, 5000);
    }

    this.recoveryForm.valueChanges.subscribe(({ value }) => {
      this.authService.forgotValue = value;
    });
  }

  async sendChoice() {
    this.loading = true;

    const payload = {
      username: Utils.onlyNumbers(this.authService.forgotEmail),
    };

    try {
      const res = await this.apiService.post<ValidateFieldResponse>({
        route: 'validate-username-exists/',

        body: payload,
      });

      if (res.exists) {
        await this.apiService.post({
          route: 'request-token/',
          body: {
            ...payload,
            channel: 'all',
            reset: true,
          },
        });

        this.forgotInstructionTitle = 'Instruções enviadas';
        this.forgotInstructionText =
          'Em instantes você receberá um código de segurança para continuar com a troca da senha, aguarde…';
        this.forgotStep++;
      } else {
        this.toastService.show(
          'error',
          'Ops...',
          'Parece que o e-mail não foi encontrado'
        );
        this.error = 'Tente novamente com um outro e-mail';
        // this.recoveryForm.controls.email.setErrors({ incorrect: true });
      }
    } catch (error) {
      console.error(error);
    }

    this.loading = false;
  }

  closeForgot() {
    this.onForgotClose.emit(true);
  }

  handleNextStep() {
    this.forgotStep++;
  }

  handleCloseForgot() {
    this.forgotStep = 0;

    this.onForgotClose.emit(true);
  }

  sendRequest() {
    this.closeForgot();
  }

  onCodeValidate({ valid, code }: ValidateResponse) {
    if (valid) {
      this.code = code;
      this.authService.setUserAuth('recoveryStep', code);

      this.apiService.post({
        route: 'request-reset-email/',
        body: { email: this.authService.forgotEmail },
      });

      this.forgotStep++;
    } else {
      this.toastService.show('error', 'Erro!', 'Código inválido');
    }
  }
}
