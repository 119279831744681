<form [formGroup]="form">
    <div class="row no-gutters">

        <div class="offset-1 col-8">
            <span class="basic-content-title">
                Agora crie uma senha para acessar sua conta {{'brand.name' |
                theme}}
            </span>
        </div>
    </div>

    <app-spacer [bottom]="48"></app-spacer>

    <div class="row no-gutters">

        <div class="col-12 offset-lg-1 col-lg-4">
            <div class="row">

                <div class="col-12">
                    <app-radio [readOnly]="true"
                        [isChecked]="passwordStrength().includes(1)">
                        <span class="radio-text"
                            [class.active]="passwordStrength().includes(1)">Mínimo
                            de 8
                            caracteres</span>
                    </app-radio>
                    <app-spacer [bottom]="14"></app-spacer>
                </div>
                <div class="col-12">
                    <app-radio [readOnly]="true"
                        [isChecked]="passwordStrength().includes(2)">
                        <span class="radio-text"
                            [class.active]="passwordStrength().includes(2)">Mínimo
                            de 1 número
                        </span>
                    </app-radio>
                    <app-spacer [bottom]="14"></app-spacer>
                </div>
                <div class="col-12">
                    <app-radio [readOnly]="true"
                        [isChecked]="passwordStrength().includes(3)">
                        <span class="radio-text"
                            [class.active]="passwordStrength().includes(3)">Letras
                            maiúsculas e
                            minúsculas </span>
                    </app-radio>
                    <app-spacer [bottom]="14"></app-spacer>
                </div>
                <div class="col-12">
                    <app-radio [readOnly]="true"
                        [isChecked]="passwordStrength().includes(4)">
                        <span class="radio-text"
                            [class.active]="passwordStrength().includes(4)">Caracteres
                            especiais
                        </span>
                    </app-radio>
                    <app-spacer [bottom]="21"></app-spacer>
                </div>

                <div class="col-12">
                    <p class="password-description-text">Para reforçar a
                        segurança da sua senha, não utilize informações
                        pessoais como datas de nascimentos ou números de
                        telefones e nem números sequenciais.</p>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>

                <div class="col-12">
                    <app-input type="password" [maxLength]="32"
                        label="Defina uma senha de acesso" [group]="form"
                        controlName="password" placeholder="Digite sua senha">
                    </app-input>
                    <div class="password-meter">
                        <p class="password-meter-text">
                            {{passwordStrengthText()}}</p>

                        <div class="password-meter-bars">
                            <div class="password-meter-bar"
                                [class.active]="passwordStrengthPercentage() > 30">
                            </div>
                            <div class="password-meter-bar"
                                [class.active]="passwordStrengthPercentage() > 60">
                            </div>
                            <div class="password-meter-bar"
                                [class.active]="passwordStrengthPercentage() > 90">
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </div>
</form>