import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-choices',
  templateUrl: './choices.component.html',
  styleUrls: ['./choices.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ChoicesComponent),
    },
  ],
})
export class ChoicesComponent implements OnInit, ControlValueAccessor {
  @Input() choices: Choice[] = [];

  @Output() choiceSelected = new EventEmitter<Choice>();

  selectedChoice?: Choice;

  constructor() {}

  ngOnInit(): void {}

  selectChoice(choice: Choice) {
    this.selectedChoice = choice;
    this.value = choice.value;
    this.choiceSelected.emit(choice);
  }

  _value: string = '';

  set value(val: string) {
    this._value = val;
    this.onChangeCallback(val);
  }

  get value(): string {
    return this._value;
  }

  onChangeCallback: (_: any) => void = () => {};
  onTouchedCallback: () => void = () => {};

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
