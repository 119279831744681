import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import utils from '../../helpers/utils';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() id?: string = utils.makeID();
  @Input() name?: string;
  @Input() placeholder: string = 'Digite';
  @Input() label?: string;
  @Input() noResize?: boolean = true;
  @Input() type = 'text';
  @Input() alwaysValid: boolean = false;

  @Input() standalone: boolean = false;
  @Input() value: string = '';

  @Input() pattern?: string;

  @Input() min?: number | string;
  @Input() max?: number | string;

  @Input() maxLength: number = 150;

  @Input() showPassword: boolean = true;

  @Input() hasDate?: boolean;

  // Form control
  @Input() controlName: string = '';
  @Input() group?: any = new FormGroup({});

  @Input() helpText?: string;

  @Input() errorText: string = 'Campo inválido';

  @Input() readOnly: boolean = false;
  // @Input() validated: boolean = false;
  @Input() rows: number = 0;

  @Input() isUpperCase = false;

  @Input() button: string = '';

  @Input() isDocument: boolean = false;

  @Input() hasError: boolean = false;

  @Output() onButtonClick: EventEmitter<MouseEvent> = new EventEmitter();

  @Output() onChange: EventEmitter<string> = new EventEmitter();

  error: boolean = false;

  isPassword = false;

  validated = false;

  constructor(private apiService: ApiService) {}

  onButtonClicked(event: MouseEvent): void {
    this.onButtonClick.emit(event);
  }

  handleChange(event: any): void {
    this.onChange.emit(event.target.value);
  }

  ngOnInit(): void {
    if (this.showPassword) {
      this.isPassword = this.type === 'password';
    }

    if (
      this.type === 'date' &&
      (this.controlName === 'birth_date' || this.name === 'birth_date')
    ) {
      const today = new Date();

      const minAge = 18;
      const maxAge = 120;

      const minDate = new Date(
        today.getFullYear() - maxAge,
        today.getMonth(),
        today.getDate()
      )
        .toISOString()
        .split('T')[0];

      const maxDate = new Date(
        today.getFullYear() - minAge,
        today.getMonth(),
        today.getDate()
      )
        .toISOString()
        .split('T')[0];

      this.max = maxDate;
      this.min = minDate;
    }

    if (this.isDocument) {
      this.group.controls[this.controlName].valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((values: any) => {
          this.documentInUse(utils.onlyNumbers(values));
        });
    }
  }

  filledDate() {
    if (this.type === 'date') {
      if (this.standalone) {
        return this.value !== null && this.value !== '';
      } else {
        const control = this.group.controls[this.controlName];

        return control.value !== null && control.value !== '';
      }
    }
    return false;
  }

  getClasses(): string {
    if (this.error) {
      return 'error';
    }

    return '';
  }

  getInvalid(): boolean {
    if (this.standalone) {
      return this.hasError;
    } else {
      let invalid = false;

      if (this.group) {
        const control = this.group.controls[this.controlName];

        if (control.invalid) {
          invalid = true;
        }
      }

      return invalid;
    }
  }

  getTouched(): boolean {
    if (this.standalone) {
      return true;
    } else {
      let touched = false;

      if (this.readOnly) return touched;

      if (this.group) {
        const control = this.group.controls[this.controlName];

        if (control) {
          if (control.touched) {
            touched = true;
          }
        } else {
          console.error(`Control of ${this.controlName} not found!`);
        }
      }

      return touched;
    }
  }

  getRequired(): boolean {
    if (this.standalone) {
      return true;
    } else {
      const control = this.group.controls[this.controlName];

      if (control && control.validator) {
        let required = false;

        const validator = control.validator({} as AbstractControl);

        if (validator && validator.required) {
          required = true;
        }

        return required;
      }
    }

    return false;
  }

  getError(): boolean {
    if (this.alwaysValid) return false;
    return this.getTouched() && this.getInvalid();
  }

  passwordReveal(): void {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  async documentInUse(document: string) {
    try {
      const res = await this.apiService.post<ValidateFieldResponse>({
        route: 'validate-document-exists/',
        body: { document: document },
      });

      if (res.exists) {
        this.errorText = 'Este documento já está cadastrado';
        this.error = true;
        this.group.controls[this.controlName].setErrors({
          documentInUse: true,
        });
      } else {
        this.errorText = 'Campo inválido';
        this.error = false;
        this.group.controls[this.controlName].setErrors(null);
      }
    } catch (error) {
      this.error = false;
      this.group.controls[this.controlName].setErrors(null);
    }
  }
}
