import { SignupService } from 'src/app/services/signup/signup.service';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import Utils from 'src/app/helpers/utils';
import { MaskPipe } from 'ngx-mask';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalFilesUrlsProps } from '../dashboard-approval-attachments/dashboard-approval-attachments.component';
import employeesOptions from 'src/app/data/employeesOptions';
import { ApiService } from 'src/app/services/api/api.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-approval-complementary',
  templateUrl: './dashboard-approval-complementary.component.html',
  styleUrls: ['./dashboard-approval-complementary.component.scss'],
})
export class DashboardApprovalComplementaryComponent implements OnInit {
  @ViewChild('modalRoot') modalRoot;

  approval: UserApprovalResponse;

  filteredFiles: any;
  hideDocument = true;

  bankColumns: TableColumnList<Bankinformation>[] = [
    {
      name: 'Banco',
      cell: (row) => `<span>${row.bank.bank_code} - ${row.bank.name}</span>`,
      size: 2,
    },
    {
      name: 'Conta',
      cell: (row) =>
        `Ag: ${row.agency} Conta: ${row.account_number}${
          row.account_digit ? '-' + row.account_digit : ''
        }`,
    },
    {
      name: 'Tipo de conta',
      cell: (row) => `
      <span>${row.account_type_display ?? 'Conta corrente'}</span>
      <span class="app-table-details-body-row-subtitle">${
        row.main_account ? 'Conta principal' : ''
      }</span>`,
    },
  ];
  bankData: Bankinformation[] = [];

  economicGroupColumns: TableColumnList<Economicgroup>[] = [
    { name: 'Razão Social', cell: (row) => row.corporate_name.toUpperCase() },
    {
      name: 'CNPJ',
      cell: (row) => this.formatCnpj(row.company_document_number),
    },
  ];

  economicGroupData: Economicgroup[] = [];

  pepColumns: TableColumnList<CompanyComplementaryPepListValues>[] = [
    {
      name: 'Nome',
      cell: (row) => `
    <span>${row.name}</span>
    <span class="app-table-details-body-row-subtitle">${row.document}</span>
  `,
    },
    { name: 'Vínculo', cell: (row) => row.bound },
  ];

  pepData: CompanyComplementaryPepListValues[] = [];

  documents: any[] = [];

  uspersonColumns: TableColumnList<any>[] = [
    { name: 'Arquivo', cell: (row) => this.formatFileName(row.file) },
    {
      name: 'Emissão',
      cell: (row) => this.formateDate(row.created_at),
    },
    {
      name: '',
      cell: (row) =>
        `<div class="icon-container">
            <div class="power-details row-${row.id}">
                <i class="fas fa-eye ml-auto"></i>
            </div>
        </div> `,
    },
  ];

  role = '';

  isSimplified = false;

  cards: any;

  isFund = false;

  file: any = null;
  modalFiles: any = null;
  modalFilesUrls: ModalFilesUrlsProps[] | null = null;
  selectedFile: ModalFilesUrlsProps | null = null;

  employeesOptions = employeesOptions;

  isAdmin = false;
  fatca: FatcaProps = null;

  isPDF = false;
  url: any;
  isXLS = false;
  loading = true;

  showed = false;

  constructor(
    private signupService: SignupService,
    private maskPipe: MaskPipe,
    private router: Router,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private api: ApiService,
    private route: ActivatedRoute
  ) {}

  //   ngAfterViewInit() {
  //     this.setHandlers();
  //   }

  ngOnInit(): void {
    if (this.authService.approvalData.complementary_data) {
      this.approval = this.authService.approvalData.complementary_data;

      this.setData();
    } else {
      this.getApprovalData();
    }

    this.authService.changeVersion.subscribe(() => {
      this.loading = true;
      this.getApprovalData();
    });

    this.authService.reloadComplementaryDataEvent
      //   .pipe(take(1))
      .subscribe((params) => {
        this.loading = true;
        this.getApprovalData(params);
      });
  }

  async getApprovalData(param: any = null) {
    try {
      const req_params = {
        register: param ? param.id : this.route.snapshot.params['id'],
        tab: 'complementary_data',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<UserApprovalResponse[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      this.authService.approvalData.complementary_data = data[0];
      this.approval = data[0];

      this.setData();
    } catch (error) {
      console.error(error);
    }
  }

  setData() {
    this.bankData = this.approval.register.bank_information;

    this.economicGroupData = this.approval.register.economic_group;
    this.pepData =
      this.approval.register.person &&
      this.approval.register.person.pep_relationship
        ? [this.approval.register.person.pep_relationship]
        : null;
    this.cards = this.approval.register.cards;
    this.isSimplified = this.approval.register.is_simplified;

    this.setFilteredFiles();
    let range = this.router.url.split('/').length;
    this.role = this.router.url.split('/')[range - 2];
    const user = this.authService.getUser();

    this.handleGetDocuments();

    const register = user.active_register
      ? user.active_register.register
      : user;

    this.isAdmin = user ? register.role.slug.includes('ADM') : false;
    this.isFund = this.approval.register.role.slug.includes('PJ-FI');

    if (this.approval.register.role.slug.includes('SHAREHOLDER')) {
      this.fatca = this.approval.register.profiles_quiz[0];
    }

    this.loading = false;
  }

  async handleGetDocuments() {
    const documents_slugs = [
      'us-person-declaration',
      'suitability_pf',
      'suitability_pj',
      'declaration_investor',
      'registration_form',
      'refusal_term',
      'disqualification',
    ];

    await documents_slugs.forEach(async (slug) => {
      await this.getDocuments(slug);
    });
  }

  async getDocuments(slug: string) {
    const res = await this.api.get({
      route: 'api/document/',
      params: {
        register: this.approval.register.id,
        type: slug,
      },
      token: true,
    });

    if (!res) return;

    this.documents.push({
      slug,
      data: res.data,
    });

    const allEmpty = this.documents.every((item) => item.data.length === 0);
    this.hideDocument = allEmpty;
  }

  getDocumentLabel(slug: string) {
    switch (slug) {
      case 'us-person-declaration':
        return 'Declaração US Person (FATCA)';

      case 'suitability_pf':
        return 'Suitability';

      case 'suitability_pj':
        return 'Suitability';

      case 'declaration_investor':
        return 'Declaração de Investidor';

      case 'refusal_term':
        return 'Termo de Recusa';

      case 'registration_form':
        return 'Ficha cadastral';

      case 'disqualification':
        return 'Termo de Desenquadramento';
      default:
        return '';
    }
  }

  setFilteredFiles() {
    this.filteredFiles = {};

    Object.keys(this.cards).forEach((cardSlug) => {
      const card = this.cards[cardSlug];
      const eachItem: fileSlugArrProps[] = [];

      const newCard = card.files.filter((file) => file.referent === 'register');

      newCard.forEach((file) => {
        if (!eachItem.find((item) => item.slug === file.type.slug)) {
          eachItem.push({ slug: file.type.slug, label: file.type.name });
        }
      });

      this.filteredFiles = {
        ...this.filteredFiles,
        [cardSlug]: eachItem,
      };
    });
  }

  getPointsName(points: any) {
    if (points) {
      if (points >= 9 && points <= 18) {
        return 'Conservador';
      } else if (points >= 19 && points <= 28) {
        return 'Moderado';
      } else if (points > 28) {
        return 'Agressivo';
      } else {
        return 'Insuficiente';
      }
    }
    return 'Não possui';
  }

  getPointsColor(points: any) {
    if (points) {
      if (points >= 9 && points <= 18) {
        return '#2ecc71';
      } else if (points >= 19 && points <= 28) {
        return '#f1c40f';
      } else if (points > 28) {
        return '#e74c3c';
      } else {
        return '#c0392b';
      }
    }
    return '#f1c40f';
  }

  formatPhone(phone) {
    if (phone.length === 11) {
      return this.maskPipe.transform(phone, '(00) 00000-0000');
    } else {
      return this.maskPipe.transform(phone, '(00) 0000-0000');
    }
  }

  getEmployeesName(value: string) {
    const label = this.employeesOptions.find((item) => item.value === value);

    return label ? label.label : '';
  }

  getChoicesName(object: string, value: any) {
    const choices = this.signupService.signupChoices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  formatBrl(amount: number | string) {
    const formatted = Utils.formatBrl(Number(amount));

    return formatted;
  }

  formatCnpj(value: string) {
    const newValue = this.maskPipe.transform(value, '00.000.000/0000-00');

    return newValue;
  }

  formatCpf(obj: any) {
    let newValue = obj.number;

    if (obj.number && obj.number.length === 11) {
      newValue = this.maskPipe.transform(obj.number, '000.000.000-00');
    }

    return newValue;
  }

  formatRg(item: any) {
    const newValue = this.maskPipe.transform(item.number, '00.000.000-0');
    const agency = item.dispatching_agency.split(' ')[0];

    return `${newValue} ${agency}-${item.uf}`;
  }

  formatDocument(item: any) {
    let newValue = item.number;
    let agency = item.dispatching_agency.split(' ')[0];
    let docType = this.getChoicesName('document_type', item.type)?.label;

    if (item.type === '34') {
      newValue = this.maskPipe.transform(item.number, '00.000.000-0');
      docType = 'RG';
    }

    if (item.type === '1') {
      docType = 'CNH';
    }

    return `${docType} ${newValue} ${agency}-${item.uf}`;
  }

  handleSelect(slug: any, cardSlug: string) {
    const files = this.cards[cardSlug].files.filter(
      (item) => item.type.slug === slug
    );

    this.openFile(files);

    //   this.getImgUrl();
  }

  getImgUrl(attachment: Partial<AttachmentListValues>) {
    let url: any = attachment.file;
    let type: string = '';

    const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

    const resultado = regex.exec(attachment.file);

    if (resultado && resultado[1]) {
      type = resultado[1].toLowerCase();
    }

    if (type === 'pdf') {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(attachment.file);
    }

    if (type === 'xls' || type === 'xlsx') {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${attachment.file}`
      );
    }

    let auxExtensionTypes = ['pdf', 'xls', 'xlsx'];

    return {
      url,
      isImg: !auxExtensionTypes.includes(type),
      name: this.formatFileName(attachment.file),
    };
  }

  formatFileName(file: string) {
    return file.split('/').pop()?.split('.').shift();
  }

  openFile(attachments: Partial<AttachmentListValues>[]) {
    this.modalFiles = attachments;

    const newModalFilesUrls = attachments.map(
      (attachment: Partial<AttachmentListValues>) => this.getImgUrl(attachment)
    );

    this.modalFilesUrls = newModalFilesUrls;
    this.selectedFile = newModalFilesUrls[0];

    this.modalRoot.show();
  }

  setPrevAttachment() {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (index === 0) return;

    this.selectedFile = this.modalFilesUrls[index - 1];
  }

  setNextAttachment() {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (index === this.modalFilesUrls?.length - 1) return;

    this.selectedFile = this.modalFilesUrls[index + 1];
  }

  getDisabled(direction) {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (direction === 'prev') {
      return index === 0;
    } else {
      return index === this.modalFilesUrls?.length - 1;
    }
  }

  urlDecoding(label: string) {
    return decodeURIComponent(label);
  }

  renderContractType(contractType: string) {
    let label: string = 'Digital';

    if (contractType === 'physical') {
      label = 'Físico';
    }

    return label;
  }

  formateDate(date: string) {
    const day = date.split('T').shift()?.split('-').reverse().join('/');
    const hours = date.split('T').pop()?.slice(0, 5);

    return `${day} às ${hours}`;
  }

  //   setHandlers() {
  //     const power = document.querySelectorAll('.power-details');

  //     power.forEach((button) => {
  //       button.addEventListener('click', (event) => {
  //         const el: any = event.currentTarget;
  //         const idClass: any = el.classList.item(1);
  //         const id = idClass.split('-')[1];

  //         const finded = this.documents.find((item: any) => {
  //           if (item.id) {
  //             return Number(item.id) === Number(id);
  //           }

  //           return false;
  //         });

  //         if (finded) {
  //           this.openUsPerson(finded);
  //         }
  //       });
  //     });
  //   }

  openUsPerson(file: string) {
    window.open(file, '_blank');
  }

  getRiskLabel(risk: number) {
    let label = '';

    switch (risk) {
      case 1:
        label = 'Conservador';
        break;
      case 2:
        label = 'Moderado';
        break;
      case 3:
        label = 'Agressivo';
        break;
      default:
        label = 'Não informado';
        break;
    }

    return label;
  }

  dataCheckForReverseRow() {
    const verify1 = this.role === 'shareholder';

    const verify2 = this.approval.register.type !== 'PJ' && this.pepData;

    const verify3 = this.role === 'shareholder' && !this.isFund;

    return verify1 && !(verify2 || verify3);
  }

  renderValue(parentPath: string[], key) {
    return Utils.renderValue(parentPath, key, this.approval);
  }

  setHistoryData() {
    this.bankData =
      this.approval.history.BankInformation ||
      this.approval.register.bank_information;

    if (this.approval.register.type === 'PF') {
      this.pepData = this.approval.history.Person.pep_relationship
        ? [this.approval.history.Person.pep_relationship]
        : this.approval.register.person &&
          this.approval.register.person.pep_relationship
        ? [this.approval.register.person.pep_relationship]
        : null;
    }

    if (this.approval.register.type === 'PJ') {
      this.economicGroupData =
        this.approval.history.EconomicGroup ||
        this.approval.register.economic_group;
    }

    if (this.approval.register.role.slug.includes('SHAREHOLDER')) {
      this.fatca = this.approval.history?.InvestorProfileQuiz
        ? this.approval.history?.InvestorProfileQuiz[0]
        : this.approval.investor_profile_quiz[0];
    }
  }
}
