<div>
    <h4 data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
        aria-controls="collapseExample" (click)="handleChange()">
        {{title}}

        <ng-container *ngIf="isCollapsed">
            <i class="fas fa-angle-down"></i>
        </ng-container>

        <ng-container *ngIf="!isCollapsed">
            <i class="fas fa-angle-up"></i>
        </ng-container>
    </h4>

    <div id="collapseExample" [ngbCollapse]="isCollapsed">
        <ng-content>
        </ng-content>
    </div>
</div>