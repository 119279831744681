interface State {
  initials: string;
  name: string;
}

export const statesItems: State[] = [
  { initials: '1', name: 'Acre' },
  { initials: '2', name: 'Alagoas' },
  { initials: '3', name: 'Amapá' },
  { initials: '4', name: 'Amazonas' },
  { initials: '5', name: 'Bahia' },
  { initials: '6', name: 'Ceará' },
  { initials: '7', name: 'Distrito Federal' },
  { initials: '8', name: 'Espírito Santo' },
  { initials: '9', name: 'Goiás' },
  { initials: '10', name: 'Maranhão' },
  { initials: '11', name: 'Mato Grosso' },
  { initials: '12', name: 'Mato Grosso do Sul' },
  { initials: '13', name: 'Minas Gerais' },
  { initials: '14', name: 'Pará' },
  { initials: '15', name: 'Paraíba' },
  { initials: '16', name: 'Paraná' },
  { initials: '17', name: 'Pernambuco' },
  { initials: '18', name: 'Piauí' },
  { initials: '19', name: 'Rio de Janeiro' },
  { initials: '20', name: 'Rio Grande do Norte' },
  { initials: '21', name: 'Rio Grande do Sul' },
  { initials: '22', name: 'Rondônia' },
  { initials: '23', name: 'Roraima' },
  { initials: '24', name: 'Santa Catarina' },
  { initials: '25', name: 'São Paulo' },
  { initials: '26', name: 'Sergipe' },
  { initials: '27', name: 'Tocantins' },
];

export const statesOptions: SelectItem[] = statesItems.map((state) => ({
  label: state.name,
  value: state.initials,
}));
