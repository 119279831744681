<div class="dash-details-card">
    <div class="dash-details-card-body pt-4">
        <div class="row">
            <div class="col-12 col-lg-9">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <app-label label="Classificação tributária">
                                </app-label>
                            </div>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-3">
                            <app-select label="Prazo"
                                [items]="fundService.choices.tax_classification_deadline_choices"
                                [group]="form" placeholder="selecione"
                                controlName="tax_classification_deadline">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-5">
                            <app-select label="Forma de tributação"
                                [items]="fundService.choices.tax_classification_tax_choices"
                                [group]="form" placeholder="selecione"
                                controlName="tax_classification_tax">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <app-input label="Classificação dos riscos"
                                [group]="form"
                                controlName="risk_classification">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <app-input
                                label="Descrição analítica dos direitos creditórios"
                                [group]="form"
                                controlName="analytical_description_of_credit_rights">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <app-select label="Tipo de direito creditórios"
                                [items]="creditors_rights_items" [group]="form"
                                controlName="creditors_rights"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-radio [multiple]="true"
                                formControlName="credit_rights_will_be_granted">
                                <span class="flex-1">Direitos creditórios
                                    registrados</span>
                            </app-radio>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12">
                            <app-radio [multiple]="true"
                                formControlName="assignment_expectation_greater_than_400">
                                <span class="flex-1">Cessão maior que
                                    400 recebíveis por trimestre
                                </span>
                            </app-radio>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12">
                            <app-radio [multiple]="true"
                                formControlName="delivery_asset_quotas_issuance">
                                Emissão de cotas com entrega de ativos
                            </app-radio>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="offset-1 col-7"
                            *ngIf="form.controls.delivery_asset_quotas_issuance.value">
                            <app-currency-input [group]="form"
                                controlName="quota_fair_value"
                                label="Parâmetro utilizado para definição do valor justo dos ativos entregues na emissão de cotas">
                            </app-currency-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>