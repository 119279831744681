import UIDefaultTheme from 'src/themes';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CookieService } from 'ngx-cookie';
import Utils from 'src/app/helpers/utils';

type GetUrl = {
  route: string;
  params?: any;
  noDefautHeaders?: boolean;
  external?: boolean;
};

type HttpGet = {
  route: string;
  params?: object;
  noDefautHeaders?: boolean;
  external?: boolean;
  token?: boolean;
};

type HttpPost = {
  route: string;
  params?: object;
  noDefautHeaders?: boolean;
  external?: boolean;
  body?: any;
  token?: boolean;
};

type HttpPut = {
  route: string;
  params?: object;
  noDefautHeaders?: boolean;
  external?: boolean;
  body?: any;
  token?: boolean;
};

type HttpDelete = {
  route: string;
  params?: object;
  noDefautHeaders?: boolean;
  external?: boolean;
  token?: boolean;
};

type HttpPatch = {
  route: string;
  params?: object;
  noDefautHeaders?: boolean;
  external?: boolean;
  body?: any;
  token?: boolean;
};

type HttpTypes = HttpGet | HttpPost | HttpPut | HttpDelete | HttpPatch;

type HttpRetry = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  props: HttpTypes;
};
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  urls = UIDefaultTheme.api;

  url = this.getAPIBaseURL();

  retry = 1;
  maxRetry = 5;

  defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    // 'ngrok-skip-browser-warning': 69420,
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private cookieService: CookieService
  ) {}

  getAPIBaseURL() {
    // if (process.env.BASE_URL) return process.env.BASE_URL;

    const location = window.location.hostname;

    if (location.includes('localhost')) {
      return this.urls.local;
    }

    if (location.includes('dev')) {
      return this.urls.dev;
    }

    if (location.includes('stage')) {
      return this.urls.stage;
    }

    return this.urls.prod;
  }

  getHeaders(
    token: boolean,
    noDefaultHeaders: boolean
  ): HttpHeaders['headers'] {
    if (token) {
      const tokenString = this.authService.getToken();

      const headers = noDefaultHeaders ? {} : this.defaultHeaders;

      if (tokenString) {
        headers['Authorization'] = `Bearer ${tokenString}`;
      }

      return headers;
    } else {
      return this.defaultHeaders;
    }
  }

  saveResponseOnCookies(route: HttpTypes, res: any) {
    return;
    const key = JSON.stringify(route);

    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + 30);

    this.cookieService.put(key, JSON.stringify(res), {
      secure: true,
      expires,
      storeUnencoded: true,
    });
  }

  getResponseFromCookies(route: HttpTypes) {
    return null;

    const key = JSON.stringify(route);

    const res = sessionStorage.getItem(key);

    if (res) {
      try {
        return JSON.parse(res);
      } catch (error) {
        return null;
      }
    }

    return null;
  }

  getUrl(props: GetUrl): string {
    const { route, params, external = false } = props;

    const url = external ? route : `${this.url}/${route}`;

    let urlString = url;

    if (params) {
      urlString += '?';

      const paramsArr = Object.keys(params).map((key) => {
        if (params[key]) {
          return `${key}=${params[key]}`;
        }

        return '';
      });

      urlString += paramsArr.join('&');
    }

    return urlString;
  }

  async handleError(error: HttpErrorResponse, retry: HttpRetry): Promise<any> {
    if (error.status === 401) {
      this.retry++;

      if (this.retry >= this.maxRetry) {
        this.authService.clearUser();
        this.retry = 1;
        window.location.reload();
        return Promise.reject(error);
      }

      try {
        const user = this.authService.getUser();

        if (user) {
          const newToken = await this.refreshToken(user.tokens.refresh);

          const newTokens = { ...user.tokens };
          newToken.access = newToken.access;

          const newUser: LoginResponse = { ...user, tokens: newTokens };

          this.authService.setUser(newUser);
          this.authService.setToken(newToken.access);

          switch (retry.method) {
            case 'GET':
              return await this.get(retry.props);
            case 'POST':
              return await this.post(retry.props);
            case 'PUT':
              return await this.put(retry.props);
            case 'DELETE':
              return await this.delete(retry.props);
            case 'PATCH':
              return await this.patch(retry.props);
          }
        } else {
          return Promise.reject(error);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }

  async get<T = any>(props: HttpGet): Promise<T> {
    const storedResponse = this.getResponseFromCookies(props);

    if (storedResponse) {
      return storedResponse;
    }

    const { token = false, noDefautHeaders } = props;

    const headers = this.getHeaders(token, noDefautHeaders ?? false);

    const url = this.getUrl(props);

    try {
      const res = await this.http
        .get<T>(url, {
          headers,
        })
        .toPromise();

      this.saveResponseOnCookies(props, res);

      this.retry = 0;
      return res;
    } catch (error: any) {
      return this.handleError(error, { method: 'GET', props });
    }
  }

  async post<T = any>(props: HttpPost): Promise<T> {
    const storedResponse = this.getResponseFromCookies(props);

    if (storedResponse) {
      return storedResponse;
    }

    const { body = {}, token = false, noDefautHeaders } = props;

    const headers = this.getHeaders(token, noDefautHeaders ?? false);

    const url = this.getUrl(props);

    try {
      const res = await this.http
        .post<T>(url, body, {
          headers,
        })
        .toPromise();

      this.saveResponseOnCookies(props, res);

      this.retry = 0;
      return res;
    } catch (error: any) {
      return this.handleError(error, { method: 'POST', props });
    }
  }

  async put<T = any>(props: HttpPut): Promise<T> {
    const storedResponse = this.getResponseFromCookies(props);

    if (storedResponse) {
      return storedResponse;
    }

    const { body = {}, token = false, noDefautHeaders } = props;

    const headers = this.getHeaders(token, noDefautHeaders ?? false);

    const url = this.getUrl(props);

    try {
      const res = await this.http
        .put<T>(url, body, {
          headers,
        })
        .toPromise();

      this.saveResponseOnCookies(props, res);

      this.retry = 0;
      return res;
    } catch (error: any) {
      return this.handleError(error, { method: 'PUT', props });
    }
  }

  async delete<T = any>(props: HttpDelete): Promise<T> {
    const storedResponse = this.getResponseFromCookies(props);

    if (storedResponse) {
      return storedResponse;
    }

    const { token = false, noDefautHeaders } = props;

    const headers = this.getHeaders(token, noDefautHeaders ?? false);

    const url = this.getUrl(props);

    try {
      const res = await this.http
        .delete<T>(url, {
          headers,
        })
        .toPromise();

      this.retry = 0;

      this.saveResponseOnCookies(props, res);

      return res;
    } catch (error: any) {
      return this.handleError(error, { method: 'DELETE', props });
    }
  }

  async patch<T = any>(props: HttpPatch): Promise<T> {
    const storedResponse = this.getResponseFromCookies(props);

    if (storedResponse) {
      return storedResponse;
    }

    const { body = {}, token = false, noDefautHeaders } = props;

    const headers = this.getHeaders(token, noDefautHeaders ?? false);

    const url = this.getUrl(props);

    try {
      const res = await this.http
        .patch<T>(url, body, {
          headers,
        })
        .toPromise();

      this.retry = 0;

      this.saveResponseOnCookies(props, res);

      return res;
    } catch (error: any) {
      return this.handleError(error, { method: 'PATCH', props });
    }
  }

  async refreshToken(token: string) {
    const res = await this.post<{ access: string }>({
      route: 'api/token/refresh/',
      body: { refresh: token },
      token: true,
    });

    return res;
  }

  async getPersonName(document: string) {
    const res = await this.get<ApiResponse<any[]>>({
      route: `api/registration/register/?document=${Utils.onlyNumbers(
        document
      )}`,
      token: true,
    });

    let name = '';

    if (res.results.length > 0) {
      name =
        res.results[0].person !== null
          ? res.results[0].person.full_name
          : res.results[0].company.corporate_name;
    }

    return { name, foundPerson: res.results.length > 0 };
  }
}
