import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss'],
})
export class SideModalComponent implements OnInit {
  @Input() active = false;

  @Input() paddingBottom?: string;
  @Input() padding?: string;

  @Input() title: string = '';

  @Output() onClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.onClose.emit();
  }
}
