<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <ng-container *ngIf="loading">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading && data">
                <div class="dash-details-card-body">

                    <ng-container *ngIf="data">
                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE TRANSFERÊNCIA
                                </span>

                                <span class="dash-details-card-item-title">
                                    <ng-container *ngIf="data.transfer_date">
                                        {{data.transfer_date |
                                        date :
                                        'dd/MM/yyyy'}}
                                    </ng-container>

                                    <ng-container *ngIf="!data.transfer_date">
                                        -
                                    </ng-container>
                                </span>
                            </div>
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA ASSEMBLEIA
                                </span>

                                <span class="dash-details-card-item-title">
                                    <ng-container *ngIf="data.assembly_date">
                                        {{data.assembly_date |
                                        date :
                                        'dd/MM/yyyy'}}
                                    </ng-container>

                                    <ng-container *ngIf="!data.assembly_date">
                                        -
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Informações do administrador anterior">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.last_company_document
                                    ??
                                    '-'}}
                                </span>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    RAZÃO SOCIAL
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.last_company_name ??
                                    '-'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CONTATO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.last_company_contact
                                    ?? '-'}}
                                </span>
                            </div>
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CELULAR
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    data.last_company_cellphone_number
                                    ?
                                    formatPhone(data.last_company_cellphone_ddi,data.last_company_cellphone_number)
                                    :
                                    '-'}}
                                </span>
                            </div>
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    TELEFONE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    data.last_company_cellphone_number
                                    ?
                                    formatPhone(data.last_company_telephone_ddi,data.last_company_telephone_number)
                                    :
                                    '-'}}
                                </span>
                            </div>
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    E-MAIL
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.last_company_email
                                    ?? '-'}}
                                </span>
                            </div>
                        </div>
                    </ng-container>

                    <app-empty-content [isVisible]="!data"
                        title="Nenhum dado de transferência"
                        description="Não foi encontrado nenhuma transferência nesse cadastro"
                        icon="/assets/images/forgot-password-icon2.png">
                    </app-empty-content>
                </div>
            </ng-container>
        </div>
    </div>
</div>