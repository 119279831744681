import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// import { TableColumn } from 'src/app/components/table/table.component';
import Utils, { GetHelper } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';

export type PersonIncomeOthersValues = {
  type: string;
  current_value: string;
  description: string;
  id: number;
};

@Component({
  selector: 'app-signup-unified-person-income-others',
  templateUrl: './signup-unified-person-income-others.component.html',
  styleUrls: ['./signup-unified-person-income-others.component.scss'],
})
export class SignupUnifiedPersonIncomeOthersComponent implements OnInit {
  form = this.formBuilder.group({
    type: [null, [Validators.required]],
    current_value: [null, [Validators.required]],
    description: [null, []],
  });

  columns: any[] = [
    { name: 'Tipo', cell: (row) => row.type },
    {
      name: 'Descrição',
      cell: (row) => row.description,
    },
    {
      name: 'Valor atual',
      cell: (row) => Utils.formatBrl(Number(row.current_value)),
    },
  ];

  data: Partial<PersonIncomeOthersValues>[] = [];

  footerData: Partial<PersonIncomeOthersValues>[] = [];

  showForm = true;

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  setFooterData() {
    const total = String(
      this.data.reduce(
        (prev, curr) => Number(prev) + Number(curr.current_value ?? 0),
        0
      )
    );

    this.footerData = [
      {
        type: '<span><strong>Total declarado</strong></span>',
        current_value: total,
      },
    ];
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/other-assets/',
        token: true,
      });

      this.data = data;
      this.signupService.setPersonFormStatus(
        'incomeOthers',
        this.data.length > 0
      );

      this.showForm = this.data.length === 0;

      this.setFooterData();
    } catch (error) {
      console.log(error);
    }
  }

  async addNewOthers() {
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        type: valuesHelper.get('type'),
        current_value: valuesHelper.get('current_value'),
        description: valuesHelper.get('description'),
      };
      await this.api.post({
        route: 'api/registration/other-assets/',
        token: true,
        body: payload,
      });

      this.getData();

      this.form.reset();
    } catch (error) {
      console.log(error);
    }
  }

  async removeOthers(index: number) {
    try {
      await this.api.delete({
        route: `api/registration/other-assets/${this.data[index].id}`,
        token: true,
      });

      const newData = [...this.data];
      newData.splice(index, 1);

      this.data = newData;

      this.signupService.setPersonFormData('incomeOthers', newData);
      this.signupService.setPersonFormStatus(
        'incomeOthers',
        this.data.length > 0
      );

      this.showForm = this.data.length === 0;

      this.setFooterData();
    } catch (error) {
      console.log(error);
    }
  }

  buttonDisabled() {
    if (this.showForm) {
      return this.form.invalid;
    }

    return false;
  }

  triggerForm() {
    if (this.showForm) {
      this.addNewOthers();
    }

    this.showForm = !this.showForm;
  }

  handleCancel() {
    this.showForm = false;
    this.form.reset();
  }
}
