<div *ngIf="isVisible">
    <div class="empty-card">
        <div class="img-container">
            <img [src]="icon" alt="icone">
        </div>

        <h3>{{title}}</h3>
        <p>
            {{description}}
        </p>

        <ng-container *ngIf="buttonLabel">
            <app-button (click)="click($event)"
                [class.add-button]="haveButtonIcon">
                <i class="fas fa-plus-circle mr-2"
                    *ngIf="haveButtonIcon"></i>{{buttonLabel}}
            </app-button>
        </ng-container>
    </div>
</div>