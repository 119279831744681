<div class="dash-details-card">
    <div class="dash-details-card-body pt-4">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <app-label label="Características comuns">
                                </app-label>
                            </div>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-select
                                [items]="fundService.choices.quota_type_choices"
                                label="Tipo de Cota" [group]="form"
                                controlName="quota_types">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-6 col-lg-2">
                            <app-currency-input [group]="form"
                                controlName="initial_quota"
                                label="Valor da cota inicial do fundo"
                                placeholder="R$ 1.000,00">
                            </app-currency-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <app-label
                                    label="Limite mínimo para movimentação">
                                </app-label>
                            </div>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-currency-input [group]="form"
                                controlName="min_initial_application"
                                label="Aplicação inicial"
                                placeholder="R$ 1.000,00">
                            </app-currency-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-currency-input [group]="form"
                                controlName="min_additional_application"
                                label="Aplicação adicional"
                                placeholder="R$ 1.000,00">
                            </app-currency-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-currency-input [group]="form"
                                controlName="min_redemption" label="Resgate"
                                placeholder="R$ 1.000,00">
                            </app-currency-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-currency-input [group]="form"
                                controlName="min_permanence" label="Permanência"
                                placeholder="R$ 1.000,00">
                            </app-currency-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <app-label label="Aplicação">
                                </app-label>
                            </div>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12  col-lg-2">
                            <app-masked-input
                                controlName="application_conversion"
                                label="Conversão em dias" [group]="form"
                                mask="0*" [max]="3">
                            </app-masked-input>
                        </div>

                        <div
                            class="col-12 col-lg-2 d-flex flex-column justify-content-end">
                            <app-radio
                                formControlName="application_conversion_unit"
                                value="1" [alternativeStyle]="true"
                                [isChecked]="verifyChecked('application_conversion_unit', '1')">Úteis</app-radio>
                            <app-radio
                                formControlName="application_conversion_unit"
                                value="2" [alternativeStyle]="true"
                                [isChecked]="verifyChecked('application_conversion_unit', '2')">Corridos</app-radio>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12  col-lg-2">
                            <app-masked-input controlName="application_sale_off"
                                label="Liquidação em dias" [group]="form"
                                mask="0*" [max]="3">
                            </app-masked-input>
                        </div>

                        <div
                            class="col-12 col-lg-2 d-flex flex-column justify-content-end">
                            <app-radio
                                formControlName="application_sale_off_unit"
                                value="1" [alternativeStyle]="true"
                                [isChecked]="verifyChecked('application_sale_off_unit', '1')">Úteis</app-radio>

                            <app-radio
                                formControlName="application_sale_off_unit"
                                value="2" [alternativeStyle]="true"
                                [isChecked]="verifyChecked('application_sale_off_unit', '2')">Corridos</app-radio>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <app-spacer [top]="24"></app-spacer>
                            <div class="d-flex align-items-center">
                                <app-label label="Resgate">
                                </app-label>
                            </div>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-masked-input controlName="rescue_conversion"
                                label="Conversão em dias" [group]="form"
                                mask="0*" [max]="3">
                            </app-masked-input>
                        </div>

                        <div
                            class="col-12 col-lg-2 d-flex flex-column justify-content-end">
                            <app-radio formControlName="rescue_conversion_unit"
                                value="1" [alternativeStyle]="true"
                                [isChecked]="verifyChecked('rescue_conversion_unit', '1')">Úteis</app-radio>

                            <app-radio formControlName="rescue_conversion_unit"
                                value="2" [alternativeStyle]="true"
                                [isChecked]="verifyChecked('rescue_conversion_unit', '2')">Corridos</app-radio>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-masked-input controlName="rescue_sale_off"
                                label="Liquidação em dias" [group]="form"
                                mask="0*" [max]="3">
                            </app-masked-input>

                        </div>

                        <div
                            class="col-12 col-lg-2 d-flex flex-column justify-content-end">
                            <app-radio class="mr-3"
                                formControlName="rescue_sale_off_unit" value="1"
                                [alternativeStyle]="true"
                                [isChecked]="verifyChecked('rescue_sale_off_unit', '1')">Úteis</app-radio>

                            <app-radio formControlName="rescue_sale_off_unit"
                                value="2" [alternativeStyle]="true"
                                [isChecked]="verifyChecked('rescue_sale_off_unit', '2')">Corridos</app-radio>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="dash-details-card" *ngIf="quotas.length > 0">
    <div class="dash-details-card-body mt-4 pt-4">
        <div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <app-label label="Cotas registradas">
                    </app-label>
                </div>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <table class="bordered">
                <thead>
                    <tr>
                        <th>Classe</th>
                        <th>Sub classe</th>
                        <th>Data da emissão</th>
                        <th>Encerramento da cota</th>
                        <th>Quantidade de cotas</th>
                        <th>Valor da cota</th>
                        <th>Saldo (qtde. cotas)</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of quotas; let i = index">
                        <td>{{getChoicesName('quotas_classes_choices',item.quota_class)?.label}}
                        </td>
                        <td>A definir</td>
                        <td>{{item.quota_initial_date | date:'dd/MM/yyyy'}}</td>
                        <td>{{item.quota_final_date | date:'dd/MM/yyyy'}}</td>
                        <td>{{item.quota_quantity}}</td>
                        <td>{{item.quota_amount | currency:'BRL' }}</td>
                        <td>{{item.balance}}</td>
                        <td>{{item.quota_status === 'actived' ? 'Ativa' :
                            'Encerrada' }}</td>
                        <td>
                            <div class="dash-card-item-buttons">
                                <button class="dash-item-button"
                                    (click)="handleAddAttachment(i)">
                                    <i class="fas fa-paperclip"></i>
                                </button>
                                <app-spacer [right]="16"></app-spacer>

                                <button class="dash-item-button"
                                    (click)="handleEdit(i)">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <app-spacer [right]="16"></app-spacer>

                                <button class="dash-item-button"
                                    (click)="handleOpenQuota(i)">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <app-spacer [right]="16"></app-spacer>

                                <button class="dash-item-button"
                                    (click)="removeQuota(i)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <app-spacer [bottom]="28"></app-spacer>
            </table>
        </div>
    </div>
</div>

<div class="dash-details-card">
    <div class="dash-details-card-body mt-4 pt-4">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="quotaForm">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <app-label label="Inclusão de cotas">
                                </app-label>
                            </div>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-select
                                [items]="fundService.choices.quota_offer_type_choices"
                                label="Tipo de Oferta de Cotas"
                                [group]="quotaForm"
                                controlName="quota_offer_type">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-input label="Benchmark" [group]="quotaForm"
                                controlName="benchmark" [maxLength]="30">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-2">
                            <app-select label="Classe"
                                [items]="fundService.choices.quotas_classes_choices"
                                [group]="quotaForm" controlName="quota_class"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-select label="Sub classe"
                                [items]="quota_order_items" [group]="quotaForm"
                                controlName="quota_order"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-select label="Emissão"
                                [items]="fundService.choices.quotas_classes_order_choices"
                                [group]="quotaForm" controlName="emission"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-2">
                            <app-masked-input
                                label="Quantidade inicial de cotas"
                                [group]="quotaForm" controlName="quota_quantity"
                                placeholder="Digite" mask="0*" [max]="10">
                            </app-masked-input>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-currency-input label="Valor cota inicial"
                                [group]="quotaForm" controlName="quota_amount"
                                placeholder="R$ 1.000,00">
                            </app-currency-input>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-currency-input label="Valor da série"
                                [group]="quotaForm"
                                controlName="quota_serie_amount"
                                [readOnly]="true" placeholder="R$ 1.000,00">
                            </app-currency-input>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-masked-input label="Saldo (qtde. de cotas)"
                                [group]="quotaForm" controlName="balance"
                                placeholder="Digite" mask="0*" [max]="10"
                                [readOnly]="selectedQuota === null">
                            </app-masked-input>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-select label="Status"
                                [items]="quota_status_items" [group]="quotaForm"
                                controlName="quota_status"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-2">
                            <app-masked-input [group]="quotaForm"
                                controlName="quota_initial_date"
                                mask="00/00/0000" label="Data da emissão"
                                placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-masked-input [group]="quotaForm"
                                controlName="quota_cvm_register_date"
                                mask="00/00/0000"
                                label="Data de registro na CVM"
                                placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-masked-input [group]="quotaForm"
                                controlName="quote_offer_final_date"
                                mask="00/00/0000" [errorText]="errorText"
                                label="Data encerramento da oferta"
                                placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-masked-input [group]="quotaForm"
                                controlName="quota_final_date" mask="00/00/0000"
                                label="Data encerramento da cota"
                                placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <app-label label="Amortização">
                                </app-label>
                            </div>
                            <app-spacer [bottom]="8"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-select
                                [items]="fundService.choices.amortization_frequency_choices"
                                label="Periodicidade da Amortização"
                                [group]="quotaForm"
                                controlName="amortization_frequency">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-2">
                            <app-masked-input [group]="quotaForm"
                                controlName="amortization_initial_date"
                                mask="00/00/0000"
                                label="Data de início da amortização"
                                placeholder="dd/mm/aaaa">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-lg-2"></div>

                        <div class="col-12 col-lg-4">
                            <app-select
                                [items]="fundService.choices.amortization_grace_period_choices"
                                label="Amortização calculada sobre"
                                placeholder="Selecione" [group]="quotaForm"
                                controlName="amortization_grace_period"></app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-10">
                            <app-input
                                label="Informações adicionais sobre a amortização"
                                [group]="quotaForm"
                                controlName="amortization_information"
                                [rows]="4" [noResize]="true">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row justify-content-end">
                        <!-- <div class="col-6 d-flex justify-content-end"
                            *ngIf="selectedQuota !== null">
                            <div class="col-xs-12 col-lg-4 mb-2 text-center"
                                *ngFor="let file of quotas[selectedQuota].attachments; let i = index">

                                <div
                                    class="d-flex flex-column justify-content-center">
                                    <img [src]="getFileIcon(file.file.name)"
                                        alt="attachment" class="img-preview">

                                    <span
                                        class="text-overflow">{{getFileName(file.file.name)}}</span>

                                    <app-spacer [bottom]="8"></app-spacer>

                                    <div
                                        class="d-flex flex-row justify-content-between">
                                        <app-button [inverted]="true"
                                            [center]="true"
                                            (onClick)="openAttachment(file.file)">
                                            <i class="fa fa-eye"></i>
                                        </app-button>

                                        <app-button [danger]="true"
                                            [inverted]="true" [center]="true"
                                            (onClick)="removeFile(i)">
                                            <i class="fa fa-trash"></i>
                                        </app-button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-4">
                            <app-dropzone (filesChange)="setFiles($event)"
                                [clearAttachments]="clearAttachments">
                            </app-dropzone>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-2">

                        </div> -->
                    </div>

                    <div class="row">
                        <div class="col-10 text-right">
                            <app-button [inverted]="true"
                                (onClick)="resetQuotaForm()" class="mr-3">
                                Limpar campos
                            </app-button>

                            <app-button [inverted]="true" (onClick)="addQuota()"
                                [disabled]="getQuotaDisabled()">
                                {{selectedQuota !== null ? 'Atualizar Cota' :
                                'Adicionar Cota'}}
                            </app-button>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="showQuotaDetails" (onClose)="closeModal()"
    paddingBottom="30" title="Cota registrada">
    <div class="container" *ngIf="selectedQuota !==null">
        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    tipo de oferta de cota
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('quota_offer_type_choices',quotas[selectedQuota].quota_offer_type)?.label
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    benchmark
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].benchmark ?? '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    classe
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('quotas_classes_choices',quotas[selectedQuota].quota_class)?.label}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    sub classe
                </span>

                <span class="dash-details-card-item-title">
                    <!-- {{quotas[selectedQuota].quota_order.label}} -->
                    A definir
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    emissão
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('quotas_classes_order_choices',quotas[selectedQuota].emission)?.label
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    quantidade inicial de cotas
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_quantity}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    valor cota inicial
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_amount | currency:'BRL'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    valor da série
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_serie_amount |
                    currency:'BRL'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    saldo (qtde. de cotas)
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].balance | currency:'BRL'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    status
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_status === 'actived' ? 'Ativa'
                    :'Encerrada'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data emissão
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_initial_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data de registro na cvm
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_cvm_register_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data encerramento da oferta
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quote_offer_final_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data encerramento da cota
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_final_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <div class="d-flex align-items-center">
                    <app-label label="Série de cotas">
                    </app-label>
                </div>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    periodicidade da amortização
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('amortization_frequency_choices',quotas[selectedQuota].amortization_frequency)?.label
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data de inicio da amortização
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].amortization_initial_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    amortização calculada sobre
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('amortization_grace_period_choices',quotas[selectedQuota].amortization_grace_period)?.label
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column"
                *ngIf="quotas[selectedQuota].amortization_information">
                <span class="dash-details-card-item-subtitle">
                    informações adicionais sobre amortização
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].amortization_information}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>
    </div>
</app-side-modal>


<app-side-modal [active]="showAttachmentsDetails"
    (onClose)="closeAttachmentModal()" paddingBottom="30"
    title="Adicionar anexos">
    <div class="container" *ngIf="selectedQuota !==null">
        <div class="row">
            <div class="col-12">
                <app-dropzone (filesChange)="setFiles($event)"
                    [clearAttachments]="clearAttachments">
                </app-dropzone>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <ng-container
                *ngIf="quotas[selectedQuota].attachments && quotas[selectedQuota].attachments.length > 0">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <app-label label="Anexos">
                        </app-label>
                    </div>
                    <app-spacer [bottom]="8"></app-spacer>
                </div>

                <div class="col-10">Nome</div>
                <div class="col-2"></div>

                <div class="col-12 mb-5">
                    <div class="row attachment-each-item" *ngFor="let attachment of
                        quotas[selectedQuota].attachments; let i = index">
                        <div class="col-10 attachment-title">
                            {{attachment.file.name}}
                        </div>
                        <div class="col-2 attachment-options d-flex">
                            <button (click)="removeFile(i)">
                                <i class="fas fa-trash"></i>
                            </button>
                            <!-- 
                            <app-spacer [right]="16"></app-spacer>

                            <button
                                (click)="openFile(quotas[selectedQuota].attachments)">
                                <i class="fas fa-eye"></i>
                            </button> -->
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="row">
            <div class="col-12 text-right">
                <app-button [inverted]="true" (onClick)="closeAttachmentModal()"
                    class="mr-3">
                    Cancelar
                </app-button>

                <app-button [inverted]="true"
                    (onClick)="addAttachmentsToQuota()"
                    [disabled]="getAttachmentDisabled()">
                    Salvar anexos
                </app-button>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>
    </div>
</app-side-modal>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="selectedFile">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <!-- <div class="text-center">{{modalFiles[0].type.name}}</div> -->
            <div></div>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-body">
        <ng-container *ngIf="selectedFile">
            <img [src]="selectedFile.url" *ngIf="selectedFile.isImg"
                style="width:100%">

            <iframe [src]="selectedFile.url" *ngIf="!selectedFile.isImg"
                frameborder="0" height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-footer">
        <div class="attachment-modal-navigation">
            <button class="attachment-modal-navigation-arrow"
                (click)="setPrevAttachment()" [disabled]="getDisabled('prev')">
                <i class="fas fa-angle-left"></i>
            </button>

            <!-- <div class="pl-3 pr-3">{{selectedFile?.name ?
                urlDecoding(selectedFile.name) : ''}}</div> -->

            <button class="attachment-modal-navigation-arrow"
                (click)="setNextAttachment()" [disabled]="getDisabled('next')">
                <i class="fas fa-angle-right"></i>
            </button>
        </div>
    </ng-container>
</app-modal>