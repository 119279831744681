import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreviousUrlService {
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  constructor() {}

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  getPreviousUrl(): string {
    return this.previousUrl.getValue();
  }
}
