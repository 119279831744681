<div class="dash">
    <div class="row">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card no-padding">
                <ng-container *ngIf="!loading">
                    <app-back-button></app-back-button>
                </ng-container>

                <div
                    class=" dash-card-header d-flex align-items-start justify-content-between">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            width: '80px',
                            height: '25px'
                          }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <h2>Testemunhas</h2>
                    </ng-container>

                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                        width: '180px',
                        height: '40px'
                      }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button (onClick)="addWitnessesToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i> Adicionar
                            testemunha
                        </app-button>
                    </ng-container>
                </div>

                <div class="dash-card-body padding-container">
                    <ng-container *ngIf="loading">
                        <table #table>
                            <thead>
                                <tr>
                                    <th colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>

                                    <th colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>
                                    <th colspan="1">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                            height: '15px',
                                            width: '100px',
                                        marginBottom:'0'
                                          }">
                                        </ngx-skeleton-loader>
                                    </td>
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '30px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </td>
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '60px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <table class="bordered"
                        *ngIf="witnesses.length > 0 && !loading">
                        <thead>
                            <tr>
                                <th colspan="1">Nome</th>
                                <th colspan="1">Email</th>
                                <th colspan="1"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let witness of witnesses">
                                <td colspan="1">
                                    <div class="d-flex flex-column">
                                        <span
                                            class="documents-name">{{witness.name}}</span>
                                    </div>
                                </td>
                                <td colspan="1">{{witness.email}}</td>
                                <td colspan="1">
                                    <div class="dash-card-item-buttons">
                                        <button class="dash-item-button"
                                            (click)="removeWitnesses(witness.id)"
                                            *ngIf="permissionObj.canDelete">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                        <app-spacer [right]="16"></app-spacer>

                                        <button class="dash-item-button"
                                            (click)="setWitness(witness)"
                                            *ngIf="permissionObj.canChange">
                                            <i class="fas fa-pen"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>

                    <div *ngIf="witnesses.length === 0 && !loading">
                        <span
                            class="dash-card-item-content-title text-center d-block">
                            Nenhuma testemunha adicionada
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card-pagination">
                <app-pagination [data]="witnesses" [show]="!loading"
                    [dashPagination]="true">
                </app-pagination>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="addWitnesses" (onClose)="addWitnessesToggle(false)">
    <ng-container *ngIf="addWitnesses">
        <div class="row" [formGroup]="witnessForm">
            <div class="col-12">
                <span class="organization-form-title">{{witness ? 'Atualizar' :
                    'Nova'}} testemunha</span>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <div class="col-12">
                <span class="organization-form-description">{{witness ?
                    'Atualize os dados da testemunha' : 'Adicione uma nova
                    testemunha'}}
                </span>
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Nome" [group]="witnessForm"
                    controlName="name">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Email" [group]="witnessForm"
                    controlName="email">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input label="CPF" [group]="witnessForm"
                    controlName="document" mask="000.000.000-00">
                </app-masked-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 d-flex justify-content-between">
                <app-button [phantom]="true"
                    (onClick)="addWitnessesToggle(false)">
                    Cancelar
                </app-button>

                <app-button [disabled]="witnessForm.invalid"
                    (click)="addNewWitnesss()">{{witness ? 'Atualizar
                    testemunha' : 'Adicionar nova testemunha' }}
                </app-button>
            </div>
        </div>
    </ng-container>
</app-side-modal>