import {
  Component,
  ErrorHandler,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnChanges, ErrorHandler {
  @Input() tabs: Tab[] = [];
  @Input() currentTab?: number;

  @Input() dashboardTab: boolean = false;

  @Output() onChangeTab = new EventEmitter<number>();

  activedTab: number = 0;

  posX = '0px';
  width = '0px';

  constructor() {}

  handleError(error: any): void {
    console.table(error);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentTab) {
      if (this.currentTab !== this.activedTab) {
        this.activedTab = this.currentTab;

        this.setIndicator();
      }
    }
  }

  ngOnInit(): void {
    if (this.currentTab) {
      this.activedTab = this.currentTab;
    }
  }

  setIndicator() {
    setTimeout(() => {
      const elements: any = document.querySelectorAll('.app-tabs-item');
      if (elements[this.activedTab]) {
        this.width = `${elements[this.activedTab].offsetWidth}px`;
        this.posX = `${elements[this.activedTab].offsetLeft}px`;
      }
    }, 1000);
  }

  ngAfterContentChecked() {
    this.setIndicator();
  }

  activeTab(index: number, event: any): void {
    if (this.tabs[index].disabled) {
      return;
    }
    const element = event.srcElement;

    this.width = `${element.offsetWidth}px`;
    this.posX = `${element.offsetLeft}px`;

    this.activedTab = index;
    this.onChangeTab.emit(index);
  }
}
