<div class="content">

  <div class="logo"></div>
  <div class="code-error"></div>

  <div class="text-container">
    <h2>Oops!</h2>
    <p class="mb-2">A página que você procura não está disponível no momento. Tente novamente mais tarde ou contate o
      administrador do sistema.</p>


    <app-button class="mb-3" (click)="goBack()">
      Voltar para a página anterior
    </app-button>

    <br>
    <hr>

    <app-button class="mb-3" (click)="reload()" [outline]="true">
      Recarregar página
    </app-button>


  </div>
</div>
