import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-drafts',
  templateUrl: './dashboard-drafts.component.html',
  styleUrls: ['./dashboard-drafts.component.scss'],
})
export class DashboardDraftsComponent implements OnInit {
  form = this.formBuilder.group({
    name: [null, []],
    type: [null, []],

    initial_date: [null, []],
    final_date: [null, []],
    enabled: [null, []],

    roles: [[], []],
    agents: [[], []],
    coobligation: [null, []],

    content: [null, []],
  });

  applicableItems = [];

  coobligationItems = [
    { label: 'Sem coobrigação', value: false },
    { label: 'Com coobrigação', value: true },
  ];

  agentItems = [];
  typeItems = [];
  statusItems = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false },
  ];

  canAdd = true;
  canEdit = true;
  canDelete = true;

  roles: Role[] = [];
  agents: Agent[] = [];
  types: any[] = [];

  drafts = [];
  visibleDrafts = [];
  loadingDrafts: boolean = true;

  showFilterModal = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (!this.authService.verifyPermission(['de_can_view_draft_document'])) {
      this.toastService.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.fetchData();
  }

  async fetchData() {
    this.checkPermissions();

    await Promise.all([
      this.fetchDrafts(),
      this.fetchRoles(),
      this.fetchAgents(),
      this.fetchTypes(),
    ]);

    this.loadingDrafts = false;
  }

  async fetchRoles() {
    try {
      const response = await this.apiService.get<Role[]>({
        route: 'api/roles',
        token: true,
      });

      this.roles = response;

      const acceptedApplicables = ['shareholder', 'assignor', 'provider'];

      const mappedData = response
        .filter((item) => acceptedApplicables.includes(item.applicable))
        .map((item) => ({
          label: `${item.applicable_display} - ${item.name}`,
          value: item.id,
        }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

      this.applicableItems = mappedData;
    } catch (error) {
      console.log(error);

      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de papeis.'
      );
    }
  }

  async fetchAgents() {
    try {
      const response = await this.apiService.get<Agent[]>({
        route: 'api/registration/agent',
        token: true,
      });

      this.agents = response;

      const mappedData = response
        .map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

      this.agentItems = mappedData;
    } catch (error) {
      console.log(error);

      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de agentes.'
      );
    }
  }

  async fetchTypes() {
    try {
      const { data } = await this.apiService.get<ApiResponse<any[]>>({
        route: 'api/document-type',
        token: true,
      });

      this.types = data;

      const mappedData = data
        .map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

      this.typeItems = mappedData;
    } catch (error) {
      console.log(error);

      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de tipos de documento.'
      );
    }
  }

  async fetchDrafts(params = {}) {
    const newParams = params;

    Object.keys(newParams).forEach((key) => {
      if (typeof newParams[key] === 'boolean') {
        newParams[key] = String(newParams[key]);
      }
    });

    try {
      const { data } = await this.apiService.get({
        route: 'api/draft/',
        token: true,
        params: newParams,
      });

      this.drafts = data;

      this.visibleDrafts = [...data].splice(0, 10);
      this.showFilterModal = false;
    } catch (error) {
      this.toastService.show('error', 'Erro', 'Falha ao carregar as minutas');
    }
  }

  async deleteDraft(id: string) {
    try {
      await Swal.fire({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Sim, apague!',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.apiService
            .delete({
              route: `api/draft/${id}`,
              token: true,
            })
            .then((res) => {
              this.fetchDrafts();
            });
        },
      });
    } catch (error) {
      this.toastService.show('error', 'Erro', 'Falha ao deletar a minuta');
    }
  }

  redirectToForm(id?: number) {
    this.router.navigateByUrl(`/app/drafts/${id ?? 'new'}`);
  }

  toggleModal(show: boolean) {
    this.showFilterModal = show;
  }

  joinRoles(roles: Role[]) {
    return roles
      .map((role) => `${role.applicable_display} (${role.name})`)
      .join(', ');
  }

  verifyIdIsApplicable(field: string, applicable: string) {
    const fieldValue = this.form.controls[field].value;

    const roles = this.roles.filter((item) => fieldValue.includes(item.id));

    return roles.some((item) => item.applicable === applicable);
  }

  resetForm() {
    this.form.reset();
  }

  renderDate(date: string) {
    return date.split('-').reverse().join('/');
  }

  async submitForm() {
    try {
      const cleaned_fields = Utils.noEmptyFields(this.form.value);

      const payload = {
        ...cleaned_fields,
      };

      if (cleaned_fields.initial_date) {
        payload['initial_date'] = cleaned_fields.initial_date
          .split('/')
          .reverse()
          .join('-');
      }

      if (cleaned_fields.final_date) {
        payload['final_date'] = cleaned_fields.final_date
          .split('/')
          .reverse()
          .join('-');
      }

      this.fetchDrafts(payload);
    } catch (error) {
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao fazer a filtragem.');
    }
  }

  checkPermissions() {
    this.canAdd = this.authService.verifyPermission([
      'de_can_add_draft_document',
    ]);
    this.canEdit = this.authService.verifyPermission([
      'de_can_change_draft_document',
    ]);
    this.canDelete = this.authService.verifyPermission([
      'de_can_delete_draft_document',
    ]);
  }
}
