import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-approval-accompaniment',
  templateUrl: './dashboard-approval-accompaniment.component.html',
  styleUrls: ['./dashboard-approval-accompaniment.component.scss'],
})
export class DashboardApprovalAccompanimentComponent implements OnInit {
  columns: TableColumnList<UserApprovalSignature>[] = [
    {
      name: 'Fase',
      cell: (row) =>
        row.department_approval.type === 'department'
          ? row.department_approval.department.name
          : row.department_approval.agent.name,
    },
    {
      name: 'Usuário',
      cell: (row) => {
        const is_person = row.register.person !== null;
        let name = is_person
          ? row.register.person?.full_name
          : row.register.company?.corporate_name;

        return `
        <span>${name}</span>
        `;
        // <span class="app-table-details-body-row-subtitle">
        //   ${row.register.type_display}
        // </span>
      },
      size: 2,
    },
    {
      name: 'Ação',
      cell: (row) =>
        row.approved ? 'Aprovado' : row.restart ? 'Retornado' : 'Reprovado',
    },
    {
      name: 'Data',
      cell: (row) => this.formateDate(row.date),
    },
    {
      name: 'Observação',
      cell: (row) => row.reason,
      size: 2,
    },
    // {
    //   name: '',
    //   cell: (row) =>
    //     `<div class="icon-container"><i class="fas fa-eye ml-auto"></i></div> `,
    // },
  ];
  data: Partial<UserApprovalSignature>[] = [];

  loading = true;

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.authService.approvalData.history) {
      this.data = this.authService.approvalData.history;
      this.loading = false;
    } else {
      this.getApprovalData();
    }
  }

  async getApprovalData() {
    try {
      const req_params = {
        register: this.route.snapshot.params.id,
        tab: 'history',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<any[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      this.authService.approvalData.history = data[0].register.signatures;

      this.data = data[0].register.signatures;

      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  handleText(text: string) {
    if (text.length > 40) {
      const shortText = text.substring(0, 40);
      return `${shortText}...`;
    }
    return text;
  }

  redirectTo(path: string) {
    this.router.navigateByUrl(path);
  }

  formateDate(date: string) {
    const day = date.split('T').shift()?.split('-').reverse().join('/');
    const hours = date.split('T').pop()?.slice(0, 5);

    return `${day} às ${hours}`;
  }
}
