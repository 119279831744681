import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils, { GetHelper } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export type PersonTaxValues = {
  citizen_us: string;
  permanent_resident_us: boolean;
  has_green_card: boolean;
  us_tax_return: boolean;
  description_us_tax: string;

  signed: boolean;
  id?: number;
};

@Component({
  selector: 'app-signup-unified-person-tax',
  templateUrl: './signup-unified-person-tax.component.html',
  styleUrls: ['./signup-unified-person-tax.component.scss'],
})
export class SignupUnifiedPersonTaxComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    citizen_us: [null, [Validators.required]],
    permanent_resident_us: [null, [Validators.required]],
    has_green_card: [null, [Validators.required]],
    us_tax_return: [null, [Validators.required]],
    description_us_tax: [null, []],
    signed: [false, [Validators.requiredTrue]],
    ssn: [null, [Validators.required]],
    ssn_free: [false, []],
  });

  event: any;
  data: PersonTaxValues;

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.event = this.signupService.sendPersonData.subscribe((value) => {
      if (value === 'tax') {
        this.sendData();
      }
    });

    this.getData();

    this.form.statusChanges.subscribe((status) => {
      Utils.getErrors(this.form);
      this.signupService.setPersonFormStatus(
        'tax',
        status === 'VALID' && this.form.value.signed
      );
    });

    this.form.controls.ssn_free.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.ssn.setValue(null);
        this.form.controls.ssn.clearValidators();
      } else {
        this.form.controls.ssn.setValidators([Validators.required]);
      }
      this.form.controls.ssn.updateValueAndValidity();
    });

    this.form.controls.us_tax_return.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.form.controls.description_us_tax.setValidators([
          Validators.required,
          Validators.minLength(1),
        ]);
      } else {
        this.form.controls.description_us_tax.setValue(null);
        this.form.controls.description_us_tax.clearValidators();
      }
      this.form.controls.description_us_tax.updateValueAndValidity();
    });

    this.form.controls.description_us_tax.valueChanges.subscribe((value) => {
      if (value) {
        value.trim();
        if (value.trim().length === 0) {
          this.form.controls.description_us_tax.setErrors({ whitespace: true });
        } else {
          this.form.controls.description_us_tax.setErrors(null);
        }
      }
    });
  }

  async sendData() {
    this.signupService.loading = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        citizen_us: valuesHelper.get('citizen_us') === 'yes' ? true : false,
        permanent_resident_us:
          valuesHelper.get('permanent_resident_us') === 'yes' ? true : false,
        has_green_card:
          valuesHelper.get('has_green_card') === 'yes' ? true : false,
        us_tax_return:
          valuesHelper.get('us_tax_return') === 'yes' ? true : false,
        description_us_tax: valuesHelper.get('description_us_tax'),
        ssn: valuesHelper.get('ssn'),
        signature: valuesHelper.get('signature'),
      };

      if (this.data && this.data.id) {
        await this.api.put({
          route: `api/registration/investor_profile_quiz/${this.data.id}/`,
          token: true,
          body: payload,
        });
      } else {
        await this.api.post({
          route: 'api/registration/investor_profile_quiz/',
          token: true,
          body: payload,
        });
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.signupService.loading = false;
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/investor_profile_quiz/me/',
        token: true,
      });

      this.form.patchValue({
        ...data,
        signed: data ? true : false,
        permanent_resident_us: data.permanent_resident_us ? 'yes' : 'no',
        citizen_us: data.citizen_us ? 'yes' : 'no',
        has_green_card: data.has_green_card ? 'yes' : 'no',
        us_tax_return: data.us_tax_return ? 'yes' : 'no',
        ssn_free: !data.ssn,
      });
      this.form.updateValueAndValidity();

      this.data = data;
    } catch (error) {
      console.log(error);
    }
  }
}
