import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-approval-draft',
  templateUrl: './dashboard-approval-draft.component.html',
  styleUrls: ['./dashboard-approval-draft.component.scss'],
})
export class DashboardApprovalDraftComponent implements OnInit {
  loading = true;

  form = this.formBuilder.group({
    content: [null, [Validators.required]],
    fund: [null, [Validators.required]],
  });

  role: string = '';
  userId: number | null = null;
  haveCoobligation: boolean = false;
  selectedDraft = null;

  data: Partial<any>[] = [];

  funds: SelectItem[] = [];
  document: any;

  documentation = [];

  showModal = false;

  config = {
    theme: 'silver',
    height: '500',
    width: '100%',
    plugins:
      'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools contextmenu colorpicker textpattern help',
    toolbar:
      'formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify indent outdent | numlist bullist outdent indent | removeformat',
    menubar: true,
    statusbar: true,
    branding: true,
  };

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getApprovalData();
  }

  async getApprovalData() {
    try {
      const data = await this.api.get<any[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: {
          register: this.route.snapshot.params.id,
          tab: 'draft',
        },
      });

      this.userId = data[0].id;
      this.generateDocumentation();
      this.getData();

      this.role = data[0].register.role.applicable;
      this.role = data[0].register.role.applicable;
      this.haveCoobligation = data[0].assignor
        ? data[0].assignor.joint_obligation
          ? true
          : false
        : false;
      this.funds = data[0].links
        ? data[0].links.map((item) => {
            return {
              value: item.fund.id,
              label: item.fund.name,
            };
          })
        : [];
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async createTemplate() {
    let documentName = '';
    let coobligation = '';
    let fund = this.funds.find(
      (item) => item.value === this.form.controls.fund.value
    ).label;
    let res: any;

    if (this.role === 'assignor') {
      if (this.haveCoobligation) {
        coobligation = 'sem coobrigação';
      } else {
        coobligation = 'com coobrigação';
      }

      documentName = `Minuta de contrato ${coobligation} - ${fund}`;
    }

    if (this.role === 'shareholder') {
      documentName = `Termo de Adesão - ${fund}`;
    }

    const payload = {
      name: documentName,
      codiname: '',
      content: this.form.controls.content.value,
      coobligation:
        this.role === 'assignor' && this.haveCoobligation ? true : false,
      user: this.userId,
      fund: this.form.controls.fund.value,
      applicable: this.role,
    };

    try {
      if (this.selectedDraft) {
        res = await this.api.patch({
          route: `api/draft/${this.selectedDraft}/`,
          token: true,
          body: payload,
        });

        const findIdx = this.data.findIndex(
          (item) => item.id === this.selectedDraft
        );

        this.data[findIdx] = {
          file: res.name,
          applicable: res.applicable,
          date_updated: res.updated_at,
          id: res.id,
          fund: res.fund,
          content: res.content,
        };
      } else {
        res = await this.api.post({
          route: `api/draft/`,
          token: true,
          body: payload,
        });

        this.data.push({
          file: res.name,
          applicable: res.applicable,
          date_updated: res.updated_at,
          id: res.id,
          fund: res.fund,
          content: res.content,
        });
      }

      this.toast.show(
        'info',
        'Sucesso',
        this.selectedDraft
          ? 'Template atualizado, já está disponível para ser utilizado pelo sistema'
          : 'Um novo template foi criado e já está disponível para ser utilizado pelo sistema'
      );

      this.closeModal();
    } catch (error) {
      console.error(error);
    }
  }

  async getData() {
    const res = await this.api.get({
      route: `api/draft`,
      token: true,
    });

    res.data
      .filter((item) => item.user === this.userId)
      .map((item: any) => {
        this.data.push({
          file: item.name,
          applicable: item.applicable,
          date_updated: item.updated_at,
          id: item.id,
          fund: item.fund,
          content: item.content,
        });
      });
  }

  async handleDelete(id: any) {
    if (this.findItem(id)) {
      try {
        const res = await this.api.delete({
          route: `api/draft/${this.document.id}/`,
          token: true,
        });

        this.data = this.data.filter(
          (item: any) => Number(item.id) !== Number(this.document.id)
        );

        this.document = null;
      } catch (error) {
        console.error(error);
      }
    }
  }

  handleEdit(id: any) {
    if (this.findItem(id)) {
      this.selectedDraft = id;

      this.form.patchValue({
        content: this.document.content,
        fund: this.document.fund,
      });
      this.showModal = true;
    }
  }

  async handleOpenVisualizer(id: any) {
    if (this.findItem(id)) {
      window
        .open(
          `${this.api.url}/api/draft/${this.document.id}/?pdf=true`,
          '_blank'
        )
        .focus();
    }
  }

  findItem(id: any) {
    const finded = this.data.find((item: any) => {
      if (item.id) {
        return Number(item.id) === Number(id);
      }

      return false;
    });

    if (finded) {
      this.document = finded;
    }

    return finded;
  }

  formatApplicable(applicable: string) {
    switch (applicable) {
      case 'assignor':
        return 'Cedente';
      case 'shareholder':
        return 'Cotista';
      case 'provider':
        return 'Prestador de serviço';
      default:
        return 'Cedente';
    }
  }

  formatDate(date: string) {
    const day = date.split('T').shift()?.split('-').reverse().join('/');
    const hours = date.split('T').pop()?.slice(0, 5);

    return `${day} às ${hours}`;
  }

  generateEndereco(tipo) {
    return {
      logradouro: `Logradouro do ${tipo}`,
      numero: `Número do ${tipo}`,
      complemento: `Complemento do ${tipo}`,
      bairro: `Bairro do ${tipo}`,
      cidade: `Cidade do ${tipo}`,
      estado: `Estado do ${tipo}`,
      cep: `CEP do ${tipo}`,
    };
  }

  generateUsuario(tipo) {
    return {
      nome: `Nome completo do ${tipo}`,
      documento: `Número do documento do ${tipo}`,
      endereco: this.generateEndereco(tipo),
    };
  }

  generateData() {
    return {
      dia: `Dia`,
      mes: `Mês`,
      ano: `Ano`,
      hora: `Hora`,
      minutos: `Minutos`,
    };
  }

  generateFundo(tipo) {
    return {
      nome: `Nome do ${tipo}`,
      documento: `Número do documento do ${tipo}`,
    };
  }

  generateBank(tipo) {
    return {
      banco: `Nome do banco do ${tipo}`,
      agencia: `Número da agência do ${tipo}`,
      conta: `Número da conta do ${tipo}`,
    };
  }

  generateDocumentationArray(obj, prefix) {
    const result = [];
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object') {
        result.push(
          ...this.generateDocumentationArray(obj[key], `${prefix}${key}.`)
        );
      } else {
        result.push(`{{${prefix}${key}}}: ${obj[key]}`);
      }
    });
    return result;
  }

  generatePrimaryArray = (obj) => {
    const array = [];

    Object.keys(obj).forEach((key) => {
      array.push({
        name: key,
        children: this.generateDocumentationArray(obj[key], `${key}.`),
      });
    });

    return array;
  };

  generateDocumentation() {
    const documentation = {
      fundo: { ...this.generateFundo('Fundo'), ...this.generateBank('Fundo') },
      cedente: {
        ...this.generateUsuario('Cedente'),
        ...this.generateBank('Cedente'),
      },
      administrador: this.generateUsuario('Administrador'),
      gestor: this.generateUsuario('Gestor'),
      consultor: this.generateUsuario('Consultor'),
      data: this.generateData(),
    };

    this.documentation = this.generatePrimaryArray(documentation);
  }

  formatFileName(file: string) {
    return file.split('/').pop()?.split('.').shift();
  }

  closeModal() {
    this.showModal = false;
    this.selectedDraft = null;
    this.form.reset();
  }

  triggerModal() {
    this.showModal = true;
  }

  getDisabled() {
    return !this.form.valid;
  }
}
