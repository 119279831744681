import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-signature-card',
  templateUrl: './signature-card.component.html',
  styleUrls: ['./signature-card.component.scss'],
})
export class SignatureCardComponent implements OnInit {
  @Input() name: string = 'Você';
  @Input() role: string = 'Representante';
  @Input() canSign: boolean = false;
  @Input() sign: boolean = false;
  @Input() reSend: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onClick = new EventEmitter<MouseEvent>();
  @Output() onResend = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}

  click(event: MouseEvent) {
    this.onClick.emit(event);
  }

  resend(event: MouseEvent) {
    if (!this.disabled) {
      this.onResend.emit(event);
    }
  }
}
