import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @ViewChild('content') content: any;

  @Input() label: string = 'OK';
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() danger: boolean = false;

  @Input() bordless: boolean = false;

  @Input() outline: boolean = false;

  @Input() inverted: boolean = false;

  @Input() phantom: boolean = false;

  @Input() next: boolean = false;
  @Input() reverse: boolean = false;

  @Input() reject: boolean = false;

  @Input() full: boolean = false;

  @Input() center: boolean = false;

  @Input() showContent: boolean = true;

  @Input() fitContent: boolean = false;

  @Output() onClick = new EventEmitter<MouseEvent>();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.showContent =
      this.content.nativeElement &&
      this.content.nativeElement.childNodes.length > 0;

    this.cdRef.detectChanges();
  }

  click(event: MouseEvent) {
    if (!this.disabled && !this.loading) {
      this.onClick.emit(event);
    }
  }
}
