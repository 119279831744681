import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-fund-approval-attachments',
  templateUrl: './dashboard-fund-approval-attachments.component.html',
  styleUrls: ['./dashboard-fund-approval-attachments.component.scss'],
})
export class DashboardFundApprovalAttachmentsComponent implements OnInit {
  @Output() onClick = new EventEmitter<MouseEvent>();

  linkForm = this.formBuilder.group({
    draft: [null, []],
  });

  columns: TableColumnList<AttachmentListValues>[] = [
    {
      name: 'Nome',
      cell: (row) => row.file,
      size: 3,
    },
    {
      name: 'Destino',
      cell: (row) => this.formatApplicable(row.applicable),
    },
    {
      name: 'Última atualização',
      cell: (row) => this.formatDate(row.date_updated),
    },
    {
      name: '',
      cell: (row) =>
        `<div class="d-flex align-items-center icon-container">
              <div class="attachment-reject-button row-${row.id}">
                <i class="fas fa-trash"></i>
              </div>
              <div class="document-download row-${row.id}">
                  <i class="fas fa-cloud-download-alt"></i>
              </div>
              <div class="document-visualizer row-${row.id}">
                  <i class="fas fa-eye"></i>
              </div>
          </div>`,
    },
  ];
  data: Partial<AttachmentListValues>[] = [];

  showDocument = false;

  document: any;

  fundId: number = 0;
  applicableRole = 'ADM';

  linkModal = false;

  drafts = [];
  draftsItems = [];

  constructor(
    private formBuilder: FormBuilder,
    private routeParams: ActivatedRoute,
    private authService: AuthService,
    private route: Router,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    if (this.authService.user.active_register) {
      const role =
        this.authService.user.active_register.register.role.slug.split('-');
      this.applicableRole = role[0];
    }

    this.fundId = this.routeParams.snapshot.params.id;

    this.fetchData();
  }

  async fetchData() {
    await Promise.all([this.fetchFundDrafts(), this.fetchDrafts()]);
  }

  formatApplicable(applicable: string) {
    switch (applicable) {
      case 'assignor':
        return 'Cedente';
      case 'shareholder':
        return 'Cotista';
      case 'provider':
        return 'Prestador de serviço';
      default:
        return 'Cedente';
    }
  }

  async fetchDrafts() {
    try {
      const { data } = await this.api.get({
        route: `api/draft/`,
        token: true,
      });

      this.drafts = data;
      this.draftsItems = data.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async fetchFundDrafts() {
    try {
      const { data } = await this.api.get({
        route: `api/draft/?in__fund=${this.fundId}`,
        token: true,
      });

      const newData = data.map((item: any) => ({
        file: item.name,
        applicable: item.applicable,
        date_updated: item.updated_at,
        initial_date: item.initial_date,
        final_date: item.final_date,
        id: item.id,
      }));

      this.data = newData;
    } catch (error) {
      console.error(error);
    }
  }

  findItem(id: any) {
    const finded = this.data.find((item: any) => {
      if (item.id) {
        return Number(item.id) === Number(id);
      }

      return false;
    });

    if (finded) {
      this.document = finded;
    }

    return finded;
  }

  handleEdit(id: any) {
    if (this.findItem(id)) {
      this.route.navigateByUrl(`/app/drafts/${id}/${this.fundId}`);
    }
  }

  async handleDelete(id: any) {
    if (this.findItem(id)) {
      try {
        const res = await this.api.delete({
          route: `api/draft/${this.document.id}/`,
          token: true,
        });

        this.data = this.data.filter(
          (item: any) => Number(item.id) !== Number(this.document.id)
        );

        this.document = null;
      } catch (error) {
        console.error(error);
      }
    }
  }

  async handleOpenVisualizer(id: any) {
    if (this.findItem(id)) {
      window
        .open(
          `${this.api.url}/api/draft/${this.document.id}/?pdf=true`,
          '_blank'
        )
        .focus();
    }
  }

  addNewDocument() {
    this.route.navigateByUrl(`/app/drafts/new/${this.fundId}`);
  }

  formatDate(date: string, haveHours = true) {
    if (date === null) return '';
    const day = date.split('T').shift()?.split('-').reverse().join('/');
    const hours = date.split('T').pop()?.slice(0, 5);

    return `${day} ${haveHours ? 'às ' + hours : ''}`;
  }

  closeModal() {
    this.showDocument = false;
    this.document = null;
  }

  toggleLinkModal(status: boolean) {
    this.linkModal = status;
  }

  submitForm() {
    try {
      const { draft } = this.linkForm.value;

      if (draft) {
        this.api
          .post({
            route: `api/draft/${draft}/link/`,
            body: { fund: this.fundId },
            token: true,
          })
          .then((res) => {
            this.toggleLinkModal(false);
            this.fetchData();
          });
      }
    } catch (error) {
      console.error(error);
    }
  }
}
