import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss'],
})
export class EmptyContentComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Input() haveButtonIcon: boolean = true;
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() buttonLabel: string = '';

  @Output() onClick = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}

  click(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
