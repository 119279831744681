import { Theme } from './../index.d';
import UI_THEME_DEFAULT from './UI_THEME_DEFAULT';

const UI_THEME_CMCAPITAL: Theme = {
  pallete: {
    ...UI_THEME_DEFAULT.pallete,
    // logo: 'url("/assets/images/logo-cmcapital3.png")',
    // 'logo-horizontal': 'url("/assets/images/logo-cmcapital.png")',
    logo: '',
    'logo-horizontal': '',
  },
  brand: {
    ...UI_THEME_DEFAULT.brand,
    name: 'Banvox',
    desc: 'Módulo de cadastro',
    prefix: 'banvox',
    footerText: 'Banvox - Todos os direitos reservados 2024',
    logoText: 'Banvox',
    logoWidth: 60,
    minWidth: 60,
    logo: '',
    logoHorizontal: '',
    // logo: '/assets/images/logo-cmcapital3.png',
    // logoHorizontal: '/assets/images/logo-cmcapital.png',
    favicon: '/assets/favicon/banvox-favicon.ico',
    simplifiedFund: false,
  },
  api: {
    ...UI_THEME_DEFAULT.api,
    // local: 'http://localhost:8000',
    local: 'https://dev.api.data.banvox.prosperita.io',
    dev: 'https://dev.api.data.banvox.prosperita.io',
    stage: 'https://stage.api.data.banvox.prosperita.io',
    prod: 'https://api.data.banvox.prosperita.io',
    clarity_id: 'fhco559uuk',
    sentry_dsn:
      'https://561c977b8b3148de9e15e4e241b2e6ca@sentry.tools.prosperita.io/25',
  },
};

export default UI_THEME_CMCAPITAL;
