<ng-container *ngIf="loadingContent">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-8">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<form [formGroup]="form" *ngIf="!loadingContent">
    <!-- <div class="row">
        <div class="col-12 col-lg-8">
            <app-select label="Fundo de interesse" [group]="form"
                controlName="interest_fund" [items]="fundOptions">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div> -->

    <div class="row">
        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="cnpj" label="CNPJ"
                placeholder="12.345.678/0001-90" mask="00.000.000/0000-00"
                [readOnly]="!!invitedInfo.document">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-8">
            <app-input [group]="form" controlName="name" label="Razão Social"
                [isUpperCase]="true" placeholder="ACME LTDA">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">

        <div class="col-12 col-lg-4">
            <app-input label="Nome fantasia" [group]="form"
                controlName="brand_name">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="foundation_date"
                mask="00/00/0000" label="Data da fundação"
                placeholder="dd/mm/aaaa">
            </app-masked-input>

            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-6">
            <app-select [items]="signupService.choices().frameworks"
                [group]="form" controlName="framework"
                label="Natureza jurídica">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-select [items]="signupService.choices().branch_choices"
                [group]="form" controlName="branch"
                label="Forma de constituição">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row" *ngIf="!isFund">
        <div class="col-12 col-lg-4">
            <app-input [group]="form" controlName="municipal_registration"
                [maxLength]="11" label="Inscrição Municipal"
                placeholder="Digite">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-input [group]="form" controlName="state_registration"
                label="Inscrição Estadual" placeholder="Digite" [maxLength]="9"
                [readOnly]="form.controls.free_state_registration.value">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-spacer [bottom]="36"></app-spacer>
            <app-radio [multiple]="true"
                formControlName="free_state_registration">
                Isento de inscrição estatual
            </app-radio>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-6" *ngIf="!isFund">
            <app-input [group]="form" controlName="nire" label="NIRE"
                [maxLength]="11" placeholder="Digite"
                helpText="Número de Identificação do Registro Empresarial">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <ng-container *ngIf="isFund">
            <div class="col-12 col-lg-6">
                <app-input [group]="form" controlName="giin" label="GIIN"
                    [maxLength]="25" placeholder="Digite"
                    helpText="Global Intermediary Identification Number">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </ng-container>

        <div class="col-12 col-lg-6">
            <app-select [group]="form" controlName="contract_type"
                [items]="contractTypeItems" label="Tipo de contrato">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-2">
            <app-select [group]="form" controlName="cellphone_ddi"
                [items]="DDIOptions" label="DDI">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="cellphone_number"
                [label]="isFund ? 'Celular contato do Fundo' : 'Celular'"
                mask="(00) 00000-0000||(00) 0000-0000"
                placeholder="(00) 99887-7665">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-select [group]="form" controlName="telephone_ddi"
                [items]="DDIOptions" label="DDI">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="telephone_number"
                [label]="isFund ? 'Telefone contato do Fundo' : 'Telefone'"
                mask="(00) 00000-0000||(00) 0000-0000" placeholder="(opcional)">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-6" *ngIf="!isFund">
            <app-input [group]="form" type="email" controlName="contact_email"
                label="E-mail" placeholder="contato@sitedaempresa.com.br"
                [readOnly]="!!invitedInfo.email">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-6" *ngIf="!isFund">
            <app-input [group]="form" type="url" controlName="site" label="Site"
                placeholder="www.sitedaempresa.com.br">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <app-select [items]="signupService.choices().cnaes" [group]="form"
                controlName="cnae" label="CNAE Principal">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <ng-container *ngIf="!isFund">
        <div class="row">
            <ng-container *ngIf="userRole !== 'provider'">
                <div class="col-12 col-lg-6">
                    <app-currency-input [group]="form"
                        controlName="yearly_income" label="Faturamento
      nos últimos 12 meses">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-currency-input [group]="form" controlName="capital"
                        label="Capital Social">
                    </app-currency-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </ng-container>

            <div class="col-12 col-lg-6">
                <app-select
                    [items]="signupService.choices().company_size_choice"
                    [group]="form" controlName="company_size" label="Porte">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-select [items]="employeesOptions" [group]="form"
                    controlName="number_employees"
                    label="Número de funcionários">
                </app-select>

                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="userRole === 'provider'">
        <app-spacer [bottom]="48"></app-spacer>
    </ng-container>

    <div class="row">
        <div class="col-12 col-lg-6" *ngIf="isFund">
            <app-currency-input [group]="form" controlName="net_worth"
                label="Patrimônio Líquido">
            </app-currency-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row" *ngIf="isFund">
        <div class="col-12">
            <div class="d-flex align-items-center">
                <div class="mr-3">
                    <app-input [group]="form" type="email"
                        controlName="secondary_email"
                        label="E-mail de contato do Fundo"
                        placeholder="contato@sitedaempresa.com.br">
                    </app-input>
                </div>

                <div>
                    <app-spacer [top]="16"></app-spacer>
                    <app-radio [multiple]="true"
                        formControlName="primary_contact"
                        *ngIf="emailArr.length > 0">
                        Definir como e-mail principal
                    </app-radio>
                </div>
            </div>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <app-button (onClick)="addEmail()" class="add-button"
                [disabled]="!form.controls.secondary_email.valid || !form.controls.secondary_email.value"
                [outline]="true">
                <i class="fas fa-plus-circle mr-2"></i>
                Adicionar e-mail
            </app-button>

            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row" *ngIf="emailArr.length > 0 && isFund">
        <div class="col-12">
            <app-table [columns]="columns" [data]="emailArr"
                (onRemove)="removeEmail($event)"></app-table>
            <app-spacer [bottom]="32"></app-spacer>
        </div>
    </div>

    <ng-container *ngIf="userRole !== 'provider'">
        <div class="row">
            <!-- <div class="col-12">
                <app-radio [multiple]="true" formControlName="joint_obligation"
                    [disabled]="form.controls.judicial_recovery.value">
                    Cessão com coobrigação
                </app-radio>
                <app-spacer [bottom]="24"></app-spacer>
            </div> -->

            <ng-container *ngIf="!isFund">
                <div class="col-12">
                    <app-radio [multiple]="true"
                        formControlName="billet_emitter">
                        Emite boleto
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-radio [multiple]="true" formControlName="nfe_emitter">
                        Emite NFe
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-radio [multiple]="true"
                        formControlName="duplicate_emitter">
                        Emite duplicata
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </ng-container>

            <ng-container *ngIf="!isFund">
                <div class="col-12">
                    <app-radio [multiple]="true"
                        formControlName="judicial_recovery">
                        Está em recuperação judicial
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-5 offset-lg-1"
                    *ngIf="form.controls.judicial_recovery.value">
                    <app-radio [multiple]="true"
                        formControlName="have_judicial_recovery">
                        há plano de recuperação judicial
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>

                    <app-masked-input [group]="form"
                        controlName="judicial_recovery_date" mask="00/00/0000"
                        label="Data de aprovação do plano homologado"
                        placeholder="dd/mm/aaaa">
                    </app-masked-input>

                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </ng-container>

            <ng-container *ngIf="userRole === 'assignor'">
                <div class="col-12">
                    <app-radio [multiple]="true"
                        formControlName="have_guarantor">
                        Possuo avalista
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-5 offset-lg-1"
                    *ngIf="form.controls.have_guarantor.value">
                    <div>
                        <app-select [items]="guarantors" [group]="form"
                            controlName="guarantor" label="Avalista">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>
            </ng-container>

            <div class="col-12" *ngIf="!isFund">
                <app-radio [multiple]="true"
                    formControlName="authorization_bacen_queries">
                    Autorizo a {{'brand.name' | theme}}
                    a realizar consultas sobre meus dados no BACEN
                    <span
                        *ngIf="form.controls.authorization_bacen_queries.errors"
                        class="required-field">*</span>
                </app-radio>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>
    </ng-container>
</form>