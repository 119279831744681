<div class="row">
    <div class="col-12">
        <div class="dash-card card-padding-top">
            <app-empty-content [isVisible]="filesGroups.length === 0"
                title="Nenhum anexo encontrado"
                description="Não foi encontrado nenhum anexo nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <div class="dash-card-body">
                <div class="text-right" style="padding-right: 30px;"
                    *ngIf="isFundAdmin">
                    <app-button (onClick)="showAttachmentsModal = true"
                        [outline]="true">
                        Adicionar anexo
                    </app-button>

                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="dash-card-scroll" *ngIf="filesGroups.length > 0">
                    <table>
                        <thead>
                            <tr class="alternative-header">
                                <th>Nome</th>
                                <th>Início de validade</th>
                                <th>Fim validade</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filesGroups"
                                class="alternative-header">
                                <td style="max-width: 500px;">
                                    <div class="dash-card-item">
                                        <div>
                                            <span>
                                                <img class="file-icon-img"
                                                    src="{{getFileIcon(item.files[0].file)}}" />
                                            </span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{item.title}}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{item.files.length > 1 ?
                                                item.files.length + ' arquivos'
                                                : '1 arquivo'}}
                                            </div>
                                        </div>

                                    </div>
                                </td>
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-content-title"
                                            *ngIf="item.files[0].date_due">
                                            {{ item.files[0].date_due |
                                            date:'dd/MM/yyyy' }}
                                        </div>

                                        <div class="dash-card-item-content-title"
                                            *ngIf="!item.files[0].date_due">
                                            {{
                                            getAttachmentDate('start',
                                            item.files[0].date_created) }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-content-title"
                                            *ngIf="item.files[0].end_date_due">
                                            {{ item.files[0].end_date_due |
                                            date:'dd/MM/yyyy' }}
                                        </div>

                                        <div class="dash-card-item-content-title"
                                            *ngIf="!item.files[0].end_date_due">
                                            {{
                                            getAttachmentDate('end',
                                            item.files[0].date_created) }}
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div
                                        class="d-flex align-items-center justify-content-end">
                                        <button class="button"
                                            (click)="openFile(item.files)">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="showAttachmentsModal"
    (onClose)="closeAttachmentModal()" paddingBottom="30"
    title="Adicionar anexos">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <app-select [items]="attachmentOptions" [group]="form"
                    controlName="type" label="Selecione o arquivo">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="haveDueDate">
                <div class="col-6">
                    <app-masked-input mask="00/00/0000" label="De"
                        [group]="form" controlName="date_due"
                        placeholder="dd/mm/aaaa">
                    </app-masked-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-6">
                    <app-masked-input mask="00/00/0000" label="Até"
                        [group]="form" controlName="end_date_due"
                        placeholder="dd/mm/aaaa">
                    </app-masked-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </ng-container>

            <div class="col-12">
                <p>{{AttachmentDescription}}</p>
            </div>

            <div class="col-12" *ngIf="form.controls.type.value !== null">
                <app-dropzone (filesChange)="setNewFiles($event)"
                    [clearAttachments]="clearAttachments">
                </app-dropzone>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-xs-12 col-lg-4" *ngFor="let file of attachedFiles">
                <div class="d-flex flex-column justify-content-center">
                    <img [src]="file.file" alt="attachment" class="img-preview">

                    <span
                        class="text-overflow">{{getFileName(file.file)}}</span>
                    <app-spacer [bottom]="8"></app-spacer>

                    <div class="d-flex flex-row justify-content-between">
                        <app-button [inverted]="true" [center]="true"
                            (onClick)="openSingleFile(file.file)">
                            <i class="fa fa-eye"></i>
                        </app-button>

                        <app-button [danger]="true" [inverted]="true"
                            [center]="true" (onClick)="removeFile(file.id)">
                            <i class="fa fa-trash"></i>
                        </app-button>
                    </div>
                </div>

                <app-spacer [bottom]="24"></app-spacer>
            </div>

        </div>

        <div class="row">
            <div class="col-12 text-right">
                <app-button [inverted]="true" (onClick)="closeAttachmentModal()"
                    class="mr-3">
                    Cancelar
                </app-button>

                <app-button [inverted]="true" (onClick)="sendData()"
                    [disabled]="getFormDisabled()" [loading]="sending">
                    Salvar anexos
                </app-button>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>
    </div>
</app-side-modal>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="selectedFile">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div class="text-center">{{modalFiles[0].type?.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-body">
        <ng-container *ngIf="selectedFile">
            <img [src]="selectedFile.url" *ngIf="selectedFile.isImg"
                style="width:100%">

            <iframe [src]="selectedFile.url" *ngIf="!selectedFile.isImg"
                frameborder="0" height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-footer">
        <div class="attachment-modal-navigation">
            <button class="attachment-modal-navigation-arrow"
                (click)="setPrevAttachment()" [disabled]="getDisabled('prev')">
                <i class="fas fa-angle-left"></i>
            </button>

            <div class="pl-3 pr-3">{{selectedFile?.name ?
                urlDecoding(selectedFile.name) : ''}}</div>

            <button class="attachment-modal-navigation-arrow"
                (click)="setNextAttachment()" [disabled]="getDisabled('next')">
                <i class="fas fa-angle-right"></i>
            </button>
        </div>
    </ng-container>
</app-modal>