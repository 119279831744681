import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss'],
})
export class ValidateComponent implements OnInit {
  @Input() title: string = 'Validação de segurança';
  @Input() description: string =
    'Informe o código de segurança que enviamos para o seu email.';

  @ViewChild('digit1') digit1: any;
  @ViewChild('digit2') digit2: any;
  @ViewChild('digit3') digit3: any;
  @ViewChild('digit4') digit4: any;

  @Output() onDigitsValidated = new EventEmitter<ValidateResponse>();

  @Input() noPadding = false;

  digits: string[] = [];
  codeError: boolean = false;

  loading = false;

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    document.addEventListener('visibilitychange', async () => {
      if (!document.hidden) {
        const clipboard = await navigator.clipboard.readText();

        if (clipboard.length === 4) {
          this.toastService.show(
            'info',
            'Sucesso',
            'Token copiado com sucesso'
          );

          const digits = clipboard.split('');

          digits.forEach((digit, i) => {
            this.digits[i] = digit;
          });
        }
      }
    });
  }

  invalidDigit() {
    return this.digits.some((i) => i === '');
  }

  getRemainingDigits() {
    const remainingDigits = 4 - this.digits.length;

    return remainingDigits;
  }

  async resendToken() {
    this.loading = true;

    const user: NewLoginResponse = this.storageService.getPreUser();

    try {
      await this.apiService.post({
        route: 'resend-token/',
        body: { email: user.email },
      });
      this.toastService.show('info', 'Sucesso', 'Token reenviado com sucesso!');
    } catch (error) {
      this.toastService.show('error', 'Erro', 'Erro ao reenviar o token');
    }

    this.loading = false;
  }

  async validateDigits() {
    this.loading = true;

    const digits = this.digits.join('');
    const user: NewLoginResponse = this.storageService.getPreUser();

    try {
      await this.apiService.post({
        route: 'email-verify/',
        body: { email: user.email, token: digits },
      });

      this.toastService.show(
        'info',
        'Sucesso',
        'Seu token foi validado com sucesso'
      );

      this.onDigitsValidated.emit({ valid: true, code: digits });
    } catch (error) {
      console.error(error);

      this.digits = [];
      this.codeError = true;

      this.toastService.show('error', 'Erro', 'Erro ao validar o token');

      this.onDigitsValidated.emit({ valid: false, code: digits });
    }

    this.loading = false;
  }

  onChangeDigit(event: KeyboardEvent, index: number) {
    if (event.key === 'Backspace') {
      this.digits = this.digits.filter((_, i) => i !== index);
      switch (index) {
        case 1:
          this.digit1.nativeElement.focus();
          return;
        case 2:
          this.digit2.nativeElement.focus();
          return;
        case 3:
          this.digit3.nativeElement.focus();
          return;
        default:
          return;
      }
    } else {
      switch (index) {
        case 0:
          this.digit2.nativeElement.focus();
          return;
        case 1:
          this.digit3.nativeElement.focus();
          return;
        case 2:
          this.digit4.nativeElement.focus();
          return;
        default:
          return;
      }
    }
  }

  onPasteDigit(event: ClipboardEvent, index: number) {
    event.preventDefault();

    const value = event.clipboardData?.getData('text') ?? '';

    value
      .slice(0, 4)
      .split('')
      .forEach((digit, i) => {
        this.digits[i] = digit;
      });
  }
}
