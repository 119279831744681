import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-dashboard-approval-bonds',
  templateUrl: './dashboard-approval-bonds.component.html',
  styleUrls: ['./dashboard-approval-bonds.component.scss'],
})
export class DashboardApprovalBondsComponent implements OnInit {
  @Input() bonds: BondValues[] = [];

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  getFundInitials(fund: any) {
    if (!fund) {
      return '';
    }

    return fund.name
      ? fund.name
          .toUpperCase()
          .replace(/[^\w\s]/gi, '')
          .split(' ')
          .slice(0, 2)
          .map((word: string) => word.charAt(0))
          .join('')
      : '';
  }
}
