<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12">
        <div class="dash-details-card">
            <app-empty-content [isVisible]="!data"
                title="Nenhum dado profissional encontrado"
                description="Não foi encontrado nenhum dado profissional nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">

            </app-empty-content>

            <ng-container *ngIf="data">
                <div class="dash-details-card-header">
                    <span>Dados da entidade</span>
                </div>

                <app-spacer [bottom]="15"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.document_number
                                    ?
                                    formatCnpj(data.document_number)
                                    : '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-8">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    RAZÃO SOCIAL
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.corporate_name
                                    ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    OCUPAÇÃO PROFISSIONAL
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.occupation
                                    ?
                                    data.occupation :
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    E-MAIL CORPORATIVO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.corporate_email
                                    ?? '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.phone && data.phone.number
                                    ?
                                    formatPhone(data.phone)
                                    :'-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    RAMAL
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.phone?.branch
                                    ??
                                    '-'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <ng-container *ngIf="data.address">
                            <div class="col-12 col-md-4">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CEP
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{data.address?.cep
                                        ?? '-'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-8">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        ENDEREÇO COMPLETO
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{data.address?.public_place
                                        ?? '-'}},
                                        {{data.address?.number??
                                        '-'}},
                                        {{data.address?.complement??
                                        '-'}} -
                                        {{data.address?.district??
                                        '-'}},
                                        {{data.address?.city??
                                        '-'}} -
                                        {{data.address?.uf??
                                        '-'}}
                                        Brasil
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>