import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-fund-approval-complementary',
  templateUrl: './dashboard-fund-approval-complementary.component.html',
  styleUrls: ['./dashboard-fund-approval-complementary.component.scss'],
})
export class DashboardFundApprovalComplementaryComponent implements OnInit {
  loading = true;
  data;

  constructor(
    public fundService: FundService,
    private api: ApiService,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {}

  creditors_rights_items = [
    { value: '0', label: 'Escriturais' },
    { value: '1', label: 'Físicos' },
    { value: '2', label: 'N/A' },
  ];

  ngOnInit(): void {
    if (this.fundService.fundData.complementary) {
      this.data = this.fundService.fundData.complementary;
      this.loading = false;
    } else {
      this.getFundData();
    }
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  fromatCreditorsRight(value: any) {
    const creditor = this.creditors_rights_items.find(
      (_creditor) => _creditor.value == value
    );

    return creditor.label;
  }

  async getFundData() {
    try {
      const data = await this.api.get({
        route: 'api/v2/approvals/version/',
        params: {
          fund: this.route.snapshot.params.id,
          tab: 'complementary_data',
        },
        token: true,
      });

      const fund = { ...data };

      this.data = fund[0].fund[0];

      this.fundService.fundData = {
        ...this.fundService.fundData,
        complementary: fund[0].fund[0],
      };
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Erro ao carregar dados complementares: ' + error
      );
    }

    this.loading = false;
  }
}
