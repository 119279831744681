<div class="dash">
    <div class="row">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card no-padding">
                <ng-container *ngIf="!loading">
                    <app-back-button></app-back-button>
                </ng-container>

                <div
                    class=" dash-card-header d-flex align-items-start justify-content-between">

                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            width: '80px',
                            height: '25px'
                          }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <h2>Departamentos</h2>
                    </ng-container>

                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                        width: '180px',
                        height: '40px'
                      }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button (onClick)="formToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i> Adicionar
                            departamento
                        </app-button>
                    </ng-container>
                </div>

                <div class="dash-card-body padding-container">
                    <ng-container *ngIf="loading">
                        <table #table>
                            <thead>
                                <tr>
                                    <th colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>

                                    <th colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>
                                    <th colspan="1">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                            height: '15px',
                                            width: '100px',
                                        marginBottom:'0'
                                          }">
                                        </ngx-skeleton-loader>
                                    </td>
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '30px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </td>
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '60px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <table class="bordered"
                        *ngIf="departments.length > 0 && !loading">
                        <thead>
                            <tr>
                                <th colspan="1">Nome</th>
                                <th colspan="1">Vínculo</th>
                                <th colspan="1"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let department of departments">
                                <td colspan="1">
                                    <div class="d-flex flex-column">
                                        <span
                                            class="documents-name">{{department.name}}</span>
                                    </div>
                                </td>
                                <td colspan="1">{{department.links}}</td>
                                <td colspan="1">
                                    <div class="dash-card-item-buttons">
                                        <ng-container
                                            *ngIf="department.links === 0 && permissionObj.canDelete">
                                            <button class="dash-item-button"
                                                (click)="deleteDepartments(department.id)">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                            <app-spacer
                                                [right]="16"></app-spacer>
                                        </ng-container>

                                        <button class="dash-item-button"
                                            (click)="showLinks(department)">
                                            <svg-icon
                                                src="assets/images/check-list.svg"></svg-icon>
                                        </button>

                                        <ng-container
                                            *ngIf="permissionObj.canChange">
                                            <app-spacer
                                                [right]="16"></app-spacer>

                                            <button class="dash-item-button"
                                                (click)="setDepartment(department)">
                                                <i class="fas fa-pen"></i>
                                            </button>
                                        </ng-container>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>

                    <div *ngIf="departments.length === 0 && !loading">
                        <span
                            class="dash-card-item-content-title text-center d-block">
                            Nenhum departamento adicionado
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card-pagination">
                <app-pagination [data]="departments" [show]="!loading"
                    [dashPagination]="true">
                </app-pagination>
            </div>
        </div>
    </div>
</div>


<app-side-modal [active]="modal" (onClose)="closeModal()">

    <ng-container *ngIf="modal">
        <div class="row" [formGroup]="departmentForm">
            <div class="col-12">
                <span class="organization-form-title">{{ department ?
                    'Atualizando ' + department.name : 'Novo
                    departamento'}}
                </span>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <div class="col-12">
                <span class="organization-form-description">
                    {{department ? 'Atualize o departamento de ' +
                    department.name : 'Crie um novo
                    departamento'}}</span>
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Insira um nome para este departamento"
                    [group]="departmentForm" controlName="name">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-button [disabled]="departmentForm.invalid"
                    (click)="addDepartments()">
                    Salvar novo departamento
                </app-button>
            </div>
        </div>
    </ng-container>
</app-side-modal>


<app-side-modal [active]="departmentLinks" (onClose)="closeModal()"
    [title]="department ? department.name : ''" padding="0">
    <div class="dash-card links" *ngIf="department">
        <div class="row no-gutters">
            <div class="col-12 padding-container dash-card-body">
                <div clsas="styled-scroll">
                    <table class="bordered"
                        *ngIf="department.links_data.length > 0; else noResult">
                        <tbody>
                            <tr *ngFor="let item of department.links_data">
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-initials">
                                            <span>{{getFundInitials(item)}}</span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{item.first_name + ' ' +
                                                item.last_name}}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{item.email}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>

                    <ng-template #noResult>
                        <span
                            class="dash-card-item-content-title text-center d-block pb-4 pt-4 mb-0">
                            Nenhum colaborador vinculado
                        </span>
                    </ng-template>

                    <div *ngIf="department.links_data.length === 0">
                        <app-spacer [bottom]="30"></app-spacer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-side-modal>