<ng-container *ngIf="loadingContent">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-8">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<form [formGroup]="form" *ngIf="!loadingContent">
    <div class="row">
        <div class="col-12 col-lg-4 cep-container">
            <app-masked-input [group]="form" controlName="cep" label="CEP"
                mask="00000-000" placeholder="99887-766"
                (input)="handleSearchZipcode($event)">
            </app-masked-input>
            <i *ngIf="loading"
                class="fas fa-circle-notch fa-spin app-button-icon"></i>
        </div>
    </div>
    <app-spacer [bottom]="24"></app-spacer>

    <ng-container *ngIf="zipcodeFetched">
        <div class="row">
            <div class="col-12 col-lg-6">
                <app-input [group]="form" controlName="public_place"
                    label="Logradouro" [maxLength]="30"
                    [readOnly]="addressObj.public_place !== ''">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-2">
                <app-input [group]="form" controlName="number" type="number"
                    class="houseNumber" label="Número">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-4">
                <app-input [group]="form" controlName="complement"
                    [maxLength]="20" label="Complemento">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6">
                <app-input [group]="form" controlName="district" label="Bairro"
                    [maxLength]="30" [readOnly]="addressObj.district !== ''">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-3">
                <app-input [group]="form" controlName="city" label="Cidade"
                    [maxLength]="30" [readOnly]="addressObj.city !== ''">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-3">
                <app-select [items]="signupService.ufs" [group]="form"
                    controlName="uf" label="UF" placeholder="SP"
                    [readOnly]="addressObj.uf !== ''">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-4">
                <app-select [items]="signupService.nationalityChocies"
                    [group]="form" controlName="country"
                    [readOnly]="addressObj.country !== ''" label="País">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>
    </ng-container>
</form>