import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() approval: UserApprovalResponse = undefined;

  rejected: boolean = false;
  analysis: boolean = false;
  waitingSigns: boolean = false;
  homologation: boolean = false;
  blocked: boolean = false;
  finished: boolean = false;
  label: string = '';

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (this.approval.step <= this.approval.queue?.departments?.length) {
      this.analysis = true;
      this.label = 'Em análise';
    }

    if (this.approval.step == 0) {
      this.analysis = true;
      this.label = 'Aguardando representantes';
    }

    if (this.approval.editing) {
      this.analysis = true;
      this.label = 'Cadastro retornado';
    }

    if (this.approval.completed && this.approval.is_approved) {
      this.analysis = false;
      this.waitingSigns = false;
      this.label = 'Aprovado';
    }

    if (
      this.approval.completed &&
      !this.approval.is_approved &&
      !this.approval.is_draft
    ) {
      this.analysis = false;
      this.waitingSigns = false;
      this.homologation = false;
      this.rejected = true;
      this.label = 'Rejeitado';
    }
  }
}
