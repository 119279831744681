<div class="validate-container" [class.p-0]="noPadding">

  <div class="box-content">

    <p class="box-title">{{title}}</p>

    <app-spacer [bottom]="24"></app-spacer>

    <p class="box-description">{{description}}</p>

    <app-spacer [bottom]="48"></app-spacer>

    <form class="box-inputs">
      <input [(ngModel)]="digits[0]" type="password" maxlength="1" class="box-input"
        [ngModelOptions]="{standalone: true}" #digit1 (keyup)="onChangeDigit($event, 0)"
        (paste)="onPasteDigit($event,0)">
      <input [(ngModel)]="digits[1]" type="password" maxlength="1" class="box-input"
        [ngModelOptions]="{standalone: true}" #digit2 (keyup)="onChangeDigit($event, 1)"
        (paste)="onPasteDigit($event,1)">
      <input [(ngModel)]="digits[2]" type="password" maxlength="1" class="box-input"
        [ngModelOptions]="{standalone: true}" #digit3 (keyup)="onChangeDigit($event, 2)"
        (paste)="onPasteDigit($event,2)">
      <input [(ngModel)]="digits[3]" type="password" maxlength="1" class="box-input"
        [ngModelOptions]="{standalone: true}" #digit4 (keyup)="onChangeDigit($event, 3)"
        (paste)="onPasteDigit($event,3)">
    </form>

    <app-spacer [bottom]="24"></app-spacer>

    <div class="alert alert-danger" role="alert" *ngIf="codeError">
      Código inválido
    </div>

    <app-spacer [bottom]="24"></app-spacer>

    <app-button [full]="true" [disabled]="digits.length < 4 || loading || invalidDigit()" (onClick)="validateDigits()"
      [center]="true" [loading]="loading">
      <ng-container *ngIf="digits.length < 4">
        Faltam {{getRemainingDigits()}} dígitos
      </ng-container>
      <ng-container *ngIf="digits.length === 4">
        Validar token de segurança
      </ng-container>
    </app-button>

    <app-spacer [bottom]="24"></app-spacer>

    <p class="resend-msg">Ainda não recebi. <span class="resend-button" (click)="resendToken()">Reenviar código de
        segurança</span></p>

  </div>
</div>
