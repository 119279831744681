import { Theme } from './../index.d';
import UI_THEME_DEFAULT from './UI_THEME_DEFAULT';

const UI_THEME_AVENTIS: Theme = {
  pallete: {
    ...UI_THEME_DEFAULT.pallete,
    logo: 'url("/assets/images/logo-aventis.png")',
    'logo-horizontal': 'url("/assets/images/logo-aventis-h.png")',
  },
  brand: {
    ...UI_THEME_DEFAULT.brand,
    name: 'Aventis',
    desc: 'Módulo de cadastro',
    prefix: 'aventis',
    footerText: 'Aventis - Todos os direitos reservados 2022',
    logoText: 'Aventis',
    logoWidth: 60,
    minWidth: 60,
    logo: '/assets/images/logo-aventis.png',
    logoHorizontal: '/assets/images/logo-aventis-h.png',
    favicon: '/assets/favicon/aventis-favicon.ico',
    supportEmail: 'matheus.thome@atlanticfidc.com.br',
    simplifiedFund: false,
  },
  api: {
    ...UI_THEME_DEFAULT.api,
    // local: 'http://localhost:8000',
    local: 'https://v2.api.data.aventis.prosperita.io',
    dev: 'https://dev.v2.api.data.aventis.prosperita.io',
    stage: 'https://stage.v2.api.data.aventis.prosperita.io',
    prod: 'https://v2.api.data.aventis.prosperita.io',
    clarity_id: 'fhdinnxap5',
  },
};

export default UI_THEME_AVENTIS;
