import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-badge',
  templateUrl: './info-badge.component.html',
  styleUrls: ['./info-badge.component.scss'],
})
export class InfoBadgeComponent implements OnInit {
  @Input() approval: any = undefined;

  @Input() rejected: boolean = false;
  @Input() analysis: boolean = false;
  @Input() waitingSigns: boolean = false;
  @Input() homologation: boolean = false;
  @Input() blocked: boolean = false;
  @Input() finished: boolean = false;
  @Input() label: string = '';

  constructor() {}

  ngOnInit(): void {}
}
