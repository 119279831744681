import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import { ModalFilesUrlsProps } from '../../dashboard-approval/dashboard-approval-attachments/dashboard-approval-attachments.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import Utils from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

type FileGroup = {
  slug: string;
  title: string;
  files: Files[];
};
@Component({
  selector: 'app-dashboard-fund-approval-files',
  templateUrl: './dashboard-fund-approval-files.component.html',
  styleUrls: ['./dashboard-fund-approval-files.component.scss'],
})
export class DashboardFundApprovalFilesComponent implements OnInit {
  @ViewChild('modalRoot') modalRoot;

  attachments = [];
  attachmentOptions: SelectItem[] = [];
  newFiles: FilePicked[] = [];
  files = [];
  filesGroups = [];
  attachedFiles = [];

  modalFiles: any = null;
  modalFilesUrls: ModalFilesUrlsProps[] | null = null;
  selectedFile: ModalFilesUrlsProps | null = null;

  AttachmentDescription: string = '';

  showAttachmentsModal = false;
  haveDueDate = false;
  required = false;
  haveFilesToSend = false;
  sending = false;
  clearAttachments: boolean = false;
  isFundAdmin: boolean = false;

  form = this.formBuilder.group({
    type: [null, [Validators.required]],
    end_date_due: [null, []],
    date_due: [null, []],
  });

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public fundService: FundService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAttachments();

    this.isFundAdmin =
      this.authService.user.active_register?.register?.agent.some(
        (eachAgent) => eachAgent.name === 'Administrador'
      );

    this.form.controls.type.valueChanges.subscribe((value) => {
      const attachment = this.attachmentOptions.find(
        (_attachment) => _attachment.value === value
      );

      this.AttachmentDescription = attachment
        ? attachment.extra.description
        : '';

      this.haveDueDate = attachment ? attachment.extra.have_due_date : false;
      this.required = attachment ? attachment.extra.required : false;

      this.attachedFiles = this.getSendedFiles(value);

      if (!value) {
        this.haveFilesToSend = false;
      }

      this.setDueDateRequirement(this.haveDueDate, this.required);
    });
  }

  setDueDateRequirement(haveDueDate: boolean, required: boolean) {
    if (haveDueDate && required) {
      this.form.controls.end_date_due.setValidators([
        Validators.required,
        (control: FormControl) => Utils.validateMaxMinToday(control, false),
        Utils.validateMaxDate,
      ]);
      this.form.controls.date_due.setValidators([
        Validators.required,
        Utils.validateMaxMinToday,
        Utils.validateMinDate,
      ]);

      this.form.controls.end_date_due.updateValueAndValidity();
      this.form.controls.date_due.updateValueAndValidity();

      return;
    }

    if (haveDueDate) {
      this.form.controls.end_date_due.setValidators([
        Utils.validateWhenNotEmpty((control: FormControl) =>
          Utils.validateMaxMinToday(control, false)
        ),
        Utils.validateWhenNotEmpty(Utils.validateMaxDate),
      ]);
      this.form.controls.date_due.setValidators([
        Utils.validateWhenNotEmpty(Utils.validateMaxMinToday),
        Utils.validateWhenNotEmpty(Utils.validateMinDate),
      ]);

      this.form.controls.end_date_due.updateValueAndValidity();
      this.form.controls.date_due.updateValueAndValidity();

      return;
    }

    this.form.controls.end_date_due.setValidators([]);
    this.form.controls.date_due.setValidators([]);

    this.form.controls.end_date_due.updateValueAndValidity();
    this.form.controls.date_due.updateValueAndValidity();
  }

  async getFiles() {
    try {
      const { data } = await this.api.get<ApiResponse<Files[]>>({
        route: 'api/registration/files/',
        token: true,
        params: {
          fund: this.route.snapshot.params.id,
          //   referent: 'fund',
        },
      });

      this.files = data;

      this.setFilesData(data);
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
  }

  setFilesData(files: Files[]) {
    const newFilesGroup: FileGroup[] = [];

    files.forEach((file) => {
      const fileGroup = newFilesGroup.find(
        (_fileGroup) => _fileGroup.slug === file.type.slug
      );

      if (fileGroup) {
        fileGroup.files.push(file);
      } else {
        newFilesGroup.push({
          slug: file.type.slug,
          title: file.type.name,
          files: [file],
        });
      }
    });

    this.filesGroups = newFilesGroup;
  }

  async getAttachments() {
    try {
      const { data } = await this.api.get<ApiResponse<Files[]>>({
        route: 'api/approvals/files_type/',
        token: true,
        params: {
          type: 'fund',
        },
      });

      const newArr = data.map((item) => {
        const obj = {
          label: item.name,
          value: item.id,
          extra: {
            description: item.description,
            have_due_date: item.have_due_date,
            required: item.required,
          },
        };

        return obj;
      });

      this.attachmentOptions = newArr;

      await this.getFiles();
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
  }

  setFiles(index: number, files: FilePicked[]) {
    const newAttachments = [...this.attachments];
    newAttachments[index].files = [...files];

    this.attachments = newAttachments;

    if (newAttachments[index].files.length > 0) {
      newAttachments[index].isFromInvalid = false;
      newAttachments[index].isToInvalid = false;
    }
  }

  async removeFile(id: number) {
    try {
      await Swal.fire({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Sim, apague!',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.api
            .delete<ApiResponse<Files>>({
              route: `api/registration/files/${id}/`,

              token: true,
            })
            .finally(() => {
              this.toast.show('info', 'Sucesso', 'Arquivo removido');

              this.attachedFiles = this.attachedFiles.filter(
                (file) => file.id !== id
              );

              this.getFiles();
            });
        },
      });
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  getFileName(file: string) {
    let fileName = '';

    const regex = /uploads\/\d{4}\/\d{2}\/\d{2}\/(.*)\?Expires/;

    if (file.match(regex)) fileName = file.match(regex)[1];

    return fileName;
  }

  getSendedFiles(id) {
    const files = this.files.filter(
      (file) => file.referent === 'fund' && file.type.id === id
    );

    return files;
  }

  openSingleFile(file: string) {
    window.open(file, '_blank');
  }

  getFileIcon(file: string) {
    const fileExtension = file.split('?')[0].split('.').pop();

    switch (fileExtension) {
      case 'pdf':
        return 'assets/images/icon_filepdf.svg';
      case 'doc' || 'docx':
        return 'assets/images/word.png';
      case 'xls' || 'xlsx':
        return 'assets/images/xls.png';
      case 'ppt' || 'pptx':
        return 'assets/images/powerpoint.png';
      case 'jpg':
        return '/assets/images/icon_filejpg.svg';
      case 'png':
        return '/assets/images/icon_filejpg.svg';

      default:
        return '/assets/images/icon_filejpg.svg';
    }
  }

  getInvalid() {
    const invalid = this.attachments.some((_attachment) => {
      if (_attachment.required) {
        if (_attachment.files?.length === 0) {
          return true;
        } else {
          if (_attachment.have_due_date) {
            if (!(!!_attachment.date_due && !!_attachment.end_date_due)) {
              return true;
            }
          }
        }
      }

      return false;
    });

    const validArr = [invalid];

    return validArr.some((item) => item);
  }

  async sendAttachment(payload) {
    try {
      await this.api.post<ApiResponse<Files>>({
        route: 'api/registration/files/',
        token: true,
        body: payload,
        noDefautHeaders: true,
      });
    } catch (error) {
      this.toast.show('error', 'Erro', error.error.message);
    }
  }

  //   async sendAttachments(_attachment: Attachment) {
  //     try {
  //       const fund = this.fundService.data;

  //       const file_payload = _attachment.files.map((_file) => {
  //         const formData = new FormData();

  //         formData.append('fund', String(fund.id));
  //         formData.append('type', String(_attachment.id));
  //         formData.append('file', _file.file);
  //         formData.append('referent', 'fund');

  //         if (_attachment.end_date_due)
  //           formData.append('end_date_due', _attachment.end_date_due);
  //         if (_attachment.date_due)
  //           formData.append('date_due', _attachment.date_due);

  //         return formData;
  //       });

  //       await Promise.all(
  //         file_payload.map((payload) => this.sendAttachment(payload))
  //       );
  //     } catch (error) {
  //       this.toast.show(
  //         'error',
  //         'Erro',
  //         `Erro ao enviar arquivo ${_attachment.name}`
  //       );
  //     }
  //   }

  getAttachmentDate(term: string, file: string | number) {
    const date = new Date(file);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (term === 'start') {
      return `${day}/${month}/${year}`;
    } else {
      return `${day}/${month}/${year + 2}`;
    }
  }

  async sendData() {
    this.sending = true;
    try {
      //   const registerAttachmentsRequests = this.attachments
      //     .filter((_attachments) => _attachments.files?.length > 0)
      //     .map((_attachments) => this.sendAttachments(_attachments));

      //   await Promise.all([...registerAttachmentsRequests]);

      const form = this.form.value;

      const fund = this.fundService.fundData.basic;

      const file_payload = this.newFiles.map((_file) => {
        const formData = new FormData();

        formData.append('fund', String(fund.id));
        formData.append('type', String(form.type));
        formData.append('file', _file.file);
        formData.append('referent', 'fund');

        if (form.end_date_due)
          formData.append('end_date_due', form.end_date_due);
        if (form.date_due) formData.append('date_due', form.date_due);

        return formData;
      });

      await Promise.all(
        file_payload.map((payload) => this.sendAttachment(payload))
      );

      this.toast.show('info', 'Sucesso', 'Arquivo enviado com sucesso!');

      this.closeAttachmentModal();

      //   this.attachments = [];
      await this.getAttachments();
    } catch (error) {
      console.error(error);
    }
    this.sending = false;
  }

  getImgUrl(attachment: Partial<AttachmentListValues>) {
    let url: any = attachment.file;
    let type: string = '';

    const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

    const resultado = regex.exec(attachment.file);

    if (resultado && resultado[1]) {
      type = resultado[1].toLowerCase();
    }

    if (type === 'pdf') {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(attachment.file);
    }

    if (type === 'xls' || type === 'xlsx') {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${attachment.file}`
      );
    }

    let auxExtensionTypes = ['pdf', 'xls', 'xlsx'];

    return {
      url,
      isImg: !auxExtensionTypes.includes(type),
      name: this.formatFileName(attachment.file),
    };
  }

  formatFileName(file: string) {
    return file.split('/').pop()?.split('.').shift();
  }

  openFile(attachments: Partial<AttachmentListValues>[]) {
    this.modalFiles = attachments;

    const newModalFilesUrls = attachments.map(
      (attachment: Partial<AttachmentListValues>) => this.getImgUrl(attachment)
    );

    this.modalFilesUrls = newModalFilesUrls;
    this.selectedFile = newModalFilesUrls[0];

    this.modalRoot.show();
  }

  getDisabled(direction) {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (direction === 'prev') {
      return index === 0;
    } else {
      return index === this.modalFilesUrls?.length - 1;
    }
  }

  setNextAttachment() {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (index === this.modalFilesUrls?.length - 1) return;

    this.selectedFile = this.modalFilesUrls[index + 1];
  }

  setPrevAttachment() {
    const index = this.modalFilesUrls?.findIndex(
      (item) => item.url === this.selectedFile?.url
    );

    if (index === 0) return;

    this.selectedFile = this.modalFilesUrls[index - 1];
  }

  urlDecoding(label: string) {
    return decodeURIComponent(label);
  }

  closeAttachmentModal() {
    this.showAttachmentsModal = false;
    this.form.reset();
  }

  setNewFiles(files: FilePicked[]) {
    this.haveFilesToSend = files.length > 0;

    this.newFiles = files;
  }

  getFormDisabled() {
    return this.form.invalid || this.sending || !this.haveFilesToSend;
  }
}
