import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-fund-approval-tax',
  templateUrl: './dashboard-fund-approval-tax.component.html',
  styleUrls: ['./dashboard-fund-approval-tax.component.scss'],
})
export class DashboardFundApprovalTaxComponent implements OnInit {
  loading = true;
  data;

  constructor(
    public fundService: FundService,
    private api: ApiService,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    if (this.fundService.fundData.tax) {
      this.data = this.fundService.fundData.tax;
      this.loading = false;
    } else {
      this.getFundData();
    }
  }

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  async getFundData() {
    try {
      const data = await this.api.get({
        route: 'api/v2/approvals/version/',
        params: {
          fund: this.route.snapshot.params.id,
          tab: 'taxes',
        },
        token: true,
      });

      const fund = { ...data };

      this.data = fund[0].fund[0];

      this.fundService.fundData = {
        ...this.fundService.fundData,
        tax: fund[0].fund[0],
      };
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar taxas: ' + error);
    }

    this.loading = false;
  }
}
