import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CurrencyMaskConfig } from 'ngx-currency';

import utils from '../../helpers/utils';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
})
export class CurrencyInputComponent implements OnInit {
  @Input() id?: string = utils.makeID();
  @Input() name?: string;
  @Input() placeholder: string = 'Digite';
  @Input() label?: string;
  @Input() type = 'text';
  @Input() maxLength?: number = 13;

  // Form control
  @Input() controlName: string = '';
  @Input() group?: any;

  @Input() helpText?: string;
  @Input() errorText: string = 'Campo inválido';

  @Input() readOnly: boolean = false;
  @Input() validated: boolean = false;
  @Input() rows: number = 0;

  @Input() button: string = '';

  @Output() onButtonClick: EventEmitter<MouseEvent> = new EventEmitter();

  options: Partial<CurrencyMaskConfig> = {
    align: 'left',
    allowNegative: false,
    decimal: ',',
    precision: 2,
    prefix: 'R$ ',
    suffix: '',
    max: 999999999999,
    thousands: '.',
  };

  error: boolean = false;

  constructor() {}

  onButtonClicked(event: MouseEvent): void {
    this.onButtonClick.emit(event);
  }

  ngOnInit(): void {}

  getClasses(): string {
    if (this.error) {
      return 'error';
    }

    if (this.validated) {
      return 'validated';
    }

    return '';
  }

  getRequired(): boolean {
    const control = this.group.controls[this.controlName];

    if (control && control.validator) {
      let required = false;

      const validator = control.validator({} as AbstractControl);

      if (validator && validator.required) {
        required = true;
      }

      return required;
    }
    return false;
  }

  getTouched(): boolean {
    let touched = false;

    if (this.readOnly) return touched;

    if (this.group) {
      const control = this.group.controls[this.controlName];

      if (control) {
        if (control.touched) {
          touched = true;
        }
      } else {
        console.error(`Control of ${this.controlName} not found!`);
      }
    }

    return touched;
  }

  getInvalid(): boolean {
    let invalid = false;

    if (this.readOnly) return invalid;

    if (this.group) {
      const control = this.group.controls[this.controlName];

      if (control.invalid) {
        invalid = true;
      }
    }

    return invalid;
  }

  //   getError() {
  //     const control = this.group.controls[this.controlName];

  //     if (control) {
  //       let invalid = false;

  //       if (this.readOnly) return invalid;

  //       if (this.group) {
  //         if (control.invalid) {
  //           invalid = true;
  //         }
  //       }

  //       return invalid;
  //     }
  //     return true;
  //   }

  getError(): boolean {
    return this.getTouched() && this.getInvalid();
  }
}
