import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-contributors-departments',
  templateUrl: './dashboard-contributors-departments.component.html',
  styleUrls: ['./dashboard-contributors-departments.component.scss'],
})
export class DashboardContributorsDepartmentsComponent implements OnInit {
  @Input() loading: boolean;
  @Input() departments: any[];
  @Output() updateDepartments = new EventEmitter<MouseEvent>();

  departmentForm = this.formBuilder.group({
    name: [null, [Validators.required]],
  });

  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
    canDelete: false,
  };

  department: any = undefined;
  addDepartment = false;

  departmentOptions: SelectItem[] = [];

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setDepartments();
    this.setPermissions();
  }

  addDepartmentToggle(status: boolean) {
    this.addDepartment = status;
  }

  setDepartments() {
    const options = this.departments.map((_department) => ({
      label: _department.name,
      value: _department.id,
    }));

    this.departmentOptions = options;
  }

  async addDepartments() {
    try {
      const values = this.departmentForm.value;

      const alreadyHaveDepartment = this.departments.find(
        (item) => item.name === values.name
      );

      if (alreadyHaveDepartment) {
        this.toast.show('error', 'Erro!', 'Departamento já existe');
        return;
      }

      const res = this.department
        ? await this.api.put({
            route: 'api/departments',
            token: true,
            body: values,
            params: { id: this.department.id },
          })
        : await this.api.post({
            route: 'api/departments',
            token: true,
            body: values,
          });

      this.toast.show('info', 'Sucesso!', 'Departamento adicionado');
      this.updateDepartments.emit();
      this.closeModal();
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao adicionar departamento');
    }
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }

  closeModal() {
    this.addDepartment = false;
    this.departmentForm.reset();
    this.department = undefined;
  }

  setDepartment(department: any) {
    this.departmentForm.patchValue({
      ...department,
    });
    this.department = department;
    this.addDepartmentToggle(true);
  }

  formatDepartament(id: number) {
    const label = this.departmentOptions.find(
      (item) => Number(item.value) === id
    )?.label;

    return label ?? '';
  }

  async removeDepartment(id: number) {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    });

    if (isConfirmed) {
      try {
        const res = this.api.delete({
          route: 'api/departments',
          token: true,
          params: {
            id,
          },
        });

        this.departments = this.departments.filter(
          (department) => department.id !== id
        );

        Swal.fire({
          title: 'Apagado!',
          text: 'Departamento apagado com sucesso',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      } catch (error) {
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao apagar departamento',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        });
      }
    }
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_department`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
