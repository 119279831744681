<div class="dashboard">

    <div class="row">

        <div class="col-12">

            <div class="dash-card">

                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="dash-card-header">
                            <span>Fundos</span>
                        </div>
                    </div>

                    <div class="col-12 padding-container dash-card-body">
                        <div clsas="styled-scroll">
                            <table class="bordered">
                                <thead>
                                    <tr>
                                        <th>Fundo</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let fund of funds">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>{{getFundInitials(fund)}}</span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{fund.name}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        {{fund.slug}}
                                                    </div>
                                                </div>

                                            </div>
                                        </td>
                                        <td>
                                            <!-- {{fund.initial_quota | currency}} -->
                                        </td>
                                        <td>
                                            <!-- {{fund.min_redemption | currency}} -->
                                        </td>
                                        <td style="text-align: right;">
                                            <a
                                                [routerLink]="['/app/dashboard/approval/', fund.id]">
                                                <button class="button">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </a>
                                        </td>

                                    </tr>

                                    <tr *ngIf="funds.length === 0">
                                        <td colspan="3">
                                            <span
                                                class="dash-card-item-content-subtitle">Nenhum
                                                fundo
                                                adicionado</span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <div *ngIf="funds.length === 0">
                                <app-spacer [bottom]="30"></app-spacer>
                            </div>
                        </div>
                        <app-spacer [bottom]="2"></app-spacer>
                    </div>
                </div>
            </div>

            <div class="dash-card-pagination">
                <app-pagination [dashPagination]="true" [data]=" funds">
                </app-pagination>
            </div>

        </div>
    </div>
</div>