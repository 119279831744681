import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-approval-composition',
  templateUrl: './dashboard-approval-composition.component.html',
  styleUrls: ['./dashboard-approval-composition.component.scss'],
})
export class DashboardApprovalCompositionComponent implements OnInit {
  @ViewChild('modalRoot') modalRoot;
  data: PartnerResponse[];

  compositionDetails = false;
  selectedPartner: Partner = null;
  loading = true;

  showModal = false;
  file: Files = null;

  isPDF = false;
  url: any;
  isXLS = false;

  constructor(
    private sanitizer: DomSanitizer,
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.authService.approvalData.composition) {
      this.formatData(
        this.authService.approvalData.composition.register.partners
      );
      this.loading = false;
    } else {
      this.getApprovalData();
    }
  }

  async getApprovalData() {
    try {
      const req_params = {
        register: this.route.snapshot.params.id,
        tab: 'partners',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<UserApprovalResponse[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      this.authService.approvalData.composition = data[0];

      this.formatData(data[0].register.partners);
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  filterPartners(partners) {
    return partners.filter((partner: Partner) => partner.document);
  }

  closeModal() {
    this.compositionDetails = false;
  }

  openModal(item: Partner) {
    this.compositionDetails = true;
    this.selectedPartner = item;
  }

  handleSelect(idx: any) {
    this.file = this.selectedPartner.files[idx];
    this.getImgUrl();
  }

  formatData(data: any) {
    const firstObject: PartnerResponse = {
      id: undefined,
      percentage: 0,
      isOpen: true,
      pep: false,
      full_name: 'Grupo principal',
      document: '',
      parent: null,
      title: '',
      pep_bond: '',
      pep_relationship: false,
      pep_relationship_document: '',
      pep_relationship_name: '',
      shareholders: [],
      register: '',
      collapsed: false,
    };

    const rootObjects = [];

    data.forEach((item) => {
      if (item.parent === null) {
        firstObject.shareholders.push(item);
      }

      let doc = Utils.onlyNumbers(item.document);

      if (doc.length > 14) {
        rootObjects.push(item);
      }
    });

    const newData = data.map((item) => {
      return {
        ...item,
        collapsed: false,
        isOpen: true,
      };
    });

    const newFormattedData = [firstObject, ...newData];

    this.data = newFormattedData;
  }

  getShareholdersPercentage(item_id?: number) {
    let maxPercentage = 0;

    if (item_id !== undefined) {
      const partner = this.data.find((item) => item.id === item_id);
      if (partner) {
        maxPercentage = partner.shareholders.reduce((prev, curr) => {
          return Number(prev.toFixed(4)) + Number(curr.percentage.toFixed(4));
        }, 0);
      }
    } else {
      const rootObjects = this.data.filter((item) => item.parent === null);
      maxPercentage = rootObjects.reduce((prev, curr) => {
        return Number(prev.toFixed(4)) + Number(curr.percentage.toFixed(4));
      }, 0);
    }

    return Number(maxPercentage.toFixed(4));
  }

  handleSetCollapsed(index: number, isOpen: boolean) {
    if (!isOpen) {
      this.data[index].shareholders.forEach((item) => {
        const shareholderIndex = this.data.findIndex((i) => item.id === i.id);

        this.data[shareholderIndex].collapsed = false;
      });

      this.data[index].isOpen = true;
      return;
    }

    this.data[index].isOpen = false;

    if (isOpen) {
      this.data[index].shareholders.forEach((item) => {
        const shareholderIndex = this.data.findIndex((i) => item.id === i.id);

        this.data[shareholderIndex].collapsed = true;

        this.handleSetCollapsed(shareholderIndex, true);
      });
    }
  }

  getSpace(index: number) {
    const multiplyNumber = this.getMultiplier(index);

    return multiplyNumber * 32;
  }

  getMultiplier(index: number) {
    let multiplyNumber = this.data[index].id ? 1 : 0;
    let haveParent = this.data[index].parent !== null;
    let parentId = 0;
    let findedIndex = index;

    while (haveParent) {
      multiplyNumber++;
      parentId = this.data[findedIndex].parent;

      if (parentId === null) {
        haveParent = false;
        break;
      }

      findedIndex = this.data.findIndex((item) => item.id === parentId);

      if (findedIndex === -1) {
        haveParent = false;
        break;
      }

      haveParent = this.data[findedIndex].parent !== null;
    }

    return multiplyNumber;
  }

  getImgUrl() {
    const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

    const resultado = regex.exec(this.file.file);

    if (resultado && resultado[1]) {
      const extension = resultado[1].toLowerCase();

      switch (extension) {
        case 'pdf':
          this.isPDF = true;
          break;
        case 'xls':
        case 'xlsx':
          this.isXLS = true;
          break;
        default:
          this.isPDF = false;
          this.isXLS = false;
          break;
      }
    }

    let url: any = this.file.file;

    if (this.isPDF) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(this.file.file);
    }

    if (this.isXLS) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${this.file.file}`
      );
    }

    this.url = url;

    this.modalRoot.show();
  }
}
