import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import utils from 'src/app/helpers/utils';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  @Input() items: SelectItem[] = [];
  @Input() multiple: boolean = false;
  @Input() selected: string = '';

  @Input() id?: string = utils.makeID();
  @Input() name?: string;
  @Input() placeholder: string = 'Selecione';
  @Input() label?: string;
  @Input() type = 'text';

  // Form control
  @Input() controlName: string = '';
  @Input() group?: any;

  @Input() helpText?: string;
  @Input() errorText?: string = 'Campo inválido';

  @Input() readOnly?: boolean = false;
  @Input() validated: boolean = false;

  error: boolean = false;

  visibleItems: SelectItem[] = [];

  constructor(config: NgSelectConfig) {
    config.notFoundText = 'Nenhum item encontrado';

    config.bindLabel = 'label';
    config.bindValue = 'value';
  }

  ngOnInit(): void {
    this.visibleItems = this.items;
  }

  ngOnChanges(): void {
    this.visibleItems = this.items;
  }

  getClasses(): string {
    if (this.error) {
      return 'error';
    }

    if (this.validated) {
      return 'validated';
    }

    return '';
  }

  getTouched(): boolean {
    let touched = false;

    if (this.group) {
      const control = this.group.controls[this.controlName];

      if (control) {
        if (control.touched) {
          touched = true;
        }
      } else {
        console.error(`Control of ${this.controlName} not found!`);
      }
    }

    return touched;
  }

  getInvalid(): boolean {
    let invalid = false;

    if (this.group) {
      const control = this.group.controls[this.controlName];

      if (control.invalid) {
        invalid = true;
      }
    }

    return invalid;
  }

  getRequired(): boolean {
    const control = this.group.controls[this.controlName];

    if (control && control.validator) {
      let required = false;

      const validator = control.validator({} as AbstractControl);

      if (validator && validator.required) {
        required = true;
      }

      return required;
    }
    return false;
  }

  getError(): boolean {
    return this.getTouched() && this.getInvalid();
  }
}
