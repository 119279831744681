import { Component, EventEmitter, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import UIDefaultTheme from 'src/themes';

@Component({
  selector: 'app-signup-unified-person',
  templateUrl: './signup-unified-person.component.html',
  styleUrls: ['./signup-unified-person.component.scss'],
})
export class SignupUnifiedPersonComponent implements OnInit {
  steps: Step[] = [
    {
      icon: 'fas fa-file-alt',
      svg: 'assets/images/icon-inputdata-active.svg',
      title: 'Dados complementares',
      slug: 'complementary',
      substeps: [
        {
          title: 'Geral',
          slug: 'complementaryGeneral',
        },
        {
          title: 'Endereço',
          slug: 'complementaryAddress',
        },
        {
          title: 'Dados bancários',
          slug: 'complementaryBank',
        },
        {
          title: 'Profissional',
          slug: 'complementaryProfessional',
        },
        {
          title: 'Representantes',
          slug: 'complementaryRepresentatives',
        },
      ],
    },
    {
      icon: 'fas fa-file-alt',
      title: 'Resumo dos poderes',
      svg: 'assets/images/icon-inputdata-active.svg',
      slug: 'powers',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/icon-attachments.svg',
      title: 'Anexos & comprovantes',
      slug: 'attachments',
    },
  ];

  shareholderSteps: Step[] = [
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Rendimentos & patrimônios',
      slug: 'income',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Declarações & autorizações',
      slug: 'declarations',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Análise de perfil de investidor',
      slug: 'analysis',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Declaração de Condição de Investidor',
      slug: 'conditions',
    },
    {
      icon: 'fas fa-paperclip',
      svg: 'assets/images/check-list.svg',
      title: 'Declaração US Person / Non US Person (FATCA)',
      slug: 'tax',
    },
  ];

  _activeStep: number = 0;

  activeSubstep: PersonSteps = 'complementaryRepresentatives';

  redirected = false;
  hasMultipleAccounts = false;
  userOptionsOpen: boolean = false;

  get activeStep() {
    return this._activeStep;
  }

  set activeStep(value) {
    this._activeStep = value;

    if (this.isShareholder) {
      switch (value) {
        case 0:
          this.currentTab = 'Dados complementares';
          break;

        case 1:
          this.currentTab = 'Resumo dos poderes';
          break;

        case 2:
          this.currentTab = 'Rendimentos & patrimônios';
          break;

        case 3:
          this.currentTab = 'Declarações & autorizações';
          break;

        case 4:
          this.currentTab = 'Análise de perfil de investidor';
          break;

        case 5:
          this.currentTab = 'Declaração de Condição de Investidor';
          break;

        case 6:
          this.currentTab = 'Declaração US Person / Non US Person (FATCA)';
          break;

        case 7:
          this.currentTab = 'Anexos & comprovantes';
          break;
        default:
          this.currentTab = '';
          break;
      }
    } else {
      switch (value) {
        case 0:
          this.currentTab = 'Dados complementares';
          break;

        case 1:
          this.currentTab = 'Resumo dos poderes';
          break;

        case 2:
          this.currentTab = 'Anexos & comprovantes';
          break;
        default:
          this.currentTab = '';
          break;
      }
    }
  }

  currentTab: string = '';

  isShareholder = false;

  loading = false;

  showSupportModal = false;

  name: string | null = null;
  role: string | null = null;
  label: string | null = null;

  agentOptions: SelectItem[] = [];

  userCredentials: UserCredentials = {
    email: '',
    name: '',
  };

  accounts: RegisterUserResponse[] = [];

  public UIDefaultTheme = UIDefaultTheme;

  constructor(
    private router: Router,
    public signupService: SignupService,
    private apiService: ApiService,
    private toastService: ToastService,
    private authService: AuthService,
    private titleService: Title
  ) {
    if (this.authService.user.active_register.register.role.slug) {
      this.titleService.setTitle(
        Utils.setTitle(this.authService.user.active_register.register.role.slug)
      );
    }
  }

  ngOnInit(): void {
    this.getAccounts();

    this.isShareholder = this.router.url.includes('shareholder');

    if (this.isShareholder) {
      this.steps.splice(2, 0, ...this.shareholderSteps);
    }

    this.userCredentials = {
      email: this.authService.user?.email,
      name:
        this.authService.user?.first_name +
        ' ' +
        this.authService.user?.last_name,
    };

    document.onkeydown = (e) => {
      if (e.key === 'Enter') {
        this.proceed();
      }
    };

    this.signupService.changePersonStepEvent.subscribe((step) => {
      const aux = `complementary${
        step.charAt(0).toUpperCase() + step.slice(1)
      }` as PersonSteps;

      this.activeSubstep = aux;
      this.loading = false;
      this.activeStep = 0;

      this.signupService.updateUserStep('register', step);
    });

    this.signupService.changeBasicInfoEvent.subscribe((value) => {
      this.label = this.getRoleLabel(this.role);

      this.name = value.toUpperCase();
    });

    this.signupService.fetchDataEvent.emit('PF');
    let urlParts = this.router.url.split('/pf')[0].split('/');
    let range = urlParts.length;
    this.role = urlParts[range - 1];

    this.getData();
  }

  async getData() {
    await this.getAgents();
    await Promise.all([this.setInviteInfo(), this.redirectUser()]);
  }

  async getAgents() {
    try {
      const res = await this.apiService.get({
        route: 'api/registration/simplified-agent/',
      });

      this.agentOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
    }
  }

  async redirectToDash() {
    await this.apiService.patch({
      route: `user/me/`,
      token: true,
      body: {
        active_register: this.accounts[0].id,
      },
    });

    const { data } = await this.apiService.get<ApiResponse<NewLoginResponse>>({
      route: 'user/me/',
      token: true,
    });

    this.authService.setUser(data);
    this.router.navigateByUrl('/app/dashboard');
  }

  async getAccounts() {
    try {
      const data = await this.apiService.get<RegisterUserResponse[]>({
        route: 'api/v2/registration/account-switch/',
        token: true,
        params: {
          type: 'profile',
        },
      });

      const newData = data.sort((a, b) => b.id - a.id).reverse();
      this.accounts = newData;

      this.hasMultipleAccounts = data.length > 1;
    } catch (error) {
      console.error(error);
    }
  }

  toggleUserOptions() {
    this.userOptionsOpen = !this.userOptionsOpen;
  }

  getUserInitials(string) {
    return string
      .toUpperCase()
      .replace(/[^\w\s]/gi, '')
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('');
  }

  async redirectUser() {
    const { data } = await this.apiService.get({
      route: 'api/registration/trace_step/me/',
      token: true,
    });

    this.signupService.setPersonStep(data.substep);

    this.setBasicInfo();

    if (data.step === 'register') {
      if (this.isShareholder) {
        switch (data.substep) {
          case 'powers':
            this.activeStep = 1;
            break;
          case 'income':
            this.activeStep = 2;
            break;
          case 'declarations':
            this.activeStep = 3;
            break;
          case 'analysis':
            this.activeStep = 4;
            break;
          case 'conditions':
            this.activeStep = 5;
            break;
          case 'tax':
            this.activeStep = 6;
            break;
          case 'attachments':
            this.activeStep = 7;
            break;
          default:
            this.activeSubstep = `complementary${
              data.substep.charAt(0).toUpperCase() + data.substep.slice(1)
            }` as PersonSteps;
            this.signupService.setPersonStep(this.activeSubstep);
            this.activeStep = 0;
            break;
        }
      } else {
        switch (data.substep) {
          case 'powers':
            this.activeStep = 1;
            break;
          case 'attachments':
            this.activeStep = 2;
            break;
          default:
            this.activeSubstep = `complementary${
              data.substep.charAt(0).toUpperCase() + data.substep.slice(1)
            }` as PersonSteps;
            this.signupService.setPersonStep(this.activeSubstep);
            this.activeStep = 0;
            break;
        }
      }
    }

    if (data.step === 'review') {
      this.router.navigateByUrl('/status/pf');
    }

    if (data.step !== 'register' && data.step !== 'review') {
      this.router.navigateByUrl(`/app/dashboard`);
    }

    this.redirected = true;
  }

  async setBasicInfo() {
    try {
      const { data } = await this.apiService.get<ApiResponse<Person>>({
        route: 'api/registration/pf_general/me/',
        token: true,
      });

      if (data.full_name !== null && data.full_name !== '') {
        this.label = this.getRoleLabel(this.role);

        this.name = data.full_name.toUpperCase();
      }
    } catch (error) {
      console.log(error);
    }
  }

  getRoleLabel(role: string) {
    switch (role) {
      case 'assignor':
        return 'Cedente Pessoa Física';

      case 'provider':
        const user = this.authService.getUser();

        let label = '';

        if (user.active_register.register.invite) {
          label = user.active_register.register.invite.data.agent
            .map((item) => {
              return this.agentOptions.find((agent) => agent.value === item)
                .label;
            })
            .join(', ');
        } else {
          label = user.active_register.register.agent
            .map((item) => item.name)
            .join(', ');
        }

        return label;

      default:
        return 'Cotista Pessoa Física';
    }
  }

  async sendApproval() {
    try {
      await this.apiService.post({
        route: 'api/approvals/user_approval/',
        token: true,
      });

      this.toastService.show(
        'info',
        'Sucesso',
        'Solicitação de aprovação enviada com sucesso'
      );

      return true;
    } catch (error) {
      this.toastService.show(
        'error',
        'Erro',
        'Falha ao enviar solicitação de aprovação ao sistema'
      );

      this.activeStep--;

      return false;
    }
  }

  canProceed() {
    if (this.isShareholder) {
      switch (this.activeStep) {
        case 0:
          const step = this.signupService.getPersonStep();
          return this.signupService.getPersonFormStatus(step);

        case 1:
          const powers = this.signupService.getPersonFormStatus('powers');
          return powers;

        case 2:
          const realy = this.signupService.getPersonFormStatus('incomeRealty');
          const others = this.signupService.getPersonFormStatus('incomeOthers');
          const income = this.signupService.getPersonFormStatus('incomeSalary');

          return realy && income && others;

        case 3:
          const declarations =
            this.signupService.getPersonFormStatus('declarations');
          return declarations;

        case 4:
          const analysis = this.signupService.getPersonFormStatus('analysis');
          return analysis;

        case 5:
          const conditions =
            this.signupService.getPersonFormStatus('conditions');
          return conditions;

        case 6:
          const tax = this.signupService.getPersonFormStatus('tax');
          return tax;

        case 7:
          const attachment =
            this.signupService.getPersonFormStatus('attachments');
          return attachment;

        case 8:
          return true;
        default:
          return false;
      }
    } else {
      switch (this.activeStep) {
        case 0:
          const step = this.signupService.getPersonStep();
          return this.signupService.getPersonFormStatus(step);

        case 1:
          const powers = this.signupService.getPersonFormStatus('powers');
          return powers;

        case 2:
          const attachment =
            this.signupService.getPersonFormStatus('attachments');
          return attachment;

        case 3:
          return true;
        default:
          return false;
      }
    }
  }

  getButtonIcon(stepAction: string) {
    if (stepAction === 'next') {
      return this.canProceed()
        ? 'assets/images/icon_button_arrow.svg'
        : 'assets/images/icon_button_block.svg';
    } else {
      return this.activeStep === 0
        ? 'assets/images/icon_button_block.svg'
        : 'assets/images/icon_button_arrow.svg';
    }
  }

  setActiveStep(event: any) {
    this.activeStep = event;
  }

  runBack() {
    if (this.isShareholder) {
      switch (this.activeStep) {
        case 6:
          this.activeStep--;

          this.signupService.updateUserStep('register', 'tax');
          break;

        case 5:
          this.activeStep--;

          this.signupService.updateUserStep('register', 'conditions');
          break;

        case 4:
          this.activeStep--;

          this.signupService.updateUserStep('register', 'analysis');
          break;

        case 3:
          this.activeStep--;

          this.signupService.updateUserStep('register', 'declarations');
          break;

        case 2:
          this.activeStep--;

          this.signupService.updateUserStep('register', 'income');
          break;

        case 1:
          this.activeStep--;

          this.signupService.changePersonStepEvent.emit('representatives');

          this.signupService.setPersonStep('complementaryRepresentatives');
          break;

        case 0:
          switch (this.activeSubstep) {
            case 'complementaryAddress':
              this.signupService.changePersonStepEvent.emit('general');

              this.signupService.setPersonStep('complementaryGeneral');
              break;

            case 'complementaryBank':
              this.signupService.changePersonStepEvent.emit('address');

              this.signupService.setPersonStep('complementaryAddress');
              break;

            case 'complementaryProfessional':
              this.signupService.changePersonStepEvent.emit('bank');

              this.signupService.setPersonStep('complementaryBank');
              break;

            case 'complementaryRepresentatives':
              this.signupService.changePersonStepEvent.emit('professional');

              this.signupService.setPersonStep('complementaryProfessional');
              break;

            default:
              break;
          }

          break;
        default:
          break;
      }
    } else {
      switch (this.activeStep) {
        case 2:
          this.activeStep--;

          this.signupService.updateUserStep('register', 'powers');
          break;

        case 1:
          this.activeStep--;

          this.signupService.changePersonStepEvent.emit('representatives');

          this.signupService.setPersonStep('complementaryRepresentatives');
          break;

        case 0:
          switch (this.activeSubstep) {
            case 'complementaryAddress':
              this.signupService.changePersonStepEvent.emit('general');

              this.signupService.setPersonStep('complementaryGeneral');
              break;

            case 'complementaryBank':
              this.signupService.changePersonStepEvent.emit('address');

              this.signupService.setPersonStep('complementaryAddress');
              break;

            case 'complementaryProfessional':
              this.signupService.changePersonStepEvent.emit('bank');

              this.signupService.setPersonStep('complementaryBank');
              break;

            case 'complementaryRepresentatives':
              this.signupService.changePersonStepEvent.emit('professional');

              this.signupService.setPersonStep('complementaryProfessional');
              break;

            default:
              break;
          }

          break;
        default:
          break;
      }
    }
  }

  async proceed() {
    this.loading = true;
    if (this.canProceed()) {
      if (this.isShareholder) {
        switch (this.activeStep) {
          case 0:
            let step = this.signupService.getPersonStep();

            if (step !== 'complementaryRepresentatives') {
              this.signupService.sendPersonData.emit(step);
              return;
            }

            this.signupService.updateUserStep('register', 'powers');
            this.activeStep++;

            break;

          case 1:
            this.signupService.sendPersonData.emit('powers');
            this.signupService.updateUserStep('register', 'income');
            this.activeStep++;

            break;

          case 2:
            this.signupService.sendPersonData.emit('income');
            this.signupService.updateUserStep('register', 'declarations');
            this.activeStep++;

            break;

          case 3:
            this.signupService.sendPersonData.emit('declarations');
            this.signupService.updateUserStep('register', 'analysis');
            this.activeStep++;

            break;

          case 4:
            this.signupService.sendPersonData.emit('analysis');
            this.signupService.updateUserStep('register', 'conditions');
            this.activeStep++;

            break;

          case 5:
            this.signupService.sendPersonData.emit('conditions');
            this.signupService.updateUserStep('register', 'tax');
            this.activeStep++;

            break;

          case 6:
            this.signupService.sendPersonData.emit('tax');
            this.signupService.updateUserStep('register', 'attachments');
            this.activeStep++;

            break;

          case 7:
            this.activeStep++;

            const approvalDone = await this.sendApproval();
            if (approvalDone) {
              const { data } = await this.apiService.get<ApiResponse<Person>>({
                route: 'api/registration/pf_general/me/',
                token: true,
              });

              this.router.navigateByUrl(`/status/pf`);
            }

            break;

          case 8:
            this.router.navigate([`/app/dashboard`]);
            break;
        }
      } else {
        switch (this.activeStep) {
          case 0:
            let step = this.signupService.getPersonStep();

            if (step !== 'complementaryRepresentatives') {
              this.signupService.sendPersonData.emit(step);
              return;
            }

            this.signupService.updateUserStep('register', 'powers');
            this.activeStep++;

            break;

          case 1:
            this.signupService.sendPersonData.emit('powers');
            this.signupService.updateUserStep('register', 'attachments');
            this.activeStep++;

            break;

          case 2:
            this.activeStep++;

            const approvalDone = await this.sendApproval();
            if (approvalDone) {
              const { data } = await this.apiService.get<ApiResponse<Person>>({
                route: 'api/registration/pf_general/me/',
                token: true,
              });

              this.router.navigateByUrl(`/status/pf`);
            }

            break;

          case 3:
            this.router.navigate([`/app/dashboard`]);
            break;
        }
      }
    }

    this.loading = false;
  }

  setInviteInfo() {
    const user = this.authService.getUser();
    const invite = user.active_register.register.invite;

    if (!invite) return;

    this.label = this.getRoleLabel(this.role);

    this.name = this.name = invite.data.name
      ? invite.data.name.toUpperCase()
      : '';

    this.signupService.basicInfo = {
      name: invite.data.name,
      email: invite.data.email,
      document: invite.data.document,
    };
  }

  getDisabled() {
    return !this.canProceed() || this.loading;
  }

  toggleSupportModal() {
    this.showSupportModal = !this.showSupportModal;
  }

  logoff() {
    this.authService.clearUser();

    this.router.navigate(['/login']);
  }
}
