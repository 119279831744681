import { Component, Input, OnInit } from '@angular/core';
import { MaskPipe } from 'ngx-mask';
import { FundService } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-dashboard-fund-approval-basic',
  templateUrl: './dashboard-fund-approval-basic.component.html',
  styleUrls: ['./dashboard-fund-approval-basic.component.scss'],
})
export class DashboardFundApprovalBasicComponent implements OnInit {
  @Input() isLoading = true;

  constructor(public fundService: FundService, public maskPipe: MaskPipe) {}

  ngOnInit(): void {}

  getChoicesName(object: string, value: any) {
    const choices = this.fundService.choices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice: any) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  formatDate(date: string) {
    return new Date(date).toLocaleDateString();
  }

  formatCnpj(value: string) {
    const newValue = this.maskPipe.transform(value, '00.000.000/0000-00');

    return newValue;
  }
}
