<div class="shareholder" *ngIf="shareholder; else loading">

    <div class="shareholder-card">
        <div class="row w-100">

            <div class="col-12 col-lg-6 d-flex flex-row">
                <img class="shareholder-profile-avatar"
                    src="/assets/images/profile.png">

                <app-spacer [right]="32"></app-spacer>

                <div class="d-flex flex-column">

                    <span
                        class="shareholder-profile-name">{{shareholder.user.first_name}}
                        {{shareholder.user.last_name}}</span>
                    <div class="d-flex flex-row align-items-center">
                        <span
                            class="shareholder-profile-type">{{shareholder.assignor
                            ? 'Cedente' : 'Cotista'}}</span>
                        <app-spacer [right]="16"></app-spacer>

                        <div class="shareholder-profile-separator"></div>
                        <app-spacer [right]="16"></app-spacer>

                        <span class="shareholder-profile-date">Desde
                            {{shareholder.user.created_at |
                            date:'dd/MM/yyyy'}}</span>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-3">
                <div class="d-flex flex-row align-items-center">
                    <i class="shareholder-profile-icon fas fa-envelope"></i>
                    <app-spacer [right]="16"></app-spacer>

                    <span
                        class="shareholder-profile-contact">{{shareholder.user.email}}</span>
                </div>

                <!-- <div class="d-flex flex-row align-items-center">
          <i class="shareholder-profile-icon fas fa-phone"></i>
          <app-spacer [right]="16"></app-spacer>
          <span class="shareholder-profile-contact">+55 (11) 99887-7665</span>
        </div> -->
            </div>


        </div>
    </div>
    <app-spacer [bottom]="24"></app-spacer>


    <app-spacer [bottom]="60"></app-spacer>

    <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)"></app-tabs>
    <app-spacer [bottom]="34"></app-spacer>

    <div [class.d-none]="tabIndex !== 0">
        <div class="row">

            <div class="col-12 col-lg-4">
                <div class="row">
                    <div class="col-12">
                        <div class="dashboard-card">
                            <div class="row">
                                <ng-container
                                    *ngIf="shareholder.user.type === 'PF'">
                                    <div
                                        class="col-12 d-flex flex-row justify-content-between align-items-start">
                                        <div class="d-flex flex-column">
                                            <span
                                                class="dashboard-card-item-value">
                                                {{shareholder.user.birth_date |
                                                date:'dd/MM/yyyy'}}
                                            </span>
                                            <app-spacer
                                                [bottom]="4"></app-spacer>

                                            <span
                                                class="dashboard-card-item-title">
                                                Data de nascimento
                                            </span>
                                            <app-spacer
                                                [bottom]="16"></app-spacer>
                                        </div>

                                        <!-- <span class="dashboard-card-item-profile"
                      [style.background-color]="getPointsColor(shareholder.investor.score)">{{getPointsName(shareholder.investor.score)}}</span> -->
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{getChoicesName('gender_choices',
                                            shareholder.general.gender)?.label}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Gênero
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{getChoicesName('marital_choices',
                                            shareholder.general.marital_status)?.label}}

                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Estado civil
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.naturalness}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Naturalidade
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column"
                                        *ngIf="shareholder.user.type === 'PF' && shareholder.general.cpf">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.cpf.number}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            CPF
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column"
                                        *ngFor="let doc of shareholder.general.documents">
                                        <span class="dashboard-card-item-value">
                                            {{doc.number}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-value">
                                            {{doc.dispatching_agency}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>


                                        <span class="dashboard-card-item-title">
                                            {{getChoicesName('document_types',doc.type)?.label}}
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.mother_name}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Nome da mãe do investidor
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.father_name}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Nome do pai do investidor
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>
                                </ng-container>

                                <ng-container
                                    *ngIf="shareholder.user.type === 'PJ'">
                                    <div
                                        class="col-12 d-flex flex-row justify-content-between align-items-start">

                                        <div class="d-flex flex-column">
                                            <span
                                                class="dashboard-card-item-value">
                                                {{shareholder.general.foundation_date
                                                | date: 'dd/MM/yyyy'}}
                                            </span>
                                            <app-spacer
                                                [bottom]="4"></app-spacer>

                                            <span
                                                class="dashboard-card-item-title">
                                                Data de fundação
                                            </span>
                                            <app-spacer
                                                [bottom]="16"></app-spacer>
                                        </div>

                                        <!-- <span class="dashboard-card-item-profile"
                      [style.background-color]="getPointsColor(shareholder.investor.score)">{{getPointsName(shareholder.investor.score)}}</span> -->
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.cnpj.number}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            CNPJ
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.corporate_name}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Razão Social
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{getChoicesName('branch_choices',
                                            shareholder.general.branch)?.label}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Natureza jurídica
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{getChoicesName('frameworks',
                                            shareholder.general.framework)?.label}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Enquadramento
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.municipal_registration}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Inscrição municipal
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.state_registration}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Inscrição estadual
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.nire}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            NIRE
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{getChoicesName('cnaes',
                                            shareholder.general.cnae)?.label}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            CNAE
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            <a [href]="'mailto:'+shareholder.general.contact_email"
                                                target="_blank">
                                                {{shareholder.general.site}}
                                            </a>
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Site
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            <a [href]="'mailto:'+shareholder.general.contact_email"
                                                target="_blank">
                                                {{shareholder.general.contact_email}}
                                            </a>
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            E-mail
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                    <div class="col-12 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{shareholder.general.judicial_recovery
                                            ? 'Sim' : 'Não'}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            Em recuperação judicial
                                        </span>
                                        <app-spacer [bottom]="16"></app-spacer>
                                    </div>

                                </ng-container>

                            </div>


                        </div>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12">
                        <div class="dashboard-card">
                            <div class="row">
                                <div class="col-12">
                                    <span
                                        class="dashboard-card-title">Endereço</span>
                                    <app-spacer [bottom]="24"></app-spacer>
                                </div>

                                <div class="col-12 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{shareholder.address.cep}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        CEP
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>

                                <div class="col-12 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{shareholder.address.public_place}},
                                        {{shareholder.address.number}},
                                        {{shareholder.address.district}}<br>
                                        {{shareholder.address.complement}},
                                        {{shareholder.address.city}} -
                                        {{shareholder.address.uf}}<br>
                                        Brasil
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        ENDEREÇO COMPLETO
                                    </span>
                                    <app-spacer [bottom]="30"></app-spacer>
                                </div>

                                <div class="col-12 d-flex flex-column">
                                    <iframe [src]="shareholder.map_address"
                                        width="100%" height="200"
                                        frameborder="0" style="border:0;"
                                        allowfullscreen></iframe>
                                </div>

                            </div>

                        </div>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

            </div>

            <div class="col-12 col-lg-8">
                <div class="row">

                    <div class="col-12">
                        <div class="dashboard-card">
                            <div class="row">
                                <div class="col-12">
                                    <span class="dashboard-card-title">Contas
                                        bancárias</span>
                                    <app-spacer [bottom]="24"></app-spacer>
                                </div>

                                <div class="col-12">
                                    <div
                                        class="dashboard-card-scroll styled-scroll">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Banco</th>
                                                    <th>Agência</th>
                                                    <th>Conta corrente</th>
                                                    <th>Tipo de conta</th>
                                                    <!-- <th>Identificação de
                                                        co-titular</th> -->
                                                    <th>CPF</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let bank of shareholder.bankinfo">
                                                    <td>{{getChoicesName('banks',
                                                        bank.bank)?.label}}</td>
                                                    <td>{{bank.agency}}</td>
                                                    <td>{{bank.account_number}}
                                                    </td>
                                                    <td>Conta
                                                        {{bank.account_type ===
                                                        '1' ? 'Corrente' :
                                                        'Poupança'}}
                                                    </td>
                                                    <td>{{bank.joint_holders_identification}}
                                                    </td>
                                                    <td>{{bank.cpf}}</td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <div class="col-12"
                        *ngFor="let representative of shareholder.representatives">
                        <div class="dashboard-card">
                            <div class="row">
                                <div class="col-12">
                                    <span
                                        class="dashboard-card-title">Representante</span>
                                    <app-spacer [bottom]="24"></app-spacer>
                                </div>

                                <div class="col-12 col-lg-9 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{representative.cpf_cnpj}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        CPF / CNPJ
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>

                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-badge"
                                        *ngIf="representative.pep">
                                        Declarado PEP
                                    </span>

                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>

                                <div class="col-12 col-lg-6 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{representative.representative.user.first_name}}
                                        {{representative.representative.user.last_name}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        NOME COMPLETO / RAZÃO SOCIAL
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>

                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{representative.birth_date}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        DATA DE NASCIMENTO
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>

                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{getChoicesName('marital_choices',
                                        representative.marital_status)?.label}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        ESTADO CIVIL
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>

                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        Brasil
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        NACIONALIDADE
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>

                                <div class="col-12 col-lg-3 d-flex flex-column"
                                    *ngIf="representative.naturalness">
                                    <span class="dashboard-card-item-value">
                                        {{representative.naturalness}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        NATURALIDADE
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>

                                <ng-container
                                    *ngFor="let doc of representative.documents">

                                    <div
                                        class="col-12 col-lg-3 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{doc.number}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            {{doc.dispatching_agency}}
                                        </span>
                                        <app-spacer [bottom]="18"></app-spacer>
                                    </div>


                                    <div
                                        class="col-12 col-lg-3 d-flex flex-column">
                                        <span class="dashboard-card-item-value">
                                            {{doc.expedition_date | date:
                                            'dd/MM/yyyy'}}
                                        </span>
                                        <app-spacer [bottom]="4"></app-spacer>

                                        <span class="dashboard-card-item-title">
                                            DATA EXPEDIÇÃO
                                        </span>
                                        <app-spacer [bottom]="18"></app-spacer>
                                    </div>
                                </ng-container>


                                <div class="col-12 col-lg-3 d-flex flex-column"
                                    *ngFor="let phone of representative.phones; let i = index">
                                    <span class="dashboard-card-item-value">
                                        <a [href]="'tel:' + phone.number">{{phone.number}}
                                            <i class="fas fa-link"></i></a>
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        TELEFONE {{i+1}}
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        <a
                                            [href]="'mailto:' + representative.user.email">{{representative.user.email}}
                                            <i class="fas fa-link"></i></a>
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        E-MAIL
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                                <div class="col-12 col-lg-3 d-flex flex-column"
                                    *ngIf="representative.site">
                                    <span class="dashboard-card-item-value">
                                        <a [href]="representative.site">{{representative.site}}
                                            <i class="fas fa-link"></i></a>
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        SITE
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{getChoicesName('representative_link',
                                        representative.link)?.label}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        VÍNCULO
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{getChoicesName('sign_power_choices',
                                        representative.sign_power)?.label}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        PODER DE ASSINATURA
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{representative.max_sign_value}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        VALOR MÁXIMO PARA ASSINATURA
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{representative.expiration_date | date:
                                        'dd/MM/yyyy'}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        VALIDADE DO MANDATO
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                                <div class="col-12 col-lg-3 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{representative.address.cep}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        CEP
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                                <div class="col-12 col-lg-9 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{representative.address.public_place}},
                                        {{representative.address.number}},
                                        {{representative.address.district}} -
                                        {{representative.address.complement}},
                                        {{representative.address.city}} -
                                        {{representative.address.uf}}

                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        ENDEREÇO COMPLETO
                                    </span>
                                    <app-spacer [bottom]="18"></app-spacer>
                                </div>


                            </div>
                        </div>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>

                    <!-- <div class="col-12 col-lg-6">
            <div class="dashboard-card">
              <div class="dashboard-card-profile">

                <div class="dashboard-card-profile-points"
                  [style.background-color]="getPointsColor(shareholder.investor.score)">
                  <span>
                    {{shareholder.investor.score}}
                  </span>
                </div>
                <app-spacer [right]="32"></app-spacer>

                <div class="d-flex flex-column">
                  <span class="dashboard-card-profile-name">
                    Perfil de investidor
                  </span>
                  <app-spacer [bottom]="12"></app-spacer>

                  <span class="dashboard-card-profile-description">{{getPointsName(shareholder.investor.score)}}</span>
                </div>

              </div>

            </div>
            <app-spacer [bottom]="24"></app-spacer>
          </div> -->

                    <div class="col-12 col-lg-6">
                        <div class="dashboard-card">
                            <div class="row">

                                <div class="col-12 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{shareholder.user.updated_at | date:
                                        'dd/MM/yyyy HH:mm'}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        ATUALIZAÇÃO
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>

                                <div class="col-12 d-flex flex-column">
                                    <span class="dashboard-card-item-value">
                                        {{shareholder.user.created_at | date:
                                        'dd/MM/yyyy HH:mm'}}
                                    </span>
                                    <app-spacer [bottom]="4"></app-spacer>

                                    <span class="dashboard-card-item-title">
                                        CADASTRO
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div [class.d-none]="tabIndex !== 1">
        <div class="row">

            <div class="col-12">

                <div class="dashboard-card">
                    <table>
                        <thead>
                            <tr>
                                <th>Arquivo</th>
                                <th>Documento</th>
                                <th>Observações</th>
                                <th>Primeiro upload</th>
                                <th>Última atualização</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                *ngFor="let attachment of shareholder.attachments">
                                <td>
                                    <div role="button"
                                        (click)="openFile(attachment)">
                                        <i
                                            class="attachments-item-icon far fa-file-image"></i>
                                    </div>
                                </td>
                                <td>
                                    <span
                                        class="attachments-item-file">{{attachment.type.name}}</span>
                                </td>
                                <td>{{attachment.description}}</td>
                                <td>{{attachment.date_created
                                    |date:'dd/MM/yyyy'}}</td>
                                <td>{{attachment.date_updated
                                    |date:'dd/MM/yyyy'}}</td>
                                <td>{{getFileStatus(attachment.situation)}}</td>
                                <td>
                                    <div class="attachments-item-buttons">

                                        <button class="attachments-item-button"
                                            (click)="openFile(attachment)">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </div>

                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>

    </div>

</div>

<ng-template #loading>
    <div
        class="w-100 min-vh-100 d-flex align-items-center justify-content-center">
        <i class="fas fa-spinner fa-spin display-1"></i>
    </div>
</ng-template>