import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-document-visualizer-modal',
  templateUrl: './document-visualizer-modal.component.html',
  styleUrls: ['./document-visualizer-modal.component.scss'],
})
export class DocumentVisualizerModalComponent implements OnInit {
  @Input() active = false;
  @Input() file: any;

  @Output() onClose = new EventEmitter();

  preview: any = '';

  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.preview = this.getFilePreview();
  }

  close() {
    this.onClose.emit();
  }

  getFilePreview() {
    let element = '';

    if (this.file) {
      if (this.verifyIsDoc() || this.verifyIsXls()) {
        element = `<iframe src="https://docs.google.com/gview?url=${this.apiService.url}${this.file.file}&embedded=true" frameborder="1" scrolling="auto"></iframe>`;
      }

      if (this.verifyIsPdf()) {
        window.open(this.apiService.url + this.file.file, '_blank');
      }

      if (this.verifyIsImg()) {
        element = `<img class="w-100" src="${
          this.apiService.url + this.file.file
        }">`;
      }
    }

    return this.sanitizer.bypassSecurityTrustHtml(element);
  }

  verifyIsImg() {
    const extension = this.file.file.split('.').pop();
    return extension === 'png' || extension === 'jpg' || extension === 'jpeg';
  }

  verifyIsPdf() {
    const extension = this.file.file.split('.').pop();
    return extension === 'pdf';
  }

  verifyIsDoc() {
    const extension = this.file.file.split('.').pop();
    return extension === 'doc' || extension === 'docx';
  }

  verifyIsXls() {
    const extension = this.file.file.split('.').pop();
    return extension === 'xls' || extension === 'xlsx';
  }
}
