<div class="app-select-container" [formGroup]="group">
    <label class="app-select-label" *ngIf="label" [for]="controlName">
        <span *ngIf="getRequired()" class="required-field"
            [class.error]="getInvalid()">*</span>
        {{ label }}
    </label>

    <div class="app-select-entry-container" [ngClass]="getClasses()"
        [class.readonly]="!!readOnly" [class.invalid]="getError()">

        <ng-select [closeOnSelect]="!multiple" [formControlName]="controlName"
            [items]="visibleItems" [labelForId]="controlName"
            [placeholder]="placeholder ?? 'Selecione'" [multiple]="multiple"
            [readonly]="!!readOnly"
            [inputAttrs]="{autocomplete:'nope', name: controlName}">
        </ng-select>

        <div *ngIf="getError()" class="app-select-icon error">
            <i class="fas fa-exclamation"></i>
        </div>
    </div>
    <p class="app-select-helper" *ngIf="helpText && !getError()">{{helpText}}
    </p>
    <p class="app-select-error" *ngIf="getError()">{{errorText}}</p>
</div>