import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-shareholder',
  templateUrl: './dashboard-shareholder.component.html',
  styleUrls: ['./dashboard-shareholder.component.scss'],
})
export class DashboardShareholderComponent implements OnInit {
  tabs: Tab[] = [
    { label: 'Dados complementares' },
    { label: 'Rendimentos & patrimônios' },
    { label: 'Anexos & comprovantes' },
  ];

  tabIndex = 0;

  shareholder: any = undefined;

  constructor(
    private route: ActivatedRoute,
    private toast: ToastService,
    private api: ApiService,
    private signupService: SignupService,
    protected sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    try {
      const res = await this.api.get({
        route: 'api/shareholders',
        params: {
          id: this.route.snapshot.params.id,
        },
        token: true,
      });

      const shareholder = { ...res };

      shareholder.map_address = this.getMapAddress(shareholder);

      this.shareholder = shareholder;
    } catch (error) {
      console.error(error);
    }
  }

  onChangeTab(index: number) {
    this.tabIndex = index;
  }

  openFile(attachment: any) {
    const url = this.api.url + attachment.file;
    window.open(url, '_blank');
  }

  getFileStatus(status: any) {
    switch (status) {
      case 1:
        return 'Arquivo Enviado';
      case 2:
        return 'Reprovado';
      case 3:
        return 'Aprovado';
      default:
        return 'Desconhecido';
    }
  }

  calculateTotal(properties: any) {
    let total = 0;

    properties.forEach((property: any) => {
      total += Number(property.current_value);
    });

    return total;
  }

  getPointsName(points: any) {
    if (points >= 9 && points <= 18) {
      return 'Conservador';
    } else if (points >= 19 && points <= 28) {
      return 'Moderado';
    } else if (points > 28) {
      return 'Agressivo';
    } else {
      return 'Insuficiente';
    }
  }

  getPointsColor(points: any) {
    if (points >= 9 && points <= 18) {
      return '#2ecc71';
    } else if (points >= 19 && points <= 28) {
      return '#f1c40f';
    } else if (points > 28) {
      return '#e74c3c';
    } else {
      return '#c0392b';
    }
  }

  getChoicesName(object: string, value: any) {
    const choices = this.signupService.signupChoices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  getMapAddress(approval: any) {
    const fullAddress = encodeURIComponent(
      `${approval.address.public_place}, ${approval.address.number}, ${approval.address.district}, ${approval.address.city} - ${approval.address.uf}`
    );
    const mapUrl = `http://maps.google.com/maps?q=${fullAddress}&z=15&output=embed`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
  }
}
