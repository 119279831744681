import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils from 'src/app/helpers/utils';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MaskPipe } from 'ngx-mask';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-collaborators',
  templateUrl: './dashboard-collaborators.component.html',
  styleUrls: ['./dashboard-collaborators.component.scss'],
})
export class DashboardCollaboratorsComponent implements OnInit {
  contributorsForm = this.formBuilder.group({
    name: [null, [Validators.required]],
    document: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    department: [null, [Validators.required]],
    is_approver: [false, []],
  });

  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
    canDelete: false,
  };

  modal = false;

  collaborators: any[] = [];
  collaborator: any = undefined;

  filteredData = this.collaborators;

  perPage = 20;
  currentPage = 1;
  pages = [1];

  departments: any[] = [];

  departmentOptions: SelectItem[] = [];

  fetchedRegister = false;
  foundedRegister = false;

  searchingRegister = false;

  loading = true;

  firstOpen = true;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private maskPipe: MaskPipe,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.authService.verifyPermission(['de_can_view_collaborator'])) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.getData();
    this.setPermissions();

    this.contributorsForm.controls.document.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((values: any) => {
        if (this.collaborator && this.firstOpen) {
          this.firstOpen = false;
          this.fetchedRegister = true;
          return;
        } else {
          const formatted = Utils.onlyNumbers(values);

          if (
            formatted.length === 11 &&
            this.collaborator &&
            formatted === this.collaborator.username
          ) {
            this.fetchedRegister = true;
            this.foundedRegister = false;
            this.setValues();
            return;
          }

          this.handleDocumentBlur();
        }
      });
  }

  async handleDocumentBlur() {
    const { document } = this.contributorsForm.value;
    const formatted = Utils.onlyNumbers(document);

    if (formatted.length === 11) {
      this.fetchRegister();
    }
  }

  renderTitle() {
    return this.collaborator
      ? `Colaborador(a) ${this.collaborator.first_name}`
      : ' Novo colaborador';
  }

  renderSubtitle() {
    return this.collaborator
      ? `Atualize os dados de ${
          this.collaborator.first_name + ' ' + this.collaborator.last_name
        }`
      : 'Adicione um novo colaborador ao sistema';
  }

  async fetchRegister() {
    this.fetchedRegister = false;
    this.searchingRegister = true;
    try {
      const { document } = this.contributorsForm.value;

      const documentNumber = Utils.onlyNumbers(document);

      const { exists } = await this.api.post<ValidateFieldResponse>({
        route: 'validate-username-exists/',

        body: { username: documentNumber },
      });

      this.foundedRegister = exists;

      if (exists) {
        this.contributorsForm.controls.name.setValidators([]);
        this.contributorsForm.controls.email.setValidators([]);

        this.toast.show(
          'info',
          'Atenção',
          'Cadastro já existente, preencha os dados abaixo para atualizar'
        );
      } else {
        if (!this.collaborator) {
          this.resetFields();
        }

        this.contributorsForm.controls.name.setValidators([
          Validators.required,
        ]);
        this.contributorsForm.controls.email.setValidators([
          Validators.required,
          Validators.email,
        ]);
      }

      this.contributorsForm.controls.name.updateValueAndValidity();
      this.contributorsForm.controls.email.updateValueAndValidity();

      this.fetchedRegister = true;
    } catch (error) {
      this.fetchedRegister = true;
    }

    this.searchingRegister = false;
  }

  resetFields() {
    this.contributorsForm.controls.name.setValue(null);
    this.contributorsForm.controls.email.setValue(null);
    this.contributorsForm.controls.department.setValue(null);
    this.contributorsForm.controls.is_approver.setValue(false);
  }

  async getData() {
    await Promise.all([this.getContributors(), this.getDepartments()]);
  }

  async getContributors() {
    try {
      const { data } = await this.api.get({
        route: 'api/collaborator/',
        token: true,
      });

      this.collaborators = data;
      this.loading = false;
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao recuperar colaboradores');
    }
  }

  async getDepartments() {
    try {
      const res = await this.api.get({
        route: 'api/departments',
        token: true,
      });

      this.departments = res;

      const options = this.departments.map((_department) => ({
        label: _department.name,
        value: _department.id,
      }));

      this.departmentOptions = options;
    } catch (error) {
      this.toast.show('error', 'Erro!', error.error.detail);
    }
  }

  formatDepartament(id: number) {
    const label = this.departmentOptions.find(
      (item) => Number(item.value) === id
    )?.label;

    return label ?? '';
  }

  formToggle(status: boolean) {
    this.modal = status;
  }

  closeModal() {
    this.contributorsForm.reset();
    this.modal = false;
    this.collaborator = undefined;
    this.firstOpen = true;
    this.fetchedRegister = false;
  }

  setCollaborator(collaborator: any) {
    this.collaborator = collaborator;
    this.fetchedRegister = true;
    this.foundedRegister = false;
    this.setValues();
    this.formToggle(true);
  }

  setValues() {
    const contributor = this.collaborator;
    this.contributorsForm.patchValue({
      name: `${contributor.first_name} ${contributor.last_name}`,
      email: contributor.email,
      document: this.maskPipe.transform(contributor.username, '000.000.000-00'),

      department: contributor.department.id,
      is_approver: contributor.is_approver,
    });
  }

  async removeCollaborator(id: number) {
    await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.api
          .delete({
            route: `api/collaborator/${id}/`,
            token: true,
          })
          .then((res) => {
            this.collaborators = this.collaborators.filter(
              (collaborator) => collaborator.id !== id
            );

            Swal.fire({
              title: 'Apagado!',
              text: 'Colaborador apagado com sucesso',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });
          })
          .catch((error) => {
            Swal.fire({
              title: 'Erro!',
              text: 'Erro ao apagar colaborador',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
          });
      },
    });
  }

  async addCollaborator() {
    try {
      const values = this.contributorsForm.value;

      let contribuitorId = this.collaborator ? this.collaborator.id : null;

      let alreadyHaveEmail = null;

      let payload: any = {
        username: Utils.onlyNumbers(values.document),
        first_name: 'Dataengine',
        last_name: 'Collaborator',
        email: 'dataengine@prosperita.io',
        department: values.department,
        is_approver: values.is_approver ?? false,
      };

      const alreadyHaveDocument = this.collaborators.find(
        (item) => item.username === payload.username
      );

      if (!this.foundedRegister) {
        const [first_name, ...last_name] = values.name.split(' ');

        payload = {
          ...payload,
          first_name: first_name,
          last_name: last_name.join(' '),
          email: values.email,
        };

        alreadyHaveEmail = this.collaborators.find(
          (item) => item.email === payload.email
        );

        if (alreadyHaveEmail && !this.collaborator) {
          this.toast.show(
            'error',
            'Erro!',
            `Colaborador com e-mail
               já cadastrado`
          );
          return;
        }
      }

      if (this.foundedRegister) {
        if (alreadyHaveDocument) {
          if (payload.username === this.collaborator?.username) {
            payload = {
              ...payload,
              first_name: values.name,
              last_name: '',
              email: values.email,
            };
          } else {
            contribuitorId = alreadyHaveDocument.id;

            const selectedCollaborator = this.collaborators.find(
              (item) => item.username === payload.username
            );

            payload = {
              username: Utils.onlyNumbers(values.document),
              first_name: selectedCollaborator.first_name,
              last_name: selectedCollaborator.last_name,
              email: selectedCollaborator.email,
              department: values.department,
              is_approver: values.is_approver ?? false,
            };
          }
        }
      }

      const res =
        this.collaborator || (this.foundedRegister && alreadyHaveDocument)
          ? await this.api.put({
              route: `api/collaborator/${contribuitorId}/`,
              token: true,
              body: payload,
            })
          : await this.api.post({
              route: 'api/collaborator/',
              token: true,
              body: payload,
            });

      if (contribuitorId) {
        this.toast.show(
          'info',
          'Sucesso!',
          'Colaborador atualizado com sucesso'
        );
      } else {
        this.toast.show('info', 'Sucesso!', 'Colaborador adicionado');
      }

      this.closeModal();
      this.getData();
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao adicionar colaborador');
    }
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_collaborator`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
