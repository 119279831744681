import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
})
export class CollapseComponent implements OnInit {
  isCollapsed = true;

  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}

  handleChange() {
    this.isCollapsed = !this.isCollapsed;
  }
}
