<div class="row no-gutters">
    <div class="offset-1 col-6">
        <span class="basic-content-title">
            Defina como deseja continuar seu cadastro
        </span>
    </div>
</div>

<app-spacer [bottom]="48"></app-spacer>

<div class="row no-gutters" [formGroup]="form">
    <div class="col-12 offset-lg-1 col-lg-6">
        <app-choices [choices]="profileTypes" formControlName="type">
        </app-choices>
    </div>
</div>