<div class="dash">
    <div class="row">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card no-padding">
                <ng-container *ngIf="!loading">
                    <app-back-button></app-back-button>
                </ng-container>

                <div
                    class=" dash-card-header d-flex align-items-start justify-content-between">

                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            width: '80px',
                            height: '25px'
                          }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <h2>Colaboradores</h2>
                    </ng-container>

                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            width: '180px',
                            height: '40px'
                          }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button (onClick)="formToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i> Adicionar
                            colaborador
                        </app-button>
                    </ng-container>
                </div>

                <div class="dash-card-body padding-container">
                    <ng-container *ngIf="loading">
                        <table #table>
                            <thead>
                                <tr>
                                    <th colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>

                                    <th colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>
                                    <th colspan="1">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                            height: '15px',
                                            width: '100px',
                                        marginBottom:'0'
                                          }">
                                        </ngx-skeleton-loader>
                                    </td>
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '30px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </td>
                                    <td colspan="1">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '60px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <table class="bordered" *ngIf="!loading">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Departamento</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let collaborator of collaborators">
                                <td class="">
                                    <div class="d-flex flex-column">
                                        <span
                                            class="documents-name">{{collaborator.first_name}}
                                            {{collaborator.last_name}}</span>
                                    </div>
                                </td>
                                <td style="width: 500px;">{{collaborator.email}}
                                </td>
                                <td>
                                    {{collaborator.department.name}}
                                </td>
                                <td>
                                    <div class="dash-card-item-buttons">
                                        <ng-container
                                            *ngIf="permissionObj.canDelete">
                                            <button class="dash-item-button"
                                                (click)="removeCollaborator(collaborator.id)">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                            <app-spacer
                                                [right]="16"></app-spacer>
                                        </ng-container>

                                        <button class="dash-item-button"
                                            (click)="setCollaborator(collaborator)"
                                            *ngIf="permissionObj.canChange">
                                            <i class="fas fa-pen"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>

                    <div *ngIf="collaborators.length === 0 && !loading">
                        <span
                            class="dash-card-item-content-title text-center d-block">
                            Nenhum colaborador adicionado
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card-pagination">
                <app-pagination [data]="collaborators" [dashPagination]="true"
                    [show]="!loading">
                </app-pagination>
            </div>
        </div>
    </div>

    <app-side-modal [active]="modal" (onClose)="closeModal()">
        <ng-container *ngIf="modal">
            <div class="row align-items-center" [formGroup]="contributorsForm">
                <div class="col-12">
                    <span
                        class="organization-form-title">{{renderTitle()}}</span>
                    <app-spacer [bottom]="8"></app-spacer>
                </div>

                <div class="col-12">
                    <span
                        class="organization-form-description">{{renderSubtitle()}}</span>
                    <app-spacer [bottom]="48"></app-spacer>
                </div>

                <div class="col-8">
                    <div class="d-flex align-items-center">
                        <app-masked-input label="Documento do colaborador"
                            [group]="contributorsForm" controlName="document"
                            mask="000.000.000-00" placeholder="123.456.789-01">
                        </app-masked-input>
                        <i *ngIf="searchingRegister"
                            class="fas fa-circle-notch fa-spin app-button-icon ml-3 mt-4"></i>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <ng-container *ngIf="fetchedRegister">
                    <ng-container *ngIf="!foundedRegister">
                        <div class="col-12">
                            <app-input label="Nome do novo colaborador"
                                [group]="contributorsForm" controlName="name">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12">
                            <app-input
                                label="Informe o email para receber o convite"
                                [group]="contributorsForm" controlName="email">
                            </app-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                    </ng-container>

                    <div class="col-12 col-lg-6">
                        <app-select [items]="departmentOptions"
                            label="Departamento" placeholder="Selecione o dpto"
                            helpText="Sem abreviações" controlName="department"
                            [group]="contributorsForm">
                        </app-select>
                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                    <div class="col-12">
                        <app-radio [multiple]="true"
                            formControlName="is_approver">
                            <span class="app-radio-text">Delegar poderes de
                                aprovação</span>
                        </app-radio>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-lg-6"></div>

                    <div class="col-12 col-lg-6 text-right">
                        <app-button [disabled]="contributorsForm.invalid"
                            (click)="addCollaborator()">Salvar colaborador
                        </app-button>
                    </div>

                </ng-container>
            </div>
        </ng-container>
    </app-side-modal>