import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { statesOptions } from 'src/app/data/states';
import Utils, { GetHelper, UtilsValidators } from 'src/app/helpers/utils';
import { SignupService } from 'src/app/services/signup/signup.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { distinctUntilChanged } from 'rxjs/operators';

import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import faker from '@faker-js/faker';

type FormFields = {
  [key: string]: [any, ValidatorFn[]];
};

@Component({
  selector: 'app-signup-unified-person-complementary-representatives',
  templateUrl:
    './signup-unified-person-complementary-representatives.component.html',
  styleUrls: [
    './signup-unified-person-complementary-representatives.component.scss',
  ],
})
export class SignupUnifiedPersonComplementaryRepresentativesComponent
  implements OnInit
{
  DDIOptions = DDIOptions;

  user_form_fields: FormFields = {
    birth_date: [null, []],
    marital_status: [null, []],
    document_type: [null, [Validators.required]],
    nationality: ['23', []],
    naturalness: [null, []],
    naturalness_city: [null, []],
    rg: [null, [Validators.required, Validators.pattern('')]],
    issuing_agency: [null, [Validators.required]],
    issuing_uf: [null, [Validators.required]],
    shipping_date: [
      null,
      [Validators.required, Utils.validateMaxMinToday, Utils.validateMinDate],
    ],
    celphone_ddi: ['55', [Validators.required]],
    celphone_number: [null, [Validators.required, UtilsValidators.celular]],

    email: [null, [Validators.required, Validators.email]],

    occupation: [null, []],

    zipcode: [null, [Validators.required, UtilsValidators.cep]],
    public_place: [null, [Validators.required]],
    number: [null, [Validators.required, Validators.max(99999)]],
    complement: [null, []],
    district: [null, [Validators.required]],
    city: [null, [Validators.required]],
    uf: [null, [Validators.required]],
    country: [null, []],

    spouse_name: [null, []],
    spouse_document: [null, []],
    spouse_statute: [null, []],
  };

  representative_form_fields: FormFields = {
    document: [null, [Validators.required, UtilsValidators.cpf]],
    full_name: [null, [Validators.required]],

    indefinite_term: [null, []],
    term_validity: [
      null,
      [Validators.required, Utils.validateMaxMinToday, Utils.validateMinDate],
    ],
    max_term_validity: [
      null,
      [
        Validators.required,
        (control: FormControl) => Utils.validateMaxMinToday(control, false),
        Utils.validateMaxDate,
      ],
    ],

    pep: [false, []],
  };

  form_fields = {
    ...this.user_form_fields,
    ...this.representative_form_fields,
  };

  form = this.formBuilder.group(this.form_fields);

  haveAgent = false;
  showForm = true;

  columns: TableColumn<RepresentativeResponse>[] = [
    {
      name: 'Nome completo',
      cell: (row) => row.full_name,
    },
    {
      name: 'Documento',
      cell: (row) => this.formatCpf(row.document_number),
    },
  ];

  data: RepresentativeResponse[] = [];

  selectedRepresentative: number | null = null;

  zipcodeFetched = false;
  representativeFetched = false;

  addressObj: AddressProps = {
    public_place: '',
    district: '',
    city: '',
    uf: '',
    country: '',
  };

  states = statesOptions;

  today = Utils.todayString();

  tomorrow = Utils.tomorrowString();

  isIndefinite = false;

  isForeign = false;

  addAnotherModal = false;

  loadingContent = true;

  searchingZipcode = false;

  removeModal = false;

  selectedIndex = 0;

  cities_options: SelectItem[] = [];

  loadingRepresentative = false;

  foundedRegister: Partial<Register> | null = null;
  foundSpouse = false;

  sending = false;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private utilsService: UtilsService,
    private maskPipe: MaskPipe,
    private toastService: ToastService,
    private api: ApiService,
    private toast: ToastService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getDataAndFillForm();

    document.onkeyup = (event) => {
      event.preventDefault();
      if (event.key === 'Enter' && this.form.valid) {
        this.triggerForm();
      }
    };

    this.form.controls.naturalness.valueChanges.subscribe((naturalness) => {
      if (naturalness !== null && naturalness !== '') {
        this.getCities(naturalness);
      }

      this.form.controls.naturalness_city.reset();
      this.form.controls.naturalness_city.updateValueAndValidity();
    });

    this.form.controls.spouse_document.valueChanges.subscribe(async (value) => {
      const document = this.form.controls.document.value;

      if (value && value.length === 14) {
        if (value === document) {
          this.form.controls.spouse_document.setErrors({ invalid: true });
          return;
        } else {
          this.form.controls.spouse_document.setErrors(null);
        }

        const { name, foundPerson } = await this.api.getPersonName(value);

        this.foundSpouse = foundPerson;

        if (name) this.form.controls.spouse_name.setValue(name);
      }
    });

    // this.form.controls.term_validity.valueChanges
    //   .pipe(distinctUntilChanged((a: any, b: any) => a === b))
    //   .subscribe((value) => {
    //     if (!value) {
    //       this.form.controls.term_validity.setValidators([]);
    //     } else {
    //       this.form.controls.term_validity.setValidators([
    //         Validators.required,
    //         Utils.validateMaxMinToday,
    //         Utils.validateMinDate,
    //       ]);
    //     }
    //     this.form.controls.term_validity.updateValueAndValidity();
    //   });

    // this.form.controls.max_term_validity.valueChanges
    //   .pipe(distinctUntilChanged((a: any, b: any) => a === b))
    //   .subscribe((value) => {
    //     if (!value) {
    //       this.form.controls.max_term_validity.setValidators([]);
    //     } else {
    //       this.form.controls.max_term_validity.setValidators([
    //         Validators.required,
    //         (control: FormControl) => Utils.validateMaxMinToday(control, false),
    //         Utils.validateMaxDate,
    //       ]);
    //     }
    //     this.form.controls.max_term_validity.updateValueAndValidity();
    //   });

    this.form.controls.indefinite_term.valueChanges.subscribe((values) => {
      this.isIndefinite = values;

      if (values) {
        this.form.controls.max_term_validity.setValue(null);
        this.form.controls.max_term_validity.setValidators([]);
      } else {
        this.form.controls.max_term_validity.setValidators([
          Validators.required,
          (control: FormControl) => Utils.validateMaxMinToday(control, false),
          Utils.validateMaxDate,
        ]);
      }

      this.form.controls.max_term_validity.updateValueAndValidity();
    });

    this.form.controls.nationality.valueChanges.subscribe(() => {
      if (this.form.controls.nationality.value !== '23') {
        this.form.controls.naturalness.setValue(null);
        this.form.controls.naturalness_city.setValue(null);
        this.form.controls.naturalness.setValidators([]);
        this.form.controls.naturalness_city.setValidators([]);

        this.isForeign = true;
      } else {
        if (
          this.authService.user.active_register.register.role.applicable ===
          'provider'
        ) {
          this.form.controls.naturalness.setValidators([Validators.required]);
          this.form.controls.naturalness_city.setValidators([
            Validators.required,
          ]);
        }

        this.isForeign = false;
      }

      this.form.controls.naturalness.updateValueAndValidity();
      this.form.controls.naturalness_city.updateValueAndValidity();
    });

    this.form.controls.marital_status.valueChanges.subscribe(() =>
      this.setMarried()
    );

    this.form.controls.birth_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value) {
          this.form.controls.birth_date.setValidators([
            Validators.required,
            Utils.validateMinDate,
            Utils.validateLegalAge,
          ]);
        } else {
          this.form.controls.birth_date.setValidators([]);
        }

        this.form.controls.birth_date.updateValueAndValidity();

        this.form.controls.shipping_date.setValidators([
          Validators.required,
          Utils.validateMaxMinToday,
          Utils.validateMinDate,
          (control: FormControl) => {
            if (!control.value || !value) return null;

            const birthDateArr = value.split('/');

            const birthDateDay = birthDateArr[0];
            const birthDateMonth = birthDateArr[1];
            const birthDateYear = birthDateArr[2];

            const birthDate = new Date(
              birthDateYear,
              birthDateMonth - 1,
              birthDateDay
            );

            const shipping_dateArr = control.value.split('/');

            const shippingDateDay = shipping_dateArr[0];
            const shippingDateMonth = shipping_dateArr[1];
            const shippingDateYear = shipping_dateArr[2];

            const shippingDate = new Date(
              shippingDateYear,
              shippingDateMonth - 1,
              shippingDateDay
            );

            if (shippingDate < birthDate) {
              return { minDate: true };
            }

            return null;
          },
        ]);

        this.form.controls.shipping_date.updateValueAndValidity();
      });

    this.form.controls.shipping_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe(() => {
        const value = this.form.controls.birth_date.value;

        this.form.controls.shipping_date.setValidators([
          Validators.required,
          Utils.validateMaxMinToday,
          Utils.validateMinDate,
          (control: FormControl) => {
            if (!control.value || !value) return null;

            const birthDateArr = value.split('/');

            const birthDateDay = birthDateArr[0];
            const birthDateMonth = birthDateArr[1];
            const birthDateYear = birthDateArr[2];

            const birthDate = new Date(
              birthDateYear,
              birthDateMonth - 1,
              birthDateDay
            );

            const shipping_dateArr = control.value.split('/');

            const shippingDateDay = shipping_dateArr[0];
            const shippingDateMonth = shipping_dateArr[1];
            const shippingDateYear = shipping_dateArr[2];

            const shippingDate = new Date(
              shippingDateYear,
              shippingDateMonth - 1,
              shippingDateDay
            );

            if (shippingDate < birthDate) {
              return { minDate: true };
            }

            return null;
          },
        ]);

        this.form.controls.shipping_date.updateValueAndValidity();
      });

    this.signupService.choicesFetchedEvent.subscribe(() => this.setMarried());

    this.signupService.fillFormEvent.subscribe(() => {
      this.haveAgent = true;

      const zipcode = fakerbr.address.zipCodeValidByState('SP');

      const formatted = this.maskPipe.transform(zipcode, '00000-000');

      const userDocument = this.maskPipe.transform(
        fakerbr.br.cpf(),
        '000.000.000-00'
      );

      const birthdate_date = new Date();
      birthdate_date.setFullYear(birthdate_date.getFullYear() - 25);

      if (this.foundedRegister === null) {
        this.form.patchValue({
          document: userDocument,
          full_name: fakerbr.name.findName(),

          birth_date: birthdate_date.toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          marital_status: String(fakerbr.random.number({ min: 1, max: 6 })),
          document_type: String(fakerbr.random.number({ min: 1, max: 2 })),
          rg: String(fakerbr.random.number({ min: 100000000, max: 999999999 })),

          issuing_agency: 'SSP - Secretaria de Segurança Pública',
          issuing_uf: 'SP',
          nationality: '23',
          naturalness: 1,
          naturalness_city: 4707,

          shipping_date: fakerbr.date.past(10).toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          celphone_ddi: '55',
          celphone_number: this.maskPipe.transform(
            '139' +
              String(fakerbr.random.number({ min: 11111111, max: 99999999 })),
            '(00) 00000-0000'
          ),

          email: fakerbr.internet.email(),
          occupation: fakerbr.name.jobDescriptor(),
          max_term_validity: fakerbr.date
            .future(10)
            .toLocaleDateString('pt-BR', {
              timeZone: 'UTC',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          term_validity: fakerbr.date.past(2).toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          pep: false,

          zipcode: formatted,
          number: fakerbr.random.number({ min: 1, max: 1000 }),
          complement: 'Casa',
          country: '23',

          spouse_name: fakerbr.name.findName(undefined, undefined, 'female'),
          spouse_document: this.maskPipe.transform(
            fakerbr.br.cpf(),
            '000.000.000-00'
          ),
          spouse_statute: '1',
        });

        this.handleSearchZipcode({
          target: { value: formatted },
        });

        this.zipcodeFetched = true;

        this.searchRepresentative(userDocument, true);
      } else {
        this.form.patchValue({
          indefinite_term: false,
          email: fakerbr.internet.email(),
          max_term_validity: fakerbr.date
            .future(10)
            .toLocaleDateString('pt-BR', {
              timeZone: 'UTC',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          term_validity: fakerbr.date.past(2).toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
        });
      }
    });
  }

  setRequiredFields() {
    if (
      this.authService.user.active_register.register.role.applicable ===
      'provider'
    ) {
      this.form.controls.birth_date.setValidators([
        Validators.required,
        Utils.ageValidation,
      ]);
      this.form.controls.occupation.setValidators([Validators.required]);
      this.form.controls.nationality.setValidators([Validators.required]);
      this.form.controls.naturalness.setValidators([Validators.required]);
      this.form.controls.naturalness_city.setValidators([Validators.required]);

      this.form.updateValueAndValidity();
    }
  }

  async getCities(state: number) {
    try {
      const res = await this.api.get({
        route: `api/registration/city`,
        params: { state },
        token: true,
      });

      this.cities_options = res.map((city: { name: string; id: number }) => ({
        label: city.name,
        value: city.id,
      }));

      if (this.selectedRepresentative !== null) {
        const data = this.data[this.selectedRepresentative];

        const sameState =
          data.representative_register.person.naturalness_state.id === state;

        if (sameState) {
          this.form.patchValue({
            naturalness_city: data.naturalness_city
              ? data.naturalness_city.id
              : data.representative_register.person.naturalness_city?.id,
          });
        }
      }

      if (this.foundedRegister !== null && this.foundedRegister.person) {
        this.form.patchValue({
          naturalness_city: this.foundedRegister.person?.naturalness_city?.id,
        });
      }
    } catch (error) {
      console.error(error);
      //   this.toastService.show('error', 'Erro', 'Erro ao recuperar cidades');
    }
  }

  setMarried() {
    if (
      this.form.controls.marital_status.value === '2' ||
      this.form.controls.marital_status.value === '3'
    ) {
      this.form.controls.spouse_name.setValidators([Validators.required]);
      this.form.controls.spouse_document.setValidators([
        Validators.required,
        UtilsValidators.cpf,
        (control: FormControl) => {
          const removeSpecialCharacters = control.value
            ? control.value.replace(/[^0-9]/g, '')
            : '';
          const userDocument = this.authService.user?.documents?.number.replace(
            /[^0-9]/g,
            ''
          );
          return userDocument === removeSpecialCharacters
            ? { invalid: true }
            : null;
        },
      ]);

      if (this.form.controls.spouse_document.value) {
        const document = this.form.controls.document.value;
        const spouseDocument = this.form.controls.spouse_document.value;

        if (spouseDocument && spouseDocument.length === 14) {
          if (spouseDocument === document) {
            this.form.controls.spouse_document.setErrors({ invalid: true });
          } else {
            this.form.controls.spouse_document.setErrors(null);
          }
        }
      }

      this.form.controls.spouse_statute.setValidators([Validators.required]);
    } else {
      this.form.controls.spouse_name.setValidators([]);
      this.form.controls.spouse_document.setValidators([]);
      this.form.controls.spouse_statute.setValidators([]);
    }

    this.form.controls.spouse_name.updateValueAndValidity();
    this.form.controls.spouse_document.updateValueAndValidity();
    this.form.controls.spouse_statute.updateValueAndValidity();
  }

  handleEditData(index: number) {
    const data = this.data[index];

    const data_person = data.representative_register.person;

    const data_address =
      data.representative_register.address ?? data.info.register.address;

    const secondary_document =
      data_person.secondary_document ??
      data.info.register.person.secondary_document;

    const phone =
      data.representative_register.person.phone ??
      data.info.register.person.cellphone;

    this.setUserFieldsValidation(false);
    this.foundedRegister = {
      id: data.representative_register.id,
    };

    this.selectedRepresentative = index;
    this.addAnotherModal = true;
    this.signupService.setPersonFormStatus(
      'complementaryRepresentatives',
      false
    );
    this.zipcodeFetched = true;
    this.representativeFetched = true;

    this.form.patchValue({
      ...data_person,
      ...data_address,
      country: String(data_address.country),
      birth_date: this.renderBirthDate(data),
      full_name: data.full_name ?? data_person.full_name,
      document: this.maskPipe.transform(data.document_number, '000.000.000-00'),
      pep: data.pep,

      document_type: data_person.secondary_document
        ? String(data_person.secondary_document?.type?.id)
        : data.info.register.person.secondary_document?.type,

      email: data_person.email,
      term_validity: data.min_term_validity.split('-').reverse().join('/'),
      max_term_validity: data.max_term_validity?.split('-').reverse().join('/'),

      indefinite_term: data.max_term_validity ? false : true,
      marital_status: data_person.marital_status
        ? String(data_person.marital_status)
        : null,

      nationality: data_person.naturalness,
      naturalness: data_person.naturalness_state?.id ?? null,

      rg: secondary_document?.number,
      issuing_agency: secondary_document?.dispatching_agency,
      issuing_uf: secondary_document?.uf,
      shipping_date: secondary_document?.expedition_date
        .split('-')
        .reverse()
        .join('/'),
      celphone_ddi: String(phone.code),
      celphone_number: this.maskPipe.transform(
        '139' + phone.number,
        '(00) 00000-0000'
      ),

      zipcode: data_address.cep,
    });

    if (data_address && data_address.cep) {
      this.handleSearchZipcode({
        target: { value: data_address.cep },
      });
    }

    if (
      String(data_person.marital_status) === '2' ||
      String(data_person.marital_status) === '3'
    ) {
      this.form.patchValue({
        spouse_name: data_person.spouse.name,
        spouse_document: this.maskPipe.transform(
          data_person.spouse.document,
          '000.000.000-00'
        ),
        spouse_statute: String(data_person.spouse.marriage_bond),
      });
    }
  }

  renderBirthDate(data: RepresentativeResponse) {
    if (data.birth_date) {
      return data.birth_date.split('-').reverse().join('/');
    }

    const birth_date = data.representative_register.person.birth_date;

    return birth_date ? birth_date.split('-').reverse().join('/') : null;
  }

  handleRemoveData(index: number) {
    this.removeModal = true;
    this.selectedIndex = index;
  }

  handleRemoveCancel() {
    this.removeModal = false;
    this.selectedIndex = 0;
  }

  async removeData(index: number) {
    try {
      const findedRepresentative = this.data[index];

      await this.api.delete<ApiResponse<null>>({
        route: `api/registration/representative/${findedRepresentative.id}/`,
        token: true,
      });

      this.handleRemoveCancel();

      this.getDataAndFillForm();
    } catch (error) {
      this.toastService.show('error', 'Erro', error.error.message);
    }
  }

  getDisabled() {
    if (this.addAnotherModal) {
      return this.form.invalid;
    }

    if (this.showForm) {
      return this.form.invalid;
    }

    return false;
  }

  async triggerForm() {
    if (this.showForm) {
      await this.sendData();
    } else {
      if (!this.addAnotherModal) {
        this.addAnotherModal = true;
        this.signupService.setPersonFormStatus(
          'complementaryRepresentatives',
          false
        );
      } else {
        await this.sendData();
      }
    }
  }

  setUserFieldsValidation(required: boolean) {
    // Object.entries(this.user_form_fields).forEach(
    //   ([key, [value, validator]]) => {
    //     if (required) {
    //       this.form.get(key).setValidators(validator);
    // this.form.get(key).setValue(value);
    // } else {
    //   this.form.get(key).setValidators([]);
    //   this.form.get(key).setValue(null);
    // }

    // update value and validity
    //     this.form.get(key).updateValueAndValidity();
    //   }
    // );
    if (required) {
      this.setRequiredFields();
    }
  }

  async searchRepresentative(event: any, simplified: boolean = false) {
    if (this.selectedRepresentative !== null) return;

    this.loadingRepresentative = true;

    const document = simplified
      ? Utils.onlyNumbers(event)
      : Utils.onlyNumbers(event.target.value);

    if (document.length === 11) {
      try {
        if (this.data.some((item) => item.document_number === document)) {
          this.toast.show('error', 'Atenção', 'Representante já adicionado');
          throw new Error('Representante já adicionado');
        }

        const { data } = await this.api.get<ApiResponse<Register | null>>({
          route: `api/registration/search/representative`,
          params: { query: document },
          token: true,
        });

        if (data) {
          this.foundedRegister = data;
          this.toast.show(
            'info',
            'Atenção',
            'Representante já cadastrado, cadastre os dados abaixo para prosseguir'
          );

          const person = data.person;

          delete person.document;

          this.form.patchValue({
            ...person,
            ...data.address,
            birth_date: person.birth_date
              ? person.birth_date.split('-').reverse().join('/')
              : '',
            marital_status: person.marital_status
              ? String(person.marital_status)
              : null,
            country: data.address ? String(data.address.country) : '',

            full_name: data.person.full_name,
            document_type: person.secondary_document
              ? String(person?.secondary_document?.type?.id)
              : null,
            nationality: data.person.naturalness,
            naturalness: data.person.naturalness_state?.id ?? '',
            rg: data.person.secondary_document?.number ?? '',
            issuing_agency:
              data.person.secondary_document?.dispatching_agency ?? null,
            issuing_uf: data.person.secondary_document?.uf ?? null,
            shipping_date: data.person.secondary_document
              ? data.person.secondary_document?.expedition_date
                  .split('-')
                  .reverse()
                  .join('/')
              : '',
            celphone_ddi: data.person.cellphone
              ? String(data.person.cellphone?.code)
              : null,
            celphone_number: data.person.cellphone
              ? this.maskPipe.transform(
                  '139' + data.person.cellphone.number,
                  '(00) 00000-0000'
                )
              : '',
            zipcode: data.address?.cep ?? '',
            spouse_name: data.person.spouse?.name ?? '',
            spouse_document: data.person.spouse
              ? this.maskPipe.transform(
                  data.person.spouse.document,
                  '000.000.000-00'
                )
              : '',
            spouse_statute: data.person.spouse?.marriage_bond ?? null,
            pep: data.person.pep_relationship,
          });

          this.zipcodeFetched = data.address !== null;

          this.setUserFieldsValidation(false);
        } else {
          this.foundedRegister = null;
          this.form.patchValue({
            nationality: '23',
            celphone_ddi: '55',
          });

          this.setUserFieldsValidation(true);
        }

        this.representativeFetched = true;
      } catch (error) {
        this.representativeFetched = false;
        console.error(error);
      }
    } else {
      this.form.reset();
      this.form.patchValue({
        document: simplified ? event : event.target.value,
      });
      this.representativeFetched = false;
    }
    this.loadingRepresentative = false;
  }

  async handleSearchZipcode(event: any) {
    this.searchingZipcode = true;
    const zipcode = event.target.value;

    var obj = {
      public_place: '',
      district: '',
      city: '',
      uf: '',
      country: '',
    };

    try {
      const zipcodeRes = await this.utilsService.getZipcode(zipcode);

      if (zipcodeRes) {
        const foundCep = Object.keys(zipcodeRes).some((key) => {
          return key === 'partner_response' ? false : zipcodeRes[key] !== '';
        });

        if (foundCep) {
          obj = {
            public_place: zipcodeRes.logradouro,
            district: zipcodeRes.bairro,
            city: zipcodeRes.localidade,
            uf: zipcodeRes.uf,
            country: '23',
          };

          this.form.patchValue(obj);

          this.zipcodeFetched = true;
        } else {
          //   Utils.resetZipcodeFields(this.form);
          this.form.updateValueAndValidity();

          this.zipcodeFetched = zipcode.length === 9;
        }
      }
    } catch (error) {
      this.zipcodeFetched = false;
      console.error(error);
    }

    this.addressObj = obj;

    this.searchingZipcode = false;
  }

  getChoices() {
    return this.signupService.choices();
  }

  handleCloseModal() {
    this.addAnotherModal = false;
    this.signupService.setPersonFormStatus(
      'complementaryRepresentatives',
      true
    );
    setTimeout(() => {
      this.form.reset();
      this.selectedRepresentative = null;
      this.foundedRegister = null;
      this.zipcodeFetched = false;
      this.representativeFetched = false;
    }, 500);
  }

  clearForm() {
    this.form.reset();
    this.selectedRepresentative = null;
    this.foundedRegister = null;
    this.zipcodeFetched = false;
    this.representativeFetched = false;
  }
  renderTitle() {
    let title = 'Adicionar representante';

    if (this.selectedRepresentative !== null) {
      title = `Editar representante ${
        this.data[this.selectedRepresentative].full_name
      }`;
    }

    return title;
  }

  async getDataAndFillForm() {
    this.loadingContent = true;
    try {
      const { data } = await this.api.get<
        ApiResponse<RepresentativeResponse[]>
      >({
        route: 'api/registration/representative/',
        token: true,
      });

      this.data = data;

      if (this.data.length > 0) {
        this.haveAgent = true;
      }

      this.showForm = !(this.data.length > 0);
      this.representativeFetched = false;

      this.signupService.setPersonFormStatus(
        'complementaryRepresentatives',
        true
      );
    } catch (error) {
      if (error.status !== 404) {
        this.toast.show('error', 'Erro', error.error.message);
      }
    }
    this.loadingContent = false;
  }

  async sendData() {
    this.sending = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        representative: {
          birth_date: valuesHelper.get('birth_date')
            ? valuesHelper.get('birth_date').split('/').reverse().join('-')
            : null,
          document_number: Utils.onlyNumbers(valuesHelper.get('document')),
          full_name: valuesHelper.get('full_name'),
          email: valuesHelper.get('email'),

          can_sign: true,

          expiration_date: valuesHelper.get('max_term_validity')
            ? valuesHelper
                .get('max_term_validity')
                .split('/')
                .reverse()
                .join('-')
            : null,

          pep: valuesHelper.get('pep'),

          max_term_validity: valuesHelper.get('max_term_validity')
            ? valuesHelper
                .get('max_term_validity')
                .split('/')
                .reverse()
                .join('-')
            : null,
          min_term_validity: valuesHelper.get('term_validity')
            ? valuesHelper.get('term_validity').split('/').reverse().join('-')
            : null,
        },
      };

      const names = valuesHelper.get('full_name').split(' ');
      const [first_name, ...last_names] = names;
      const last_name = last_names.join(' ');

      const roles = this.signupService.getRoles();

      const role = roles.find((item) => item.slug === 'REPRESENTATIVE-PF-PF');

      const id = faker.datatype.uuid();

      payload['user'] = {
        username: Utils.onlyNumbers(valuesHelper.get('document')),
        first_name: first_name,
        last_name: last_name,
        type: 'PF',
        email: valuesHelper.get('email'),
        nationality: valuesHelper.get('nationality')
          ? Number(valuesHelper.get('nationality'))
          : null,
        password: id,
        phone: {
          type: 'phone',
          code: Number(valuesHelper.get('celphone_ddi')),
          number: Utils.onlyNumbers(valuesHelper.get('celphone_number')),
          branch: 0,
        },
        documents: {
          type: 'CPF',
          number: Utils.onlyNumbers(valuesHelper.get('document')),
        },
      };

      payload['register'] = {
        type: 'PF',
        role: role.id,
        address: {
          cep: valuesHelper.get('zipcode'),
          public_place: valuesHelper.get('public_place'),
          number: Number(valuesHelper.get('number')),
          complement: valuesHelper.get('complement'),
          district: valuesHelper.get('district'),
          city: valuesHelper.get('city'),
          uf: valuesHelper.get('uf'),
          country: Number(valuesHelper.get('country')),
          type: 1,
          address_type: 'representative',
        },
        person: {
          email: valuesHelper.get('email'),
          full_name: valuesHelper.get('full_name'),
          birth_date: valuesHelper.get('birth_date')
            ? valuesHelper.get('birth_date').split('/').reverse().join('-')
            : null,
          marital_status: valuesHelper.get('marital_status'),
          naturalness: valuesHelper.get('nationality'),
          naturalness_state: valuesHelper.get('naturalness'),
          naturalness_city: valuesHelper.get('naturalness_city'),

          occupation: valuesHelper.get('occupation'),

          cellphone: {
            type: 'phone',
            code: Number(valuesHelper.get('celphone_ddi')),
            number: Utils.onlyNumbers(valuesHelper.get('celphone_number')),
            branch: 0,
          },

          document: {
            type: 'CPF',
            number: Utils.onlyNumbers(valuesHelper.get('document')),
          },
          secondary_document: {
            number: Utils.onlyNumbers(valuesHelper.get('rg')),
            type: valuesHelper.get('document_type'),
            dispatching_agency: valuesHelper.get('issuing_agency'),
            uf: valuesHelper.get('issuing_uf'),
            expedition_date: valuesHelper
              .get('shipping_date')
              .split('/')
              .reverse()
              .join('-'),
          },
        },
      };

      const marital_status = valuesHelper.get('marital_status');

      if (marital_status === '2' || marital_status === '3') {
        payload['register']['person']['spouse'] = {
          name: valuesHelper.get('spouse_name'),
          document: Utils.onlyNumbers(valuesHelper.get('spouse_document')),
          marriage_bond: valuesHelper.get('spouse_statute'),
        };
      }
      if (this.foundedRegister !== null) {
        payload['registered'] = this.foundedRegister.id;
      }

      const response =
        this.selectedRepresentative !== null
          ? await this.api.put<ApiResponse<RepresentativeResponse>>({
              route: `api/registration/representative/${
                this.data[this.selectedRepresentative].id
              }/`,
              token: true,
              body: payload,
            })
          : await this.api.post<ApiResponse<RepresentativeResponse>>({
              route: 'api/registration/representative/',
              token: true,
              body: payload,
            });

      this.toast.show('info', 'Sucesso', response.message);

      this.form.reset();

      this.addAnotherModal = false;
      this.foundedRegister = null;
      this.selectedRepresentative = null;

      this.getDataAndFillForm();
    } catch (error) {
      console.error(error);
      if (error.error.message.includes('username')) {
        this.toast.show('info', 'Erro', 'Esse usuário já está em uso.');
      } else {
        this.toast.show('info', 'Erro', error.error.message);
      }
    }
    this.sending = false;
  }

  formatCpf(document: string) {
    return this.maskPipe.transform(document, '000.000.000-00');
  }

  handleHaveAgent(haveAgent: boolean) {
    if (haveAgent) {
      if (this.data.length === 0) {
        this.signupService.setPersonFormStatus(
          'complementaryRepresentatives',
          false
        );
      } else {
        this.signupService.setPersonFormStatus(
          'complementaryRepresentatives',
          true
        );
      }
    } else {
      this.signupService.setPersonFormStatus(
        'complementaryRepresentatives',
        true
      );
    }

    this.signupService.setPersonFormData('haveAgent', haveAgent);
  }
}
