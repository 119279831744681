<div class="app-input-container" [formGroup]="group">
    <div class="app-input-label-row">
        <label class="app-input-label" *ngIf="label" [for]="controlName">
            <span *ngIf="getRequired()" class="required-field"
                [class.error]="getInvalid()">*</span>
            {{ label }}
        </label>

        <button type="button" tabindex="1000" *ngIf="button !== ''"
            class="app-input-label-link"
            (click)="onButtonClicked($event)">{{button}}</button>
    </div>

    <div class="app-input-entry-container" [ngClass]="getClasses()">
        <ng-container *ngIf="rows ===0 ">
            <ng-container *ngIf="!standalone;else standaloneInput">

                <input *ngIf="!isUpperCase" class="app-input-entry"
                    [class.filled-date]="filledDate()" [type]="type"
                    [class.alwaysValid]="alwaysValid" [id]="controlName"
                    [name]="name ?? controlName" [placeholder]="placeholder"
                    [maxLength]="maxLength" [attr.min]="min" [attr.max]="max"
                    [readOnly]="!!readOnly" [formControlName]="controlName"
                    [attr.pattern]="pattern">

                <input *ngIf="isUpperCase" class="app-input-entry"
                    [class.filled-date]="filledDate()" [type]="type"
                    [id]="controlName" [name]="name ?? controlName"
                    [placeholder]="placeholder" [maxLength]="maxLength"
                    [attr.min]="min" [attr.max]="max" [readOnly]="!!readOnly"
                    [formControlName]="controlName" [attr.pattern]="pattern"
                    appUpperCase>

            </ng-container>


            <ng-template #standaloneInput>
                <input *ngIf="rows === 0" class="app-input-entry"
                    [class.filled-date]="filledDate()" [type]="type"
                    [id]="controlName" [name]="name ?? controlName"
                    [placeholder]="placeholder" [maxLength]="maxLength"
                    [attr.min]="min" [attr.max]="max" [readOnly]="!!readOnly"
                    [attr.pattern]="pattern" [(ngModel)]="value"
                    [ngModelOptions]="{standalone: true}"
                    (change)="handleChange($event)">
            </ng-template>
        </ng-container>

        <textarea class="app-input-entry" [id]="controlName"
            [name]="name ?? controlName" [placeholder]="placeholder"
            [readOnly]="!!readOnly" [formControlName]="controlName"
            *ngIf="rows !== 0" [rows]="rows" [maxLength]="maxLength"
            [class.no-resize]="noResize"></textarea>

        <div *ngIf="isPassword" class="app-input-icon"
            [class.password]="isPassword">
            <button type="button" class="invisible-button"
                (click)="passwordReveal()">
                <i
                    [ngClass]="type === 'password' ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </button>
        </div>

        <div *ngIf=" getError()" class="app-input-icon error"
            [class.mr-4]="isPassword">
            <i class="fas fa-exclamation"></i>
        </div>
    </div>
    <p class="app-input-helper" *ngIf="helpText && !getError()">{{helpText}}
    </p>
    <p class="app-input-error" *ngIf="getError()">{{errorText}}</p>
</div>