import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-attachment-collapse',
  templateUrl: './attachment-collapse.component.html',
  styleUrls: ['./attachment-collapse.component.scss'],
})
export class AttachmentCollapseComponent implements OnInit {
  isCollapsed = true;

  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}
}
