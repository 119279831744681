import { Component, OnInit } from '@angular/core';
import { tick } from '@angular/core/testing';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  type = '';

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.setDashboardRole();
    this.authService.userChange.subscribe(() => {
      this.setDashboardRole();
    });
  }

  async setDashboardRole() {
    if (this.authService.user) {
      const role = this.authService.user.active_register
        ? this.authService.user.active_register.register.role
        : this.authService.user.role;

      if (role) {
        const type = role.applicable;

        this.type = type;
      } else {
        if (this.authService.user.is_staff) {
          this.type = 'admin';
        }
      }
    }
  }
}
