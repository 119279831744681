<button class="app-button" [ngClass]="loading ? 'loading' : ''"
    [disabled]="disabled" [class.full]="full" [class.inverted]="inverted"
    (click)="click($event)" [class.outline]="outline" [class.next]="next"
    [class.bordless]="bordless" [class.danger]="danger"
    [class.phantom]="phantom" [class.reverse]="reverse">
    <div class="app-button-label" [class.center]="center" *ngIf="showContent"
        #content>
        <i class="far fa-times-circle reject" [class.d-none]="!reject"></i>
        <ng-content></ng-content>
    </div>
    <span class="app-button-label" *ngIf="!showContent">{{label}}</span>
    <div *ngIf="loading" class="app-button-loading">
        <i class="fas fa-circle-notch fa-spin app-button-icon"></i>
    </div>
</button>