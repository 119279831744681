import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading = false;
  message = '';

  constructor() {}

  show(message: string) {
    this.loading = true;
    this.message = message;
  }

  hide() {
    this.loading = false;

    setTimeout(() => {
      this.message = '';
    }, 500);
  }

  toggle(message: string) {
    if (this.loading) {
      this.hide();
    } else {
      this.show(message);
    }
  }

  isShown() {
    return this.loading;
  }

  getMessage() {
    return this.message;
  }

  setMessage(message: string) {
    this.message = message;
  }

  clear() {
    this.hide();
    this.message = '';
  }

  showAndWait(message: string) {
    this.show(message);

    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        this.hide();
        resolve();
      }, 2000);
    });
  }
}
