import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GetHelper } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export type PersonAnalysisValues = {
  deposits_and_withdrawals: string;
  future_rescue_need: string;
  id: number;
  investment_assets: string;
  application_purpose: string;
  investment_experience: string;
  monthly_income: string;
  profile: number;
  risk_profile: string;
  total_assets_investments: string;
  twelve_month_investment: string;
  twenty_four_month_application: string;
  score: number;
  disqualification: boolean;
  validity: string;
  start_date: string;
};

export type QuizProps = {
  title: string;
  formControl: string;
  suboptions: {
    label: string;
    value: string;
    score?: number;
  }[];
};

@Component({
  selector: 'app-signup-unified-person-analysis',
  templateUrl: './signup-unified-person-analysis.component.html',
  styleUrls: ['./signup-unified-person-analysis.component.scss'],
})
export class SignupUnifiedPersonAnalysisComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    investment_experience: [null, [Validators.required]],
    risk_profile: [null, [Validators.required]],
    future_rescue_need: [null, [Validators.required]],
    application_purpose: [null, [Validators.required]],
    monthly_income: [null, [Validators.required]],
    investment_assets: [null, [Validators.required]],
    total_assets_investments: [null, [Validators.required]],
    // deposits_and_withdrawals: [null, [Validators.required]],
    twelve_month_investment: [null, [Validators.required]],
    twenty_four_month_application: [null, Validators.required],
  });

  data: PersonAnalysisValues;
  event: any;
  sending = false;
  refuseModal = false;
  showConfirmRefuse = false;
  redirectWarning = false;
  not_declared = false;
  showDisqualificationModal = false;
  funds: any;

  options: QuizProps[] = [
    {
      title:
        'Como você classificaria sua experiência em investimentos com sua formação acadêmica',
      formControl: 'investment_experience',
      suboptions: [
        {
          label:
            'Nula: Não tenho nenhum conhecimento em nenhum tipo de investimento e não tenho formação acadêmica na área financeira',
          value: 'primary',
        },
        {
          label:
            'Limitada: Tenho pouca experiência em investimentos, apesar de não ter formação acadêmica na área financeira',
          value: 'limited',
        },
        {
          label:
            'Moderada: Tenho alguma experiência em investimentos e possuo formação acadêmica na área financeira',
          value: 'moderate',
        },
        {
          label:
            'Extensa: Sou um investidor ativo e experiente, sinto-me confortável em tomar minhas próprias decisões de Investimento',
          value: 'extensive',
        },
      ],
    },
    {
      title:
        'Qual é a sua sensibilidade em relação aos rendimentos de seus investimentos e seu apetite de risco?',
      formControl: 'risk_profile',
      suboptions: [
        {
          label: 'Não pretendo assumir riscos',
          value: 'primary',
        },
        {
          label:
            'Aceito uma rentabilidade menor por um investimento que apresente baixo risco',
          value: 'low',
        },
        {
          label:
            'Aceito um determinado risco por um investimento que possibilite uma rentabilidade maior',
          value: 'average',
        },
        {
          label:
            'Aceito um risco maior por um investimento que possibilite elevada rentabilidade',
          value: 'high',
        },
      ],
    },
    {
      title:
        'Considerando a sua necessidade futura, qual seu horizonte de investimentos, ou seja, em quanto tempo você pretende resgatar seus investimentos?',
      formControl: 'future_rescue_need',
      suboptions: [
        {
          label: 'em até 6 meses',
          value: 'six_months',
        },
        {
          label: 'de 7 a 12 meses (até 1 ano)',
          value: 'one_year',
        },
        {
          label: 'de 13 a 24 meses (até 2 anos)',
          value: 'two_years',
        },
        {
          label: 'acima de 25 meses (acima de 2 anos)',
          value: 'over_two_years',
        },
      ],
    },
    {
      title: 'Qual a finalidade das suas aplicações?',
      formControl: 'application_purpose',

      suboptions: [
        {
          label: 'Consumo',
          value: 'consumption',
        },
        {
          label: 'Proteção do capital contra a inflação',
          value: 'capital_protection',
        },
        {
          label: 'Aposentadoria',
          value: 'retirement',
        },
        {
          label: 'Crescimento do patrimônio',
          value: 'wealth_growth',
        },
      ],
    },
    {
      title:
        'Assinale abaixo em qual alternativa o valor da receita mensal melhor se encaixa',
      formControl: 'monthly_income',
      suboptions: [
        {
          label: 'até R$ 5.000,00',
          value: 'up_to_five_thousand',
        },
        {
          label: 'de R$ 5.001,00 a R$ 10.000,00',
          value: 'up_to_ten_thousand',
        },
        {
          label: 'de R$10.001,00 a R$ 30.000,00',
          value: 'up_to_thirty_thousand',
        },
        {
          label: 'acima de R$ 30.000,00',
          value: 'over_thirty_thousand',
        },
      ],
    },
    {
      title: 'Quais Ativos de investimento abaixo compõem seu patrimônio?',
      formControl: 'investment_assets',
      suboptions: [
        {
          label: 'ainda não tenho investimentos',
          value: 'primary',
        },
        {
          label:
            'Títulos públicos, CDB, poupança, Fundos de investimentos de Renda Fixa e DI, imóveis, móveis (veículos, jóias etc)',
          value: 'low',
        },
        {
          label:
            'Títulos públicos, CDB, poupança, Fundos de investimentos de Renda Fixa e DI, imóveis, ações, Fundos de Investimentos em Ações, Fundos de Previdência, móveis (veículos, jóias etc)',
          value: 'average',
        },
        {
          label:
            'Títulos públicos, CDB, poupança, Fundos de Investimentos em Renda Fixa e DI, Imóveis, Ações, Fundos de Investimentos em Ações, Fundos de Previdência, Derivativos, Fundos de Investimentos Multimercado, Fundos de Investimentos em Renda Fixa de Médio e Alto Risco, , móveis ( veículos, joias, etc..).',
          value: 'high',
        },
      ],
    },
    {
      title:
        'Considerando seus ativos e investimentos financeiros, quais valores compõem o total de seu patrimônio?',
      formControl: 'total_assets_investments',
      suboptions: [
        {
          label: 'Ainda não tenho ativos e investimentos investidos',
          value: 'no_investments',
        },
        {
          label: 'R$ 1.000,00 a R$ 10.000,00',
          value: 'up_to_ten_thousand',
        },
        {
          label: 'R$ 10.000,01 a R$ 50.000,00',
          value: 'up_to_fifty_thousand',
        },
        {
          label: 'acima de R$ 50.000,00',
          value: 'over_fifty_thousand',
        },
      ],
    },
    // {
    //   title:
    //     'Em relação ao planejamento e necessidade de recursos investidos, qual melhor sentença descreve o planejamento com relação a depósitos e retiradas dos recursos nos próximos anos? *',
    //   formControl: 'deposits_and_withdrawals',
    //   suboptions: [
    //     {
    //       label:
    //         'Retirar parcelas de forma regular como auxílio para despesas da instituição',
    //       value: 'regular_withdrawals',
    //     },
    //     {
    //       label: 'Não adicionar nenhum dinheiro, tampouco fazer retiradas',
    //       value: 'no_deposits_or_withdrawals',
    //     },
    //     {
    //       label:
    //         'Adicionar esporadicamente apenas modestas parcelas de novos investimentos',
    //       value: 'sporadic_deposits',
    //     },
    //     {
    //       label:
    //         'Adicionar novas parcelas de investimentos ao portfólio nos próximos anos',
    //       value: 'new_investment_installments',
    //     },
    //   ],
    // },
    {
      title:
        'Quais investimentos a instituição realizou frequentemente nos últimos 12 meses?',
      formControl: 'twelve_month_investment',
      suboptions: [
        {
          label:
            'Trata-se do primeiro investimento, não realizou nenhuma operação',
          value: 'first_investment',
        },
        {
          label: 'Investiu apenas em Poupança',
          value: 'savings',
        },
        {
          label:
            'Investiu em Renda fixa (CDB, fundos multimercado/ renda fixa)',
          value: 'fixed_income',
        },
        {
          label:
            'Investiu em Renda variável (ações, derivativos ou fundos de ações)',
          value: 'variable_income',
        },
      ],
    },
    {
      title:
        'Considerando os últimos 24 meses, quantas aplicações/operações foram realizadas com produtos de investimento?',
      formControl: 'twenty_four_month_application',
      suboptions: [
        {
          label: 'Nunca realizou aplicações/operações',
          value: 'never',
        },
        {
          label: 'Realizou até 5 aplicações/operações',
          value: 'up_to_five',
        },
        {
          label: 'Realizou entre 6 e 15 aplicações/operações',
          value: 'up_to_fifteen',
        },
        {
          label: 'Realizou mais de 16 aplicações/operações',
          value: 'over_fifteen',
        },
      ],
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private api: ApiService,
    private toast: ToastService,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.event && this.event.unsubscribe();
  }

  ngOnInit(): void {
    // this.event = this.signupService.sendPersonData.subscribe((value) => {
    //   if (value === 'analysis') {
    //     this.sendData();
    //   }
    // });

    this.canProceed();

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setPersonFormStatus('analysis', status === 'VALID');
    });
  }

  getPoints() {
    let score = 0;
    let profile_label = '';

    this.options.forEach((option) => {
      const value = this.form.get(option.formControl).value;
      const suboption = option.suboptions.find((sub) => sub.value === value);

      if (suboption) {
        score += suboption.score;
      }
    });

    if (score <= 19) {
      return (profile_label = 'Agressivo');
    }

    if (score <= 27) {
      return (profile_label = 'Moderado');
    }

    return (profile_label = 'Conservador');
  }

  async canProceed() {
    try {
      await this.api.get<ApiResponse<Company>>({
        route: 'api/registration/pf_general/me/',
        token: true,
      });
      this.getData();
      this.getFunds();
    } catch (error) {
      this.redirectWarning = true;
    }
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/fund',
        token: true,
        params: {
          id: 151,
        },
      });

      this.funds = res;
    } catch (error) {
      console.log(error);
    }
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/investor_profile_pf/me/',
        token: true,
      });

      this.form.patchValue({
        ...data,
        investment_experience: data.investment_experience || 'primary',
        risk_profile: data.risk_profile || 'primary',
        investment_assets: data.investment_assets || 'primary',
      });

      this.not_declared = data.not_declared;

      if (data.not_declared) {
        this.form.reset();
      }
      this.signupService.setPersonFormStatus(
        'analysis',
        data.not_declared ? true : this.form.valid
      );

      this.data = data;
    } catch (error) {
      console.log(error);
    }
  }

  checkProfile() {
    const profile = this.getPoints();
    const fundProfile = this.funds.client_service?.profile_display ?? '';

    if (this.isProfileCompatible(profile, fundProfile)) {
      this.sendData();

      return;
    }

    this.showDisqualificationModal = true;
  }

  isProfileCompatible(profile, fundProfile) {
    if (
      (fundProfile === 'Moderado' && profile === 'Conservador') ||
      (fundProfile === 'Agressivo' && profile === 'Conservador') ||
      (fundProfile === 'Agressivo' && profile === 'Moderado')
    ) {
      return false;
    }

    return true;
  }

  async sendData(disqualification: boolean = false) {
    this.sending = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        investment_experience:
          valuesHelper.get('investment_experience') === 'primary'
            ? null
            : valuesHelper.get('investment_experience'),
        risk_profile:
          valuesHelper.get('risk_profile') === 'primary'
            ? null
            : valuesHelper.get('risk_profile'),
        future_rescue_need: valuesHelper.get('future_rescue_need'),
        application_purpose: valuesHelper.get('application_purpose'),
        monthly_income: valuesHelper.get('monthly_income'),
        investment_assets:
          valuesHelper.get('investment_assets') === 'primary'
            ? null
            : valuesHelper.get('investment_assets'),
        total_assets_investments: valuesHelper.get('total_assets_investments'),
        // deposits_and_withdrawals: valuesHelper.get('deposits_and_withdrawals'),
        twelve_month_investment: valuesHelper.get('twelve_month_investment'),
        twenty_four_month_application: valuesHelper.get(
          'twenty_four_month_application'
        ),
        not_declared: false,
        disqualification,
      };

      const res =
        this.data && this.data.id
          ? await this.api.put({
              route: `api/registration/investor_profile_pf/${this.data.id}/`,
              token: true,
              body: payload,
            })
          : await this.api.post({
              route: 'api/registration/investor_profile_pf/',
              token: true,
              body: payload,
            });

      this.showDisqualificationModal = false;

      this.getData();

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  getRiskLabel() {
    let risk = this.data.profile;
    let label = '';

    if (this.not_declared) return 'Não declarado';

    if (this.data && this.data.disqualification) return 'Desenquadrado';

    switch (risk) {
      case 1:
        label = 'Conservador';
        break;
      case 2:
        label = 'Moderado';
        break;
      case 3:
        label = 'Agressivo';
        break;
      default:
        label = 'Conservador';
        break;
    }

    return label;
  }

  toggleRefuseModal(setOpen: boolean) {
    this.refuseModal = setOpen;
    this.showConfirmRefuse = false;
  }

  async refuseQuiz() {
    this.sending = true;
    try {
      const formValues = this.form.value;
      const nullableItems = Object.keys(formValues);

      let payload = {
        not_declared: true,
      };

      nullableItems.forEach((item) => {
        payload[item] = null;
      });

      await this.api.post({
        route: 'api/registration/investor_profile_pf/',
        token: true,
        body: payload,
      });

      this.refuseModal = false;

      this.form.reset();

      this.getData();
      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.log(error);
      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  redirectUser() {
    this.signupService.changePersonStepEvent.emit('general');
  }
}
