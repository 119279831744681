<div class="row no-gutters align-items-center flex-fill" [formGroup]="form">
    <div class="col-12 offset-lg-1 col-lg-10">

        <div class="row">

            <div class="col-12" *ngFor="let option of options">
                <app-radio
                    [isChecked]="form.controls.investor_condition.value === option.value"
                    formControlName="investor_condition" [value]="option.value">
                    <div class="flex-1 d-flex flex-column">
                        <span class="app-radio-text">
                            {{option.label}}
                        </span>
                    </div>
                </app-radio>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <ng-container *ngIf="form.controls.investor_condition.value !== null">
            <div class="row ml-2">
                <div class="col-12">
                    <app-spacer [bottom]="48"></app-spacer>
                </div>

                <div class="col-12">
                    <span class="term-text">Ao assinar este termo, afirmo minha
                        condição de investidor qualificado e
                        declaro
                        possuir
                        conhecimento
                        sobre o mercado financeiro suficiente para que não me
                        sejam aplicáveis um conjunto de proteções
                        legais e
                        regulamentares conferidas aos investidores que não sejam
                        qualificados.<br><br> Como investidor
                        qualificado,
                        atesto ser
                        capaz de entender e ponderar os riscos financeiros
                        relacionados à aplicação de meus recursos em
                        valores
                        mobiliários que só podem ser adquiridos por investidores
                        qualificados.</span>

                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="row">

                <div class="offset-2 col-10">
                    <app-radio [multiple]="true" formControlName="signed">
                        <div class="flex-1 d-flex flex-column">
                            <span class="term-option-title">
                                Por ser expressão da verdade, assino a presente
                            </span>
                            <span class="term-option-description">
                                Ao selecionar este item, você estará assinando
                                este contrato
                            </span>
                        </div>
                    </app-radio>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

            </div>
        </ng-container>

    </div>
</div>