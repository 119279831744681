import UIDefaultTheme from 'src/themes';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'theme',
})
export class ThemePipe implements PipeTransform {
  // Get from UIDefaultTheme object the value of the key passed as parameter in the pipe (theme)
  // Example: {{ 'brand.name' | theme }}

  transform(value: string): any {
    const keys = value.split('.');
    let result = UIDefaultTheme;
    for (const key of keys) {
      result = result[key];
    }
    return result;
  }
}
