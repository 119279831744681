<ng-container *ngIf="loading">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-8">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<form [formGroup]="form" *ngIf="!loading">
    <ng-container *ngIf="showForm">
        <div class="row">
            <div class="col-12 col-lg-4">
                <app-masked-input [group]="form"
                    controlName="company_document_number" label="CNPJ"
                    mask="00.000.000/0000-00" placeholder="99.887.766/5544-33">
                </app-masked-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-input [group]="form" controlName="corporate_name"
                    [isUpperCase]="true" label="Razão Social"
                    placeholder="ACME LTDA">
                </app-input>
                <app-spacer [bottom]="48"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6">
                <app-button *ngIf="data.length === 0" (onClick)="triggerForm()"
                    [loading]="sending" [disabled]="getDisabled()"
                    class="add-button" [outline]="true">
                    <i class="fas fa-plus-circle mr-2"></i>
                    Salvar grupo econômico
                </app-button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!showForm">
        <div class="row">
            <div class="col-12">
                <app-table [columns]="columns" [data]="data"
                    (onRemove)="handleOpenRemoveModal($event)" [haveEdit]="true"
                    (onEdit)="handleEditItem($event)"></app-table>
                <app-spacer [bottom]="32"></app-spacer>
            </div>

        </div>

        <div class="row">
            <div class="col-12 col-lg-6">

                <app-button *ngIf="data.length> 0" (onClick)="triggerForm()"
                    [disabled]="getDisabled()" class="add-button"
                    [outline]="true">
                    <i class="fas fa-plus-circle mr-2"></i>
                    Adicionar outro grupo econômico
                </app-button>

            </div>
        </div>
    </ng-container>


    <app-side-modal [active]="addAnotherModal" [title]="renderTitle()"
        (onClose)="handleCloseAddAnotherModal()" paddingBottom="0">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12">
                    <app-masked-input [group]="form"
                        controlName="company_document_number" label="CNPJ"
                        mask="00.000.000/0000-00" [errorText]="errorText"
                        placeholder="99.887.766/5544-33">
                    </app-masked-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-12">
                    <app-input [group]="form" controlName="corporate_name"
                        [isUpperCase]="true" label="Razão Social"
                        placeholder="ACME LTDA">
                    </app-input>
                    <app-spacer [bottom]="28"></app-spacer>
                </div>
            </div>

            <div class="row button-sticky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="handleCloseAddAnotherModal()"
                        class="add-button" [outline]="true">
                        Cancelar
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="triggerForm()"
                            [loading]="sending" [disabled]="getDisabled()"
                            class="add-button">
                            Salvar grupo econômico
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
    </app-side-modal>

    <app-warning-modal [active]="removeModal" (onClose)="handleRemoveCancel()">
        <h3>Você tem certeza?</h3>
        <app-spacer [bottom]="24"></app-spacer>
        <p>Você não poderá reverter essa ação!</p>
        <app-spacer [bottom]="56"></app-spacer>

        <app-button (onClick)="handleRemoveCancel()" [center]="true"
            class="add-button">
            Cancelar
        </app-button>

        <app-spacer [bottom]="8"></app-spacer>

        <app-button (onClick)="removeEconomic(selectedIndex)" class="add-button"
            [center]="true" [outline]="true">
            Sim, apague!
        </app-button>
    </app-warning-modal>

</form>