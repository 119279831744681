import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns: TableColumn<any>[] = [];

  @Input() removeButton: boolean = false;
  @Input() haveEdit: boolean = false;
  @Input() footerData: any[] = [];

  @Output() onRemove = new EventEmitter<number>();
  @Output() onEdit = new EventEmitter<number>();

  columnsToDisplay: TableColumn<any>[] = [];
  footerColumnsToDisplay: TableColumn<any>[] = [];

  constructor() {}

  ngOnInit(): void {
    const newColumns = [...this.columns];

    const newFooterColumns = [...this.columns];

    newFooterColumns.push({ name: '', cell: () => '' });

    this.columnsToDisplay = newColumns;
    this.footerColumnsToDisplay = newFooterColumns;
  }

  ngAfterViewInit() {}

  onRemoveClicked(index: number) {
    this.onRemove.emit(index);
  }

  onEditClicked(index: number) {
    this.onEdit.emit(index);
  }
}
