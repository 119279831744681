const DDIOptions = [
  { value: '93', label: '+93 - Afeganistão' },
  { value: '27', label: '+27 - África do Sul' },
  { value: '355', label: '+355 - Albânia' },
  { value: '49', label: '+49 - Alemanha' },
  { value: '376', label: '+376 - Andorra' },
  { value: '244', label: '+244 - Angola' },
  { value: '1', label: '+1 - Anguilla' },
  { value: '1', label: '+1 - Antígua e Barbuda' },
  { value: '599', label: '+599 - Antilhas Holandesas' },
  { value: '966', label: '+966 - Arábia Saudita' },
  { value: '213', label: '+213 - Argélia' },
  { value: '54', label: '+54 - Argentina' },
  { value: '374', label: '+374 - Armênia' },
  { value: '297', label: '+297 - Aruba' },
  { value: '247', label: '+247 - Ascensão' },
  { value: '61', label: '+61 - Austrália' },
  { value: '43', label: '+43 - Áustria' },
  { value: '994', label: '+994 - Azerbaijão' },
  { value: '1', label: '+1 - Bahamas' },
  { value: '880', label: '+880 - Bangladesh' },
  { value: '1', label: '+1 - Barbados' },
  { value: '973', label: '+973 - Bahrein' },
  { value: '32', label: '+32 - Bélgica' },
  { value: '501', label: '+501 - Belize' },
  { value: '229', label: '+229 - Benim' },
  { value: '1', label: '+1 - Bermudas' },
  { value: '375', label: '+375 - Bielorrússia' },
  { value: '591', label: '+591 - Bolívia' },
  { value: '387', label: '+387 - Bósnia e Herzegovina' },
  { value: '267', label: '+267 - Botswana' },
  { value: '55', label: '+55 - Brasil' },
  { value: '673', label: '+673 - Brunei' },
  { value: '359', label: '+359 - Bulgária' },
  { value: '226', label: '+226 - Burkina Faso' },
  { value: '257', label: '+257 - Burundi' },
  { value: '975', label: '+975 - Butão' },
  { value: '238', label: '+238 - Cabo Verde' },
  { value: '237', label: '+237 - Camarões' },
  { value: '855', label: '+855 - Camboja' },
  { value: '1', label: '+1 - Canadá' },
  { value: '7', label: '+7 - Cazaquistão' },
  { value: '237', label: '+237 - Chade' },
  { value: '56', label: '+56 - Chile' },
  { value: '86', label: '+86 - República Popular da China' },
  { value: '357', label: '+357 - Chipre' },
  { value: '57', label: '+57 - Colômbia' },
  { value: '269', label: '+269 - Comores' },
  { value: '242', label: '+242 - Congo-Brazzaville' },
  { value: '243', label: '+243 - Congo-Kinshasa' },
  { value: '850', label: '+850 - Coreia do Norte' },
  { value: '82', label: '+82 - Coreia do Sul' },
  { value: '225', label: '+225 - Costa do Marfim' },
  { value: '506', label: '+506 - Costa Rica' },
  { value: '385', label: '+385 - Croácia' },
  { value: '53', label: '+53 - Cuba' },
  { value: '45', label: '+45 - Dinamarca' },
  { value: '253', label: '+253 - Djibuti' },
  { value: '1', label: '+1 - Dominica' },
  { value: '20', label: '+20 - Egipto' },
  { value: '503', label: '+503 - El Salvador' },
  { value: '971', label: '+971 - Emirados Árabes Unidos' },
  { value: '593', label: '+593 - Equador' },
  { value: '291', label: '+291 - Eritreia' },
  { value: '421', label: '+421 - Eslováquia' },
  { value: '386', label: '+386 - Eslovénia' },
  { value: '34', label: '+34 - Espanha' },
  { value: '1', label: '+1 - Estados Unidos' },
  { value: '372', label: '+372 - Estónia' },
  { value: '251', label: '+251 - Etiópia' },
  { value: '679', label: '+679 - Fiji' },
  { value: '63', label: '+63 - Filipinas' },
  { value: '358', label: '+358 - Finlândia' },
  { value: '33', label: '+33 - França' },
  { value: '241', label: '+241 - Gabão' },
  { value: '220', label: '+220 - Gâmbia' },
  { value: '233', label: '+233 - Gana' },
  { value: '995', label: '+995 - Geórgia' },
  { value: '350', label: '+350 - Gibraltar' },
  { value: '1', label: '+1 - Granada' },
  { value: '30', label: '+30 - Grécia' },
  { value: '299', label: '+299 - Groenlândia' },
  { value: '590', label: '+590 - Guadalupe' },
  { value: '671', label: '+671 - Guam' },
  { value: '502', label: '+502 - Guatemala' },
  { value: '592', label: '+592 - Guiana' },
  { value: '594', label: '+594 - Guiana Francesa' },
  { value: '224', label: '+224 - Guiné' },
  { value: '245', label: '+245 - Guiné-Bissau' },
  { value: '240', label: '+240 - Guiné Equatorial' },
  { value: '509', label: '+509 - Haiti' },
  { value: '504', label: '+504 - Honduras' },
  { value: '852', label: '+852 - Hong Kong' },
  { value: '36', label: '+36 - Hungria' },
  { value: '967', label: '+967 - Iêmen' },
  { value: '1', label: '+1 - Ilhas Cayman' },
  { value: '672', label: '+672 - Ilha Christmas' },
  { value: '672', label: '+672 - Ilhas Cocos' },
  { value: '682', label: '+682 - Ilhas Cook' },
  { value: '298', label: '+298 - Ilhas Féroe' },
  { value: '672', label: '+672 - Ilha Heard e Ilhas McDonald' },
  { value: '960', label: '+960 - Maldivas' },
  { value: '500', label: '+500 - Ilhas Malvinas' },
  { value: '1', label: '+1 - Ilhas Marianas do Norte' },
  { value: '692', label: '+692 - Ilhas Marshall' },
  { value: '672', label: '+672 - Ilha Norfolk' },
  { value: '677', label: '+677 - Ilhas Salomão' },
  { value: '1', label: '+1 - Ilhas Virgens Americanas' },
  { value: '1', label: '+1 - Ilhas Virgens Britânicas' },
  { value: '91', label: '+91 - Índia' },
  { value: '62', label: '+62 - Indonésia' },
  { value: '98', label: '+98 - Irã' },
  { value: '964', label: '+964 - Iraque' },
  { value: '353', label: '+353 - Irlanda' },
  { value: '354', label: '+354 - Islândia' },
  { value: '972', label: '+972 - <span class="flagicon">&nbsp;</span>Israel' },
  { value: '39', label: '+39 - Itália' },
  { value: '1', label: '+1 - Jamaica' },
  { value: '81', label: '+81 - Japão' },
  { value: '962', label: '+962 - Jordânia' },
  { value: '686', label: '+686 - Kiribati' },
  { value: '383', label: '+383 - Kosovo' },
  { value: '965', label: '+965 - Kuwait' },
  { value: '856', label: '+856 - Laos' },
  { value: '266', label: '+266 - Lesoto' },
  { value: '371', label: '+371 - Letônia' },
  { value: '961', label: '+961 - Líbano' },
  { value: '231', label: '+231 - Libéria' },
  { value: '218', label: '+218 - Líbia' },
  { value: '423', label: '+423 - Liechtenstein' },
  { value: '370', label: '+370 - Lituânia' },
  { value: '352', label: '+352 - Luxemburgo' },
  { value: '853', label: '+853 - Macau' },
  { value: '389', label: '+389 - República da Macedônia' },
  { value: '261', label: '+261 - Madagascar' },
  { value: '60', label: '+60 - Malásia' },
  { value: '265', label: '+265 - Malawi' },
  { value: '223', label: '+223 - Mali' },
  { value: '356', label: '+356 - Malta' },
  { value: '212', label: '+212 - Marrocos' },
  { value: '596', label: '+596 - Martinica' },
  { value: '230', label: '+230 - Maurícia' },
  { value: '222', label: '+222 - Mauritânia' },
  { value: '269', label: '+269 - Mayotte' },
  { value: '52', label: '+52 - México' },
  { value: '691', label: '+691 - Estados Federados da Micronésia' },
  { value: '258', label: '+258 - Moçambique' },
  { value: '373', label: '+373 - Moldávia' },
  { value: '377', label: '+377 - Mônaco' },
  { value: '976', label: '+976 - Mongólia' },
  { value: '382', label: '+382 - Montenegro' },
  { value: '1', label: '+1 - Montserrat' },
  { value: '95', label: '+95 - Myanmar' },
  { value: '264', label: '+264 - Namíbia' },
  { value: '674', label: '+674 - Nauru' },
  { value: '977', label: '+977 - Nepal' },
  { value: '505', label: '+505 - Nicarágua' },
  { value: '227', label: '+227 - Níger' },
  { value: '234', label: '+234 - Nigéria' },
  { value: '683', label: '+683 - Niue' },
  { value: '47', label: '+47 - Noruega' },
  { value: '687', label: '+687 - Nova Caledônia' },
  { value: '64', label: '+64 - Nova Zelândia' },
  { value: '968', label: '+968 - Omã' },
  { value: '31', label: '+31 - Países Baixos' },
  { value: '680', label: '+680 - Palau' },
  { value: '970', label: '+970 - Palestina' },
  { value: '507', label: '+507 - Panamá' },
  { value: '675', label: '+675 - Papua-Nova Guiné' },
  { value: '92', label: '+92 - Paquistão' },
  { value: '595', label: '+595 - Paraguai' },
  { value: '51', label: '+51 - Peru' },
  { value: '689', label: '+689 - Polinésia Francesa' },
  { value: '48', label: '+48 - Polônia' },
  { value: '1', label: '+1 - Porto Rico' },
  { value: '351', label: '+351 - Portugal' },
  { value: '974', label: '+974 - Qatar' },
  { value: '254', label: '+254 - Quênia' },
  { value: '996', label: '+996 - Quirguistão' },
  { value: '44', label: '+44 - Reino Unido' },
  { value: '236', label: '+236 - República Centro-Africana' },
  { value: '1', label: '+1 - República Dominicana' },
  { value: '420', label: '+420 - República Tcheca' },
  { value: '262', label: '+262 - Reunião' },
  { value: '40', label: '+40 - Romênia' },
  { value: '250', label: '+250 - Ruanda' },
  { value: '7', label: '+7 - Rússia' },
  { value: '212', label: '+212 - Saara Ocidental' },
  { value: '685', label: '+685 - Samoa' },
  { value: '1', label: '+1 - Samoa Americana' },
  { value: '290', label: '+290 - Santa Helena (território)' },
  { value: '1', label: '+1 - Santa Lúcia' },
  { value: '1', label: '+1 - São Cristóvão e Nevis' },
  { value: '378', label: '+378 - São Marinho' },
  { value: '508', label: '+508 - Saint-Pierre e Miquelon' },
  { value: '239', label: '+239 - São Tomé e Príncipe' },
  { value: '1', label: '+1 - São Vicente e Granadinas' },
  { value: '248', label: '+248 - Seicheles' },
  { value: '221', label: '+221 - Senegal' },
  { value: '232', label: '+232 - Serra Leoa' },
  { value: '381', label: '+381 - Sérvia' },
  { value: '65', label: '+65 - Singapura' },
  { value: '963', label: '+963 - Síria' },
  { value: '252', label: '+252 - Somália' },
  { value: '94', label: '+94 - Sri Lanka' },
  { value: '268', label: '+268 - Suazilândia' },
  { value: '249', label: '+249 - Sudão' },
  { value: '211', label: '+211 - Sudão do Sul' },
  { value: '46', label: '+46 - Suécia' },
  { value: '41', label: '+41 - Suíça' },
  { value: '597', label: '+597 - Suriname' },
  { value: '992', label: '+992 - Tadjiquistão' },
  { value: '66', label: '+66 - Tailândia' },
  { value: '886', label: '+886 - República da China' },
  { value: '255', label: '+255 - Tanzânia' },
  { value: '246', label: '+246 - Território Britânico do Oceano Índico' },
  { value: '670', label: '+670 - Timor-Leste' },
  { value: '228', label: '+228 - Togo' },
  { value: '690', label: '+690 - Tokelau' },
  { value: '676', label: '+676 - Tonga' },
  { value: '1', label: '+1 - Trinidad e Tobago' },
  { value: '216', label: '+216 - Tunísia' },
  { value: '1', label: '+1 - Turcas e Caicos' },
  { value: '993', label: '+993 - Turquemenistão' },
  { value: '90', label: '+90 - Turquia' },
  { value: '688', label: '+688 - Tuvalu' },
  { value: '380', label: '+380 - Ucrânia' },
  { value: '256', label: '+256 - Uganda' },
  { value: '598', label: '+598 - Uruguai' },
  { value: '998', label: '+998 - Uzbequistão' },
  { value: '678', label: '+678 - Vanuatu' },
  { value: '379', label: '+379 - Vaticano' },
  { value: '58', label: '+58 - Venezuela' },
  { value: '84', label: '+84 - Vietnã' },
  { value: '681', label: '+681 - Wallis e Futuna' },
  { value: '260', label: '+260 - Zâmbia' },
  { value: '263', label: '+263 - Zimbábue' },
];

export default DDIOptions;
