import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MaskPipe } from 'ngx-mask';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-dashboard-representative-approval-basic',
  templateUrl: './dashboard-representative-approval-basic.component.html',
  styleUrls: ['./dashboard-representative-approval-basic.component.scss'],
})
export class DashboardRepresentativeApprovalBasicComponent
  implements OnInit, OnChanges
{
  @Input() approval: Representative = undefined;

  representative: Representative = undefined;

  constructor(
    private signupService: SignupService,
    private maskPipe: MaskPipe
  ) {}

  ngOnChanges(): void {
    this.representative = this.approval;
  }

  ngOnInit(): void {
    this.representative = this.approval;
  }

  getChoicesName(object: string, value: any) {
    const choices = this.signupService.signupChoices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  getNacionality(value: any) {
    const choices = this.signupService.nationalityChocies;

    if (value) {
      return choices.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  formatCpf(value: string) {
    const newValue = this.maskPipe.transform(value, '000.000.000-00');

    return newValue;
  }
}
