<div class="dash-details-card">
    <div class="dash-details-card-body pt-4">
        <div class="row">
            <div class="col-12 col-lg-9">
                <form [formGroup]="form">
                    <div class="row" *ngIf="loading">
                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-8">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>


                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>


                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <app-spacer [top]="40"></app-spacer>
                            <ngx-skeleton-loader [theme]="{
                height: '22px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-lg-4"></div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="51"></app-spacer>
                        </div>

                        <div class="col-12 col-lg-4">
                            <ngx-skeleton-loader [theme]="{
                margin:'26px 0 0',
                height: '44px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="51"></app-spacer>
                        </div>

                        <div class="col-4">
                            <ngx-skeleton-loader [theme]="{
                height: '22px'
            }"></ngx-skeleton-loader>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>

                    <div class="row" *ngIf="!loading">
                        <div class="col-12 col-lg-4">
                            <app-masked-input [group]="form"
                                controlName="document" label="CNPJ"
                                placeholder="12.345.678/0001-90"
                                mask="00.000.000/0000-00">
                            </app-masked-input>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <ng-container *ngIf="canEdit">

                            <div class="col-12 col-lg-4">
                                <app-input label="GIIN" [group]="form"
                                    [maxLength]="25" controlName="giin">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-masked-input [group]="form"
                                    controlName="cbm_constitution_date"
                                    mask="00/00/0000"
                                    label="Data de Constituição"
                                    placeholder="dd/mm/aaaa">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-8">
                                <app-input label="Razão Social" [group]="form"
                                    controlName="name">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>


                            <div class="col-12 col-lg-4">
                                <app-input label="Nome fantasia" [group]="form"
                                    controlName="fantasy_name">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>


                            <div class="col-12 col-lg-4">
                                <app-masked-input [group]="form"
                                    controlName="structuring_fee_date"
                                    mask="00/00/0000"
                                    label="Integralização de cotas"
                                    placeholder="dd/mm/aaaa">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-masked-input [group]="form"
                                    controlName="duration_period"
                                    mask="00/00/0000"
                                    label="Prazo de duração do fundo"
                                    placeholder="dd/mm/aaaa"
                                    [readOnly]="form.controls.indefinite_valid_date.value">
                                    >
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-spacer [top]="40"></app-spacer>
                                <app-switch [group]="form"
                                    controlName="indefinite_valid_date"
                                    label="Prazo indeterminado">
                                </app-switch>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-select
                                    [items]="fundService.choices.cvm_classification_choices"
                                    label="Classificação CVM" [group]="form"
                                    controlName="cvm_classification">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-select
                                    [items]="fundService.choices.anbima_classification_choices"
                                    label="Classificação Anbima" [group]="form"
                                    controlName="anbima_classification">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-lg-4"></div>

                            <div class="col-12 col-lg-4">
                                <app-select
                                    [items]="fundService.choices.investor_profile_choices"
                                    label="Perfil do investidor" [group]="form"
                                    controlName="profile">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-select
                                    [items]="fundService.choices.target_audience_choices"
                                    label="Público Alvo" [group]="form"
                                    controlName="target_audience">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-select
                                    [items]="fundService.choices.fund_social_exercise_choices"
                                    label="Exercício social do fundo"
                                    [group]="form"
                                    controlName="fund_social_exercise">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-select
                                    [items]="fundService.choices.condominium_type_choices"
                                    label="Tipo de Condomínio" [group]="form"
                                    controlName="condominium_type">
                                </app-select>
                                <app-spacer [bottom]="51"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-select
                                    [items]="fundService.choices.financial_assets_choices"
                                    label="Ativos financeiros" [group]="form"
                                    controlName="financial_assets">
                                </app-select>
                                <app-spacer [bottom]="51"></app-spacer>
                            </div>

                            <div class="col-12">
                                <app-switch controlName="transfer"
                                    [group]="form" label="Transferência">
                                </app-switch>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="form.controls.transfer.value">
                        <div class="row">
                            <div class="col-6 col-lg-3">
                                <app-masked-input [group]="form"
                                    controlName="transfer_date"
                                    mask="00/00/0000"
                                    label="Data de transferência"
                                    placeholder="dd/mm/aaaa">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-6 col-lg-3">
                                <app-masked-input [group]="form"
                                    controlName="assembly_date"
                                    mask="00/00/0000" label="Data assembleia"
                                    placeholder="dd/mm/aaaa">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Dados do administrador anterior"></app-label>
                                <app-spacer [bottom]="12"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-masked-input label="CNPJ" [group]="form"
                                    controlName="last_company_document"
                                    mask="00.000.000/0000-00"
                                    placeholder="00.000.000/0000-00">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-8">
                                <app-input label="Razao Social" [group]="form"
                                    controlName="last_company_name">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-input label="Nome do administrador"
                                    [group]="form"
                                    controlName="last_company_contact">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4">
                                <app-input type="email" label="E-mail"
                                    [group]="form"
                                    controlName="last_company_email">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-4"></div>

                            <div class="col-12 col-lg-2">
                                <app-select label="DDI" [group]="form"
                                    controlName="last_company_telephone_ddi"
                                    [items]="DDIOptions" placeholder="55">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-3">
                                <app-masked-input [group]="form"
                                    controlName="last_company_telephone_number"
                                    label="Telefone"
                                    mask="(00) 00000-0000||(00) 0000-0000"
                                    placeholder="Digite">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-2">
                                <app-select label="DDI" [group]="form"
                                    controlName="last_company_cellphone_ddi"
                                    [items]="DDIOptions" placeholder="55">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-3">
                                <app-masked-input [group]="form"
                                    controlName="last_company_cellphone_number"
                                    label="Celular"
                                    mask="(00) 00000-0000||(00) 0000-0000"
                                    placeholder="Digite">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>
                    </ng-container>
                </form>
            </div>
        </div>
    </div>
</div>