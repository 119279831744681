import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-representative-approval',
  templateUrl: './dashboard-representative-approval.component.html',
  styleUrls: ['./dashboard-representative-approval.component.scss'],
})
export class DashboardRepresentativeApprovalComponent implements OnInit {
  tabs: Tab[] = [
    { label: 'Dados básicos', value: 'dados-basicos' },
    { label: 'Vínculos', value: 'vinculos' },
  ];

  tabIndex = 0;
  activedTab = 'dados-basicos';

  representative: Representative = undefined;

  loading = true;

  self_view = false;

  constructor(
    private route: ActivatedRoute,
    private toast: ToastService,
    private api: ApiService,
    protected sanitizer: DomSanitizer,
    public fundService: FundService,
    public router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getData();

    this.route.params.subscribe((params) => {
      this.self_view = this.authService.user.active_register
        ? this.authService.user.active_register.register.id === params.id
        : false;
    });
  }

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;
    this.tabIndex = index;
  }

  async getData() {
    try {
      const { data } = await this.api.get<ApiResponse<any[]>>({
        route: `api/approvals/user_approval/?register=${this.route.snapshot.params.id}`,
        token: true,
      });

      const approval = data[0];

      this.representative = approval.register;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar dados: ' + error);
    }
    this.loading = false;
  }

  getInitials(name: string) {
    return name
      ? name
          .toUpperCase()
          .replace(/[^\w\s]/gi, '')
          .split(' ')
          .slice(0, 2)
          .map((word: string) => word.charAt(0))
          .join('')
      : '-';
  }
}
