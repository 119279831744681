import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type ToastTypes = 'error' | 'warning' | 'info';

type ToastData = {
  visible: boolean;
  type: string;
  title: string;
  message: string;
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  data = new Subject<Partial<ToastData>>();

  timeout: any;

  constructor() {
    this.data.next({
      visible: true,
      type: 'info',
      title: '',
      message: '',
    });
  }

  show(type: ToastTypes, title: string, message: string) {
    this.data.next({
      visible: true,
      type: type,
      title: title,
      message: message,
    });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.hide();
    }, 5000);
  }

  hide() {
    this.data.next({
      ...this.data,
      visible: false,
    });
  }
}
