import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-redirect-user',
  templateUrl: './redirect-user.component.html',
  styleUrls: ['./redirect-user.component.scss'],
})
export class RedirectUserComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {}
}
