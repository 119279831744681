<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <app-empty-content [isVisible]="data.length === 0"
                title="Nenhuma assinatura encontrada"
                description="Não foi encontrada nenhuma assinatura nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <ng-container *ngIf="data.length > 0">
                <ng-container *ngFor="let item of data">
                    <ng-container *ngIf="item.signs && item.signs.length > 0">
                        <div class="col-12 d-flex justify-content-end">
                            <app-button [center]="true" [outline]="true"
                                (onClick)="openDocumentInNewTab(item.file)">
                                Visualizar documento
                            </app-button>
                        </div>

                        <app-table-list [columns]="columns" [data]="item.signs"
                            size="16px"></app-table-list>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="file">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div>{{file.type.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>
    <ng-container class="app-modal-body">
        <ng-container *ngIf="file">
            <img [src]="url" *ngIf="!isPDF && !isXLS" style="width:100%">
            <iframe [src]="url" *ngIf="isPDF || isXLS" frameborder="0"
                height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>
</app-modal>