
const gitInfo = {
  branch: "master",
  commit: "0f03678",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/0f036787ebc74323613252b8602172e87be99a8d",
  date: "12/11/2024",
};

export default gitInfo;
  