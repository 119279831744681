<div class="container">
    <div class="container-label">
        <h3>{{ name }}</h3>
        <p>{{ role }}</p>
    </div>
    <div class="container-button">
        <button (click)="click($event)" *ngIf="canSign">Revisar</button>
        <button [disabled]="disabled" class="resend" (click)="resend($event)" *ngIf="reSend">Reenviar e-mail</button>
        <span *ngIf="sign">
            Assinado
        </span>
    </div>
</div>
