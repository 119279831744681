<div class="app-tabs-container">
  <div class="app-tabs-list">

    <ng-container *ngFor="let tab of tabs; let i= index">
      <div class="app-tabs-item"
        *ngIf="tab"
        [class.dashboard-tab]="dashboardTab"
        [class.active]="i === activedTab"
        [class.disabled]="tab.disabled !== undefined ? tab.disabled : false"
        [class.d-none]="tab.hided"
        (click)="activeTab(i, $event)">
        <span class="app-tabs-item-text">{{tab.label}}</span>
        <span class="app-tabs-item-badge" [class.active]="tab.error"
          [class.d-none]="dashboardTab">!</span>
      </div>
    </ng-container>
    <div class="app-tabs-indicator" [style.width]="width" [style.left]="posX"></div>
  </div>

</div>
