<div class="dashboard h-100">
    <div class="dashboard-card no-padding d-flex flex-column justify-content-between"
        style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <div class="padding-container d-flex flex-column">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            height: '24px',
                            width: '30px',
                            margin:'0'
                          }">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader [theme]="{
                                height: '27px',
                                width: '100px',
                            marginBottom:'30px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <span class="card-info-number">{{agents.length > 9 ? ''
                            :
                            0}}{{agents.length}}</span>
                        <span class="card-info-title">Agentes</span>
                    </ng-container>
                </div>
            </div>

            <div class="col-12 dashboard-card-body">
                <ng-container *ngIf="loading">
                    <table #table>
                        <thead>
                            <tr>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>

                                <th class="text-right">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                <td>
                                    <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '100px',
                                    marginBottom:'0'                            
                                      }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td class="item-number">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '30px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table #table *ngIf="!loading">
                    <thead>
                        <tr>
                            <th>AGENTE</th>
                            <th style="text-align:right;">Vínculos</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let agent of visible_agents">
                            <td>{{agent.name}}</td>
                            <td style="text-align:right;">
                                <ng-container *ngIf="agent.users">
                                    {{agent.users.length}}
                                </ng-container>
                            </td>
                            <td class="pl-0">
                                <div class="table-options">
                                    <app-spacer [right]="19"></app-spacer>
                                    <button (click)="setAgent(agent)"
                                        *ngIf="permissionObj.canChange">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12">
                <div
                    class="padding-container d-flex flex-row justify-content-between">
                    <ng-container *ngIf="loading">
                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                        width: '180px',
                            marginBottom:'0'                            
                              }">
                            </ngx-skeleton-loader>

                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                width: '180px',
                                marginBottom:'0'                            
                                  }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button [inverted]="true"
                            (onClick)="redirect('/app/settings/agents')">Ver
                            todos
                        </app-button>

                        <app-button (click)="addAgentsToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            agente</app-button>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 " *ngIf="agents.length === 0 && !loading">
                <div class="empty-card">
                    <div class="img-container">
                        <img src="/assets/images/collaborator-icon.png"
                            alt="icone">
                    </div>

                    <ng-container *ngIf="!permissionObj.canAdd">
                        <h3>
                            Ainda não existem agentes cadastrados
                        </h3>
                    </ng-container>

                    <ng-container *ngIf="permissionObj.canAdd">
                        <h3>Adicione o primeiro agente</h3>
                        <p>
                            Curabitur maximus ipsum non quam bibendum facilisis.
                            Ut
                            sollicitudin id erat vel
                        </p>

                        <app-button (click)="addAgentsToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            agente
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="addAgents" (onClose)="closeModal()">
    <ng-container *ngIf="addAgents">
        <form class="row" [formGroup]="agentsForm">
            <div class="col-12">
                <span class="card-info-form-title">
                    {{agent ? 'Editar agente' : 'Novo agente'}}
                </span>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <div class="col-12">
                <span class="card-info-form-description">
                    {{agent ? 'Editar agente' : 'Adicione um novo agente ao seu
                    sistema.'}}
                    {{agent ? agent.name : ''}}
                </span>
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Nome" [group]="agentsForm" controlName="name">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-button [disabled]="agentsForm.invalid"
                    (click)="addAgent()">
                    {{agent ? 'Atualizar agente' : 'Salvar novo agente'}}
                </app-button>
            </div>
        </form>
    </ng-container>
</app-side-modal>