import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-unified-requirements',
  templateUrl: './signup-unified-requirements.component.html',
  styleUrls: ['./signup-unified-requirements.component.scss'],
})
export class SignupUnifiedRequirementsComponent implements OnInit {
  @Input() userRole: string;

  requirements = [
    'Você é <b>sócio ou representante legal</b> dela, para assinar o termo;',
    'Ela está com o <b>CNPJ ativo</b>;',
    'Você está com seus documentos em mãos;',
    'Você está com os documentos da sua empresa também',
  ];

  documents = [
    'Cartão CNPJ pra facilitar o cadastro básico;',
    'Número da Inscrição Municipal;',
    'Número da Inscrição Estadual;',
    'Número de Identificação do Registro Empresarial;',
    'Número de funcionários;',
    'Dados dos sócios;',
    'Dados dos representantes, caso haja;',
    'Procuração de representante, caso haja;',
    'Dados bancários;',
    'Contrato Social ou Estatuto Social e Atos Societários;',
    'Comprovante de documento dos sócios/representantes;',
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.userRole !== 'provider') {
      this.documents.push(
        'Comprovante de faturamento dos últimos 12 meses;',
        'Faturamento bruto anual dos últimos 12 meses;'
      );
    }
  }
}
