<app-container>
    <div class="row no-gutters w-100 auth-layout signup-basic-page">
        <aside class="col-12 col-lg-4">
            <div class="basic-sidebar-body">
                <div *ngIf="redirected">
                    <!-- <img [src]="UIDefaultTheme.brand.logoHorizontal"
                        class="sidebar-logo d-none d-lg-flex"> -->

                    <app-spacer [bottom]="56" class="d-none d-lg-block">
                    </app-spacer>

                    <app-stepper [steps]="steps" [activeIndex]="activeStep"
                        [haveNavigation]="true"
                        [activeSubstepSlug]="activeSubstep"
                        (changeActiveStep)="setActiveStep($event)">
                    </app-stepper>
                </div>

                <div *ngIf="!redirected">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                        height: '80px',
                        width: '200px',
                        'border-radius': '10px'
                      }">
                    </ngx-skeleton-loader>

                    <app-spacer [bottom]="56" class="d-none d-lg-block">
                    </app-spacer>

                    <ngx-skeleton-loader count="7"></ngx-skeleton-loader>
                </div>

                <ng-container *ngIf="hasMultipleAccounts">
                    <app-button (onClick)="redirectToDash()" [inverted]="true"
                        [outline]="true" [full]="true" [center]="true">
                        Retornar ao painel
                    </app-button>
                </ng-container>
            </div>

            <div class="basic-sidebar-footer">
                <div class="basic-sidebar-footer-content">
                    <div class="row no-gutters w-100">
                        <div class="col-4 left-side">
                            <ng-container *ngIf="!redirected">
                                <ngx-skeleton-loader [theme]="{
                                    height: '40px',
                                    width: '200px',
                                    marginBottom:'0px'
                                }">
                                </ngx-skeleton-loader>
                            </ng-container>

                            <ng-container *ngIf="redirected">
                                <i class="fal fa-question-circle"
                                    placement="top" ngbTooltip="Ajuda"
                                    tooltipClass="custom-tooltip"
                                    *ngIf="UIDefaultTheme.brand.supportEmail"
                                    (click)="toggleSupportModal()"></i>

                                <span class="basic-sidebar-footer-text"
                                    [class.text-right]="UIDefaultTheme.brand.supportEmail">
                                    Alguns campos são de preenchimento
                                    obrigatório
                                </span>
                            </ng-container>
                        </div>

                        <div class="col-8 right-side"
                            [class.inviteLabels]="label !== null && name !== ''">
                            <ng-container *ngIf="label !== null && name !== ''">
                                <div class="basic-info">
                                    <h3>{{label}}</h3>
                                    <h4>{{name}}</h4>
                                </div>
                            </ng-container>

                            <div class="button-container">
                                <ng-container *ngIf="!redirected">
                                    <ngx-skeleton-loader [theme]="{
                                        height: '40px',
                                        width: '200px',
                                        marginBottom:'0px'
                                    }">
                                    </ngx-skeleton-loader>
                                </ng-container>

                                <ng-container *ngIf="redirected">
                                    <ng-container
                                        *ngIf="activeSubstep !== 'complementaryGeneral'">
                                        <app-button (onClick)="runBack()"
                                            class="mr-3" [inverted]="true"
                                            [fitContent]="true">
                                            Retornar
                                        </app-button>
                                    </ng-container>

                                    <app-button [disabled]="getDisabled()"
                                        (onClick)="proceed()"
                                        [loading]="loading" [next]="true"
                                        [full]="currentTab
                                        === 'Anexos & comprovantes'">
                                        <div
                                            class="basic-siderbar-footer-button">
                                            <span
                                                class="basic-sidebar-footer-button-text"
                                                [class.avaliable]="canProceed() || loading"
                                                [style]="currentTab
                                                === 'Anexos & comprovantes' ? 'margin-right: 16px !important' : ''">{{currentTab
                                                === 'Anexos & comprovantes' ?
                                                'Enviar para aprovação':
                                                'Seguir'}}</span>
                                            <svg-icon
                                                [src]="getButtonIcon('next')"
                                                [class.avaliable]="canProceed() || loading">
                                            </svg-icon>
                                        </div>
                                    </app-button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>

        <main class="col-12 col-lg-8 basic-content">
            <div class="main-header">
                <ng-container *ngIf="!redirected">
                    <ngx-skeleton-loader [theme]="{
                        height: '40px',
                        width: '200px',
                        marginBottom:'0px'
                    }">
                    </ngx-skeleton-loader>
                </ng-container>

                <ng-container *ngIf="redirected">
                    <div class="credentials-container"
                        (click)="toggleUserOptions()">

                        <div class="dash-card-item-initials user-credentials">
                            <span>{{getUserInitials(userCredentials.name)}}</span>
                        </div>

                        <div>
                            <h3>{{userCredentials.name}}</h3>
                            <p>{{userCredentials.email}}</p>
                        </div>
                        <div>
                            <i class="fas fa-caret-down"></i>
                        </div>

                        <div class="credentials-options"
                            [class.active]="userOptionsOpen">
                            <div class="credentials-options-item"
                                (click)="logoff()">
                                Sair
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>


            <ng-container *ngIf="!redirected">
                <div class="row">
                    <div class="offset-1 col-11">
                        <div class="row">
                            <div class="col-12">
                                <ngx-skeleton-loader [theme]="{
                    height: '40px',
                    width: '200px',
                    marginBottom:'0px'
                }">
                                </ngx-skeleton-loader>

                                <app-spacer [bottom]="32"></app-spacer>
                            </div>

                            <div class="col-4">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-8">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>

                            <div class="col-4">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-4">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-4">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-6">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-12">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-6">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-6">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>

                            <div class="col-2">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-4">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>

                            <div class="col-2">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-4">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>

                            <div class="col-6">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-6">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-4">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-4">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="col-12">
                                <ngx-skeleton-loader [theme]="{height: '40px'}">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="redirected">
                <ng-container *ngIf="isShareholder">
                    <div *ngIf="activeStep === 0">
                        <app-signup-unified-person-complementary>
                        </app-signup-unified-person-complementary>
                    </div>

                    <div *ngIf="activeStep === 1">
                        <app-signup-unified-person-powers>
                        </app-signup-unified-person-powers>
                    </div>

                    <div *ngIf="activeStep === 2">
                        <app-signup-unified-person-income>
                        </app-signup-unified-person-income>
                    </div>

                    <div *ngIf="activeStep === 3">
                        <app-signup-unified-person-declarations>
                        </app-signup-unified-person-declarations>
                    </div>

                    <div *ngIf="activeStep === 4">
                        <app-signup-unified-person-analysis>
                        </app-signup-unified-person-analysis>
                    </div>

                    <div *ngIf="activeStep === 5">
                        <app-signup-unified-person-conditions>
                        </app-signup-unified-person-conditions>
                    </div>

                    <div *ngIf="activeStep === 6">
                        <app-signup-unified-person-tax>
                        </app-signup-unified-person-tax>
                    </div>


                    <div *ngIf="activeStep === 7">
                        <app-signup-unified-person-attachments>
                        </app-signup-unified-person-attachments>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isShareholder">
                    <div *ngIf="activeStep === 0">
                        <app-signup-unified-person-complementary>
                        </app-signup-unified-person-complementary>
                    </div>

                    <div *ngIf="activeStep === 1">
                        <app-signup-unified-person-powers>
                        </app-signup-unified-person-powers>
                    </div>

                    <div *ngIf="activeStep === 2">
                        <app-signup-unified-person-attachments>
                        </app-signup-unified-person-attachments>
                    </div>
                </ng-container>
            </ng-container>
        </main>
    </div>
</app-container>

<ng-container *ngIf="UIDefaultTheme.brand.supportEmail">
    <app-warning-modal [active]="showSupportModal" [hasBorder]="true"
        (onClose)="toggleSupportModal()">
        <h2>Precisa de ajuda</h2>
        <app-spacer [bottom]="8"></app-spacer>
        <p>você pode entrar em contato conosco pelos meios abaixo:</p>
        <app-spacer [bottom]="8"></app-spacer>
        <a
            [href]="'mailto:' + UIDefaultTheme.brand.supportEmail">{{UIDefaultTheme.brand.supportEmail}}</a>
    </app-warning-modal>
</ng-container>

<!-- <app-warning-modal [active]="true" [hasBorder]="true"
    (onClose)="toggleSupportModal()">
    <h2>Atenção</h2>
    <app-spacer [bottom]="8"></app-spacer>
    <p>Você está prestes a sair do cadastro.</p>
    <p>Deseja manter os dados do cadastro alterados como "rascunho"?</p>
    <app-spacer [bottom]="56"></app-spacer>
    <app-button [center]="true" [danger]="true">
        Não, descartar alterações
    </app-button>
    <app-spacer [bottom]="8"></app-spacer>
    <app-button [center]="true" class="add-button">
        Sim, manter alterações
    </app-button>
</app-warning-modal> -->