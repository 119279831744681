<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <app-empty-content [isVisible]="data.length === 0"
                title="Nenhum representante encontrado"
                description="Não foi encontrado nenhum representante nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <ng-container *ngIf="data.length > 0">
                <app-table-list [columns]="columns"
                    [data]="data"></app-table-list>
            </ng-container>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-side-modal [active]="representativeDetails"
    [title]="selectedRepresentative?.full_name ?? '-'" (onClose)="closeModal()"
    paddingBottom="30">
    <div class="container" *ngIf="selectedRepresentative">
        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    cpf
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.document_number ?
                    formatCpf(selectedRepresentative.document_number) : '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-badged"
                    *ngIf="selectedRepresentative.pep">
                    Declarado PEP
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Avalista
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.guarantor ?
                    'Sim' : 'Não'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    Devedor solidário
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.solidarity_debtor
                    ? 'Sim' : 'Não'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="!isContribuitor">
                <div class="col-12 d-flex flex-column"
                    *ngIf="selectedRepresentative.representative_register.person.birth_date">
                    <span class="dash-details-card-item-subtitle">
                        DATA DE NASCIMENTO
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.birth_date ?
                        formatDate(selectedRepresentative.birth_date) :
                        formatDate(selectedRepresentative.representative_register.person.birth_date)}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <ng-container
                    *ngIf="selectedRepresentative.representative_register.person.secondary_document">
                    <div div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            TIPO DE DOCUMENTO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{
                            selectedRepresentative.representative_register.person.secondary_document.type.name
                            }}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            DOCUMENTO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{selectedRepresentative.representative_register.person.secondary_document.number}}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            ÓRGÃO EXPEDITOR
                        </span>

                        <span class="dash-details-card-item-title">
                            {{selectedRepresentative.representative_register.person.secondary_document.uf}}/
                            {{selectedRepresentative.representative_register.person.secondary_document.dispatching_agency}}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            DATA DE EXPEDIÇÃO
                        </span>

                        <span class="dash-details-card-item-title">
                            {{selectedRepresentative.representative_register.person.secondary_document.expedition_date
                            |
                            date: 'dd/MM/yyyy'}}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                </ng-container>

                <div class="col-12 d-flex flex-column"
                    *ngIf="selectedRepresentative.representative_register.person.occupation">
                    <span class="dash-details-card-item-subtitle">
                        Ocupação profissional
                    </span>

                    <span class="dash-details-card-item-title">
                        {{
                        selectedRepresentative.representative_register.person.occupation
                        }}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column"
                    *ngIf="selectedRepresentative.representative_register.person.naturalness">
                    <span class="dash-details-card-item-subtitle">
                        NACIONALIDADE
                    </span>

                    <span class="dash-details-card-item-title">
                        {{
                        getNacionality(selectedRepresentative.representative_register.person.naturalness)?.label
                        }}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <ng-container
                    *ngIf="selectedRepresentative.representative_register.person.naturalness_state">
                    <div class="col-12 col-md-6 d-flex flex-column">
                        <span class="dash-details-card-item-subtitle">
                            NATURALIDADE
                        </span>

                        <span class="dash-details-card-item-title">
                            <ng-container
                                *ngIf="selectedRepresentative.representative_register.person.naturalness_city">
                                {{selectedRepresentative.representative_register.person.naturalness_city.name}}
                                /
                            </ng-container>
                            {{selectedRepresentative.representative_register.person.naturalness_state.name}}
                        </span>
                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                </ng-container>

                <div class="col-12 d-flex flex-column"
                    [class.col-md-6]="selectedRepresentative.spouse"
                    *ngIf="selectedRepresentative.representative_register.person.marital_status_display">
                    <span class="dash-details-card-item-subtitle">
                        estado civil
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.representative_register.person.marital_status_display}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </ng-container>

            <ng-container
                *ngIf="selectedRepresentative.representative_register.person.spouse">
                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        regime de casamento
                    </span>

                    <span class="dash-details-card-item-title">
                        {{getChoicesName('marital_statutes',selectedRepresentative.representative_register.person.spouse.marriage_bond)?.label
                        ?? '-'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        cpf do cônjuge
                    </span>

                    <span class="dash-details-card-item-title">
                        {{formatCpf(selectedRepresentative.representative_register.person.spouse.document)}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        nome do cônjuge
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.representative_register.person.spouse.name}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="dash-details-card-item-subtitle">
                        Possui outorga uxória
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.spousal_consent
                        ? 'Sim' : 'Não'}}
                    </span>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </ng-container>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    celular
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.representative_register.person.cellphone?
                    formatNumber(selectedRepresentative.representative_register.person.cellphone)
                    :
                    '-'
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>


            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    e-mail
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.representative_register.person.email
                    ?? selectedRepresentative.info.register.person.email}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    início do mandato
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.min_term_validity ?
                    formatDate(selectedRepresentative.min_term_validity) :
                    '-'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    validade do mandato
                </span>

                <span class="dash-details-card-item-title">
                    {{selectedRepresentative.expiration_date ?
                    formatDate(selectedRepresentative.expiration_date) :
                    'indeterminado'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <ng-container *ngIf="!isContribuitor">
                <div class="col-12 col-md-6 d-flex flex-column"
                    *ngIf="selectedRepresentative.representative_register.address">
                    <span class="dash-details-card-item-subtitle">
                        endereço completo
                    </span>

                    <span class="dash-details-card-item-title">
                        {{selectedRepresentative.representative_register.address
                        ?
                        formatAddress(selectedRepresentative.representative_register.address)
                        : '-'}}<br>
                        {{selectedRepresentative.representative_register.address
                        ?
                        selectedRepresentative.representative_register.address.cep
                        :
                        '-'}}
                    </span>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>
            </ng-container>
        </div>

        <ng-container
            *ngIf="selectedRepresentative.files && selectedRepresentative.files.length > 0 ">
            <div class="row">
                <div class="col-12">
                    <h3>Anexos</h3>
                </div>
                <!-- <div class="col-12">
                    <app-attachment-button
                        [files]="selectedRepresentative.files" [full]="true"
                        (onSelect)="handleSelect($event)"></app-attachment-button>
                </div> -->
            </div>
            <app-spacer [bottom]="32"></app-spacer>
        </ng-container>

        <!-- <div class="row">
            <div class="col-12" *ngIf="pepData.length > 0">
                <div class="dash-details-card" style="box-shadow:none;">
                    <div class="dash-details-card-header p-0">
                        <h3>Possui relacionamento com PEP</h3>
                    </div>

                    <app-table-list [columns]="pepColumns" [data]="pepData" [custom]="true"></app-table-list>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div> -->
    </div>
</app-side-modal>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="file">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div>{{file.type.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>
    <ng-container class="app-modal-body">
        <ng-container *ngIf="file">
            <img [src]="url" *ngIf="!isPDF && !isXLS" style="width:100%">
            <iframe [src]="url" *ngIf="isPDF || isXLS" frameborder="0"
                height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>
</app-modal>