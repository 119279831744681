import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import utils from 'src/app/helpers/utils';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() items: UserProps[] = [];
  @Input() selected: string = '';

  @Input() id?: string = utils.makeID();
  @Input() name?: string;
  @Input() placeholder: string = 'Selecione';
  @Input() type = 'text';
  @Input() isActive: boolean = false;
  @Input() isSearching: boolean = false;
  @Input() maxLength: number = 150;
  @Input() min?: number | string;
  @Input() max?: number | string;

  // Form control
  @Input() controlName: string = '';
  //   @Input() group?: any;
  @Input() group?: any = new FormGroup({});

  @Input() helpText?: string;

  @Output() onClick = new EventEmitter<MouseEvent>();
  @Output() changeValue = new EventEmitter<MouseEvent>();

  visibleItems: UserProps[] = [];
  @Input() columns: TableColumnList<any>[] = [];
  gridTemplate = 'repeat(auto-fit, 1fr)';
  columnsToDisplay: TableColumnList<any>[] = [];
  footerColumnsToDisplay: TableColumnList<any>[] = [];

  constructor(config: NgSelectConfig) {
    config.notFoundText = 'Nenhum item encontrado';

    config.bindLabel = 'label';
    config.bindValue = 'value';
  }

  ngOnInit(): void {
    this.visibleItems = this.items;

    const newColumns = [...this.columns].map((column) => ({
      ...column,
      size: column.size || 1,
    }));

    const gridTemplate = newColumns
      .map((column) => `${column.size}fr`)
      .join(' ');
    this.gridTemplate = gridTemplate;

    const newFooterColumns = [...this.columns];

    newFooterColumns.push({ name: '', cell: () => '' });

    this.columnsToDisplay = newColumns;
    this.footerColumnsToDisplay = newFooterColumns;
  }

  ngOnChanges(changes): void {
    this.visibleItems = this.items;
  }

  ngDoCheck(): void {
    this.visibleItems = this.items;
  }

  click(event: MouseEvent) {
    this.onClick.emit(event);
  }

  selectItem(id: any, event: MouseEvent) {
    event.stopPropagation();
    this.changeValue.emit(id);
  }

  getRequired(): boolean {
    const control = this.group.controls[this.controlName];

    if (control && control.validator) {
      let required = false;

      const validator = control.validator({} as AbstractControl);

      if (validator && validator.required) {
        required = true;
      }

      return required;
    }
    return false;
  }

  getGrid() {
    return {
      'grid-template-columns': this.gridTemplate,
    };
  }
}
