<div class="dashboard h-100">
    <div class="dashboard-card no-padding d-flex flex-column justify-content-between"
        style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <div class="padding-container d-flex flex-column">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            height: '24px',
                            width: '30px',
                            margin:'0'
                          }">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader [theme]="{
                                height: '27px',
                                width: '100px',
                            marginBottom:'30px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <span class="card-info-number">{{departments.length
                            > 9 ? '' :
                            0}}{{departments.length}}</span>
                        <span class="card-info-title">Departamentos</span>
                    </ng-container>
                </div>
            </div>

            <div class="col-12 dashboard-card-body">
                <ng-container *ngIf="loading">
                    <table #table>
                        <thead>
                            <tr>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>

                                <th class="text-right">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0,1,2,3]">
                                <td>
                                    <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '100px',
                                    marginBottom:'0'                            
                                      }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td class="item-number">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '30px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table #table *ngIf="!loading && departments.length > 0">
                    <thead>
                        <tr>
                            <th>Departamento</th>
                            <th style="text-align:right;">Vínculos</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let department of departments.slice(0,10)">
                            <td>{{department.name}}</td>
                            <td style="text-align:right;">
                                {{department.links ?? 0}}
                            </td>
                            <td class="pl-0">
                                <div class="table-options">
                                    <ng-container
                                        *ngIf="department.links === 0 && permissionObj.canDelete">
                                        <button
                                            (click)="removeDepartment(department.id)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                        <app-spacer [right]="19"></app-spacer>
                                    </ng-container>
                                    <button (click)="setDepartment(department)"
                                        *ngIf="permissionObj.canChange">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12" *ngIf="!loading && departments.length > 0">
                <div
                    class="padding-container d-flex flex-row justify-content-between">
                    <ng-container *ngIf="loading">
                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                        width: '180px',
                            marginBottom:'0'                            
                              }">
                            </ngx-skeleton-loader>

                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                width: '180px',
                                marginBottom:'0'                            
                                  }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button [inverted]="true"
                            (onClick)="redirect('/app/organizations/departments')">Ver
                            todos
                        </app-button>

                        <app-button (click)="addDepartmentToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i>
                            Adicionar departamento
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="departments.length === 0 && !loading">
            <div class="col-12">
                <div class="empty-card">

                    <div class="img-container">
                        <img src="/assets/images/department-icon.png"
                            alt="icone">
                    </div>

                    <ng-container *ngIf="!permissionObj.canAdd">
                        <h3>
                            Ainda não existem departamentos cadastrados
                        </h3>
                    </ng-container>

                    <ng-container *ngIf="permissionObj.canAdd">
                        <h3>Departamentos</h3>
                        <p>
                            Adicione os departamentos da sua empresa para
                            vincular os colaboradores
                        </p>

                        <app-button (click)="addDepartmentToggle(true)"
                            class="add-button">
                            <i class="fas fa-plus-circle mr-2"></i>Adicionar
                            departamento
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="addDepartment" (onClose)="closeModal()">

    <ng-container *ngIf="addDepartment">
        <div class="row" [formGroup]="departmentForm">
            <div class="col-12">
                <span class="organization-form-title">{{ department ?
                    'Atualizando ' + department.name : 'Novo
                    departamento'}}</span>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <div class="col-12">
                <span class="organization-form-description">{{department ?
                    'Atualize o departamento de ' +
                    department.name : 'Crie um novo
                    departamento'}}</span>
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Insira um nome para este departamento"
                    [group]="departmentForm" controlName="name">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12">
                <app-button [disabled]="departmentForm.invalid"
                    (click)="addDepartments()">Salvar novo departamento
                </app-button>
            </div>
        </div>
    </ng-container>
</app-side-modal>