<form [formGroup]="form">

    <ng-container *ngIf="!showForm">
        <app-table [columns]="columns" [data]="data" [footerData]="footerData"
            (onRemove)="removeRealty($event)">
        </app-table>
        <app-spacer [bottom]="35"></app-spacer>
    </ng-container>

    <ng-container *ngIf="showForm">
        <div class="row">
            <div class="col-12 col-lg-8">
                <app-input [group]="form" controlName="type" [maxLength]="60"
                    label="Tipo do imóvel"
                    placeholder="Casa, terreno, carro etc">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-4">
                <app-currency-input [group]="form" controlName="current_value"
                    label="Valor atual" placeholder="R$ 900.000,00">
                </app-currency-input>
                <app-spacer [bottom]=" 24">
                </app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-4">
                <app-masked-input [group]="form" controlName="cep" label="CEP"
                    mask="00000-000" placeholder="99887-766"
                    (input)="searchZipcode($event)">
                </app-masked-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <ng-container *ngIf="zipcodeFetched">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <app-input [group]="form" controlName="public_place"
                        label="Logradouro">
                    </app-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-2">
                    <app-input [group]="form" controlName="number" type="number"
                        label="Número">
                    </app-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-input [group]="form" controlName="complement"
                        label="Complemento">
                    </app-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <app-input [group]="form" controlName="district"
                        label="Bairro">
                    </app-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-4">
                    <app-input [group]="form" controlName="city" label="Cidade">
                    </app-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 col-lg-2">
                    <app-masked-input [group]="form" controlName="uf" label="UF"
                        mask="SS">
                    </app-masked-input>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

            </div>

            <div class="row">
                <div class="col-12 col-lg-4">
                    <app-select [items]="countries" [group]="form"
                        controlName="country" label="País">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>
        </ng-container>

    </ng-container>

    <div class="row">
        <div class="col-12 align-items-center">
            <app-button (onClick)="triggerForm()" [disabled]="getDisabled()"
                class="add-button mr-3" [outline]="true">
                <i class="fas fa-plus-circle mr-2"></i>
                Adicionar
            </app-button>

            <app-button (onClick)="handleCancel()" [outline]="true"
                *ngIf="showForm && data.length > 0">
                Cancelar
            </app-button>
        </div>
    </div>
</form>