import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MaskDirective } from 'ngx-mask';
import { ApiService } from 'src/app/services/api/api.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import utils from '../../helpers/utils';

@Component({
  selector: 'app-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss'],
})
export class MaskedInputComponent implements OnInit {
  @Input() id?: string = utils.makeID();
  @Input() name?: string;
  @Input() placeholder: string = 'Digite';
  @Input() label?: string;
  @Input() type = 'text';
  @Input() max?: number;

  @Input() standalone: boolean = false;
  @Input() value: string = '';

  // Form control
  @Input() controlName: string = '';
  @Input() group?: any = new FormGroup({});

  @Input() helpText?: string;
  @Input() errorText: string = 'Campo inválido';

  @Input() readOnly: boolean = false;
  @Input() validated: boolean = false;
  @Input() rows: number = 0;

  @Input() isUpperCase = false;

  @Input() isDocument: boolean = false;

  @Input() button: string = '';

  @Input() mask?: string;
  @Input() usePatterns: boolean = false;

  @Input() prefix: string = '';
  @Input() suffix: string = '';

  @Input() hasError: boolean = false;
  @Input() alwaysValid: boolean = false;

  @Output() onButtonClick: EventEmitter<MouseEvent> = new EventEmitter();

  @Output() onChange: EventEmitter<string> = new EventEmitter();

  pattern: MaskDirective['patterns'] = {
    W: {
      pattern: new RegExp('[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]'),
    },
    U: {
      pattern: new RegExp('[A-Z]'),
    },
    L: {
      pattern: new RegExp('[a-z]'),
    },
    N: {
      pattern: new RegExp('[0-9]'),
    },
  };

  error: boolean = false;

  constructor(private apiService: ApiService) {}

  onButtonClicked(event: MouseEvent): void {
    this.onButtonClick.emit(event);
  }

  handleChange(event: any): void {
    this.onChange.emit(event.target.value);
  }

  ngOnInit(): void {
    if (this.isDocument) {
      this.group.controls[this.controlName].valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((values: any) => {
          this.documentInUse(utils.onlyNumbers(values));
        });
    }
  }

  getClasses(): string {
    if (this.error) {
      return 'error';
    }

    if (this.validated) {
      return 'validated';
    }

    return '';
  }

  getRequired(): boolean {
    const control = this.group.controls[this.controlName];

    if (control && control.validator) {
      let required = false;

      const validator = control.validator({} as AbstractControl);

      if (validator && validator.required) {
        required = true;
      }

      return required;
    }

    return false;
  }

  getTouched(): boolean {
    if (this.standalone) {
      return true;
    } else {
      let touched = false;

      if (this.readOnly) return touched;

      if (this.group) {
        const control = this.group.controls[this.controlName];

        if (control) {
          if (control.touched) {
            touched = true;
          }
        } else {
          console.error(`Control of ${this.controlName} not found!`);
        }
      }

      return touched;
    }
  }

  getInvalid(): boolean {
    if (this.standalone) {
      return this.hasError;
    } else {
      let invalid = false;

      if (this.readOnly) return invalid;

      if (this.group) {
        const control = this.group.controls[this.controlName];

        if (control.invalid) {
          invalid = true;
        }
      }

      return invalid;
    }
  }

  async documentInUse(document: string) {
    try {
      const res = await this.apiService.post<ValidateFieldResponse>({
        route: 'validate-document-exists/',
        body: { document: document },
      });

      if (res.exists) {
        this.errorText = 'Este documento já está cadastrado';
        this.error = true;
        this.group.controls[this.controlName].setErrors({
          documentInUse: true,
        });
      } else {
        this.errorText = 'Campo inválido';
      }
    } catch (error) {
      this.error = false;
      this.group.controls[this.controlName].setErrors(null);
    }
  }

  getError(): boolean {
    if (this.alwaysValid) return false;
    return this.getTouched() && this.getInvalid();
  }
}
