<div class="app-table-container">
    <table class="app-table">
        <thead class="app-table-head">
            <tr class="app-table-head-row">
                <th *ngFor="let column of columns">{{column.name}}</th>
            </tr>
        </thead>

        <tbody class="app-table-body">
            <tr *ngFor="let item of data; let i = index"
                class="app-table-body-row">
                <td *ngFor="let column of columnsToDisplay"
                    [innerHtml]="item ? column.cell(item) : ''">
                </td>
                <td *ngIf="!removeButton" class="d-flex">
                    <button class="app-table-remove" (click)="onEditClicked(i)"
                        *ngIf="haveEdit">
                        <i class="fa-solid fa-edit"></i>
                    </button>
                    <app-spacer [right]="8" *ngIf="haveEdit"></app-spacer>
                    <button class="app-table-remove"
                        (click)="onRemoveClicked(i)">
                        <i class="fa-solid fa-trash"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="data.length === 0" class="app-table-footer-row">
                <td [attr.colspan]="columnsToDisplay.length">Nenhum dado
                    adicionado</td>
            </tr>
        </tbody>

        <tfoot *ngIf="footerData.length > 0" class="app-table-footer">
            <tr *ngFor="let item of footerData" class="app-table-footer-row">
                <td *ngFor="let column of footerColumnsToDisplay"
                    [innerHtml]="column.cell(item)"></td>
            </tr>
        </tfoot>
    </table>
</div>