import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils, { GetHelper } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export type CompanyAnalysisValues = {
  deposits_and_withdrawals: string;
  future_rescue_need: string;
  id: number;
  investment_assets: string;
  investment_experience: string;
  investment_purpose: string;
  monthly_income: string;
  profile: any;
  risk_profile: string;
  total_assets_investments: string;
  twelve_month_investment: string;
  twenty_four_month_application: string;
  score: number;
  disqualification: boolean;
  start_date: string;
  validity: string;
};

export type QuizProps = {
  title: string;
  formControl: string;
  suboptions: {
    label: string;
    value: string;
    score?: number;
  }[];
};

@Component({
  selector: 'app-signup-unified-company-analysis',
  templateUrl: './signup-unified-company-analysis.component.html',
  styleUrls: ['./signup-unified-company-analysis.component.scss'],
})
export class SignupUnifiedCompanyAnalysisComponent
  implements OnInit, OnDestroy
{
  form = this.formBuilder.group({
    investment_experience: [null, []],
    profile: [null, []],
    risk_profile: [null, []],
    future_rescue_need: [null, []],
    investment_purpose: [null, []],
    monthly_income: [null, []],
    investment_assets: [null, []],
    total_assets_investments: [null, []],
    deposits_and_withdrawals: [null, []],
    twelve_month_investment: [null, []],
    twenty_four_month_application: [null, []],
  });

  data: CompanyAnalysisValues;
  event: any;
  sending = false;
  isFund = false;
  refuseModal = false;
  showConfirmRefuse = false;
  redirectWarning = false;
  not_declared = false;
  missingGeneralData = false;
  missingFundData = false;
  showDisqualificationModal = false;
  funds: any[] = [];

  options: QuizProps[] = [
    {
      title: 'Como classificaria a experiência em investimentos da instituição',
      formControl: 'investment_experience',
      suboptions: [
        {
          label:
            'Nula. Não possui nenhum conhecimento em nenhum tipo de investimento',
          value: 'primary',
          score: 4,
        },
        {
          label:
            'Limitada. Possui pouca experiência em investimentos, apenas em poupança, CDI e títulos públicos',
          value: 'limited',
          score: 3,
        },
        {
          label:
            'Moderada. Possui alguma experiência em investimentos, considerando renda fixa e ações, fundos de investimentos em ações, renda fixa, DI',
          value: 'moderate',
          score: 2,
        },
        {
          label:
            'Extensa. Somos investidores ativos e experientes, temos conhecimento em renda fixa, variável, derivativos, opções e demais ativos de investimentos',
          value: 'extensive',
          score: 1,
        },
      ],
    },
    {
      title:
        'Qual é a sensibilidade em relação aos rendimentos dos investimentos e o apetite de risco da instituição?',
      formControl: 'risk_profile',
      suboptions: [
        {
          label: 'Não pretendo assumir riscos',
          value: 'primary',
          score: 11,
        },
        {
          label:
            'Aceito uma rentabilidade menor por um investimento que apresente baixo risco',
          value: 'low',
          score: 10,
        },
        {
          label:
            'Aceita um determinado risco por um investimento que possibilite uma rentabilidade maior',
          value: 'average',
          score: 2,
        },
        {
          label:
            'Aceito um risco maior por um investimento que possibilite elevada rentabilidade',
          value: 'high',
          score: 1,
        },
      ],
    },
    {
      title:
        'Considerando a sua necessidade futura, qual horizonte de investimentos da instituição ,ou seja, em quanto tempo pretende resgatar os investimentos?',
      formControl: 'future_rescue_need',
      suboptions: [
        {
          label: 'em até 6 meses',
          value: 'six_months',
          score: 10,
        },
        {
          label: 'de 7 a 12 meses (até 1 ano)',
          value: 'one_year',
          score: 3,
        },
        {
          label: 'de 13 a 24 meses (até 2 anos)',
          value: 'two_years',
          score: 2,
        },
        {
          label: 'acima de 25 meses (acima de 2 anos)',
          value: 'over_two_years',
          score: 1,
        },
      ],
    },
    {
      title: 'Qual a finalidade do investimento?',
      formControl: 'investment_purpose',

      suboptions: [
        {
          label: 'Alternativa de investimento / Rentabilidade',
          value: 'profitability',
          score: 4,
        },
        {
          label: 'Proteção do capital',
          value: 'capital_protection',
          score: 3,
        },
        {
          label: 'Recursos para fluxo de caixa / Expansão',
          value: 'expansion',
          score: 2,
        },
        {
          label: 'Crescimento do patrimônio',
          value: 'wealth_growth',
          score: 1,
        },
      ],
    },
    {
      title:
        'Assinale abaixo em qual alternativa o valor da receita mensal melhor se encaixa',
      formControl: 'monthly_income',
      suboptions: [
        {
          label: 'até R$ 100.000,00',
          value: 'up_to_hundred_thousand',
          score: 4,
        },
        {
          label: 'de R$ 100.001,00 a R$ 500.000,00',
          value: 'up_to_five_hundred_thousand',
          score: 3,
        },
        {
          label: 'de R$500.001,00 a R$ 1.000.000,00',
          value: 'up_to_one_million',
          score: 2,
        },
        {
          label: 'acima de R$ 1.000.000,00',
          value: 'over_one_million',
          score: 1,
        },
      ],
    },
    {
      title:
        'Quais ativos de investimento abaixo compõem o patrimônio da instituição?',
      formControl: 'investment_assets',
      suboptions: [
        {
          label: 'Títulos públicos, CDB, poupança',
          value: 'primary',
          score: 4,
        },
        {
          label:
            'Títulos públicos, CDB, poupança, Fundos de investimentos de Renda Fixa e DI, imóveis, móveis (veículos, jóias etc)',
          value: 'low',
          score: 3,
        },
        {
          label:
            'Títulos públicos, CDB, poupança, Fundos de investimentos de Renda Fixa e DI, imóveis, ações, Fundos de Investimentos em Ações, Fundos de Previdência, móveis (veículos, jóias etc)',
          value: 'average',
          score: 2,
        },
        {
          label:
            'Títulos públicos, CDB, poupança, Fundos de Investimentos em Renda Fixa e DI, Imóveis, Ações, Fundos de Investimentos em Ações, Fundos de Previdência, Derivativos, Fundos de Investimentos Multimercado, Fundos de Investimentos em Renda Fixa de Médio e Alto Risco, , móveis ( veículos, joias, etc..).',
          value: 'high',
          score: 1,
        },
      ],
    },
    {
      title:
        'Considerando seus ativos e investimentos financeiros, qual valor que compõem o total do patrimônio da instituição?',
      formControl: 'total_assets_investments',
      suboptions: [
        {
          label: 'Ainda não tenho ativos e investimentos investidos',
          value: 'no_investments',
          score: 4,
        },
        {
          label: 'R$ 10.000,00 a R$ 20.000,00',
          value: 'up_to_twenty_thousand',
          score: 3,
        },
        {
          label: 'R$ 20.000,00 a R$ 100.000,00',
          value: 'up_to_hundred_thousand',
          score: 2,
        },
        {
          label: 'acima de R$ 100.000,00',
          value: 'over_hundred_thousand',
          score: 1,
        },
      ],
    },
    {
      title:
        'Em relação ao planejamento e necessidade de recursos investidos, qual melhor sentença descreve o planejamento com relação a depósitos e retiradas dos recursos nos próximos anos?',
      formControl: 'deposits_and_withdrawals',
      suboptions: [
        {
          label:
            'Retirar parcelas de forma regular como auxílio para despesas da instituição',
          value: 'regular_withdrawals',
          score: 4,
        },
        {
          label: 'Não adicionar nenhum dinheiro, tampouco fazer retiradas',
          value: 'no_deposits_or_withdrawals',
          score: 3,
        },
        {
          label:
            'Adicionar esporadicamente apenas modestas parcelas de novos investimentos',
          value: 'sporadic_deposits',
          score: 2,
        },
        {
          label:
            'Adicionar novas parcelas de investimentos ao portfólio nos próximos anos',
          value: 'new_investment_installments',
          score: 1,
        },
      ],
    },
    {
      title:
        'Quais investimentos a instituição realizou frequentemente nos últimos 12 meses?',
      formControl: 'twelve_month_investment',
      suboptions: [
        {
          label:
            'Trata-se do primeiro investimento, não realizou nenhuma operação',
          value: 'first_investment',
          score: 4,
        },
        {
          label: 'Investiu apenas em Poupança',
          value: 'savings',
          score: 3,
        },
        {
          label:
            'Investiu em Renda fixa (CDB, fundos multimercado/ renda fixa)',
          value: 'fixed_income',
          score: 2,
        },
        {
          label:
            'Investiu em Renda variável (ações, derivativos ou fundos de ações)',
          value: 'variable_income',
          score: 1,
        },
      ],
    },
    {
      title:
        'Considerando os últimos 24 meses, quantas aplicações/operações foram realizadas com produtos de investimento?',
      formControl: 'twenty_four_month_application',
      suboptions: [
        {
          label: 'Nunca realizou aplicações/operações',
          value: 'never',
          score: 4,
        },
        {
          label: 'Realizou até 5 aplicações/operações',
          value: 'up_to_five',
          score: 3,
        },
        {
          label: 'Realizou entre 6 e 15 aplicações/operações',
          value: 'up_to_fifteen',
          score: 2,
        },
        {
          label: 'Realizou mais de 16 aplicações/operações',
          value: 'over_fifteen',
          score: 1,
        },
      ],
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private api: ApiService,
    private toast: ToastService,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.isFund =
      this.authService.user.active_register.register.role.slug.includes(
        'PJ-FI'
      );
    this.event = this.signupService.sendCompanyData.subscribe((value) => {
      if (value === 'analysis' && this.isFund) {
        this.sendData();
      }
    });

    if (this.isFund) {
      this.form.valueChanges.subscribe(() => {
        this.signupService.setCompanyFormStatus('analysis', this.form.valid);
      });
    }

    this.canProceed();

    if (this.isFund) {
      this.options = [
        {
          title: 'Público Alvo do Fundo Cotista',
          formControl: 'profile',
          suboptions: [
            {
              label: 'Agressivo',
              value: '3',
            },
            {
              label: 'Moderado',
              value: '2',
            },
            {
              label: 'Conservador',
              value: '1',
            },
          ],
        },
      ];
      this.form.controls.profile.setValidators([Validators.required]);
    } else {
      this.form.controls.investment_experience.setValidators([
        Validators.required,
      ]);
      this.form.controls.risk_profile.setValidators([Validators.required]);
      this.form.controls.future_rescue_need.setValidators([
        Validators.required,
      ]);
      this.form.controls.investment_purpose.setValidators([
        Validators.required,
      ]);
      this.form.controls.monthly_income.setValidators([Validators.required]);
      this.form.controls.investment_assets.setValidators([Validators.required]);
      this.form.controls.total_assets_investments.setValidators([
        Validators.required,
      ]);
      this.form.controls.deposits_and_withdrawals.setValidators([
        Validators.required,
      ]);
      this.form.controls.twelve_month_investment.setValidators([
        Validators.required,
      ]);
      this.form.controls.twenty_four_month_application.setValidators([
        Validators.required,
      ]);
    }
  }

  getPoints() {
    let score = 0;
    let profile_label = '';

    this.options.forEach((option) => {
      const value = this.form.get(option.formControl).value;
      const suboption = option.suboptions.find((sub) => sub.value === value);

      if (suboption) {
        score += suboption.score;
      }
    });

    if (score <= 19) {
      return (profile_label = 'Agressivo');
    }

    if (score <= 27) {
      return (profile_label = 'Moderado');
    }

    return (profile_label = 'Conservador');
  }

  async canProceed() {
    const step1 = await this.checkGeneralData();
    const step2 = await this.getFunds();

    if (!step1) {
      this.redirectWarning = true;
      this.missingGeneralData = true;
      return;
    }

    if (!step2 && !this.isFund) {
      this.redirectWarning = true;
      this.missingFundData = true;
      return;
    }

    this.getData();
  }

  async checkGeneralData() {
    try {
      const res = await this.api.get<ApiResponse<Company>>({
        route: 'api/registration/pj_general/me/',
        token: true,
      });

      return true;
    } catch (error) {
      this.redirectWarning = true;
      return false;
    }
  }

  async getFunds() {
    try {
      const res = await this.api.get({
        route: 'api/registration/assignor_fund_interest/',
        token: true,
      });

      this.funds = res;
      return res.length > 0;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/investor_profile_pj/me/',
        token: true,
      });

      this.form.patchValue({
        ...data,
        investment_experience: data.investment_experience || 'primary',
        risk_profile: data.risk_profile || 'primary',
        investment_assets: data.investment_assets || 'primary',
        profile: data.profile ? String(data.profile) : null,
      });

      this.not_declared = data.not_declared;

      if (data.not_declared) {
        this.form.reset();
      }

      this.signupService.setCompanyFormStatus(
        'analysis',
        data.not_declared ? true : this.form.valid
      );

      this.data = data;
    } catch (error) {
      console.log(error);
    }
  }

  checkProfile() {
    const profile = this.getPoints();
    const fundProfile = this.funds[0].fund.fund_profile;

    if (this.isProfileCompatible(profile, fundProfile)) {
      this.sendData();

      return;
    }

    this.showDisqualificationModal = true;
  }

  isProfileCompatible(profile, fundProfile) {
    if (
      (fundProfile === 'Moderado' && profile === 'Conservador') ||
      (fundProfile === 'Agressivo' && profile === 'Conservador') ||
      (fundProfile === 'Agressivo' && profile === 'Moderado')
    ) {
      return false;
    }

    return true;
  }

  async sendData(disqualification: boolean = false) {
    this.sending = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      //

      const payload = this.isFund
        ? {
            profile: valuesHelper.get('profile'),
          }
        : {
            investment_experience:
              valuesHelper.get('investment_experience') === 'primary'
                ? null
                : valuesHelper.get('investment_experience'),
            risk_profile:
              valuesHelper.get('risk_profile') === 'primary'
                ? null
                : valuesHelper.get('risk_profile'),
            future_rescue_need: valuesHelper.get('future_rescue_need'),
            investment_purpose: valuesHelper.get('investment_purpose'),
            monthly_income: valuesHelper.get('monthly_income'),
            investment_assets:
              valuesHelper.get('investment_assets') === 'primary'
                ? null
                : valuesHelper.get('investment_assets'),
            total_assets_investments: valuesHelper.get(
              'total_assets_investments'
            ),
            deposits_and_withdrawals: valuesHelper.get(
              'deposits_and_withdrawals'
            ),
            twelve_month_investment: valuesHelper.get(
              'twelve_month_investment'
            ),
            twenty_four_month_application: valuesHelper.get(
              'twenty_four_month_application'
            ),
            not_declared: false,
            disqualification,
          };

      const res =
        this.data && this.data.id
          ? await this.api.put({
              route: `api/registration/investor_profile_pj/${this.data.id}/`,
              token: true,
              body: payload,
            })
          : await this.api.post({
              route: 'api/registration/investor_profile_pj/',
              token: true,
              body: payload,
            });

      this.showDisqualificationModal = false;

      this.getData();

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  getRiskLabel() {
    let risk = this.data.profile;
    let label = '';

    if (this.not_declared) return 'Não declarado';

    if (this.data && this.data.disqualification) return 'Desenquadrado';

    switch (risk) {
      case 1:
        label = 'Conservador';
        break;
      case 2:
        label = 'Moderado';
        break;
      case 3:
        label = 'Agressivo';
        break;
      default:
        label = 'Conservador';
        break;
    }

    return label;
  }

  toggleRefuseModal(setOpen: boolean) {
    this.refuseModal = setOpen;
    this.showConfirmRefuse = false;
  }

  async refuseQuiz() {
    this.sending = true;
    try {
      const formValues = this.form.value;
      const nullableItems = Object.keys(formValues);

      let payload = {
        not_declared: true,
      };

      nullableItems.forEach((item) => {
        payload[item] = null;
      });

      await this.api.post({
        route: 'api/registration/investor_profile_pj/',
        token: true,
        body: payload,
      });

      this.refuseModal = false;

      this.form.reset();

      this.getData();
      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.log(error);
      this.toast.show('info', 'Erro', error.error.message);
    }
    this.sending = false;
  }

  redirectUser() {
    if (this.missingGeneralData) {
      this.signupService.changeCompanyStepEvent.emit('general');

      return;
    }
    this.signupService.changeCompanyStepEvent.emit('bond');
  }
}
