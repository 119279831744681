import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import fakerbr from 'faker-br';
import { MaskPipe } from 'ngx-mask';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils/utils.service';
import Utils from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-settings-audit',
  templateUrl: './dashboard-settings-audit.component.html',
  styleUrls: ['./dashboard-settings-audit.component.scss'],
})
export class DashboardSettingsAuditComponent implements OnInit {
  auditForm = this.formBuilder.group({
    document: [null, [Validators.required]],
    corporate_name: [null, [Validators.required]],
    constitution_date: [null, [Validators.required]],
    branch: [null, [Validators.required]],
    email: [null, [Validators.required]],
    zipcode: [null, [Validators.required]],
    public_place: [null, [Validators.required]],
    number: [null, [Validators.required, Validators.max(99999)]],
    complement: [null, []],
    district: [null, [Validators.required]],
    city: [null, [Validators.required]],
    uf: [null, [Validators.required]],
    country: [null, [Validators.required]],
  });

  addressObj: AddressProps = {
    public_place: '',
    district: '',
    city: '',
    uf: '',
    country: '',
  };

  permissionObj: PermissionValues = {
    canAdd: false,
    canChange: false,
    canDelete: false,
  };

  DDIOptions = DDIOptions;

  audits: any[] = [];
  audit: any = undefined;

  visible_audits: any[] = [];
  addAudit = false;

  zipcodeFetched = false;
  loading = false;

  loadingAudits = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    public signupService: SignupService,
    private maskPipe: MaskPipe,
    private router: Router,
    private utilsService: UtilsService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setData();
    this.getData();
    this.setPermissions();
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }

  addAuditToggle(status: boolean) {
    this.addAudit = status;
  }

  closeModal() {
    this.addAudit = false;

    setTimeout(() => {
      this.auditForm.reset();
      this.zipcodeFetched = false;
      this.audit = undefined;
    }, 500);
  }

  async handleSearchZipcode(event: any, isValue: boolean = false) {
    this.loading = true;
    const zipcode = isValue ? event : event.target.value;

    var obj = {
      public_place: '',
      district: '',
      city: '',
      uf: '',
      country: '',
    };

    try {
      const zipcodeRes = await this.utilsService.getZipcode(zipcode);

      if (zipcodeRes) {
        const foundCep = Object.keys(zipcodeRes).some((key) => {
          return key === 'partner_response' ? false : zipcodeRes[key] !== '';
        });

        if (foundCep) {
          obj = {
            public_place: zipcodeRes.logradouro,
            district: zipcodeRes.bairro,
            city: zipcodeRes.localidade,
            uf: zipcodeRes.uf,
            country: '23',
          };

          this.auditForm.patchValue(obj);

          this.zipcodeFetched = true;
        } else {
          Utils.resetZipcodeFields(this.auditForm);
          this.auditForm.updateValueAndValidity();

          this.zipcodeFetched = zipcode.length === 9;
        }
      }
    } catch (error) {
      this.zipcodeFetched = false;
      console.error(error);
    }

    this.addressObj = obj;

    this.loading = false;
  }

  getDisabled() {
    return this.auditForm.invalid;
  }

  setaudit(audit: any) {
    this.auditForm.patchValue({
      ...audit,
      branch: audit.branch.toString(),
    });

    this.handleSearchZipcode(audit.zipcode, true);

    this.audit = audit;
    this.addAuditToggle(true);
  }

  async getData() {
    this.loadingAudits = true;
    try {
      const res = await this.api.get({
        route: 'api/registration/audit/',
        token: true,
      });

      this.audits = res;
      this.visible_audits = [...this.audits].splice(0, 10);
    } catch (error) {
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loadingAudits = false;
  }

  async addNewAudit() {
    try {
      const values = {
        ...this.auditForm.value,
        street: this.auditForm.value.public_place,
        state: this.auditForm.value.uf,
        country: Number(this.auditForm.value.country),
        funds: this.audit ? [...this.audit.funds] : [],
      };

      const alreadyHaveDocument = this.audits.find(
        (audit) => audit.document === values.document
      );

      const alreadyHaveEmail = this.audits.find(
        (audit) => audit.email === values.email
      );

      if (alreadyHaveDocument || alreadyHaveEmail) {
        this.toast.show(
          'error',
          'Erro',
          `Já existe uma auditoria com esse ${
            alreadyHaveDocument ? 'documento' : 'email'
          }.`
        );
        return;
      }

      const res = this.audit
        ? await this.api.put({
            route: `api/registration/audit/${this.audit.id}/`,
            token: true,
            body: values,
          })
        : await this.api.post({
            route: 'api/registration/audit/',
            token: true,
            body: values,
          });

      this.getData();

      this.toast.show(
        'info',
        `Auditoria ${this.audit ? 'atualizada' : 'adicionada'} com sucesso`,
        `a auditoria ${values.corporate_name} foi ${
          this.audit ? 'atualizada' : 'adicionada'
        }`
      );

      this.closeModal();
      this.audit = undefined;

      this.auditForm.reset();
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao cadastrar a auditoria.'
      );
    }
  }

  async removeaudit(id: number) {
    const { isConfirmed } = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, apague!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    });

    if (isConfirmed) {
      await this.api
        .delete({
          route: `api/registration/audit/${id}/`,
          token: true,
        })
        .then((res) => {
          this.audits = this.audits.filter((audit) => audit.id !== id);

          Swal.fire('Apagado!', 'Auditoria apagada com sucesso', 'success');
          this.getData();
        })
        .catch((error) => {
          Swal.fire('Erro!', 'Erro ao apagar auditoria', 'error');
        });
    }
  }

  setData() {
    this.signupService.fillFormEvent.subscribe(() => {
      const companyName = fakerbr.company.companyName();

      const zipcode = fakerbr.address.zipCodeValidByState('SP');

      const formatted = this.maskPipe.transform(zipcode, '00000-000');

      this.auditForm.patchValue({
        document: this.maskPipe.transform(
          fakerbr.br.cnpj(),
          '00.000.000/0000-00'
        ),
        corporate_name: companyName,
        constitution_date: fakerbr.date.past(25).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        branch: '1',
        email: fakerbr.internet.email(),
        zipcode: formatted,
        number: fakerbr.random.number({ min: 1, max: 1000 }),
        complement: 'Casa',
      }),
        this.handleSearchZipcode({
          target: { value: formatted },
        });

      this.zipcodeFetched = true;
    });
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_audit`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
