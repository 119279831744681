import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard-accounts',
  templateUrl: './dashboard-accounts.component.html',
  styleUrls: ['./dashboard-accounts.component.scss'],
})
export class DashboardAccountsComponent implements OnInit {
  roleForm = this.formBuilder.group({
    role: [null, [Validators.required]],
  });

  accounts: RegisterUserResponse[] = [];
  selectedAccount: RegisterUserResponse | null = null;
  primaryRoleOptions: SelectItem[] = [];

  accountsLoading: boolean = true;
  selectedApplicable: string = null;
  filteredRoleOptions: SelectItem[] = [];
  roleOptions: SelectItem[] = [];
  sendingRequest: boolean = false;
  warningModal: boolean = false;
  roles: Role[] = [];

  roleModal: boolean = false;
  count: number = 0;
  showFilterModal = false;
  dataFilters: any = null;
  offset: number = 0;

  today = Utils.todayString();

  filterForm = this.formBuilder.group({
    name: [null, []],
    document: [null, []],
    role: [null, []],
    agent: [null, []],
    status: [null, []],
    created_from: [null, []],
    created_to: [null, []],
    updated_from: [null, []],
    updated_to: [null, []],
  });

  constructor(
    private toast: ToastService,
    private api: ApiService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    this.getData();
    this.setAgents();
    this.getRoles();

    this.filterForm.controls.role.valueChanges.subscribe((value) => {
      const selectedRole = this.roles.find((item) => item.id === Number(value));

      this.selectedApplicable = selectedRole ? selectedRole.applicable : null;

      if (this.selectedApplicable !== 'provider') {
        this.filterForm.controls.agent.setValue(null);

        this.filterForm.controls.agent.updateValueAndValidity();
      }
    });
  }

  async getRoles() {
    const res = await this.api.get<ApiResponse<Role[]>>({
      route: 'role/',
    });

    const { data } = res;

    this.roles = data;

    const userRoles = data
      .filter(
        (_item) =>
          _item.applicable === 'assignor' ||
          _item.applicable === 'provider' ||
          _item.applicable === 'shareholder'
      )
      .sort((a, b) => {
        if (a.applicable_display < b.applicable_display) {
          return -1;
        }
        if (a.applicable_display > b.applicable_display) {
          return 1;
        }
        return 0;
      })
      .map((item) => {
        return {
          label: `${item.applicable_display} - ${item.name}`,
          value: String(item.id),
        };
      });

    this.primaryRoleOptions = userRoles;
  }

  async setAgents() {
    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.roleOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async addAgentRole() {
    try {
      const payload = [
        ...this.selectedAccount.register.agent.map((item) => item.id),
        ...this.roleForm.controls.role.value,
      ];

      await this.api.patch<ApiResponse<Register>>({
        route: `api/registration/register/${this.selectedAccount.register.id}/`,
        token: true,
        body: {
          agent: payload,
        },
      });

      this.toast.show('info', 'Sucesso!', 'Papel adicionado com sucesso');

      this.closeModal();
      this.getData();
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao adicionar papel');
    }
  }

  closeModal() {
    this.roleModal = false;
    this.selectedAccount = null;
    this.roleForm.reset();
  }

  getDisplayDocument(item: RegisterUserResponse) {
    if (item.register) {
      if (item.register.type && item.register.type.toUpperCase() === 'PF') {
        return item.register.person?.document
          ? 'CPF: ' +
              this.maskPipe.transform(
                item.register.person.document.number,
                '000.000.000-00'
              )
          : '-';
      }

      if (item.register.type && item.register.type.toUpperCase() === 'PJ') {
        return item.register.company?.document
          ? 'CNPJ: ' +
              this.maskPipe.transform(
                item.register.company.document.number,
                '00.000.000/0000-00'
              )
          : '-';
      }
    }
    return '';
  }

  async getData(page: number = 1) {
    this.accountsLoading = true;
    try {
      const params = { ...this.dataFilters, page, type: 'register' };

      const { count, results, offset } = await this.api.get<
        ApiResponse<RegisterUserResponse[]>
      >({
        route: 'api/v2/registration/my-registers/',
        token: true,
        params,
      });

      this.offset = offset;
      this.count = count;
      this.accounts = results;
    } catch (error) {
      console.error(error);
    }
    this.accountsLoading = false;
  }

  renderRoles(item: RegisterUserResponse) {
    let label = '';

    if (item.register.agent && item.register.agent.length > 0) {
      label = item.register.agent.map((agent) => agent.name).join(', ');
    }

    return label;
  }

  getInitials(item: RegisterUserResponse) {
    let name = null;

    if (item.register) {
      if (item.register.type?.toUpperCase() === 'PF' && item.register.person) {
        name = item.register.person.full_name;
      }

      if (item.register.type?.toUpperCase() === 'PJ' && item.register.company) {
        name = item.register.company.corporate_name;
      }
    }

    return name ? Utils.getInitials(name) : '-';
  }

  getName(item: RegisterUserResponse) {
    let name = null;

    if (item.register) {
      if (item.register.type?.toUpperCase() === 'PF' && item.register.person) {
        name = item.register.person.full_name;
      }

      if (item.register.type?.toUpperCase() === 'PJ' && item.register.company) {
        name = item.register.company.corporate_name;
      }
    }

    return name ? name : 'Cadastro em andamento';
  }

  getStatus(item: RegisterUserResponse) {
    const register = item.register;

    if (register.approval.length === 0) return 'Cadastro em andamento';

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Aprovado internamente';
      } else {
        return `${register ? 'Cadastro' : 'Fundo'} rejeitado`;
      }
    } else {
      if (register.approval[0].current_department !== undefined) {
        return register.approval[0].current_department?.type === 'department'
          ? register.approval[0].current_department?.department?.name
          : register.approval[0].current_department?.agent?.name;
      } else if (register.approval[0].step === 0) {
        return 'Edição';
      } else {
        return 'Departamento não definido';
      }
    }
  }

  handleEditRoleModal(id: number) {
    this.roleModal = true;
    this.selectedAccount = this.accounts.find((item) => item.id === id);

    const newRoleOptions = this.roleOptions.filter((item) => {
      return (
        this.selectedAccount?.register?.agent.findIndex(
          (agent) => agent.id === Number(item.value)
        ) === -1
      );
    });

    this.filteredRoleOptions = newRoleOptions;
  }

  getStatusInfo(item: RegisterUserResponse) {
    const register = item.register;

    if (register.approval.length === 0) return '';

    if (register.approval[0].completed) {
      if (register.approval[0].is_approved) {
        return 'Integração ao fundo';
      } else {
        if (register.approval[0].current_department !== null) {
          return register.approval[0].current_department.type === 'department'
            ? register.approval[0].current_department.department.name
            : register.approval[0].current_department.agent.name;
        } else if (register.approval[0].step === 0) {
          return 'Edição';
        } else {
          return 'Departamento não definido';
        }
      }
    } else if (register.approval[0].step === 0) {
      return 'Aguardando representantes';
    } else {
      return `Aguardando aprovação (${register.approval[0].step}/${
        register.approval[0].queue &&
        register.approval[0].queue.departments.length
      })`;
    }
  }

  renderLink(item: RegisterUserResponse) {
    if (item.register) {
      return [
        `/app/details/${item.register.role.applicable}/`,
        item.register.id,
      ];
    }

    return '';
  }

  handleOpenWarningModal(id: number) {
    this.selectedAccount = this.accounts.find((item) => item.id === id);

    this.warningModal = true;
  }

  async redirectWarn(user: RegisterUserResponse) {
    const { isConfirmed } = await Swal.fire({
      title: 'Deseja editar este cadastro?',
      text: 'Você será redirecionado para a página de cadastro, e o usuário voltará para a fila de aprovação.',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, editar!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      preConfirm: async () => {
        await this.redirectToEdit(user);
      },
    });
  }

  async filterData() {
    this.accountsLoading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          filters[key] = value;
        }
      });

      const { count, results, offset } = await this.api.get<
        ApiResponse<RegisterUserResponse[]>
      >({
        route: 'api/v2/registration/my-registers/',
        params: {
          type: 'register',
          page: 1,
          ...filters,
          return_distinct: true,
        },
        token: true,
      });

      this.dataFilters = filters;
      this.count = count;
      this.offset = offset;

      this.accounts = results;

      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
    this.accountsLoading = false;
  }

  async redirectToEdit(user: RegisterUserResponse) {
    await this.api.patch({
      route: `user/me/`,
      token: true,
      body: {
        active_register: user.id,
      },
    });

    const { data } = await this.api.get<ApiResponse<NewLoginResponse>>({
      route: 'user/me/',
      token: true,
    });

    this.authService.setUser(data);

    const permissionsArr = data.active_register.permissions_role.permissions;
    this.authService.permissions = permissionsArr;
    this.storageService.set('permissions', permissionsArr, true);

    await this.api.patch({
      route: 'api/registration/trace_step/me/',
      body: { substep: 'general', step: 'register' },
      token: true,
    });

    this.router.navigate([
      `/signup/${
        user.register.role.applicable
      }/${user.register.type.toLowerCase()}`,
    ]);
  }

  //   async changeActiveRegister(id: number) {
  async changeActiveRegister(id: any) {
    try {
      await this.api.patch({
        route: `user/me/`,
        token: true,
        body: {
          active_register: id,
        },
      });

      const { data } = await this.api.get<ApiResponse<NewLoginResponse>>({
        route: 'user/me/',
        token: true,
      });

      this.authService.setUser(data);
      this.handleRedirect(id);
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro!', 'Erro ao alterar conta ativa');
    }
  }

  handleRedirect(id: number) {
    const account = this.accounts.find((item) => item.id === id);

    const isApprover = account.register.is_approver;
    const applicable = account.register.role.applicable;
    const approvals = account.register.approval;
    const _id = account.register.id;
    const type = account.register?.type?.toLowerCase() || '';

    if (applicable === 'representative' || applicable === 'admin') {
      this.router.navigate(['/app/dashboard']);
      return;
    }

    if (approvals.length > 0) {
      if (isApprover) {
        this.router.navigate(['/app/dashboard']);
      } else {
        this.router.navigate([`/app/details/${applicable}/`, _id]);
      }
    } else {
      this.router.navigate([`/signup/${applicable}/${type}`]);
    }
  }

  resetForm() {
    this.accountsLoading = true;
    this.filterForm.reset();
    this.dataFilters = null;
    this.getData();
    this.showFilterModal = false;
  }

  changePageData(page: number) {
    this.accountsLoading = true;
    this.getData(page);
  }

  //   formatDate(date: string) {
  //     const day = new Date(date).toLocaleDateString();
  //     const hours = new Date(date).toLocaleTimeString();
  //     return `${day} às ${hours}`;
  //   }
}
