import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SignupService } from 'src/app/services/signup/signup.service';

@Component({
  selector: 'app-signup-unified-password',
  templateUrl: './signup-unified-password.component.html',
  styleUrls: ['./signup-unified-password.component.scss'],
})
export class SignupUnifiedPasswordComponent implements OnInit {
  form = this.formBuilder.group({
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        (control: FormControl) => {
          const password = control.value;

          if (!this.hasNumber(password)) return { noNumber: true };

          if (!this.hasLowerCase(password)) return { noLowerCase: true };

          if (!this.hasUpperCase(password)) return { noUpperCase: true };

          if (!this.hasSpecialCharacter(password))
            return { noSpecialCharacter: true };

          return null;
        },
      ],
    ],
  });

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((values) => {
      this.signupService.setBasicFormData('password', values);
    });

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setBasicFormStatus('password', status === 'VALID');
    });
  }

  hasLowerCase(str: string) {
    return str.toUpperCase() !== str;
  }

  hasUpperCase(str: string) {
    return str.toLowerCase() !== str;
  }

  hasSpecialCharacter(str: string) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
  }

  hasNumber(str: string) {
    return /\d/.test(str);
  }

  passwordStrength(): number[] {
    const password = this.form.get('password')?.value ?? '';

    const strengthSteps = [];

    if (password !== '') strengthSteps.push(0);
    if (password.length >= 8) strengthSteps.push(1);
    if (this.hasNumber(password)) strengthSteps.push(2);
    if (this.hasLowerCase(password) && this.hasUpperCase(password))
      strengthSteps.push(3);
    if (this.hasSpecialCharacter(password)) strengthSteps.push(4);

    return strengthSteps;
  }

  passwordStrengthPercentage(): number {
    return (100 * this.passwordStrength().length) / 5;
  }

  passwordStrengthText(): string {
    const percentage = this.passwordStrengthPercentage();

    if (percentage > 60 && percentage < 90) {
      return 'Sua senha precisa melhorar';
    } else if (percentage >= 90) {
      return 'Sua senha parece bem segura';
    }

    return 'Sua senha está fraca';
  }
}
