<ngx-skeleton-loader appearance="circle" [theme]="{
    height: '80px',
    width: '200px',
    'border-radius': '10px'
  }">
</ngx-skeleton-loader>
<app-spacer [bottom]="56"></app-spacer>

<div class="sidebar-body-welcome">
    <ngx-skeleton-loader [theme]="{
        height: '50px'
      }">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader></ngx-skeleton-loader>
</div>

<app-spacer [bottom]="56"></app-spacer>

<ngx-skeleton-loader [theme]="{
    height: '50px'
}">
</ngx-skeleton-loader>

<app-spacer [bottom]="8"></app-spacer>

<ngx-skeleton-loader></ngx-skeleton-loader>

<app-spacer [bottom]="32"></app-spacer>

<ngx-skeleton-loader [theme]="{
    height: '50px'
}">
</ngx-skeleton-loader>