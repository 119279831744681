<div class="app-choices">
  <div *ngFor="let choice of choices" class="app-choice" [class.active]="value === choice.value"
    (click)="selectChoice(choice)">

    <div class="app-choice-icon-container">
      <div class="app-choice-icon-bg"></div>
      <div class="app-choice-icon">
        <i *ngIf="choice.icon.includes('fa-');else svg" [class]="choice.icon"></i>

        <ng-template #svg>
          <svg-icon [src]="'assets/images/' + choice.icon + '.svg'"></svg-icon>
        </ng-template>
      </div>
    </div>

    <app-spacer [right]="24"></app-spacer>


    <div class="app-choice-text">
      <div class="app-choice-text-title">
        <span>{{choice.title}}</span>
      </div>
      <app-spacer [bottom]="8"></app-spacer>
      <div class="app-choice-text-description">
        <span>{{choice.description}}</span>
      </div>
    </div>

  </div>
</div>
