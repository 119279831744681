<div class="content">

  <div class="logo"></div>
  <div class="code-error"></div>

  <div class="text-container">
      <h2>Manutenção programada</h2>
      <p>Gostaríamos de informar que a nossa aplicação passará por uma manutenção programada para aprimorar a sua experiência e garantir o seu bom funcionamento. Durante esse período, os recursos ficarão temporariamente indisponíveis.</p>
  </div>
</div>
