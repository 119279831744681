<div class="dashboard" [formGroup]="form">
    <div class="dashboard-card" style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <img class="security-icon" src="/assets/images/security-icon.png">
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <span class="security-title">Trocar senha</span>
            </div>

            <div class="col-12 col-lg-6" *ngIf="step == 0">
                <div class="row">

                    <div class="col-12">
                        <app-input [group]="form" controlName="current_password" type="password"
                            label="Infome a senha atual">
                        </app-input>

                        <app-spacer [bottom]="16"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-button [full]="true" [disabled]="form.controls.current_password.invalid"
                            (click)="validate()">Validar
                        </app-button>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6" *ngIf="step == 1">
                <div class="row">

                    <div class="col-12">
                        <app-radio [readOnly]="true" [isChecked]="passwordStrength().includes(1)">Mínimo de 8 caracteres
                        </app-radio>
                        <app-spacer [bottom]="14"></app-spacer>
                    </div>
                    <div class="col-12">
                        <app-radio [readOnly]="true" [isChecked]="passwordStrength().includes(2)">Mínimo de 1 número
                        </app-radio>
                        <app-spacer [bottom]="14"></app-spacer>
                    </div>
                    <div class="col-12">
                        <app-radio [readOnly]="true" [isChecked]="passwordStrength().includes(3)">Letras maiúsculas e
                            minúsculas</app-radio>
                        <app-spacer [bottom]="14"></app-spacer>
                    </div>
                    <div class="col-12">
                        <app-radio [readOnly]="true" [isChecked]="passwordStrength().includes(4)">Caracteres especiais
                        </app-radio>
                        <app-spacer [bottom]="21"></app-spacer>
                    </div>

                    <div class="col-12">
                        <p class="password-description-text">Para reforçar a segurança da sua senha, não utilize
                            informações
                            pessoais como datas de nascimentos ou números de telefones e nem números sequenciais.</p>
                        <app-spacer [bottom]="32"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-input type="password" label="Defina uma senha de acesso" [group]="form"
                            controlName="password" placeholder="Digite sua senha">
                        </app-input>
                        <div class="password-meter">
                            <p class="password-meter-text">{{passwordStrengthText()}}</p>

                            <div class="password-meter-bars">
                                <div class="password-meter-bar" [class.active]="passwordStrengthPercentage() > 30">
                                </div>
                                <div class="password-meter-bar" [class.active]="passwordStrengthPercentage() > 60">
                                </div>
                                <div class="password-meter-bar" [class.active]="passwordStrengthPercentage() > 90">
                                </div>
                            </div>
                        </div>

                        <app-spacer [bottom]="32"></app-spacer>
                    </div>

                    <div class="col-12">
                        <app-button [full]="true" [disabled]="form.invalid">Atualizar senha</app-button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
