<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <ng-container *ngIf="loading">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="list-container"
                    *ngIf="data.length >0;else noResult">
                    <app-table-list [columns]="columns" [data]="data"
                        size="16px"></app-table-list>
                </div>
                <ng-template #noResult>
                    <span
                        class="dash-card-item-content-title text-center d-block pb-4 mb-0">Nenhum
                        cedente
                        cadastrado</span>
                </ng-template>
            </ng-container>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>