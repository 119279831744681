<div class="button-container" [class.outline]="outline">
    <button class="left-button" type="button" (click)="click($event)"
        [disabled]="disabled">
        <div class="icon" *ngIf="haveIcon">
            <i class="fas fa-plus-circle"></i>
        </div>
        <span class="app-button-label">{{label}}</span>
    </button>
    <button class="right-button" type="button"
        (click)="handleShowOptions($event)" [class.active]="showOptions">
        <div>
            <i class="fas fa-sort-down"></i>
        </div>
    </button>
    <div class="dropdown" *ngIf="showOptions">
        <ng-content></ng-content>
    </div>
</div>