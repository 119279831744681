<div class="row no-gutters">

    <div class="col-10 offset-1 col-lg-8">
        <span class="basic-content-title">
            Para continuar o cadastro da sua empresa, veja se:
        </span>

        <app-spacer [bottom]="32"></app-spacer>

        <div *ngFor="let requirement of requirements">
            <div class="requirement">
                <div class="requirement-check">
                    <i class="fas fa-check"></i>
                </div>
                <app-spacer [right]="16"></app-spacer>
                <span class="requirement-text" [innerHtml]="requirement"></span>
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </div>

        <app-spacer [bottom]="48"></app-spacer>

        <span class="section-title">Documentos necessários</span>

        <app-spacer [bottom]="32"></app-spacer>

        <div *ngFor="let document of documents">
            <div class="document">
                <div class="document-indicator"></div>
                <app-spacer [right]="16"></app-spacer>
                <span class="document-text" [innerHtml]="document"></span>
            </div>

            <app-spacer [bottom]="16"></app-spacer>
        </div>
    </div>
</div>