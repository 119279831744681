<div class="side-modal" [class.active]="active">
    <div class="side-modal-background"></div>

    <div class="side-modal-container styled-scroll" [style.paddingBottom.px]="paddingBottom">
        <ng-container *ngIf="title !== ''">
            <div class="side-modal-header">
                <h2>
                    {{ title }}
                </h2>

                <button class="side-modal-close" (click)="close()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="title === ''">
            <div class="close-button-container">
                <button class="side-modal-close" (click)="close()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </ng-container>

        <div class="side-modal-body" [style.padding.px]="padding">
            <ng-content>

            </ng-content>
        </div>

    </div>
</div>