import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-approvation-steps',
  templateUrl: './approvation-steps.component.html',
  styleUrls: ['./approvation-steps.component.scss'],
})
export class ApprovationStepsComponent implements OnInit {
  @Input() approval: UserApprovalResponse;

  constructor() {}

  ngOnInit(): void {}

  isCurrentDepartment(item: UserApprovalQueueDepartment, index: number) {
    if (this.approval.completed) {
      return true;
    }

    const is = this.approval.step >= item.order;

    return is;
  }

  getDepartmentCompleted(item: UserApprovalQueueDepartment) {
    if (this.approval.completed) {
      return true;
    }

    const attemptSigns = this.approval.signatures
      .filter(
        (signature) =>
          signature.attempt === this.approval.attempt &&
          signature.department_approval.id === item.id
      )
      .map((signature) => signature.department_approval);

    if (attemptSigns.length >= item.sign_quantity) {
      return true;
    }

    return false;
  }

  getDepartmentSigns(department: UserApprovalQueueDepartment) {
    return this.approval.signatures.filter(
      (signature) =>
        signature.department_approval.id === department.id &&
        signature.attempt === this.approval.attempt
    );
  }

  getAgentName(agent) {
    if (Array.isArray(agent)) {
      return agent.map((_) => _.name).join(', ');
    } else {
      return agent.name;
    }
  }

  getRegisterName(register) {
    return register.person
      ? register.person.full_name
      : register.company.corporate_name;
  }

  formatDateTime(date: string) {
    const newDate = new Date(date);
    const newHour = newDate.getHours() + 3;

    newDate.setHours(newHour);

    return newDate.toLocaleString();
  }
}
